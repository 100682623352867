import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const FormContainer = styled.form`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
  gap: 4rem;
`;

export const FieldsContainer = styled.div`
  max-width: 100%;
  width: 30rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.5rem;

  > div:nth-child(2) {
    height: 15rem;

    > div:last-of-type > div {
      height: 13.35rem;
    }
  }
`;

export const IconContainer = styled.div`
  width: 8.25rem;
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 0.5rem;

  color: ${({ theme }) => theme.colors.gray5};
`;

export const IconButton = styled.button`
  width: 8.25rem;
  height: 8.25rem;

  border-radius: 8px;
  border: ${({ theme }) => `2px solid ${theme.colors.gray2}`};
  background-color: ${({ theme }) => theme.colors.gray3};

  transition: all 0.2s;

  img {
    width: 100%;
    height: 100%;

    border-radius: 8px;

    &:hover {
      border-radius: 7px;
    }
  }

  div {
    width: 100%;
    height: 100%;

    border-radius: 7px;
  }

  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.gray3};
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

export const PlanningFlowContainer = styled.div`
  width: 100%;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  > div:first-of-type {
    justify-content: space-between;
  }

  div + div {
    margin-top: 0.5rem;
  }

  button {
    width: 100%;
  }

  button + button {
    margin-left: 1rem;
  }

  svg {
    width: 1.25rem;
    height: 1.25rem;

    color: ${({ theme }) => theme.colors.gray4};

    transition: all 0.2s;
  }
`;

type PlanningFlowButtonProps = {
  selected: boolean;
};

export const PlanningFlowButton = styled.button<PlanningFlowButtonProps>`
  height: fit-content;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0.75rem 1rem;
  border: 1px solid ${({ theme }) => theme.colors.gray2};
  border-radius: 5px;

  font-size: 0.875rem;
  font-weight: ${({ selected }) => (selected ? '600' : '400')};
  color: ${({ theme }) => theme.colors.gray4};

  transition: all 0.2s ease-in;

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  ${({ selected }) =>
    selected
      ? css`
          border-color: transparent;
          background: rgba(76, 148, 255, 0.12);

          color: ${({ theme }) => theme.colors.primary};
        `
      : css`
          &:not(:disabled):hover {
            border-color: ${({ theme }) => theme.colors.gray3};
            color: ${({ theme }) => theme.colors.gray5};
          }
        `}
`;
