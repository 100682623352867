const MyProjectsENTranslations = {
  projectsHeadTitle: 'Models',
  myProjectsTitleHeader: 'My Projects',
  createProject: 'Create a new Project',
  createProjSubTitle: 'Bring your data to create a new project',
  createProjectButton: 'Create project',
  myWorks: 'My Works',
  myProjects: 'My Projects',
  myWorksSubtitle: 'List of all works created and shared',
  projectHeadDependVar: 'Dependent variable',
  projectHeadLastUpdated: 'Last updated',
  searchProject: 'Search project',
  paginationText: 'projects',
  projectTooltip: 'variables',
  fetchProjectsError: 'Unable to load projects',
  fetchWorkspacesError: 'Unable to load workspaces',

  searchProjectFail: `We couldn't find any project containing`,
  downloadError: `There was an error downloading file.`,
  myProjectErrorNoProjectFound: 'No projects found, start by creating one.',
  workspaceErrorNoWorkspaceFound: 'No workspaces found, start by creating one.',
  myProjectDoNotHasPermissionToCreateProject:
    'Sorry, you only have access to demand forecast projects. To able to create projects contact our seller team.',
  myProjectsDownloadProject: 'Downloading...',
  myProjectsDownloadUpdate: '(latest update)',

  myProjectsCreateWorkspace: 'Create workspace',
  myProjectsSearchWorkspace: 'Search workspace',
  myProjectsDoNotHasPermissionToCreateWorkspace:
    'Sorry, you only have access to demand forecast workspaces. To create workspaces, please contact our sales team.',
  myProjectsWorkspaceControl: 'Control panel',

  workspaceCreateNewProjectToStart:
    'Create a new project to start exploring workspace creation.',
};

export default MyProjectsENTranslations;
