import styled from 'styled-components';

export const Content = styled.div`
  width: 30rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  padding: 0.5rem;

  h3 {
    text-align: center;

    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2rem;

    color: ${({ theme }) => theme.colors.gray6};
  }

  p {
    text-align: center;

    font-size: 1rem;
    font-weight: 400;
    line-height: 150%;

    color: ${({ theme }) => theme.colors.gray5};
  }

  h3 + p {
    margin-top: 1rem;
  }
`;
