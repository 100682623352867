export const SelectProjectsWorkspaceENUSTranslations = {
  selectProjectsWorkspaceTitle: 'Select the projects',
  selectProjectsWorkspaceDescription:
    'Click to select and confirm when finished.',
  selectProjectWorkspaceIsDisabledDifferentFrequency:
    'You can only choose projects with the same time series frequency as those already selected.',
  selectProjectWorkspaceIsDisabledDifferentFrequencyOfWorkspace:
    'You can only choose projects with the same time series frequency as your workspace.',
  selectProjectWorkspaceIsDisabledApprovalFlow:
    'You have enabled the planning flow, so only projects with a monthly time series frequency will be allowed.',
};
