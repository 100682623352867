import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 3rem;

  .search-icon {
    width: 1rem;
    height: 1rem;

    color: ${({ theme }) => theme.colors.gray4};

    margin: 0;
  }

  .label {
    width: 100%;

    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.375rem;

    color: ${({ theme }) => theme.colors.gray5};
  }

  .button-caret-down {
    width: 2.25rem;
    height: 2.25rem;

    display: flex;
    justify-content: center;
    align-items: center;

    background: ${({ theme }) => `${theme.colors.primary}1F`};
    border-radius: 100%;

    margin: 1.75rem 0;

    padding: 0;

    svg {
      width: 1.75rem;
      height: 1.75rem;

      color: ${({ theme }) => theme.colors.primary};

      margin: 0;

      transition: all 0.2s;
    }

    &:hover {
      background: ${({ theme }) => `${theme.colors.primary}26`};

      svg {
        color: ${({ theme }) => theme.colors.primaryDark};
      }
    }

    &:disabled {
      background: ${({ theme }) => `${theme.colors.gray4}1F`};

      svg {
        color: ${({ theme }) => theme.colors.gray4};
      }
    }
  }

  .filter-variables-max {
    height: calc(13rem + 4.313rem);
  }
  .filter-variables-min {
    height: 13rem;
  }
`;

export const FilterContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const FilterActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  gap: 2.5rem;

  > div:first-of-type > div {
    p {
      display: none;
    }

    > div {
      height: fit-content;

      padding: 0 !important;
      border: 0;
    }

    input {
      font-size: 1.125rem !important;
      font-weight: 600 !important;
      line-height: 1.375rem !important;

      color: ${({ theme }) => theme.colors.gray6} !important;

      border-bottom: 1px solid ${({ theme }) => theme.colors.white};

      cursor: text;

      &:focus {
        border-bottom: 1px solid ${({ theme }) => theme.colors.gray2};
      }
    }
  }
`;

export const InputErrorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  width: 37rem;

  > div:first-of-type {
    max-width: 34rem;
  }
`;

export const ButtonsContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  button {
    height: fit-content;
    padding: 0;

    svg {
      width: 1.25rem;
      height: 1.25rem;

      margin: 0;
    }
  }
`;

export const FilterOptionsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const FilterOptionsContent = styled.div`
  width: 100%;
  height: 13.75rem;

  overflow-y: auto;

  border: 1px solid ${({ theme }) => theme.colors.gray2};
  border-radius: 8px 8px 0 0;
`;

export const FilterOption = styled.div`
  display: flex;
  flex-direction: column;

  border-bottom: 1px solid ${({ theme }) => theme.colors.gray2};

  .empty-option-name {
    label {
      p {
        color: ${({ theme }) => theme.colors.gray4} !important;
      }
    }
  }
`;

export const FilterOptionInfo = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 0.75rem;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  input {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.375rem;

    color: ${({ theme }) => theme.colors.gray6};

    border-bottom: 1px solid ${({ theme }) => theme.colors.white};

    cursor: text;

    &:focus {
      border-bottom: 1px solid ${({ theme }) => theme.colors.gray2};
    }
  }

  > div:first-of-type > div {
    > div {
      p {
        display: none;
      }

      > div {
        height: fit-content;

        padding: 0 !important;
        border: 0;
      }
    }
  }
`;

export const VariableQtty = styled.span`
  width: 2.25rem;
  text-align: center;

  color: ${({ theme }) => theme.colors.secondary};

  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.375rem;

  background: ${({ theme }) => `${theme.colors.secondary}1F`};
  border-radius: 8px;

  padding: 0.25rem 0;

  margin-right: 0.5rem;
`;

export const CreateFilterOptionContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 0.5rem 0.75rem;

  margin-top: -0.5rem;

  background: ${({ theme }) => `${theme.colors.gray5}1F`};
  border: 1px solid ${({ theme }) => theme.colors.gray2};
  border-top: 0;
  border-radius: 0 0 8px 8px;

  > div > div {
    height: fit-content;

    padding: 0 !important;
    border: none;

    &:hover {
      border: none;
    }
  }

  input {
    width: 20rem;

    background: transparent;

    cursor: pointer;

    &:focus {
      cursor: text;
    }

    ::placeholder {
      color: ${({ theme }) => theme.colors.gray6};

      font-size: 0.875rem;
      font-weight: 500;
    }
  }

  button {
    height: fit-content;
    padding: 0;
  }

  .option-placeholder {
    ::placeholder {
      color: ${({ theme }) => theme.colors.gray4} !important;
    }
  }
`;

export const VariableAndOptionContainer = styled.div`
  width: 100%;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;

    > div {
      flex: 1;
    }
  }

  h2 {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.6875rem;

    color: ${({ theme }) => theme.colors.gray6};
  }

  > p {
    color: ${({ theme }) => theme.colors.gray5};
    font-weight: 400;
    font-size: 1rem;

    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
  }
`;

export const VariableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 0.75rem;

  > div {
    width: 100%;
  }

  > div:last-of-type {
    border-radius: 0 0 8px 8px;
  }

  .search-icon {
    margin-left: 0.5rem;
  }
`;

export const VariableContent = styled.div`
  width: 100%;
  height: 15.2rem;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: start;
  gap: 1rem;

  overflow-y: auto;

  padding: 1rem;

  border: 1px solid ${({ theme }) => theme.colors.gray2};
  border-radius: 8px;
`;

export const OptionContainer = styled.div`
  height: 20.699rem;

  display: flex;
  flex-direction: column;

  overflow-y: auto;

  border: 1px solid ${({ theme }) => theme.colors.gray2};
  border-radius: 8px;
`;

export const OptionVariables = styled.div`
  height: 9rem;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: start;
  gap: 1rem;

  overflow-y: auto;

  margin: 0.75rem;
  margin-top: 0;
  padding: 1rem;

  border: 1px solid ${({ theme }) => theme.colors.gray2};
  border-radius: 8px;
`;

export const SelectAllContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  padding: 0.5rem 1.5rem;

  margin-bottom: -0.75rem;

  border: 1px solid ${({ theme }) => theme.colors.gray2};
  border-bottom: 0;
  border-radius: 8px 8px 0 0;

  span {
    border-width: 1px;
  }
`;

export const FormError = styled.p`
  color: ${({ theme }) => theme.colors.red2} !important;
  line-height: 1rem;
  font-style: normal;
  font-size: 0.875rem;
`;

export const NoOptionContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    font-size: 1rem;
    font-weight: 400;
    line-height: 150%;

    text-align: center;

    color: ${({ theme }) => theme.colors.gray5};

    margin-bottom: 1.5rem;
  }

  img {
    width: 10rem;
  }
`;
