import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .workspace-tooltip {
    border-radius: 8px !important;
    background: #fff !important;

    padding: 0.5rem 1rem !important;

    font-size: 0.875rem !important;
    font-weight: 500 !important;
    line-height: 1.375rem !important;
    color: ${({ theme }) => theme.colors.gray5} !important;
  }

  #workspace-table {
    max-width: unset !important;
  }

  .categorizationTooltip.categorizationTooltip {
    max-width: 30rem !important;
  }

  .checkboxInflation {
    p {
      color: ${({ theme }) => theme.colors.gray6} !important;
      font-weight: 400;
      font-size: 0.75rem;
      text-wrap: nowrap;

      text-transform: uppercase;
      letter-spacing: 0.08rem;
    }
  }
`;

export const StepsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  gap: 3rem;

  margin-bottom: 0 !important;
`;

type StepsItemProps = {
  selected: boolean;
};

export const StepsItem = styled.div<StepsItemProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;

  > div {
    background: ${({ theme, selected }) =>
      selected ? theme.colors.primary : theme.colors.gray3};
  }

  p {
    font-size: 1rem;
    font-weight: 500;
    line-height: 150%;
    color: ${({ theme, selected }) =>
      selected ? theme.colors.gray7 : theme.colors.gray4};
  }
`;

export const StepsNumber = styled.div`
  width: 2.25rem;
  height: 2.25rem;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 1rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.white};

  border-radius: 100%;
`;
