import React, { useContext, useEffect, useState } from 'react';

import { MapPin, Tag } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from 'src/components/Select';
import {
  setBreadcumbs,
  setCategories,
  setCategoriesData,
  setCountrySelected,
  setPage,
  setSearch,
} from 'src/feature-store/redux/reducers/IndicatorsPage';
import api from 'src/feature-store/service/api';
import { RootState } from 'src/redux/store';

import { FeatureStoreSidebarContext } from '../../../Contexts/NavigationContext';
import { BackToHome } from '../../BackToHome';
import { IndicatorsGroupPremium } from '../IndicatorsGroupPremium';
import {
  BusinessButton,
  Container,
  FreeButton,
  IndicatorFilterButtonContainer,
  IndicatorFilterContainer,
  IndicatorFilterHead,
  SelectCountryFilter,
  Title,
} from './styles';
import { CategoriesProps } from './types';

export const CountryFilter: React.FC = () => {
  const { t: translate } = useTranslation();

  const dispatch = useDispatch();
  const { language, isFreemium } = useSelector(
    (state: RootState) => state.auth.user,
  );

  const { countrySelected } = useSelector(
    (state: RootState) => state.indicatorsPage,
  );

  const { setterIndicatorFilterType, indicatorFilterType } = useContext(
    FeatureStoreSidebarContext,
  );

  const [country, setCountry] = useState('');

  const { data: categoriesData, isLoading: isLoadingCategories } = useQuery(
    ['categories'],
    async () => {
      const { data } = await api.get<CategoriesProps>('/domains');

      return data.tree;
    },
  );

  function handleSelectCountry(categoryID: string) {
    const categoryInfo = categoriesData?.find(
      (category) => category.id === categoryID,
    );
    dispatch(setSearch(''));
    dispatch(setPage(1));

    categoryInfo && dispatch(setCategories(categoryInfo.children));

    categoryInfo &&
      dispatch(
        setBreadcumbs([
          {
            category_id: categoryInfo.id,
            category_node: categoryInfo.node,
            category_name: categoryInfo.name,
          },
        ]),
      );
    setCountry(categoryID);
    dispatch(setCountrySelected(categoryID));
  }

  useEffect(() => {
    dispatch(setCategoriesData(categoriesData));

    const initialCategory = categoriesData?.find(
      (category) => category.name['en-us'] === 'Brazil',
    );

    !country && setCountry(initialCategory?.id ?? '');
  }, [categoriesData, country, dispatch]);

  return (
    <Container>
      <BackToHome style={{ marginBottom: '-1rem' }} />

      <Title>
        <h5>{translate('categoriesSidebarFilters')}</h5>
      </Title>

      {isFreemium && (
        <IndicatorFilterContainer data-testid="indicator-filter-container">
          <IndicatorFilterHead>
            <Tag size="1rem" className="mappin" />
            <h3>{translate('categoriesSidebarIndicatorType')}</h3>
          </IndicatorFilterHead>

          <IndicatorFilterButtonContainer>
            <FreeButton
              data-testid="free-button-indicator-filter"
              onClick={() => {
                dispatch(setPage(1));
                setterIndicatorFilterType(
                  indicatorFilterType === 'free' ? null : 'free',
                );
              }}
              isActive={indicatorFilterType === 'free'}
            >
              Free
            </FreeButton>
            <BusinessButton
              data-testid="business-button-indicator-filter"
              onClick={() => {
                dispatch(setPage(1));
                setterIndicatorFilterType(
                  indicatorFilterType === 'default' ? null : 'default',
                );
              }}
              isActive={indicatorFilterType === 'default'}
            >
              Business
            </BusinessButton>
          </IndicatorFilterButtonContainer>
        </IndicatorFilterContainer>
      )}

      <SelectCountryFilter>
        <div>
          <MapPin size="1rem" className="mappin" />
          <h3>{translate('categoriesSidebarCountries')}</h3>
        </div>
        <Select
          options={categoriesData?.map((category) => ({
            label: category.name?.[language] ?? category.name?.['en-us'],
            value: category.id,
          }))}
          value={
            categoriesData
              ? {
                  label:
                    countrySelected === null
                      ? categoriesData?.find(
                          (category) => category.id === country,
                        )?.name?.[language] ?? ''
                      : categoriesData?.find(
                          (category) => category.id === countrySelected,
                        )?.name?.[language] ?? '',
                  value:
                    categoriesData?.find((category) => category.id === country)
                      ?.id ?? '',
                }
              : undefined
          }
          onChange={(option: any) => handleSelectCountry(option.value)}
          placeholder={translate('categoriesSidebarSelectCountries')}
          style={{ width: '100%' }}
          isDisabled={isLoadingCategories}
        />
      </SelectCountryFilter>

      <IndicatorsGroupPremium />
    </Container>
  );
};
