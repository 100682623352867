import React from 'react';

import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/Button';
import { Modal } from 'src/components/Modal';
import { ModalFooter } from 'src/components/Modal/Footer/styles';
import { useNavigate } from 'react-router-dom';
import { Status } from 'src/components/Status';
import errorImg from 'src/assets/error.svg';
import { queryClient } from 'src/service/queryClient';
import apiWorkspace from 'src/models/service/apiWorkspace';

import { CategorizationErrorModalProps } from './types';
import { Content, YsContainer } from './styles';

export const CategorizationErrorModal: React.FC<
  CategorizationErrorModalProps
> = ({ setVisible, workspaceId, title, description, variables, isWarning }) => {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();

  const handleControlPanel = async () => {
    try {
      await apiWorkspace.delete(`/workspaces/${workspaceId}/edit`);
      // eslint-disable-next-line no-empty
    } catch {}

    queryClient.removeQueries([
      'workspace staging area',
      workspaceId,
      'projects',
    ]);
    queryClient.removeQueries(['workspace staging area', workspaceId]);
    queryClient.removeQueries([
      'workspace staging area hierarchies',
      workspaceId,
    ]);
    queryClient.removeQueries(['workspace data', workspaceId]);

    navigate(`/models/workspaces/${workspaceId}/control-panel`);
  };

  return (
    <Modal
      visible
      setVisible={setVisible}
      style={{ width: '40rem' }}
      dataCy="modal-categorization-error"
    >
      <Content>
        <Status
          type={isWarning ? 'warning' : 'other'}
          img={errorImg}
          dataCy="error-content"
          isModal
          title={title}
          description={description}
        />

        {!!variables && (
          <YsContainer data-testid="container-variables">
            <p>{variables}</p>
          </YsContainer>
        )}
      </Content>

      <ModalFooter>
        <Button
          buttonType="naked"
          onClick={handleControlPanel}
          data-testid="button-control-panel"
        >
          {translate('proceedAnyway')}
        </Button>

        <Button
          buttonType="primary"
          onClick={() => setVisible(false)}
          data-testid="button-edit-categorization"
        >
          {translate('editCategorization')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
