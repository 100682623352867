import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'src/components/Button';
import apiWorkspace from 'src/models/service/apiWorkspace';
import { RootState } from 'src/redux/store';
import { sleep } from 'src/utils/sleep';
import { queryClient } from 'src/service/queryClient';
import { FailedModal } from 'src/components/Modal/Failed';
import { useTranslation } from 'react-i18next';
import { translateSomeMessagesFromBackend } from 'src/i18n';
import { updateCanSyncAdjust } from 'src/models/redux/reducers/Workspace';

type TaskStatus = 'success' | 'error' | 'created' | 'processing';

interface ResponseCreateTask {
  id: string;
  status: TaskStatus;
}

interface ResponseCheckStatusTask {
  id: string;
  status: TaskStatus;
}

export const ButtonSyncAdjust: React.FC = () => {
  const [taskId, setTaskId] = useState<string | null>(null);
  const [taskError, setTaskError] = useState<string | null>(null);

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const {
    workspace: { id, releaseSelected, ySelected, canSyncAdjust },
    auth: { user },
  } = useSelector((state: RootState) => state);

  useEffect(() => {
    (async () => {
      if (id && releaseSelected) {
        while (taskId) {
          try {
            const { data } = await apiWorkspace.get<ResponseCheckStatusTask>(
              `/workspaces/${id}/releases/${releaseSelected.id}/tasks/${taskId}`,
            );
            if (data.status === 'success') {
              setTaskId(null);
              queryClient.invalidateQueries('workspace variable performance');
              queryClient.invalidateQueries('workspace performance by period');
              break;
            }
            await sleep(3000);
          } catch {
            //@ts-expect-error: ignora
            const taskErrorMessage = e?.response?.data?.detail?.detail;
            if (taskErrorMessage) {
              setTaskError(taskErrorMessage);
            } else {
              setTaskError(t('unknownRequestErrorDescription'));
            }
            setTaskId(null);
          }
        }
      }
    })();
  }, [t, taskId, id, releaseSelected]);

  async function handleSyncInformation() {
    if (id && releaseSelected && ySelected) {
      try {
        const { data } = await apiWorkspace.post<ResponseCreateTask>(
          `/workspaces/${id}/releases/${releaseSelected.id}/ys/${ySelected.y_label}/performances/manual-adjust`,
        );

        if (data.status === 'created' || data.status === 'processing') {
          setTaskId(data.id);
        }
      } catch (e) {
        //@ts-expect-error: ignora
        const taskErrorMessage = e?.response?.data?.detail?.detail;
        if (taskErrorMessage) {
          setTaskError(taskErrorMessage);
        } else {
          setTaskError(t('unknownRequestErrorDescription'));
        }
        dispatch(updateCanSyncAdjust(false));
      } finally {
        dispatch(updateCanSyncAdjust(false));
      }
    }
  }

  const messageError = taskError
    ? translateSomeMessagesFromBackend(taskError, user.language)
    : null;

  return (
    <>
      <Button
        buttonType="primary"
        onClick={handleSyncInformation}
        disabled={!canSyncAdjust}
        loading={!!taskId}
        data-testid="button-synchronize-information"
      >
        {t('buttonSyncAdjustText')}
      </Button>
      {!!messageError && (
        <FailedModal
          visible
          errorInfo={{
            title: t('unknownRequestErrorTitle'),
            description: messageError,
            cloudIcon: false,
          }}
          setVisible={() => setTaskError(null)}
        />
      )}
    </>
  );
};
