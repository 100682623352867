import React, { useContext, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from 'src/components/Card';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { Head } from 'src/components/Head';
import { Pagination } from 'src/components/Pagination';
import { IndicatorCard } from 'src/feature-store/components/IndicatorCard';
import { setPage } from 'src/feature-store/redux/reducers/GroupPremiumIndicator';
import api from 'src/feature-store/service/api';
import { RootState } from 'src/redux/store';

import { FeatureStoreSidebarContext } from '../../../Contexts/NavigationContext';
import { ListIndicatorContainer } from '../../Indicators/styles';
import { IndicatorsProps } from '../../Indicators/types';
import {
  Container,
  ContainerWithoutGroups,
  HeaderContainer,
  IconContainer,
  PremiumContent,
} from './styles';

const QUANTITY_ITEMS_PAGE = 8;

export const PremiumGroup: React.FC = () => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();

  const [premium, setPremium] = useState<IndicatorsProps>();

  const { language } = useSelector((state: RootState) => state.auth.user);
  const {
    page,
    iconUrl,
    groupPremiumDescription,
    groupPremiumName,
    groupPremiumId,
  } = useSelector((state: RootState) => state.groupPremium);

  const { openPremiumFilters, openIndicatorFilters } = useContext(
    FeatureStoreSidebarContext,
  );

  const {
    data: premiumIndicators,
    isFetching: isPremiumIndicatorsFetching,
    isLoading: isPremiumIndicatorsLoading,
    isError: isPremiumIndicatorsErrored,
  } = useQuery(
    ['indicators premium', page, groupPremiumId],
    async () => {
      if (groupPremiumId) {
        const { data } = await api.get<IndicatorsProps>(
          `/access-groups/${groupPremiumId}/indicators?skip=${
            (page - 1) * QUANTITY_ITEMS_PAGE
          }&limit=${QUANTITY_ITEMS_PAGE}`,
        );

        return data;
      }
      const { data } = await api.get<IndicatorsProps>(
        `/indicators?skip=${
          (page - 1) * QUANTITY_ITEMS_PAGE
        }&limit=${QUANTITY_ITEMS_PAGE}&access_type=premium`,
      );
      return data;
    },
    {
      staleTime: 1000 * 60,
    },
  );

  useEffect(() => {
    openPremiumFilters(true);
    openIndicatorFilters(false);
    if (premiumIndicators) {
      setPremium(premiumIndicators);
    }
  }, [openIndicatorFilters, openPremiumFilters, premiumIndicators]);

  function handleChangePages(selectedPage: number) {
    dispatch(setPage(selectedPage));
  }

  return (
    <Container>
      <Head title={translate('groupPremiumTitle')} />

      <PremiumContent className="containerLinear">
        <HeaderContainer>
          <IconContainer data-testid="icon-access-group">
            <img
              src={
                iconUrl !== ''
                  ? iconUrl
                  : 'https://storage.googleapis.com/bkt-prod-4casthub/icons/2f9f5ebb-0ac6-461d-b816-49e33149b5b8.png'
              }
              alt="icon group"
            />
          </IconContainer>
          <Card
            style={{ marginBottom: '0' }}
            textCard={
              groupPremiumName[language]?.length > 0
                ? groupPremiumName[language]
                : translate('groupPremiumTitle')
            }
            textDescription={
              groupPremiumDescription[language]?.length > 0
                ? groupPremiumDescription[language]
                : translate('groupPremiumDescription')
            }
          />
        </HeaderContainer>
        <>
          {isPremiumIndicatorsErrored ? (
            <ContainerMaintenance
              content="indicator"
              text={translate('indicatorsUnableToLoadIndicators')}
            />
          ) : isPremiumIndicatorsFetching || isPremiumIndicatorsLoading ? (
            <>
              <ListIndicatorContainer>
                {Array.from({ length: QUANTITY_ITEMS_PAGE - 1 }).map(
                  (_, index) => (
                    <IndicatorCard
                      key={`indicator-loading${index.toString()}`}
                      loading
                    />
                  ),
                )}
              </ListIndicatorContainer>

              {!!premium?.total && (
                <Pagination
                  total={premium?.total}
                  page={page}
                  setPage={handleChangePages}
                  quantityItemsPerPage={QUANTITY_ITEMS_PAGE}
                  name={translate('indicatorsPaginationName')}
                />
              )}
            </>
          ) : premium && premium?.total > 0 ? (
            <>
              <ListIndicatorContainer>
                {premium?.data?.map((indicator) => (
                  <IndicatorCard
                    key={`indicator-${indicator.name['en-us']}`}
                    data-testid={`card-indicator-${indicator?.name['en-us']
                      .replaceAll(' ', '-')
                      .toLocaleLowerCase()}`}
                    indicator={indicator}
                    isPremium
                  />
                ))}
              </ListIndicatorContainer>

              {premium?.total && (
                <Pagination
                  total={premium?.total}
                  page={page}
                  setPage={handleChangePages}
                  quantityItemsPerPage={QUANTITY_ITEMS_PAGE}
                  name={translate('indicatorsPaginationName')}
                />
              )}
            </>
          ) : (
            !isPremiumIndicatorsLoading &&
            premium &&
            premium?.total === 0 && (
              <ContainerWithoutGroups>
                <ContainerMaintenance
                  content="group"
                  text={translate('groupPremiumError')}
                />
              </ContainerWithoutGroups>
            )
          )}
        </>
      </PremiumContent>
    </Container>
  );
};
