const homePTBRTranslations = {
  home: 'Página Inicial',
  our_products: 'Nossos Produtos',
  logout: 'SAIR',
  access: 'ACESSAR',
  homeModelsDescription:
    'Construa modelos preditivos em escala, obtenha projeções assertivas e mapeie riscos para tomar melhores decisões através da nossa plataforma.',
  homeFSDescription:
    'Acesse nossa rica base de dados, com milhares de séries atualizadas e com projeções, prontas para alimentar seus modelos.',
};

export default homePTBRTranslations;
