import { createGlobalStyle } from 'styled-components';
import 'rc-slider/assets/index.css';
import 'react-datepicker/dist/react-datepicker.css';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    ::-webkit-scrollbar {
      width: 10px;
      height: 10px;
      padding: 1px;
    }
    ::-webkit-scrollbar-track {
      margin-top: 3px;
      margin-bottom: 3px;
      background:${({ theme }) => theme.colors.gray1};
      transition: 0.2s;

      border-radius: 5px;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background: ${({ theme }) => theme.colors.gray3};
    }
    ::-webkit-scrollbar-thumb:hover {
      background: ${({ theme }) => theme.colors.gray4};
    }
  }

  html, body, #root {
    width: 100%;
    height: 100%;
  }

  html {
	  @media (max-width: 1080px) {
	    font-size: 93.75%;  // 15px
	  }
	
	  @media (max-width: 720px) {
	    font-size: 87.5%;   // 14px
	  }
  }

  body, button, input, textarea {
    font: 400 1rem 'Inter', sans-serif;
  }

  ul {
    list-style: none;
  }

  body {
    -webkit-font-smoothing: antialiased;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    input[type=number] {
      -moz-appearance: textfiel;
    }
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  button {
    cursor: pointer;
    border: none;
    background: none;
  }

  :root {
    background: ${({ theme }) => theme.colors.gray0};
    
    .side-navigation-panel-select-option-text {
      font-weight: 1000;
    }

    .side-navigation-panel-select-inner-option {
      font-weight: 500;
    }
    
    .containerLinear {
      position: relative;
      width: 100%;
      border: ${({ theme }) => `1px solid ${theme.colors.gray2}`};
      border-radius: 8px;
      background: ${({ theme }) => theme.colors.white};
      padding: 1.5rem;
      margin-bottom: 1.5rem;
    }

    .customTooltipTheme {
      background: ${({ theme }) => theme.colors.white} !important;
      color: ${({ theme }) => theme.colors.gray6} !important;
      font-weight: 400 !important;   
      max-width: 18.75rem !important;
      border: 1px solid ${({ theme }) => theme.colors.primary} !important;
      border-radius: 8px !important;
      z-index: 9000 !important;   
      opacity: 1 !important;

      > span {
        font-size: 0.875rem !important;
        font-weight: 400 !important; 
        margin: 0 !important;  
      }
    }

    .customTooltipThemeMaxWidth50 {
      max-width: 50rem !important;
    }

    .customTooltipTheme.react-tooltip__place-left {
      > div {
        border-top: 1px solid ${({ theme }) => theme.colors.primary} !important;
        border-right: 1px solid ${({ theme }) =>
          theme.colors.primary} !important;

        top: 11.8px !important;
        right: -4px !important;
      }
    }

    .customTooltipTheme.react-tooltip__place-right {
      > div {
        border-bottom: 1px solid ${({ theme }) =>
          theme.colors.primary} !important;
        border-left: 1px solid ${({ theme }) =>
          theme.colors.primary} !important;

        top: 11.8px !important;
        left: -5px !important;
      }
    }

    .customTooltipTheme.react-tooltip__place-top {
      > div {
        border-bottom: 1px solid ${({ theme }) =>
          theme.colors.primary} !important;
        border-right: 1px solid ${({ theme }) =>
          theme.colors.primary} !important;

        bottom: -5.4px !important;
      }
    }

    .customTooltipTheme.react-tooltip__place-bottom {
      > div {
        border-top: 1px solid ${({ theme }) => theme.colors.primary} !important;
        border-left: 1px solid ${({ theme }) =>
          theme.colors.primary} !important;

        top: -5.4px !important;
      }
    }

    .customTooltipWhite {
        background: ${({ theme }) => theme.colors.white} !important;
        color: ${({ theme }) => theme.colors.gray5} !important;
        font-size: 0.875rem !important;
        font-weight: 400 !important;
        border: 1px solid ${({ theme }) => theme.colors.gray1} !important;
        border-radius: 8px !important;
        box-shadow: 0px 2px 24px rgba(113, 128, 150, 0.08) !important;
        padding: 1rem !important;
        line-height: 150% !important;
        max-width: 18.75rem !important;
        text-align: center !important;

        z-index: 9000 !important;

        opacity: 1 !important;
    }

    .customTooltipWhite.react-tooltip__place-left {
      > div {
        border-top: 1px solid ${({ theme }) => theme.colors.gray1} !important;
        border-right: 1px solid ${({ theme }) => theme.colors.gray1} !important;

        top: 12.8px !important;
        right: -5px !important;
      }
    }

    .customTooltipWhite.react-tooltip__place-right {
      > div {
        border-bottom: 1px solid ${({ theme }) =>
          theme.colors.gray1} !important;
        border-left: 1px solid ${({ theme }) => theme.colors.gray1} !important;

        top: 12.8px !important;
        left: -5px !important;
      }
    }

    .customTooltipWhite.react-tooltip__place-top {
      > div {
        border-bottom: 1px solid ${({ theme }) =>
          theme.colors.gray1} !important;
        border-right: 1px solid ${({ theme }) => theme.colors.gray1} !important;

        bottom: -6.4px !important;
      }
    }

    .customTooltipWhite.react-tooltip__place-bottom {
      > div {
        border-top: 1px solid ${({ theme }) => theme.colors.gray1} !important;
        border-left: 1px solid ${({ theme }) => theme.colors.gray1} !important;

        top: -6.4px !important;
      }
    }

    .multi-line {
      text-align: left !important;
    }

    .rc-slider-tooltip-inner {
      background-color: ${({ theme }) => theme.colors.primary} !important;
    }

    .rc-slider-tooltip-arrow {
      border-top-color: ${({ theme }) => theme.colors.primary} !important;
    }

    .highcharts-tooltip {
      table th {
        padding-bottom: 2px;
        border-bottom: 1px solid ${({ theme }) => theme.colors.gray3};
      }

      td {
        padding-top: 4px;
      }
    }   

    .maxWidthUnsetImportant{
      max-width:unset !important ;
    }
  }
`;
