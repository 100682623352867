import styled, { css } from 'styled-components';

import workspaceButtonFontBackground from '../../../assets/workspace-button-font-background.png';

export const Container = styled.div`
  display: flex;

  flex-direction: column;
  position: relative;

  @media (min-width: 1140px) {
    justify-content: center !important;
    align-items: center;
  }

  section {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;

    margin-bottom: 1.5rem;

    > div {
      flex: 1 1 25rem;
      margin: 0 !important;
    }
  }

  > div:last-child,
  > section:last-child {
    margin-bottom: 0;
  }

  .containerLinear {
    position: relative;
    max-width: 1140px;

    margin-bottom: 24px !important;

    @media (min-width: 1140px) {
      min-width: 1140px;
    }
  }

  .customTooltipMyProjects {
    max-width: none !important;
  }
`;

interface ButtonIconProps {
  disabled: boolean;
}

export const ButtonIcon = styled.button<ButtonIconProps>`
  float: right;
  ${({ disabled }) =>
    !disabled
      ? css`
          color: ${({ theme }) => theme.colors.gray6};
          transition: 0.2s color;
          &:hover {
            color: ${({ theme }) => theme.colors.secondary};
          }
        `
      : css`
          color: ${({ theme }) => theme.colors.gray4};
          cursor: not-allowed;
        `};
`;

export const ContentButtons = styled.div`
  display: flex;
  gap: 1rem;

  position: absolute;

  @media (max-width: 53.125rem) {
    position: unset;
    margin-bottom: 1.5rem;
  }
`;

interface SelectOptionButtonProps {
  selected: boolean;
  isWorkspace?: boolean;
}

export const SelectOptionButton = styled.button<SelectOptionButtonProps>`
  height: 3.125rem;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.625rem;

  padding: 0.75rem 1rem;
  border: 1px solid ${({ theme }) => theme.colors.gray2};
  border-radius: 5px;

  font-size: 0.875rem;
  font-weight: ${({ selected }) => (selected ? '600' : '400')};
  color: ${({ theme }) => theme.colors.gray4};

  img {
    filter: brightness(0) saturate(100%) invert(75%) sepia(12%) saturate(416%)
      hue-rotate(174deg) brightness(91%) contrast(88%);

    width: 1.5rem;
    height: 1.5rem;
  }

  transition: color, border-color, background 0.2s ease-in;

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  ${({ selected, isWorkspace }) =>
    selected
      ? isWorkspace
        ? css`
            background: radial-gradient(
              100% 130% at 35% 100%,
              rgba(76, 148, 255, 0.15) 0%,
              rgba(242, 125, 180, 0.15) 80%,
              rgba(237, 137, 54, 0.1) 90%
            );
            border: 0;

            div {
              -webkit-background-clip: text;
              color: transparent;
              background-image: url(${workspaceButtonFontBackground});
              background-size: auto 1rem;
            }

            img {
              filter: unset;
            }
          `
        : css`
            border-width: 0;
            background: rgba(76, 148, 255, 0.12);

            color: ${({ theme }) => theme.colors.primary};
          `
      : css`
          &:not(:disabled):hover {
            border-color: ${({ theme }) => theme.colors.gray3};
            color: ${({ theme }) => theme.colors.gray5};

            img {
              filter: brightness(0) saturate(100%) invert(80%) sepia(6%)
                saturate(775%) hue-rotate(174deg) brightness(77%) contrast(86%) !important;
            }
          }
        `}
`;
