import React, { useEffect, useState } from 'react';

import {
  ArrowLeft,
  MagnifyingGlass,
  Plus,
  Trash,
  PencilSimple,
} from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'src/components/Button';
import { Card } from 'src/components/Card';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { Head } from 'src/components/Head';
import { Input } from 'src/components/Input';
import { FailedModal } from 'src/components/Modal/Failed';
import { Pagination } from 'src/components/Pagination';
import { Table, Tbody, Td, Th, Thead, Tr } from 'src/components/Table';
import api from 'src/feature-store/service/api';
import NotFound from 'src/pages/404';
import { RootState } from 'src/redux/store';

import { ButtonIcon } from '../styles';
import { CreateSeries } from './CreateSeries';
import { ModalDeleteSerie } from './ModalDeleteSerie';
import { ModalEditSerie } from './modalEditSerie';
import {
  CardFeatures,
  Container,
  ContainerTable,
  Content,
  ContentSearchAndButtonCreate,
} from './styles';
import {
  DataSeriesIndicatorsInfo,
  IndicatorsProps,
  ISerieToEdit,
} from './types';

type ParamsType = {
  id: string;
};

const QUANTITY_ITEMS_PAGE = 10;

export const IndicatorSeries: React.FC = () => {
  const [modalCreateSeries, setModalCreateSeries] = useState<boolean>(false);
  const [failedModalVisible, setFailedModalVisible] = useState<boolean>();
  const [serieToDelete, setSerieToDelete] = useState<string | null>(null);
  const [serieToEdit, setSerieToEdit] = useState<ISerieToEdit | null>(null);
  const [pages, setPages] = useState<number>(1);
  const { id } = useParams<ParamsType>();
  const navigate = useNavigate();

  const { language } = useSelector((state: RootState) => state.auth.user);
  const { t: translate } = useTranslation();
  const { data: indicatorInfo, isError: isErrorInfo } = useQuery(
    ['indicator info', id, pages],
    async () => {
      const { data } = await api.get<DataSeriesIndicatorsInfo>(
        `/indicators/${id}/`,
      );

      return data;
    },
  );

  const {
    data: indicator,
    isLoading,
    isFetching,
    isError,
  } = useQuery(['indicator data', id, pages], async () => {
    const { data } = await api.get<IndicatorsProps>(
      `/indicators/${id}/series?sort_by=code&skip=${
        (pages - 1) * QUANTITY_ITEMS_PAGE
      }&limit=${QUANTITY_ITEMS_PAGE}`,
    );

    return data;
  });

  useEffect(() => {
    setPages(1);
  }, [setPages]);

  return (
    <Container>
      <Head title="Feature Store Admin Series" />
      {isError && indicatorInfo ? (
        <>
          <Content className="containerLinear">
            <Content>
              <CardFeatures>
                <ArrowLeft
                  size="2rem"
                  onClick={() => navigate('/feature-store/admin')}
                  data-cy="button-back"
                  data-testid="button-back"
                />

                <div className="cardDescription">
                  <Card
                    textCard={
                      indicatorInfo?.name[language] ??
                      indicatorInfo?.name['en-us']
                    }
                  />
                </div>
              </CardFeatures>
            </Content>
          </Content>
          <Content className="containerLinear">
            <ContainerMaintenance
              content="table"
              text={translate('indicatorSeriesAddFirstSeries')}
              description={translate('indicatorSeriesAddFirstSeriesText')}
            />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                buttonType="primary"
                icon={<Plus size="1.125rem" />}
                data-testid="button-adicionar-nova-serie"
                data-cy="button-adicionar-nova-serie"
                onClick={() => setModalCreateSeries(true)}
                style={{ maxWidth: '15.625rem' }}
              >
                {translate('indicatorSeriesAddNewSeries')}
              </Button>
            </div>
          </Content>
        </>
      ) : isError && isErrorInfo ? (
        <>
          <Content className="containerLinear">
            <CardFeatures>
              <ArrowLeft
                size="2rem"
                onClick={() => navigate('/feature-store/admin')}
                data-cy="button-back"
                data-testid="button-back"
              />
            </CardFeatures>
          </Content>
          <Content className="containerLinear">
            <NotFound />
          </Content>
        </>
      ) : (
        <>
          {isLoading || isFetching || !indicator ? (
            <div
              className="containerLinear"
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <CardFeatures>
                <ArrowLeft
                  size="2rem"
                  onClick={() => navigate('/feature-store/admin')}
                  data-cy="button-back"
                  data-testid="button-back"
                />
                <ContainerSkeleton
                  withLoading={false}
                  style={{
                    height: '120px',
                  }}
                />
              </CardFeatures>
            </div>
          ) : (
            indicator && (
              <Content className="containerLinear">
                <CardFeatures>
                  <ArrowLeft
                    size="2rem"
                    onClick={() => navigate('/feature-store/admin')}
                    data-cy="button-back"
                    data-testid="button-back"
                  />

                  <div className="cardDescription">
                    <Card
                      textCard={
                        indicator.data[0]?.name[language] ??
                        indicator.data[0]?.name['en-us']
                      }
                      textDescription={translate('indicatorSeriesSubTitle')}
                    />
                  </div>
                </CardFeatures>
              </Content>
            )
          )}
          <Content className="containerLinear">
            <Card textCard="Series" />
            <ContentSearchAndButtonCreate>
              <Input
                placeholder={translate('indicatorSeriesSearchSeries')}
                icon={<MagnifyingGlass size="1rem" />}
                disabled
              />
              <Button
                buttonType="primary"
                icon={<Plus size="1.125rem" />}
                data-testid="button-adicionar-nova-serie"
                data-cy="button-adicionar-nova-serie"
                onClick={() => setModalCreateSeries(true)}
              >
                {translate('indicatorSeriesAddNewSeries')}
              </Button>
            </ContentSearchAndButtonCreate>
            <ContainerTable>
              <Table data-testid="table">
                <Thead>
                  <Tr>
                    <Th>{translate('indicatorSeriesSeriesCode')}</Th>
                    <Th>{translate('indicatorSeriesRegion')}</Th>
                    <Th>{translate('indicatorSeriesAggregation')}</Th>
                    <Th>{translate('indicatorSeriesPrimary')}</Th>
                    <Th>{translate('indicatorSeriesSecondary')}</Th>
                    <Th>{translate('indicatorSeriesUnit')}</Th>
                    <Th style={{ width: '100px' }}>Status</Th>
                    <Th />
                  </Tr>
                </Thead>
                <Tbody>
                  {isLoading || isFetching || !indicator
                    ? Array.from({ length: 10 }, (_, numberLoadingTr) => (
                        // eslint-disable-next-line react/jsx-indent
                        <Tr
                          key={`loading-tr-${numberLoadingTr + 1}`}
                          data-testid={`loading-tr-${numberLoadingTr + 1}`}
                        >
                          {Array.from({ length: 7 }, (__, numberLoadingTd) => (
                            // eslint-disable-next-line prettier/prettier
                            <Td
                              key={`loading-td-${numberLoadingTr + 1}-${
                                numberLoadingTd + 1
                              }`}
                              data-testid={`loading-td-${numberLoadingTr + 1}-${
                                numberLoadingTd + 1
                              }`}
                            >
                              <ContainerSkeleton
                                withLoading={false}
                                style={{
                                  height: '21px',
                                }}
                              />
                            </Td>
                          ))}
                          <Td>
                            <ContainerSkeleton
                              withLoading={false}
                              style={{
                                height: '20px',
                                width: '20px',
                                float: 'right',
                                marginLeft: '16px',
                              }}
                            />
                            <ContainerSkeleton
                              withLoading={false}
                              style={{
                                height: '20px',
                                width: '20px',
                                float: 'right',
                              }}
                            />
                          </Td>
                        </Tr>
                      ))
                    : indicator?.data.map((serie, index) => (
                        // eslint-disable-next-line react/jsx-indent
                        <Tr key={`serie-${index + 1}`}>
                          <Td data-testid={`serie-${serie.code}`}>
                            {serie?.code}
                          </Td>
                          <Td>
                            <p>
                              {serie?.region[language] ??
                                serie?.region['en-us']}
                            </p>
                          </Td>
                          <Td>
                            <p>
                              {serie?.aggregation[language] ??
                                serie?.aggregation['en-us']}
                            </p>
                          </Td>
                          <Td>
                            <p>
                              {serie?.primary_transformation[language] ??
                                serie?.primary_transformation['en-us']}
                            </p>
                          </Td>
                          <Td>
                            <p>
                              {serie?.second_transformation[language] ??
                                serie?.second_transformation['en-us']}
                            </p>
                          </Td>
                          <Td>
                            <p>
                              {serie?.unit[language] ?? serie?.unit['en-us']}
                            </p>
                          </Td>
                          <Td>
                            <p>
                              {serie?.status === 'active' &&
                              language === 'en-us'
                                ? 'active'
                                : serie?.status === 'active' &&
                                  language === 'pt-br'
                                ? 'ativo'
                                : serie?.status === 'maintenance' &&
                                  language === 'en-us'
                                ? 'maintenance'
                                : serie?.status === 'maintenance' &&
                                  language === 'pt-br'
                                ? 'manutenção'
                                : '-'}
                            </p>
                          </Td>
                          <Td>
                            <ButtonIcon
                              style={{
                                marginRight: '24px',
                                marginLeft: '16px',
                              }}
                              onClick={() => setSerieToDelete(serie.code)}
                              data-testid={`button-delete-${index}`}
                              data-cy={`button-delete-${index}`}
                            >
                              <Trash size="1.25rem" />
                            </ButtonIcon>
                            <ButtonIcon
                              onClick={() =>
                                setSerieToEdit({
                                  code: serie.code,
                                  status: serie.status,
                                  unitEN: serie.unit['en-us'],
                                  unitPT: serie.unit['pt-br'],
                                  access_type: serie.access_type,
                                })
                              }
                              data-testid={`button-maintenance-${index}`}
                              data-cy={`button-maintenance-${index}`}
                            >
                              <PencilSimple size="1.25rem" />
                            </ButtonIcon>
                          </Td>
                        </Tr>
                      ))}
                </Tbody>
              </Table>
            </ContainerTable>
            {indicator?.total && (
              <Pagination
                page={pages}
                setPage={setPages}
                total={indicator.total}
                quantityItemsPerPage={QUANTITY_ITEMS_PAGE}
                name={translate('indicatorSeries')}
              />
            )}
          </Content>
        </>
      )}
      {modalCreateSeries && indicatorInfo && (
        <CreateSeries
          visible={modalCreateSeries}
          setVisible={() => {
            setModalCreateSeries(false);
          }}
          indicator={indicatorInfo}
        />
      )}
      {serieToDelete && (
        <ModalDeleteSerie
          serieToDelete={serieToDelete}
          setSerieToDelete={setSerieToDelete}
        />
      )}
      {serieToEdit && (
        <ModalEditSerie
          indicatorAccessType={indicatorInfo?.access_type}
          serieToEdit={serieToEdit}
          setSerieToEdit={setSerieToEdit}
          setVisibleFailed={setFailedModalVisible}
        />
      )}
      {failedModalVisible && (
        <FailedModal
          visible={failedModalVisible}
          setVisible={setFailedModalVisible}
          errorInfo={{
            title: translate('editSerieFailedTitle'),
            description: translate('editSerieFailedText'),
          }}
        />
      )}
    </Container>
  );
};
