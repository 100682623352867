import React, { ReactElement, createContext } from 'react';

import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { useQuery } from 'react-query';
import { AxiosError } from 'axios';

import apiWorkspace from '../service/apiWorkspace';

interface Error {
  detail?: {
    description?: string;
  };
}

interface ListOfVariables {
  date: string;
  explanatories: string[];
  values: {
    label: {
      'en-us': string;
      'pt-br': string;
    };
    value: string;
  }[];
  y: string;
}

interface WorkspaceProjectionsContextType {
  listOfVariables: ListOfVariables | undefined;
  isLoadingListOfVariables: boolean;
  errorListOfVariables: AxiosError<Error> | null;
}

export const WorkspaceProjectionsContext = createContext(
  {} as WorkspaceProjectionsContextType,
);

export const WorkspaceProjectionsProvider: React.FC<{
  children: ReactElement;
}> = ({ children }) => {
  const { workspace } = useSelector((state: RootState) => state);

  const {
    data: listOfVariables,
    isLoading: isLoadingListOfVariables,
    error: errorListOfVariables,
  } = useQuery<ListOfVariables, AxiosError<Error>>(
    [
      'workspace',
      'projections',
      'explanatory-variables',
      'list-of-variables',
      workspace.id,
      workspace.releaseSelected?.id,
      workspace.ySelected?.y_label,
      workspace.ySelected?.model_id,
    ],
    async () => {
      const response = await apiWorkspace.get(
        `/workspaces/${workspace.id}/releases/${workspace.releaseSelected?.id}/ys/${workspace.ySelected?.y_label}/models/${workspace.ySelected?.model_id}/business/variables`,
      );
      return response.data;
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled:
        !!workspace.id &&
        !!workspace.releaseSelected?.id &&
        !!workspace.ySelected?.y_label &&
        !!workspace.ySelected?.model_id,
    },
  );

  return (
    <WorkspaceProjectionsContext.Provider
      value={{
        listOfVariables,
        isLoadingListOfVariables,
        errorListOfVariables,
      }}
    >
      {children}
    </WorkspaceProjectionsContext.Provider>
  );
};
