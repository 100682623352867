import React from 'react';

import { useTranslation } from 'react-i18next';
import { Modal } from 'src/components/Modal';
import { ModalFooter } from 'src/components/Modal/Footer/styles';
import { Button } from 'src/components/Button';

import { ConfirmMarketShareChangeModalProps } from './types';
import { Content } from './styles';

export const ConfirmMarketShareChangeModal: React.FC<
  ConfirmMarketShareChangeModalProps
> = ({ setVisible, handleEnableMarketShare }) => {
  const { t: translate } = useTranslation();

  return (
    <Modal
      visible
      setVisible={setVisible}
      dataCy="modal-confirm-market-share-change"
    >
      <Content>
        <h3>{translate('confirmMarketShareChangeTitle')}</h3>
        <p>{translate('confirmMarketShareChangeDescription')}</p>
      </Content>

      <ModalFooter>
        <Button
          buttonType="naked"
          onClick={() => setVisible(false)}
          data-testid="button-cancel-market-share-change"
        >
          {translate('cancel')}
        </Button>

        <Button
          buttonType="primary"
          onClick={() => {
            handleEnableMarketShare();
            setVisible(false);
          }}
          data-testid="button-confirm-market-share-change"
        >
          {translate('confirmMarketShareChangeYesButton')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
