import { useQuery } from 'react-query';
import apiWorkspace from 'src/models/service/apiWorkspace';
import ms from 'ms';

import { UseQueryLogDataResponse, LogData, Action } from './types';

export const useQueryStepLog = (
  workspaceId: string | null,
  releaseId: string | null,
  step: number | null,
  action: Action,
  skip: number,
  limit: number,
): UseQueryLogDataResponse => {
  const { data, isLoading, isFetching } = useQuery(
    [
      'workspace',
      workspaceId,
      'releases',
      releaseId,
      'logs',
      step,
      action,
      skip,
      limit,
    ],
    async () => {
      const response = await apiWorkspace.get<LogData>(
        `/workspaces/${workspaceId}/releases/${releaseId}/logs?step=${step}&action=${action}&skip=${skip}&limit=${limit}`,
      );

      return response.data;
    },
    {
      staleTime: ms('5 min'),
      enabled: !!workspaceId && !!releaseId && !!step,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: 'always',
      refetchInterval: ms('30s'),
    },
  );

  return { data, isLoading: isLoading || isFetching };
};
