import React, { useState } from 'react';

import i18next from 'i18next';
import { CaretDown } from 'phosphor-react';
import { useDispatch, useSelector } from 'react-redux';
import { changeLanguage, Language } from 'src/redux/reducers/Auth';
import { RootState } from 'src/redux/store';

import BrazilFlag from '../../assets/brazil_flag.svg';
import USAFlag from '../../assets/usa_flag.svg';
import { Menu, MenuButton, MenuOverlay, SelectLanguage } from './styles';

type LanguageMenuProps = React.HTMLAttributes<HTMLDivElement>;

export const LanguageMenu: React.FC<LanguageMenuProps> = ({ ...rest }) => {
  const [languageMenuVisible, setLanguageMenuVisible] = useState(false);

  const { user } = useSelector((state: RootState) => state.auth);

  const dispatch = useDispatch();

  const handleChangeLanguage = (language: Language) => {
    i18next.changeLanguage(language);
    dispatch(changeLanguage(language));
    setLanguageMenuVisible(false);
  };

  return (
    <SelectLanguage openModal={languageMenuVisible} {...rest}>
      <img
        src={user.language === 'pt-br' ? BrazilFlag : USAFlag}
        alt="flag"
        data-testid="img-flag-language"
        data-cy="img-flag-language"
      />
      <CaretDown
        size="1.5rem"
        onClick={() => setLanguageMenuVisible(!languageMenuVisible)}
        data-testid="svg-open-language-menu"
        data-cy="svg-open-language-menu"
      />
      {languageMenuVisible && (
        <Menu visible>
          <MenuOverlay
            data-testid="menu-overlay"
            onClick={() => setLanguageMenuVisible(false)}
          />
          <MenuButton
            position="start"
            type="button"
            onClick={() => handleChangeLanguage('pt-br')}
            data-testid="language-option-pt"
            data-cy="language-option-pt"
          >
            <img src={BrazilFlag} alt="flag" />
          </MenuButton>
          <MenuButton
            position="end"
            type="button"
            onClick={() => handleChangeLanguage('en-us')}
            data-testid="language-option-en"
            data-cy="language-option-en"
          >
            <img src={USAFlag} alt="flag" />
          </MenuButton>
        </Menu>
      )}
    </SelectLanguage>
  );
};
