import styled from 'styled-components';

type ButtonProps = {
  hasLabel: boolean;
};

export const Button = styled.button<ButtonProps>`
  display: flex;
  padding: ${({ hasLabel }) => (hasLabel ? '0.5rem 0.75rem' : '0.5rem')};
  align-items: center;
  gap: 0.5rem;

  border-radius: 9999px;
  border: 1px solid ${({ theme }) => theme.colors.gray2};

  cursor: pointer;

  &:disabled {
    cursor: not-allowed !important;
    opacity: 0.6 !important;
  }

  &:not(:disabled):hover {
    svg {
      color: ${({ theme }) => theme.colors.gray5};
    }

    p {
      color: ${({ theme }) => theme.colors.gray6};
    }

    &:disabled {
      background: ${({ theme }) => theme.colors.gray1} !important;

      color: ${({ theme }) => theme.colors.gray5} !important;

      svg {
        color: ${({ theme }) => theme.colors.gray4} !important;
      }
    }
  }

  svg {
    display: flex;
    width: ${({ hasLabel }) => (hasLabel ? '1rem' : '1.25rem')};
    height: ${({ hasLabel }) => (hasLabel ? '1rem' : '1.25rem')};
    justify-content: center;
    align-items: center;

    color: ${({ theme }) => theme.colors.gray4};

    transition: all 0.2s;
  }

  p {
    font-size: 0.75rem;
    font-weight: 500;

    color: ${({ theme }) => theme.colors.gray5};

    transition: all 0.2s;
  }
`;
