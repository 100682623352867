import styled, { css, keyframes } from 'styled-components';

export const Container = styled.div`
  height: calc(100% - 12.938rem);
  max-width: 80rem;
  margin: 0 auto;
  @media (max-width: 1328px) {
    margin: 0 1.5rem;
  }
  @media (max-width: 1012px) {
    height: auto;
  }
  @media (max-height: 820px) and (min-width: 1012px) {
    height: 38.25rem;
  }
`;

export const Header = styled.div`
  height: 7.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray2};

  > img {
    width: 3.5rem;
    height: 3.5rem;
  }
`;

export const ButtonLogout = styled.button`
  height: 2.563rem;
  padding: 0.625rem 2rem;
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 8px;
  font-weight: 600;
  font-size: 1rem;
  letter-spacing: 0.08em;
  color: ${({ theme }) => theme.colors.white} !important;
  margin-left: 2rem;

  transition: background-color 0.2s;

  &:hover {
    background: ${({ theme }) => theme.colors.primaryDark};
  }
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to{
    opacity: 1;
    transform: translateX(0);
  }
`;

const appearFromTop = keyframes`
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to{
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 7.5rem);
  min-height: 33.125rem;
  justify-content: center;
  @media (max-width: 1011px) {
    height: auto;
    margin-top: 3.75rem;
  }

  h2 {
    font-weight: 600;
    font-style: normal;
    font-size: 2rem;
    animation: ${appearFromLeft} 1s;

    @media (max-width: 689px) {
      animation: ${appearFromTop} 1s;
    }
  }
`;

export const ContainerProducts = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;

  @media (max-width: 689px) {
    justify-content: center;
  }
`;

interface Product {
  noAccess?: boolean;
  image?: any;
}

export const Product = styled.div<Product>`
  flex: 1 1 18.75rem;
  background: ${({ theme }) => theme.colors.white};
  animation: ${appearFromLeft} 1s;
  margin-top: 7rem;
  max-width: 24rem;
  min-height: 18.75rem;
  border: 1px solid ${({ theme }) => theme.colors.gray2};
  border-radius: 8px;
  padding: 64px 1.5rem 1.5rem;
  position: relative;
  cursor: ${({ noAccess }) => noAccess && 'not-allowed'};

  @media (max-width: 689px) {
    animation: ${appearFromTop} 1s;
  }

  h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 1.75rem;

    color: ${({ theme, noAccess }) =>
      !noAccess ? theme.colors.gray7 : theme.colors.gray4};
    margin-bottom: 1rem;
  }
  > p {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.375rem;
    color: ${({ theme, noAccess }) =>
      !noAccess ? theme.colors.gray5 : theme.colors.gray3};
  }

  .links {
    position: absolute;
    bottom: 1.5rem;
    display: flex;
    width: 20.875rem;
    height: 1.25rem;
    justify-content: space-between !important;

    a {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 1rem;
      letter-spacing: 0.08em;
      transition: font-size 0.3s;
      color: ${({ theme, noAccess }) =>
        !noAccess ? theme.colors.primary : theme.colors.gray4};
      cursor: ${({ noAccess }) => noAccess && 'not-allowed'};
      pointer-events: ${({ noAccess }) => noAccess && 'none'};

      svg {
        margin-left: 1rem;
      }

      &:hover {
        ${({ noAccess }) =>
          !noAccess &&
          css`
            font-size: 1.125rem;
          `}
      }

      @media (max-width: 689px) {
        right: 1.5rem;
      }
    }
  }

  > div:first-child {
    position: absolute;
    top: -70px;
    left: 10px;
    width: 120px;
    height: 120px;
    background: ${({ theme }) => theme.colors.gray0};
    background-image: url(${({ image }) => image});

    ${({ noAccess }) =>
      noAccess &&
      css`
        filter: grayscale(1) opacity(0.3);
      `}

    > span:first-child {
      position: absolute;
      top: 69px;
      left: 0px;
      width: 14px;
      height: 53px;
      background: ${({ theme }) => theme.colors.white};
      border-top: 1px solid ${({ theme }) => theme.colors.gray2};

      &:after {
        content: '';
        position: absolute;
        top: 14px;
        left: 11px;
        width: 0;
        height: 0;

        border-top: 38px solid transparent;
        border-left: 68px solid ${({ theme }) => theme.colors.white};
      }
    }

    > span:nth-child(2) {
      position: absolute;
      top: 69px;
      right: 0px;
      width: 14px;
      height: 53px;
      background: ${({ theme }) => theme.colors.white};
      border-top: 1px solid ${({ theme }) => theme.colors.gray2};

      &:after {
        content: '';
        position: absolute;
        top: 14px;
        right: 11px;
        width: 0;
        height: 0;
        background: transparent;
        border-top: 38px solid transparent;
        border-right: 68px solid ${({ theme }) => theme.colors.white};
      }
    }

    > span:nth-child(3) {
      position: absolute;
      top: 80px;
      left: 12px;
      width: 0;
      height: 0;
      background: transparent;
      border-top: 23px solid transparent;
      border-left: 11px solid ${({ theme }) => theme.colors.white};
    }

    > span:nth-child(4) {
      position: absolute;
      top: 80px;
      right: 12px;
      width: 0;
      height: 0;
      background: transparent;
      border-top: 23px solid transparent;
      border-right: 11px solid ${({ theme }) => theme.colors.white};
    }
  }
`;

export const DivButtonHeader = styled.div<Product>`
  display: flex;
  width: 6.25rem;
  align-items: center;
  justify-content: space-between !important;

  a {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 1.125rem;
    letter-spacing: 0.05em;
    transition: font-size 0.3s;
    color: ${({ theme }) => theme.colors.gray4} !important;
    cursor: ${({ noAccess }) => noAccess && 'not-allowed'};
    pointer-events: ${({ noAccess }) => noAccess && 'none'};
    &:hover {
      ${({ noAccess }) =>
        !noAccess &&
        css`
          font-size: 1.25rem;
          color: ${({ theme }) => theme.colors.gray5} !important;
        `}
    }
    @media (max-width: 689px) {
      right: 1.5rem;
    }

    p {
      font-weight: 500 !important;
      margin-left: 0.5rem;
    }
  }
`;

export const ContainerAdmin = styled.div`
  position: absolute;
  bottom: 1.5rem;
  right: 1.5rem;
  a {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.gray4} !important;
    transition: color 0.3s;

    &:hover {
      color: ${({ theme }) => theme.colors.gray5} !important;
    }
  }

  p {
    font-weight: 500 !important;
    margin-left: 0.5rem;
    letter-spacing: 0.08em;
    font-size: 1.125rem;
    transition: font-size 0.3s;
    &:hover {
      font-size: 1.25rem;
    }
  }
`;

export const DivContentButtons = styled.div`
  display: flex;
  align-items: center;

  button,
  a {
    display: flex;
    align-items: center;

    transition: 0.2s;

    color: ${({ theme }) => theme.colors.gray4};

    &:hover {
      color: ${({ theme }) => theme.colors.gray5};
    }
  }
`;

export const DivAccessGroupPremium = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 1.25rem;
  margin-top: 2.188rem;

  a {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.gray4} !important;
    transition: color 0.3s;

    &:hover {
      color: ${({ theme }) => theme.colors.gray5} !important;
    }
  }

  p {
    font-weight: 500 !important;
    margin-left: 0.5rem;
    letter-spacing: 0.08em;
    font-size: 0.625rem;
    transition: font-size 0.3s;
    &:hover {
      font-size: 0.75rem;
    }
  }
`;
