import styled from 'styled-components';

export const Container = styled.div`
  width: 70vw;
  height: 80vh;
  max-height: 43rem;
  margin-bottom: -1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Content = styled.div`
  overflow-y: auto;
  padding-bottom: 1.5rem;
  > div {
    width: 78.25rem;
  }
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;

  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2rem;
    color: ${({ theme }) => theme.colors.gray6};
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: ${({ theme }) => theme.colors.gray5};
    margin-top: 0.5rem;
  }

  button {
    display: flex;
    justify-content: flex-start;
  }
`;

export const WarningLockedResource = styled.div`
  display: flex;
  padding: 6px 16px;
  align-items: center;
  gap: 12px;

  margin-top: 0.875rem;

  width: fit-content;

  border-radius: 4px;
  background: rgba(56, 178, 172, 0.08);

  color: #38b2ac;

  cursor: pointer;

  p {
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  p strong {
    font-weight: 600;
  }
`;

export const FilterContent = styled.div`
  display: flex;
  margin-top: 1.5rem;
`;

export const Filter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 20.625rem;
  height: 21.875rem;
  margin-left: 2rem;
  border-right: 1px solid ${({ theme }) => theme.colors.gray1};
  overflow-y: auto;

  p {
    font-style: normal;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.688rem;
    color: ${({ theme }) => theme.colors.gray6};
  }

  span {
    top: unset;
  }
`;

export const FilterSeries = styled.div`
  padding: 0 0 1.25rem 2rem;

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.gray6};
  }
`;

export const LockedAccessIndicator = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: nowrap;

  margin-top: 1rem;

  p {
    color: ${({ theme }) => theme.colors.gray6};

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;

    flex: 1;
  }

  svg {
    color: #38b2ac;
  }
`;

export const ButtonDiv = styled.div`
  display: flex;
  height: 5.5rem;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.colors.gray0};
  border-top: 1px solid ${({ theme }) => theme.colors.gray2};
  padding: 1.5rem 2rem;
  margin: 1.5rem -1.5rem 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.gray6};
  }
  b {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const CustomDivWarning = styled.div`
  max-height: 4.625rem;
  overflow-x: auto;

  ul {
    list-style: disc;
    margin-left: 1rem;
  }

  li {
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 150%;
    list-style: disc;
    color: ${({ theme }) => theme.colors.yellow5};
  }
`;

interface MenuOverlayProps {
  visible: boolean;
}

export const MenuOverlay = styled.div<MenuOverlayProps>`
  width: 100vw;
  height: 100vh;
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
`;

export const WarningDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
