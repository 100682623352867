import React from 'react';

import { Label } from 'src/components/Label';
import { CheckBox } from 'src/components/CheckBox';

import { CheckboxFilterProps } from './types';
import { Container, Options } from './styles';

export const CheckboxFilter: React.FC<CheckboxFilterProps> = ({
  name,
  options,
  onChange,
}) => (
  <Container data-testid={`filter-${name?.toLowerCase().replaceAll(' ', '-')}`}>
    {name && (
      <Label data-testid={`label-${name?.toLowerCase().replaceAll(' ', '-')}`}>
        {name}
      </Label>
    )}

    <Options flexDirection={options.length > 2 ? 'column' : 'row'}>
      {options.map((option) => (
        <div
          key={option.label}
          data-testid={`checkbox-tooltip-${option.label
            .replaceAll(' ', '-')
            .toLowerCase()}`}
          data-tooltip-id="filters-sidebar-tooltip"
          data-tooltip-html={option.tooltipInfo}
          className={option.loading ? 'loading-checkbox-option' : ''}
        >
          <CheckBox
            label={option.label}
            disabled={option.disabled}
            checked={option.checked}
            onChange={() => onChange(option.value)}
          />
        </div>
      ))}
    </Options>
  </Container>
);
