import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { Pencil, Warning, X } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/Button';
import { Td } from 'src/components/Table';
import { CheckBox } from 'src/components/CheckBox';
import light from 'src/styles/themes/light';
import { Select } from 'src/components/Select';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { useFormatDateLanguage } from 'src/hooks/useFormatDateLanguage';
import { TextArea } from 'src/components/TextArea';
import { useModelUpdate } from 'src/models/pages/Workspace/hooks/useModelUpdate';
import { WorkspaceConfigContext } from 'src/models/contexts/WorkspaceConfigContext';

import {
  CheckBoxInflate,
  ModelUpdateContainer,
  RenameVariableAndModelIDContainer,
  TrContent,
  WarningIcon,
} from './styles';
import { ModelUpdateSelect, VariableTableRowProps } from './types';
import { ModelUpdateOptionLabel } from '../../ModelUpdateOptionLabel';
import { ModelIdMenu } from '../../ModelIdMenu';
import {
  YsDisabled,
  YsError,
  YsInflate,
  YsLabel,
  YsModelId,
  YsModelUpdate,
  YsOriginalName,
  YsSelected,
} from '../../Step2/types';

export const VariablesTableRow: React.FC<VariableTableRowProps> = memo(
  ({
    project,
    yKey,
    setValue,
    getValue,
    validateNewLabel,
    showOriginalYName,
    getYModelUpdated,
    modelUpdatesNotHaveY,
    isVisible,
  }) => {
    const translateFormat = useFormatDateLanguage();
    const { t: translate } = useTranslation();
    const [isLoadingY, setIsLoadingY] = useState(false);

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const { isEdition, frequency, workspaceId } = useContext(
      WorkspaceConfigContext,
    );

    const { modelUpdateData, modelUpdateIsLoading, modelUpdateIsFetching } =
      useModelUpdate(project, workspaceId, isEdition);

    const ysSelected = getValue('ysSelected') as YsSelected;
    const ysLabel = getValue('ysLabel') as YsLabel;
    const ysOriginalName = getValue('ysOriginalName') as YsOriginalName;
    const ysModelUpdate = getValue('ysModelUpdate') as YsModelUpdate;
    const ysInflate = getValue('ysInflate') as YsInflate;
    const ysError = getValue('ysError') as YsError;
    const ysDisabled = getValue('ysDisabled') as YsDisabled;

    const isSelected = ysSelected[yKey];
    const label = ysLabel[yKey];
    const originalName = ysOriginalName[yKey];
    const modelUpdate = ysModelUpdate[yKey];
    const inflate = ysInflate[yKey];
    const error = ysError[yKey];
    const isDisabled = ysDisabled[yKey];

    const yId = yKey.replace(`${project.id}-`, '');

    const checkEmptyVariableWhenUnselect = useCallback(() => {
      if (!label) {
        setValue('ysLabel', { [yKey]: originalName });
      }

      setValue('ysInflate', { [yKey]: false });
    }, [yKey, label, setValue, originalName]);

    const isRepeatedVariableLabelOrNameError = (err: string) =>
      err === 'createWorkspaceRepeatedVariable';

    const handleEnableFocusInput = (id: string) => {
      const input = document.getElementById(id);
      if (input) {
        input.focus();
      }
    };

    const handleCheckVariable = (checked: boolean) => {
      if (!checked) {
        checkEmptyVariableWhenUnselect();
      }

      setValue('ysSelected', { [yKey]: !ysSelected[yKey] });
    };

    async function handleSelectModelUpdated(
      modelUpdateSelect: ModelUpdateSelect,
    ) {
      setIsLoadingY(true);

      const yAux = await getYModelUpdated(
        modelUpdateSelect.value,
        yId,
        originalName,
      );

      if (yAux) {
        setValue('ysModelUpdate', {
          [yKey]: {
            ...modelUpdateSelect,
            y: yAux.id,
          },
        });
      }
      setIsLoadingY(false);
    }

    useEffect(() => {
      const onResize = () => {
        setScreenWidth(window.innerWidth);
      };

      window.addEventListener('resize', onResize);

      return () => window.removeEventListener('resize', onResize);
    }, []);

    const resizeVariableInput = useCallback(() => {
      const renameVariable = document.getElementById(
        `rename-variable-${project.id}-${yId}`,
      );

      if (renameVariable) {
        renameVariable.style.height = '1.5rem'; // Define a altura como automática para recalcular
        renameVariable.style.height = `${renameVariable?.scrollHeight / 16}rem`; // Ajusta a altura conforme o conteúdo inserido
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [project.id, yId]);

    useEffect(() => {
      if (isVisible) {
        resizeVariableInput();
      }
    }, [resizeVariableInput, isSelected, label, isVisible]);

    useEffect(() => {
      validateNewLabel(project.id, yId, label);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [project.id, yId]);

    const adjustNames = screenWidth <= 1400;

    const yNameAdjusted =
      ysOriginalName[yKey].length > 25 && adjustNames
        ? `${ysOriginalName[yKey].substring(0, 22)}...`
        : ysOriginalName[yKey];

    const modelUpdateDataAdjusted =
      modelUpdateData?.map((modelUpdateId) => ({
        ...modelUpdateId,
        isDisabled:
          modelUpdateId.isDisabled ||
          modelUpdatesNotHaveY?.[yId]?.includes(modelUpdateId.value),
      })) || [];

    const hasInflateYs = frequency === 'monthly';

    return (
      <TrContent
        key={yKey}
        disabled={isDisabled}
        data-testid={`row-project-${project.id}-variable-${yId}`}
        style={{ display: isVisible ? 'table-row' : 'none' }}
      >
        <Td className="firstTd" />

        <Td>
          <CheckBox
            onChange={({ target: { checked } }) => {
              handleCheckVariable(checked);
            }}
            checked={ysSelected[yKey]}
            disabled={isDisabled}
            style={{ paddingLeft: '1.125rem' }}
            data-testid={`checkbox-project-${project.id}-variable-${yId}`}
          />
        </Td>

        <Td className="withoutPaddingLeft">
          <RenameVariableAndModelIDContainer error={isDisabled}>
            <TextArea
              id={`rename-variable-${project.id}-${yId}`}
              onChange={({ target: { value: vl } }) => {
                setValue('ysLabel', { [yKey]: vl });

                validateNewLabel(project.id, yId, vl);

                resizeVariableInput();
              }}
              value={ysLabel[yKey]}
              disabled={isDisabled || !isSelected}
              data-testid={`variable-name-project-${project.id}-variable-${yId}`}
            />

            <Button
              buttonType="naked"
              icon={<Pencil />}
              onClick={() =>
                handleEnableFocusInput(`rename-variable-${project.id}-${yId}`)
              }
              disabled={ysDisabled[yKey] || !ysSelected[yKey]}
              data-testid={`rename-variable-button-project-${project.id}-variable-${yId}`}
            />

            {!!error &&
              isSelected &&
              (!isRepeatedVariableLabelOrNameError(error) ? (
                <WarningIcon
                  data-tooltip-id="config-workspace-tooltip"
                  data-tooltip-html={translate(error ?? '')}
                  data-testid={`rename-variable-error-project-${project.id}-variable-${yId}`}
                  color={light.colors.red2}
                >
                  <X weight="bold" size="0.875rem" />
                </WarningIcon>
              ) : (
                <WarningIcon
                  data-tooltip-id="config-workspace-tooltip"
                  data-tooltip-html={translate(error ?? '')}
                  data-testid={`rename-variable-warning-project-${project.id}-variable-${yId}`}
                  color={light.colors.secondary}
                >
                  <Warning weight="fill" size="1rem" />
                </WarningIcon>
              ))}
          </RenameVariableAndModelIDContainer>
        </Td>

        <Td
          data-testid={`original-variable-name-project-${project.id}-variable-${yId}`}
          data-tooltip-id="workspace-table"
          data-tooltip-html={
            yNameAdjusted !== ysOriginalName[yKey]
              ? ysOriginalName[yKey]
              : undefined
          }
          style={{ display: showOriginalYName ? 'table-cell' : 'none' }}
        >
          {yNameAdjusted}
        </Td>

        <Td>
          <ModelUpdateContainer>
            {modelUpdateIsFetching ||
            modelUpdateIsLoading ||
            isLoadingY ||
            !modelUpdateData ||
            (!isDisabled && !modelUpdate) ||
            !modelUpdate?.label ? (
              <ContainerSkeleton
                withLoading={false}
                style={{ height: '2.75rem' }}
                data-testid={`model-update-loading-project-${project.id}-variable-${yId}`}
              />
            ) : (
              <Select
                options={modelUpdateDataAdjusted}
                value={modelUpdate}
                onChange={(e) => {
                  if (e) {
                    setValue('ysModelUpdate', {
                      [yKey]: {
                        ...modelUpdate,
                        y: null,
                      },
                    });
                    //@ts-expect-error:ignora
                    handleSelectModelUpdated(e);
                  }
                }}
                menuPlacement="auto"
                style={{ width: '9rem' }}
                dataTestid={`model-update-project-${project.id}-variable-${yId}`}
                formatOptionLabel={({ label: updateLabel }) =>
                  ModelUpdateOptionLabel({
                    label: updateLabel,
                    dateFormat: translateFormat,
                  })
                }
                disabled={isDisabled || !isSelected}
                isDisabled={isDisabled || !isSelected}
                className={
                  !isDisabled
                    ? !isSelected
                      ? 'unselected'
                      : 'selected'
                    : undefined
                }
              />
            )}
          </ModelUpdateContainer>
        </Td>
        <Td className={`${!hasInflateYs ? 'projectLastTd' : ''}`}>
          <ModelIdMenu
            yKey={yKey}
            projectId={modelUpdate?.value ?? project.id}
            yId={modelUpdate?.y ?? yId}
            workspaceId={workspaceId}
            getValue={getValue}
            setValue={setValue}
            isDisabled={isDisabled || !isSelected}
          />
        </Td>
        {hasInflateYs && (
          <Td className="projectLastTd">
            <CheckBoxInflate
              label=""
              onChange={({ target }) =>
                setValue('ysInflate', { [yKey]: target.checked })
              }
              checked={inflate}
              disabled={isDisabled || !isSelected}
              data-cy={`checkbox-inflate-serie-project-${project.id}-variable-${yId}`}
              data-testid={`checkbox-inflate-serie-project-${project.id}-variable-${yId}`}
            />
          </Td>
        )}
      </TrContent>
    );
  },
  (prevProps, nextProps) => {
    const prevYKey = prevProps.yKey;
    const nextYKey = nextProps.yKey;

    const prevYsSelected = prevProps.getValue('ysSelected') as YsSelected;
    const prevYsLabel = prevProps.getValue('ysLabel') as YsLabel;
    const prevYsOriginalName = prevProps.getValue(
      'ysOriginalName',
    ) as YsOriginalName;
    const prevYsModelUpdate = prevProps.getValue(
      'ysModelUpdate',
    ) as YsModelUpdate;
    const prevYsModelId = prevProps.getValue('ysModelId') as YsModelId;
    const prevYsInflate = prevProps.getValue('ysInflate') as YsInflate;
    const prevYsError = prevProps.getValue('ysError') as YsError;
    const prevYsDisabled = prevProps.getValue('ysDisabled') as YsDisabled;

    const nextYsSelected = nextProps.getValue('ysSelected') as YsSelected;
    const nextYsLabel = nextProps.getValue('ysLabel') as YsLabel;
    const nextYsOriginalName = nextProps.getValue(
      'ysOriginalName',
    ) as YsOriginalName;
    const nextYsModelUpdate = nextProps.getValue(
      'ysModelUpdate',
    ) as YsModelUpdate;
    const nextYsModelId = nextProps.getValue('ysModelId') as YsModelId;
    const nextYsInflate = nextProps.getValue('ysInflate') as YsInflate;
    const nextYsError = nextProps.getValue('ysError') as YsError;
    const nextYsDisabled = nextProps.getValue('ysDisabled') as YsDisabled;

    return (
      JSON.stringify(prevProps.project) === JSON.stringify(nextProps.project) &&
      prevYKey === nextYKey &&
      JSON.stringify(prevYsSelected[prevYKey]) ===
        JSON.stringify(nextYsSelected[nextYKey]) &&
      JSON.stringify(prevYsLabel[prevYKey]) ===
        JSON.stringify(nextYsLabel[nextYKey]) &&
      JSON.stringify(prevYsOriginalName[prevYKey]) ===
        JSON.stringify(nextYsOriginalName[nextYKey]) &&
      JSON.stringify(prevYsModelUpdate[prevYKey]) ===
        JSON.stringify(nextYsModelUpdate[nextYKey]) &&
      JSON.stringify(prevYsModelId[prevYKey]) ===
        JSON.stringify(nextYsModelId[nextYKey]) &&
      JSON.stringify(prevYsInflate[prevYKey]) ===
        JSON.stringify(nextYsInflate[nextYKey]) &&
      JSON.stringify(prevYsError[prevYKey]) ===
        JSON.stringify(nextYsError[nextYKey]) &&
      JSON.stringify(prevYsDisabled[prevYKey]) ===
        JSON.stringify(nextYsDisabled[nextYKey]) &&
      prevProps.showOriginalYName === nextProps.showOriginalYName &&
      prevProps.modelUpdatesNotHaveY === nextProps.modelUpdatesNotHaveY &&
      prevProps.isVisible === nextProps.isVisible &&
      prevProps.modelUpdateId === nextProps.modelUpdateId
    );
  },
);
