import styled, { css } from 'styled-components';

type PropsInput = {
  icon: boolean;
  disabled?: boolean;
};

export const ContainerLabel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  color: ${({ theme }) => theme.colors.gray5};
`;

type PropsContainerInput = {
  error: boolean;
  disabled?: boolean;
};

export const ContainerInput = styled.div<PropsContainerInput>`
  p {
    color: ${({ theme }) => theme.colors.red2} !important;
    line-height: 1rem;
    font-style: normal;
    font-size: ${({ error }) => (error ? '0.875rem' : '0')};
    opacity: 0;

    transition: opacity 0.3s ease-in-out;
    transition: margin-top 0.3s ease-in-out;

    ${({ error }) =>
      error &&
      css`
        margin-top: 0.5rem;
        font-size: 0.875rem;
        opacity: 1;
      `}
  }

  background: ${({ disabled, theme }) =>
    disabled ? theme.colors.gray0 : 'transparent'};

  border-radius: 8px;

  input {
    background: ${({ disabled, theme }) =>
      disabled ? theme.colors.gray0 : 'transparent'};
  }

  > div {
    border: ${({ theme, error }) =>
      `1px solid ${error ? theme.colors.red2 : theme.colors.gray2}`};
    transition: border 0.2s ease-in-out;

    &:hover {
      border: ${({ theme, error, disabled }) =>
        `1px solid ${
          error
            ? theme.colors.red2
            : disabled
            ? theme.colors.gray2
            : theme.colors.gray3
        }`};
    }

    svg {
      color: ${({ theme }) => theme.colors.gray4};
      margin-left: 0 !important;

      width: 1rem !important;
      height: 1rem !important;
    }

    .input-icon-error {
      margin-left: 0.75rem !important;
      color: ${({ theme }) => theme.colors.red2};
    }
  }
`;

export const ContentInput = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 8px;
  height: 2.625rem;
`;

export const Input = styled.input<PropsInput>`
  width: 100%;
  font-size: 0.875rem !important;
  color: ${({ theme }) => theme.colors.gray6};
  padding-left: ${({ icon }) => (icon ? '1rem' : '0')};
  border: 0;
  outline: 0;
  font-weight: 400;

  flex: 1;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}

  &::placeholder {
    color: ${({ theme }) => theme.colors.gray4};
  }
`;
