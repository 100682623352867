import React from 'react';

import { useTranslation } from 'react-i18next';
import { Modal } from 'src/components/Modal';
import { ModalFooter } from 'src/components/Modal/Footer/styles';
import { Button } from 'src/components/Button';
import { Status } from 'src/components/Status';

import { DeleteFilterModalProps } from './types';
import { Container } from './styles';

export const DeleteFilterModal: React.FC<DeleteFilterModalProps> = ({
  setVisible,
  handleDeleteFilter,
  filterLevel,
  filterName,
}) => {
  const { t: translate } = useTranslation();

  return (
    <Modal visible setVisible={setVisible} style={{ width: '30rem' }}>
      <Container data-testid={`delete-modal-filter-${filterLevel + 1}`}>
        <Status
          type="deleteConfirmation"
          title={translate('configFiltersDeleteFilterTitle')}
          description={`${translate(
            'configFiltersDeleteFilterDescription',
          )} <b>${filterName}</b>`}
          isModal
        />
      </Container>

      <ModalFooter>
        <Button
          buttonType="naked"
          onClick={setVisible}
          data-testid="delete-filter-cancel-button"
        >
          {translate('cancel')}
        </Button>

        <Button
          buttonType="primary"
          onClick={handleDeleteFilter}
          data-testid="delete-filter-confirm-button"
        >
          {translate('delete')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
