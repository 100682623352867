import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Card } from 'src/components/Card';
import { WorkspaceConfigContext } from 'src/models/contexts/WorkspaceConfigContext';
import { Tooltip } from 'react-tooltip';
import { Label } from 'src/components/Label';
import {
  DownloadSimple,
  Info,
  PencilSimple,
  Plus,
  Question,
  Warning,
  X,
} from 'phosphor-react';
import { InputFile } from 'src/models/pages/Modelling/TimeSeries/components/InputFile';
import { Button } from 'src/components/Button';
import { HandTap } from '@phosphor-icons/react';
import {
  ColDef,
  ExcelExportParams,
  ExcelStyle,
  ICellRendererParams,
} from 'ag-grid-community';
import { AgGridTable } from 'src/components/AgGridTable';
import { AgGridReact } from 'ag-grid-react';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import light from 'src/styles/themes/light';
import { ButtonRounded } from 'src/components/ButtonRounded';
import { ModalOverlay } from 'src/components/Modal/Overlay';
import { ModalFooter } from 'src/components/Modal/Footer/styles';
import { Tutorial } from 'src/models/TimeSeries/DependentVariablesConfig/components/Tutorial';
import { sleep } from 'src/utils/sleep';
import { Modal } from 'src/components/Modal';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { useNavigate } from 'react-router-dom';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import Maintenance from 'src/assets/maintenance.svg';

import {
  ActionButtons,
  CategorizationActions,
  CategorizationContainer,
  ConfigButtons,
  ConfigContainer,
  ConfigOptionContainer,
  ConfirmCloseCategorizationContainer,
  Container,
  Content,
  CreateFilterContainer,
  FileButtons,
  ModalContainer,
  ModalContent,
  SelectOptionButton,
  TableContainer,
  UploadContainer,
} from './styles';
import {
  Filter,
  SheetCell,
  Step3Props,
  TableRowData,
  YsHierarchiesProps,
} from './types';
import { StepFooter } from '../StepFooter';
import { FileRequirementsModal } from '../Modal/FileRequirements';
import { ConfirmMarketShareChangeModal } from '../Modal/ConfirmMarketShareChange';
import { ConfirmCategorizationDiscardModal } from '../Modal/ConfirmCategorizationDiscard';
import { ConfigFilters } from './ConfigFilter';
import { VariableTypeWarningModal } from '../Modal/VariableTypeWarning';

export const Step3: React.FC<Step3Props> = ({ setStep, saveWorkspace }) => {
  const [currentFile, setCurrentFile] = useState<File>();
  const [manualFilters, setManualFilters] = useState<Filter[]>([]);
  const [categorizationOnManualFormat, setCategorizationOnManualFormat] =
    useState<Filter[]>([]);
  const [ys, setYs] = useState<string[]>([]);

  const [tableRowData, setTableRowData] = useState<TableRowData[]>([]);
  const [tableColumnDefs, setTableColumnDefs] = useState<ColDef[]>([]);
  const [tableRendered, setTableRendered] = useState(false);

  const [loadingTableData, setLoadingTableData] = useState(false);

  const [editingCategorization, setEditingCategorization] = useState(false);

  const [showConfirmMarketShareChange, setShowConfirmMarketShareChange] =
    useState(false);
  const [showRequirementsModal, setShowRequirementsModal] = useState(false);
  const [showDiscardConfirmation, setShowDiscardConfirmation] = useState(false);
  const [showManualCategorization, setShowManualCategorization] =
    useState(false);
  const [showTutorial, setShowTutorial] = useState(false);
  const [showConfirmCloseCategorization, setShowConfirmCloseCategorization] =
    useState(false);
  const [showVariableTypeWarning, setShowVariableTypeWarning] = useState(false);

  const [hasVariableTypeInfo, setHasVariableTypeInfo] = useState(false);

  const [fileIsEmpty, setFileIsEmpty] = useState(false);

  const tableRef = useRef<AgGridReact>(null);

  const {
    isEdition,
    frequency,
    name,
    description,
    iconUrl,
    enablePlanningFlow,
    series,
    categorization,
    saveCategorization,
    file,
    setFile,
    enableAggregation,
    setEnableAggregation,
    enableMarketShare,
    setEnableMarketShare,
    isErrorStagingAreaHierarchies,
    isLoadingStagingAreaHierarchies,
    workspaceId,
    hasSeriesChanges,
    stagingAreaData,
    updatingCategorization,
  } = useContext(WorkspaceConfigContext);
  const { t: translate } = useTranslation();

  const navigate = useNavigate();

  const [loadingCategorization, setLoadingCategorization] = useState(isEdition);

  const handleDownloadTemplate = () => {
    const workbook = new ExcelJS.Workbook();

    const worksheet = workbook.addWorksheet('Sheet1');
    0;

    // Definir cabeçalhos para a planilha
    worksheet.columns = [
      {
        header: translate('createWorkspaceTemplateVariable'),
        key: 'variable',
      },
      {
        header: translate('createWorkspaceTemplateProject'),
        key: 'project',
      },
      {
        header: translate('createWorkspaceTemplateVariableType'),
        key: 'variable_type',
      },
      {
        header: `${translate('createWorkspaceTemplateLevel')} 1`,
        key: 'level_1',
      },
      {
        header: `${translate('createWorkspaceTemplateLevel')} 2`,
        key: 'level_2',
      },
      {
        header: `${translate('createWorkspaceTemplateLevel')} 3`,
        key: 'level_3',
      },
      {
        header: `${translate('createWorkspaceTemplateLevel')} 4`,
        key: 'level_4',
      },
      {
        header: `${translate('createWorkspaceTemplateLevel')} 5`,
        key: 'level_5',
      },
    ];

    series.forEach((serie) => {
      if (serie.selected) {
        worksheet.addRow({
          variable: serie.yLabel,
          project: serie.projectName,
          variable_type: null,
          level_1: null,
          level_2: null,
          level_3: null,
          level_4: null,
          level_5: null,
        });
      }
    });

    const dataValidation = {
      type: 'list',
      allowBlank: true,
      formulae: [
        `"marketsize,sellout,${translate(
          'createWorkspaceTemplateOthers',
        ).toLowerCase()}"`,
      ],
    };

    worksheet.getColumn('C').eachCell((cell, rowNumber) => {
      if (rowNumber !== 1) {
        //@ts-expect-error:ignora
        cell.dataValidation = dataValidation;
      }
    });

    const templateName = name
      ? `${name.replaceAll(' ', '-').toLowerCase().trim()}-template`
      : 'template';

    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        `${templateName}.xlsx`,
      );
    });
  };

  const handleExportTable = () => {
    if (tableRendered) {
      const config: ExcelExportParams = {
        sheetName: translate('variables'),
        fileName: `${name} - ${translate(
          'createWorkspaceVariableCategorization',
        )}`,
        processGroupHeaderCallback(params) {
          const columnName = params.api
            .getDisplayNameForColumnGroup(params.columnGroup, null)
            .toLowerCase();

          if (['variavel', 'variable'].includes(columnName))
            return translate('createWorkspaceTemplateVariable');

          if (['projeto', 'project'].includes(columnName))
            return translate('createWorkspaceTemplateVariable');

          if (['tipo variavel', 'variable type'].includes(columnName))
            return translate('createWorkspaceTemplateVariable');

          return columnName;
        },
      };

      tableRef.current!.api.exportDataAsExcel(config);
    }
  };

  const handleDiscard = (clearFilters?: boolean) => {
    if (clearFilters || !editingCategorization) {
      setEnableMarketShare(false);
      setManualFilters([]);
      setCurrentFile(undefined);
      setFile(undefined);
      setTableRowData([]);
      setTableColumnDefs([]);
      saveCategorization(undefined);
    } else {
      setCurrentFile(file);
      setEditingCategorization(false);
    }
  };

  const handleCloseCategorization = () => {
    if (hasChanges) {
      setShowConfirmCloseCategorization(true);
    } else {
      setShowManualCategorization(false);
    }
  };

  const manualFiltersQtty =
    isEdition &&
    manualFilters.length &&
    ['variable type', 'tipo variavel'].includes(
      manualFilters[0].name.toLowerCase(),
    )
      ? manualFilters.length - 1
      : manualFilters.length;

  const handleAddNewFilter = async () => {
    setManualFilters([
      ...manualFilters,
      {
        name: `${translate('configFiltersFilter')} ${manualFiltersQtty + 1}`,
        options: [],
      },
    ]);

    await sleep(100);

    const container = document.getElementById('config-filters-container');

    if (container && container.scrollTo) {
      container.scrollTo({
        top: container.scrollHeight - 840,
        behavior: 'smooth',
      });
    }
  };

  const setTableDataFromManualFilter = () => {
    if (categorization) {
      const colDefs: ColDef[] = [
        {
          headerName: translate('createWorkspaceTemplateVariable'),
          field: 'variable',
          pinned: true,
          cellClass: 'variable-cell',
          headerClass: 'variable-header',
        },
        {
          headerName: translate('createWorkspaceTemplateProject'),
          field: 'project',
          pinned: true,
          cellClass: 'variable-cell',
          headerClass: 'variable-header',
        },
      ];

      categorization.hierarchies.forEach((hierarchy) => {
        colDefs.push({
          field: hierarchy,
          cellClass: 'filter-cell',
          headerClass: 'filter-header',
        });
      });

      const ysLabel = Object.keys(categorization.ys);

      const rows: TableRowData[] = [];

      ysLabel.forEach((y) => {
        const yRow: TableRowData = { variable: y };

        const projectName = series.find(
          (serie) => serie.yLabel === y,
        )?.projectName;

        yRow.project = projectName ?? '';

        categorization.ys[y].forEach((option) => {
          yRow[option.name] = option.value ?? '';
        });

        rows.push(yRow);
      });

      setTableRowData(rows);
      setTableColumnDefs(colDefs);
    }
  };

  const handleSaveCategorization = () => {
    setLoadingTableData(true);
    setEditingCategorization(false);

    const hierarchies: string[] = [];
    const ysHierarchy: YsHierarchiesProps = {};

    manualFilters.forEach((filter) => {
      let hasValidOptionWithY = false;

      const ysWithOptions: string[] = [];
      filter.options.forEach((option) => {
        option.ys.forEach((y) => {
          hasValidOptionWithY = true;

          ysWithOptions.push(y);

          const hierarchy = { name: filter.name, value: option.name };

          if (ysHierarchy[y]?.length) {
            ysHierarchy[y].push(hierarchy);
          } else {
            ysHierarchy[y] = [hierarchy];
          }
        });
      });

      const ysWithNoOption = ys.filter((y) => !ysWithOptions.includes(y));

      ysWithNoOption.forEach((y) => {
        const hierarchy = { name: filter.name, value: null };

        if (ysHierarchy[y]?.length) {
          ysHierarchy[y].push(hierarchy);
        } else {
          ysHierarchy[y] = [hierarchy];
        }
      });

      if (hasValidOptionWithY) {
        hierarchies.push(filter.name);
      }
    });

    setCategorizationOnManualFormat(JSON.parse(JSON.stringify(manualFilters)));
    saveCategorization({ hierarchies, ys: ysHierarchy });
  };

  const backStep = () => {
    setFile(currentFile);
    setStep(2);
  };

  const nextStep = () => {
    saveWorkspace(
      name,
      description,
      iconUrl,
      series,
      enablePlanningFlow,
      enableAggregation,
      enableMarketShare,
      hasSeriesChanges,
      stagingAreaData,
      currentFile,
      categorization,
    );
  };

  const formattedDataCy = (value?: string) =>
    value?.toLowerCase()?.replaceAll(' ', '-');

  const defaultColDef: ColDef = useMemo(
    () => ({
      flex: 1,
      minWidth: 140,
      width: 250,
      autoHeight: true,
      suppressMovable: true,
      lockPinned: true,
      sortable: false,
      unSortIcon: true,
      resizable: true,
      suppressHeaderMenuButton: true,
      cellRenderer: (param: ICellRendererParams) => (
        <div
          data-testid={`cell-row-${formattedDataCy(
            param.data.variable ??
              param.data.variavel ??
              param.data.Variable ??
              param.data.Variavel,
          )}-column-${formattedDataCy(param.column?.getColId())}`}
        >
          {param.value}
        </div>
      ),
    }),
    [],
  );

  const excelStyle: ExcelStyle[] = useMemo(() => {
    const excelBorders = {
      borderBottom: {
        color: light.colors.gray3,
      },
      borderTop: {
        color: light.colors.gray3,
      },
      borderLeft: {
        color: light.colors.gray3,
      },
      borderRight: {
        color: light.colors.gray3,
      },
    };

    const defaultOptions: ExcelStyle[] = [
      {
        id: 'variable-header',
        alignment: {
          horizontal: 'Center',
        },
        borders: excelBorders,
        interior: { color: light.colors.gray1, pattern: 'Solid' },
      },
      {
        id: 'filter-header',
        alignment: {
          horizontal: 'Center',
        },
        borders: excelBorders,
        interior: { color: light.colors.gray1, pattern: 'Solid' },
      },
      {
        id: 'variable-cell',
        borders: excelBorders,
      },
      {
        id: 'filter-cell',
        borders: excelBorders,
      },
    ];

    return defaultOptions;
  }, []);

  useEffect(() => {
    const loadDataFromExcel = async (): Promise<{
      columns: SheetCell[];
      data: TableRowData[];
      hasVariableTypeFilled: boolean;
    }> =>
      new Promise((resolve, reject) => {
        if (!currentFile) {
          reject(new Error('File not found'));
        }

        const workbook = new ExcelJS.Workbook();
        const reader = new FileReader();

        let columns: SheetCell[] = [];
        const data: TableRowData[] = [];
        let hasVariableTypeFilled = false;

        reader.readAsArrayBuffer(currentFile as File);
        reader.onload = async () => {
          const buffer = reader.result;

          await workbook.xlsx.load(buffer as Buffer).then((wb) => {
            wb.worksheets[0]?.eachRow((row, rowIndex) => {
              if (row && Array.isArray(row.values)) {
                if (rowIndex === 1) {
                  columns = row.values.map((value) => value as SheetCell);
                } else if (rowIndex !== 0 && columns.length) {
                  const rowData: TableRowData = {};

                  row.values.forEach((value, index) => {
                    const colName = columns[index];

                    if (
                      ['variable type', 'tipo variavel'].includes(
                        colName?.toLowerCase() ?? '',
                      ) &&
                      ['marketshare', 'marketsize'].includes(value as string)
                    ) {
                      hasVariableTypeFilled = true;
                    }

                    if (colName && value) {
                      rowData[colName] = value as string;
                    }
                  });

                  data.push(rowData);
                }
              }
            });

            resolve({ columns, data, hasVariableTypeFilled });
          });
        };
      });

    const formatTableData = (columns: SheetCell[], data: TableRowData[]) => {
      const colDefs: ColDef[] = [];

      columns.forEach((col) => {
        if (col) {
          const colLowerCase = col?.toLowerCase() ?? '';

          const isVariable = ['variavel', 'variable'].includes(colLowerCase);
          const isProject = ['projeto', 'project'].includes(colLowerCase);
          const isVariableType = ['variable type', 'tipo variavel'].includes(
            colLowerCase,
          );

          colDefs.push({
            headerName: isVariable
              ? translate('createWorkspaceTemplateVariable')
              : isProject
              ? translate('createWorkspaceTemplateProject')
              : isVariableType
              ? translate('createWorkspaceTemplateVariableType')
              : col ?? '',
            field: col ?? '',
            pinned: isProject || isVariable,
            cellClass: 'filter-cell',
            headerClass: 'filter-header',
          });
        }
      });

      setTableRowData(data);
      setTableColumnDefs(colDefs);
    };

    const formatFileToTableRender = async () => {
      setLoadingTableData(true);

      const { columns, data, hasVariableTypeFilled } =
        await loadDataFromExcel();

      if (!data.length) {
        setFileIsEmpty(true);
      } else {
        setEditingCategorization(false);
        setManualFilters([]);
        setCategorizationOnManualFormat([]);
        saveCategorization(undefined);

        formatTableData(columns, data);

        setLoadingTableData(false);

        setHasVariableTypeInfo(hasVariableTypeFilled);
      }
    };

    if (currentFile) {
      setHasVariableTypeInfo(false);
      setFileIsEmpty(false);
      formatFileToTableRender();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFile]);

  useEffect(() => {
    if (hasVariableTypeInfo && !enableMarketShare && currentFile) {
      setShowVariableTypeWarning(true);
    }
  }, [hasVariableTypeInfo, enableMarketShare, currentFile]);

  useEffect(() => {
    if (tableRendered) {
      const updatedTableColDefs = [...tableColumnDefs];

      updatedTableColDefs.forEach((col) => {
        const field = col?.field?.toLowerCase() ?? '';

        if (col.headerName) {
          if (['variavel', 'variable'].includes(field)) {
            col.headerName = translate('createWorkspaceTemplateVariable');
          }
          if (['variable type', 'tipo variavel'].includes(field)) {
            col.headerName = translate('createWorkspaceTemplateVariableType');
          }
          if (['project', 'projeto'].includes(field)) {
            col.headerName = translate('createWorkspaceTemplateProject');
          }
        }
      });

      tableRef.current?.api.setGridOption('columnDefs', updatedTableColDefs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [translate]);

  useEffect(() => {
    const allYs: string[] = [];

    series.forEach(({ yLabel, selected }) => {
      if (selected) {
        allYs.push(yLabel);
      }
    });

    setYs(allYs);
  }, [series]);

  useEffect(() => {
    if (categorization?.hierarchies.length && !manualFilters.length) {
      const formattedFilters: Filter[] = [];

      const ysLabel = Object.keys(categorization.ys);

      categorization.hierarchies.forEach((hierarchy) => {
        formattedFilters.push({ name: hierarchy, options: [] });
      });

      ysLabel.forEach((y) => {
        categorization.ys[y].forEach((option, index) => {
          if (formattedFilters[index]?.options) {
            const optionIndex = formattedFilters[index]?.options?.findIndex(
              ({ name: optionName }) => optionName === option.value,
            );

            if (optionIndex === -1 && option.value) {
              formattedFilters[index].options.push({
                name: option.value,
                id: String(formattedFilters[index].options.length + 1),
                ys: [y],
              });
            } else if (option.value) {
              formattedFilters[index].options[optionIndex].ys.push(y);
            }
          }
        });
      });

      setCategorizationOnManualFormat(
        JSON.parse(JSON.stringify(formattedFilters)),
      );
      setManualFilters(JSON.parse(JSON.stringify(formattedFilters)));
    }

    if (categorization?.hierarchies.length) {
      setLoadingTableData(true);

      setTableDataFromManualFilter();

      setLoadingTableData(false);
    }

    if (!isLoadingStagingAreaHierarchies) {
      setLoadingCategorization(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categorization]);

  useEffect(() => {
    setCurrentFile(file);
  }, [file]);

  useEffect(() => {
    setTableRendered(false);
  }, [loadingTableData]);

  useEffect(() => {
    if (currentFile && !editingCategorization) {
      setFile(currentFile);
    }
  }, [currentFile, editingCategorization, setFile]);

  useEffect(() => {
    if (editingCategorization) {
      setTableRendered(false);
    }
  }, [editingCategorization]);

  useEffect(() => {
    const openTutorial = async () => {
      await sleep(2100);

      setShowTutorial(true);

      localStorage.setItem('tutorialConfigVariablesWorkspaceAccessed', 'true');
    };

    const alreadyAccessed = localStorage.getItem(
      'tutorialConfigVariablesWorkspaceAccessed',
    );

    if (alreadyAccessed !== 'true') {
      openTutorial();
    }
  }, []);

  useEffect(() => {
    if (
      !enableMarketShare &&
      categorizationOnManualFormat.length &&
      isEdition
    ) {
      setHasVariableTypeInfo(false);
      const firstColumn = categorizationOnManualFormat[0];

      if (
        ['tipo variavel', 'variable type'].includes(
          firstColumn.name.toLowerCase(),
        )
      ) {
        const updatedHasVariableTypeInfo = firstColumn.options.some(
          (option) =>
            option.name.toLowerCase() === 'marketsize' ||
            option.name.toLowerCase() === 'marketshare',
        );

        setHasVariableTypeInfo(updatedHasVariableTypeInfo);

        if (updatedHasVariableTypeInfo) {
          setShowVariableTypeWarning(true);
        }
      }
    }
  }, [enableMarketShare, categorizationOnManualFormat, isEdition]);

  const marketShareDisabled = ['daily', 'weekly', 'fortnightly'].includes(
    frequency ?? '',
  );

  const hasManualFilterError = manualFilters.some(
    (filter) => filter.error || filter.options.some((option) => option.error),
  );

  const hasChanges = useMemo(
    () =>
      JSON.stringify(categorizationOnManualFormat) !==
      JSON.stringify(manualFilters),
    [manualFilters, categorizationOnManualFormat],
  );

  return (
    <Container className="containerLinear" data-testid="container-step-3">
      <Card
        textCard={translate('createWorkspaceVariableCategorization')}
        textDescription={translate(
          'createWorkspaceVariableCategorizationDescription',
        )}
      />

      {isErrorStagingAreaHierarchies ? (
        <ContainerMaintenance
          content="content"
          style={{ marginTop: '3rem' }}
          data-testid="error-staging-area-hierarchies"
        />
      ) : isEdition &&
        (isLoadingStagingAreaHierarchies || loadingCategorization) ? (
        // eslint-disable-next-line react/jsx-indent
        <ContainerSkeleton
          style={{ height: '30rem' }}
          data-testid="loading-hierarchies"
        />
      ) : (
        <Content>
          <CategorizationActions>
            {!!tableRowData.length && !editingCategorization && (
              <ButtonRounded
                label={translate('export')}
                icon={<DownloadSimple />}
                onClick={handleExportTable}
                disabled={false}
                data-testid="button-export-categorization"
              />
            )}

            {((editingCategorization && !!tableRowData.length) ||
              !!tableRowData.length) && (
              <ButtonRounded
                label={translate(
                  editingCategorization
                    ? 'createWorkspaceCancelCategorizationEdition'
                    : 'discard',
                )}
                icon={<X />}
                onClick={() => {
                  setShowDiscardConfirmation(true);
                }}
                disabled={false}
                data-testid="button-discard-categorization"
              />
            )}

            {!!tableRowData.length && !editingCategorization && (
              <ButtonRounded
                label={translate('editCategorization')}
                icon={<PencilSimple />}
                onClick={() => setEditingCategorization(true)}
                disabled={false}
                data-testid="button-edit-categorization"
              />
            )}
          </CategorizationActions>

          <ConfigContainer>
            <ConfigOptionContainer data-testid="container-enable-aggregation">
              <div>
                <Label>{translate('createWorkspaceAggregateResults')}</Label>

                <Info
                  data-tooltip-id="variable-categorization-tooltip"
                  data-tooltip-html={translate(
                    'createWorkspaceAggregateInformation',
                  )}
                  data-testid="aggregation-information"
                />
              </div>

              <div>
                <SelectOptionButton
                  selected={!enableAggregation}
                  onClick={() => setEnableAggregation(false)}
                  data-testid="button-no-aggregation"
                >
                  {translate('no')}
                </SelectOptionButton>
                <SelectOptionButton
                  selected={enableAggregation}
                  onClick={() => setEnableAggregation(true)}
                  data-testid="button-yes-aggregation"
                >
                  {translate('yes')}
                </SelectOptionButton>
              </div>
            </ConfigOptionContainer>

            <ConfigOptionContainer data-testid="container-enable-market-share">
              <div>
                <Label>{translate('createWorkspaceEnableMarketShare')}</Label>

                <Info
                  data-tooltip-id="variable-categorization-tooltip"
                  data-tooltip-html={translate(
                    'createWorkspaceEnableMarketShareInformation',
                  )}
                  data-testid="market-share-information"
                />
              </div>

              <div>
                <SelectOptionButton
                  selected={!enableMarketShare}
                  onClick={() => setEnableMarketShare(false)}
                  disabled={marketShareDisabled}
                  data-testid="button-no-market-share"
                >
                  {translate('no')}
                </SelectOptionButton>

                <SelectOptionButton
                  selected={enableMarketShare}
                  onClick={() => {
                    if (categorization?.hierarchies.length && !isEdition) {
                      setShowConfirmMarketShareChange(true);
                    } else {
                      setEnableMarketShare(true);
                    }
                  }}
                  disabled={marketShareDisabled}
                  data-testid="button-yes-market-share"
                >
                  {translate('yes')}
                </SelectOptionButton>
              </div>
            </ConfigOptionContainer>
          </ConfigContainer>

          {editingCategorization ||
          (!categorization?.hierarchies.length &&
            (!currentFile || fileIsEmpty)) ? (
            // eslint-disable-next-line react/jsx-indent
            <>
              <UploadContainer data-testid="container-upload-categorization">
                <InputFile
                  title={translate(
                    'createWorkspaceCreateCategorizationUploadTitle',
                  )}
                  fileFormats={['.xlsx']}
                  file={currentFile}
                  setFile={setCurrentFile}
                  error={fileIsEmpty ? translate('fileIsEmpty') : undefined}
                  dataCy="input-upload-categorization"
                >
                  <FileButtons>
                    <Button
                      buttonType="naked"
                      icon={<Question />}
                      onClick={() => setShowRequirementsModal(true)}
                      data-testid="button-see-requirements"
                    >
                      {translate('fileInputSeeRequirements')}
                    </Button>
                    <Button
                      onClick={handleDownloadTemplate}
                      buttonType="naked"
                      icon={<DownloadSimple />}
                      className="naked-button buttonDownloadTemplate"
                      data-testid="button-download-template"
                    >
                      {translate('templateDownload')}
                    </Button>
                  </FileButtons>
                </InputFile>
              </UploadContainer>

              <ConfigButtons>
                <Button
                  buttonType="naked"
                  icon={<HandTap />}
                  disabled={enableMarketShare}
                  onClick={() => setShowManualCategorization(true)}
                  data-testid="button-create-manually"
                  className="naked-button"
                >
                  {translate('createWorkspaceCreateCategorizationManually')}
                </Button>
              </ConfigButtons>
            </>
          ) : loadingTableData ||
            (categorization?.hierarchies.length && !tableRowData.length) ||
            updatingCategorization ? (
            // eslint-disable-next-line react/jsx-indent
            <ContainerSkeleton
              data-testid="loading-filters"
              style={{ height: '15rem' }}
            />
          ) : (
            <TableContainer>
              <AgGridTable
                tableRef={tableRef}
                rowData={tableRowData}
                columnDefs={tableColumnDefs}
                defaultColDef={defaultColDef}
                enableRangeHandle={false}
                enableRangeSelection={false}
                suppressCellFocus
                suppressMenuHide
                suppressContextMenu
                excelStyles={excelStyle}
                maxRemHeight={20}
                onFirstDataRendered={() => setTableRendered(true)}
                checkDomLayoutType={tableRendered}
              />
            </TableContainer>
          )}
        </Content>
      )}

      <ModalContainer
        visible={showManualCategorization}
        data-testid="variable-categorization-modal"
      >
        <ModalOverlay
          data-testid="variable-categorization-modal-overlay"
          onClick={handleCloseCategorization}
        />
        <ModalContent visible={showManualCategorization}>
          <Card
            textCard={translate('dependentVariablesConfigTitle')}
            textDescription={translate('dependentVariablesConfigDescription')}
            style={{ padding: '0 1.5rem' }}
          />

          <ActionButtons>
            <Button
              buttonType="naked"
              icon={<Question />}
              onClick={() => setShowTutorial(true)}
              data-testid="open-tutorial-button"
            />

            <Button
              buttonType="naked"
              icon={<X />}
              onClick={handleCloseCategorization}
              data-testid="close-variable-categorization-button"
            />
          </ActionButtons>

          {!manualFiltersQtty ? (
            <CreateFilterContainer>
              <div>
                <img src={Maintenance} alt="maintenance" />

                <p>{translate('dependentVariablesConfigNoConfig')}</p>

                <Button
                  buttonType="tertiary"
                  onClick={handleAddNewFilter}
                  data-testid="create-filter"
                >
                  {translate('dependentVariablesConfigStartConfig')}
                </Button>
              </div>
            </CreateFilterContainer>
          ) : (
            <CategorizationContainer id="config-filters-container">
              {Array.from(Array(manualFiltersQtty).keys()).map(
                (filterLevel) => (
                  <ConfigFilters
                    key={filterLevel}
                    filterLevel={
                      manualFiltersQtty !== manualFilters.length
                        ? filterLevel + 1
                        : filterLevel
                    }
                    filters={manualFilters}
                    setFilters={setManualFilters}
                    ys={ys}
                  />
                ),
              )}
            </CategorizationContainer>
          )}

          <ModalFooter className="categorization-modal-footer">
            {!!manualFiltersQtty && manualFiltersQtty < 3 && (
              <Button
                buttonType="secondary"
                icon={<Plus size="1.25rem" />}
                onClick={handleAddNewFilter}
                data-testid="add-new-filter"
              >
                {translate('createFiltersAddNew')}
              </Button>
            )}

            <Button
              buttonType="primary"
              onClick={() => {
                setCurrentFile(undefined);
                setFile(undefined);

                handleSaveCategorization();

                setShowManualCategorization(false);
              }}
              disabled={!hasChanges || hasManualFilterError}
              data-tooltip-html={
                hasManualFilterError
                  ? translate('createWorkspaceSaveChangesError')
                  : ''
              }
              data-tooltip-id="variable-categorization-tooltip"
              data-testid="save-changes"
            >
              {translate('createWorkspaceSaveVariableCategorization')}
            </Button>
          </ModalFooter>

          <Tutorial
            visible={showTutorial}
            setVisible={setShowTutorial}
            showLastPage={false}
          />
        </ModalContent>
      </ModalContainer>

      <StepFooter
        stepQtty={3}
        selectedStep={3}
        backStep={{
          text: translate('step2'),
          onClick: backStep,
          disabled: false,
        }}
        nextStep={{
          text: translate(
            isEdition ? 'editWorkspaceSaveChanges' : 'createWorkspaceCreate',
          ),
          onClick: nextStep,
          disabled: enableMarketShare && !tableRowData.length,
          removeIcon: isEdition,
        }}
        discardButton={
          isEdition
            ? {
                text: translate('discard'),
                onClick: () =>
                  navigate(`/models/workspaces/${workspaceId}/control-panel`),
                disabled: false,
              }
            : undefined
        }
      />

      {showRequirementsModal && (
        <FileRequirementsModal setVisible={setShowRequirementsModal} />
      )}

      {showConfirmMarketShareChange && (
        <ConfirmMarketShareChangeModal
          setVisible={setShowConfirmMarketShareChange}
          handleEnableMarketShare={() => {
            handleDiscard(true);
            setEnableMarketShare(true);
          }}
        />
      )}

      {showDiscardConfirmation && (
        <ConfirmCategorizationDiscardModal
          setVisible={setShowDiscardConfirmation}
          handleDiscard={handleDiscard}
        />
      )}

      {showConfirmCloseCategorization && (
        <Modal
          visible
          setVisible={setShowConfirmCloseCategorization}
          dataCy="confirm-close-categorization"
        >
          <ConfirmCloseCategorizationContainer>
            <Warning size="3rem" data-testid="x-circle-icon" />

            <h1 data-testid="warning-modal-title">
              {translate('createWorkspaceConfirmCloseCategorizationTitle')}
            </h1>
            <p data-testid="warning-modal-description">
              {translate(
                'createWorkspaceConfirmCloseCategorizationDescription',
              )}
            </p>
          </ConfirmCloseCategorizationContainer>

          <ModalFooter>
            <Button
              buttonType="naked"
              onClick={() => setShowConfirmCloseCategorization(false)}
              data-testid="cancel-button"
            >
              {translate('cancel')}
            </Button>
            <Button
              buttonType="primary"
              onClick={() => {
                setManualFilters(categorizationOnManualFormat);
                setShowConfirmCloseCategorization(false);
                setShowManualCategorization(false);
              }}
              data-testid="continue-button"
            >
              {translate('exit')}
            </Button>
          </ModalFooter>
        </Modal>
      )}

      {showVariableTypeWarning && (
        <VariableTypeWarningModal setVisible={setShowVariableTypeWarning} />
      )}

      <Tooltip
        id="variable-categorization-tooltip"
        className="categorizationTooltip customTooltipTheme"
      />
    </Container>
  );
};
