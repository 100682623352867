import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import apiWorkspace from 'src/models/service/apiWorkspace';
import ms from 'ms';

import { UseQueryReleaseDataResponse, ReleaseData } from './types';

export const useQueryReleaseData = (
  workspaceId?: string | null,
  releaseId?: string | null,
): UseQueryReleaseDataResponse => {
  const navigate = useNavigate();

  const { data, isLoading, isFetching } = useQuery(
    ['workspace', workspaceId, 'releases', releaseId],
    async () => {
      const response = await apiWorkspace.get<ReleaseData>(
        `/workspaces/${workspaceId}/releases/${releaseId}`,
      );

      return response.data;
    },
    {
      staleTime: ms('5 min'),
      onError: () => navigate('/models/projects'),
      enabled: !!workspaceId && !!releaseId,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: 'always',
      refetchInterval: ms('30s'),
    },
  );

  return { data, isLoading: isLoading || isFetching };
};
