const homeENTranslations = {
  home: 'Home',
  our_products: 'Our Products',
  logout: 'LOGOUT',
  access: 'ACCESS',
  homeModelsDescription:
    'Build predictive models at scale, obtain assertive forecasts, and map risks to make better decisions through our platform.',
  homeFSDescription:
    'Access our rich database, with thousands of up-to-date series and projections ready to feed your models.',
};

export default homeENTranslations;
