import React, { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Card } from 'src/components/Card';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { Select } from 'src/components/Select';
import { RootState } from 'src/redux/store';
import { getChartColor } from 'src/utils/colors/getChartColor';
import { useQuery } from 'react-query';
import api from 'src/models/service/api';
import { HCharts, HChartsOptions, HChartsSeries } from 'src/components/HCharts';
import { formatCompactNotation } from 'src/utils/numbers/formatCompactNotation';

import { ContainerModelSelectedCriterion } from './styles';

interface Accuracy {
  type: string;
  accuracy: number[];
  ranking: number[];
  row_id: string[];
}

export const ModelsSorting: React.FC = () => {
  const [selectedAccuracy, setSelectedAccuracy] = useState<string | null>(null);

  const { project } = useSelector((state: RootState) => state);

  const { t: translate } = useTranslation();

  useEffect(() => {
    if (project.model?.accuracy[0]) {
      setSelectedAccuracy(project.model.accuracy[0]);
    }
  }, [project.model?.accuracy]);

  const {
    data: dataAccuracy,
    isLoading: isLoadingAccuracy,
    isFetching: isFetchingAccuracy,
    isError: isErrorAccuracy,
  } = useQuery(
    [
      'model-overview-accuracy',
      project.id,
      project.selectedY,
      selectedAccuracy,
    ],
    async () => {
      const { data } = await api.get<Accuracy[]>(
        `/projects/${project.id}/${project.selectedY?.id}/models/accuracy/${selectedAccuracy}`,
      );
      return data;
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled: !!project.id && !!project.selectedY && !!selectedAccuracy,
    },
  );

  function handleAddPercentageInSomeAccuracies(accuracy: string): string {
    if (accuracy === 'MAPE' || accuracy === 'WMAPE' || accuracy === 'MPE') {
      return `${accuracy} (%)`;
    }
    return accuracy;
  }

  function handleSelectAccuracy(value: string) {
    setSelectedAccuracy(value);
  }

  const series: HChartsSeries[] = useMemo((): HChartsSeries[] => {
    if (!dataAccuracy) {
      return [];
    }

    return dataAccuracy.map((accuracy, accuracyIndex) => {
      const name =
        accuracy.type === 'ARIMA'
          ? accuracy.type
          : accuracy.type === 'Forecast Combination'
          ? `${translate(accuracy.type.replace(' ', '_'))}`
          : accuracy?.type === 'Regularized Regression'
          ? `${translate(accuracy.type.replace(' ', '_'))}`
          : accuracy?.type === 'Random Forest'
          ? accuracy?.type
          : accuracy?.type === 'Elementary'
          ? `${translate(accuracy.type)}`
          : accuracy.type;

      const hasPercentage = ['MAPE', 'WMAPE', 'MPE'].includes(
        selectedAccuracy || '',
      );

      return {
        name,
        type: 'scatter',
        color: getChartColor(accuracyIndex),
        data: accuracy.accuracy.map((y, index) => ({
          y,
          x: accuracy.ranking[index],
          custom: {
            value: `${formatCompactNotation(y)} ${hasPercentage ? '%' : ''}`,
            keyValue: selectedAccuracy,
            ranking: accuracy.ranking[index],
            rowId: accuracy.row_id[index],
          },
        })),
        marker: {
          radius: 4,
          symbol: 'circle',
        },
      };
    });
  }, [dataAccuracy, selectedAccuracy, translate]);

  const options: HChartsOptions = {
    chart: {
      height: 300,
    },
    tooltip: {
      pointFormat:
        `<tr><td><b>${translate('ranking')}:</b> </td>` +
        '<td style="text-align: right">{point.custom.ranking}</td></tr>' +
        `<tr><td><b>{point.custom.keyValue}:</b> </td>` +
        '<td style="text-align: right">{point.custom.value}</td></tr>' +
        `<tr><td><b>row_id:</b> </td>` +
        `<td style="text-align: right">{point.custom.rowId}</td></tr>`,
    },
    xAxis: {
      minTickInterval: undefined,
      type: undefined,
      title: {
        text: translate('ranking'),
      },
    },
    yAxis: {
      title: {
        text: handleAddPercentageInSomeAccuracies(selectedAccuracy || ''),
      },
    },
  };

  return (
    <ContainerModelSelectedCriterion
      className="containerLinear"
      data-testid="container-model-selected-criterion"
    >
      <Card textCard={translate('modelOverviewModelsSorting')} />

      {project.projectError ? (
        <ContainerMaintenance
          content="chart"
          data-testid="container-model-selected-criterion-error"
        />
      ) : project.model ? (
        <>
          <Select
            label={translate('modelOverviewAccuracyCriteria')}
            // information="texto aqui"
            onChange={(option: any) => {
              handleSelectAccuracy(option.value);
            }}
            value={{
              label: selectedAccuracy ?? project.model.accuracy[0],
              value: selectedAccuracy ?? project.model.accuracy[0],
            }}
            options={project.model.accuracy.map((accuracy) => ({
              label: accuracy,
              value: accuracy,
            }))}
            style={{ marginBottom: '24px', width: '320px' }}
          />
          {isErrorAccuracy ? (
            <ContainerMaintenance
              content="chart"
              data-testid="container-model-selected-criterion-chart-error"
            />
          ) : isLoadingAccuracy || isFetchingAccuracy || !dataAccuracy ? (
            <ContainerSkeleton data-testid="container-model-selected-criterion-chart-loading" />
          ) : (
            <HCharts
              series={series}
              options={options}
              dataCy="container-model-selected-criterion-chart"
              resizeWidthWithSidebar
              sleepTimerToResize={false}
              animationToResize
              removeWidthToResize={20 * 16 + 28}
            />
          )}
        </>
      ) : (
        <ContainerSkeleton
          data-testid="container-model-selected-criterion-loading"
          style={{ height: '394px' }}
        />
      )}
    </ContainerModelSelectedCriterion>
  );
};
