import styled from 'styled-components';

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 1rem;

  height: 100%;

  gap: 1.5rem;

  border-radius: 0.5rem;
  border: 1px solid ${({ theme }) => theme.colors.gray2};

  h4 {
    color: ${({ theme }) => theme.colors.gray5};

    font-family: Inter;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.06rem;
    text-transform: uppercase;
  }

  span {
    color: ${({ theme }) => theme.colors.gray6};

    font-family: Inter;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  p {
    color: ${({ theme }) => theme.colors.gray6};

    font-family: Inter;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
  }
`;

export const DescriptionTitle = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: stretch;

  svg {
    width: 0.875rem;
    height: 0.875rem;

    color: ${({ theme }) => theme.colors.gray4};

    margin-right: 0.38rem;
  }
`;

export const DescriptionLine = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: stretch;

  width: 100%;

  @media (max-width: 1870px) {
    flex-direction: column;
    align-items: start;
  }
`;

export const CopyCodeSeriesContainer = styled(DescriptionLine)`
  align-items: center;
  align-content: center;

  @media (max-width: 1870px) {
    flex-direction: column;
    align-items: start;
  }
`;
