import styled from 'styled-components';

export const Container = styled.div``;

export const StepsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;

  overflow-x: auto;

  > button {
    padding: 0.75rem;
    margin-left: 1.5rem;

    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  ::-webkit-scrollbar {
    width: 0.313rem;
    height: 0.313rem;
    padding: 0.063rem;
  }
`;

export const LatestStepContainer = styled.div`
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;

  display: flex;
  align-items: center;
  gap: 1rem;

  button {
    height: fit-content;

    padding: 0.5rem 0.75rem;

    font-weight: 500;
    font-size: 0.75rem;
    color: ${({ theme }) => theme.colors.gray5};

    border: 1px solid ${({ theme }) => theme.colors.gray2};
    border-radius: 999px;

    transition: all 0.2s;

    &:not(:disabled):hover {
      color: ${({ theme }) => theme.colors.gray6};
      border-color: ${({ theme }) => theme.colors.gray3};
    }

    &:disabled {
      color: ${({ theme }) => theme.colors.gray4};
      border-color: ${({ theme }) => theme.colors.gray1};
      cursor: not-allowed;
    }
  }
`;
