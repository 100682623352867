import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { AxiosError } from 'axios';
import { addDays, format, parseISO } from 'date-fns';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsStock from 'highcharts/modules/stock';
import { Action } from 'history';
import { ChartBar, ChartLine, DownloadSimple, Heart } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import {
  useLocation,
  useNavigate,
  useNavigationType,
  useParams,
} from 'react-router-dom';
import { ChartTypeButton } from 'src/components/ChartTypeButton';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { Head } from 'src/components/Head';
import { BackToHome } from 'src/feature-store/components/BackToHome';
import { FeatureStoreSidebarContext } from 'src/feature-store/Contexts/NavigationContext';
import { clearSerieInfo } from 'src/feature-store/redux/reducers/SerieToView';
import api from 'src/feature-store/service/api';
import NotFound from 'src/pages/404';
import { RootState } from 'src/redux/store';
import light from 'src/styles/themes/light';
import { getChartColor } from 'src/utils/colors/getChartColor';
import { formatCompactNotation } from 'src/utils/numbers/formatCompactNotation';

import { ReactComponent as ChartArea } from '../../../assets/chart_area.svg';
import { Select } from '../../../components/Select';
import { BlurChart } from './components/BlurChart';
import { ExportSeries } from './components/ExportSeries';
import FilterIndicatorSeries from './components/FilterIndicatorSeries';
import { IndicatorInfo } from './components/IndicatorInfo';
import {
  ActionButton,
  ActionButtonContainer,
  ChartContainer,
  ChartContent,
  Container,
  ContainerErrorChart,
  GridContainer,
  Header,
  HeaderChart,
  SelectContainer,
  TypeChartContainer,
} from './styles';
import {
  Aggregation,
  ChartDataType,
  DataGraph,
  DataIndicatorInfo,
  DataIndicatorsSeries,
  DataIndicatorType,
  ParamsType,
  PrimaryTransformation,
  Region,
  SecondaryTransformation,
  SelectOptions,
  SerieInfo,
  SeriesIndicator,
  ValueByLanguage,
} from './types';

export type ErrorObject = {
  title?: string;
  description?: string;
  cloudIcon?: boolean;
};

type ErrorObservationProjectionProps = {
  response: {
    status: number;
  };
};

HighchartsStock(Highcharts);

export const VisualizeFeatures: React.FC = () => {
  const [type, setType] = useState<'line' | 'column' | 'area'>('line');
  const [dataIndicatorsSeries, setDataIndicatorsSeries] =
    useState<SeriesIndicator[]>();

  const [codeSerieGraph, setCodeSerieGraph] = useState<string>('');
  const [forPlotGraph, setForPlotGraph] = useState<SeriesIndicator[]>([]);

  const [regionOptions, setRegionOptions] = useState<SelectOptions[]>();
  const [aggregationOptions, setAggregationOptions] =
    useState<SelectOptions[]>();
  const [transformationPrimaryOptions, setTransformationPrimaryOptions] =
    useState<SelectOptions[]>();
  const [transformationSecondaryOptions, setTransformationSecondaryOptions] =
    useState<SelectOptions[]>();

  const [allRegionOptions, setAllRegionOptions] = useState<SelectOptions[]>();
  const [allAggregationOptions, setAllAggregationOptions] =
    useState<SelectOptions[]>();
  const [allTransformationPrimaryOptions, setAllTransformationPrimaryOptions] =
    useState<SelectOptions[]>();
  const [
    allTransformationSecondaryOptions,
    setAllTransformationSecondaryOptions,
  ] = useState<SelectOptions[]>();

  const [serieActive, setSerieActive] = useState<boolean>(false);
  const [serieSelectedByParams, setSerieSelectedByParams] = useState(false);

  const [selectedRegion, setSelectedRegion] = useState<SelectOptions>();
  const [selectedAggregation, setSelectedAggregation] =
    useState<SelectOptions>();
  const [selectedPrimaryTransformation, setSelectedPrimaryTransformation] =
    useState<SelectOptions>();
  const [selectedSecondaryTransformation, setSelectedSecondaryTransformation] =
    useState<SelectOptions>();

  const [chartData, setChartData] = useState<ChartDataType[]>([]);

  const [modalFilters, setModalFilters] = useState<boolean>(false);
  const [modalExportSeries, setModalExportSeries] = useState<boolean>(false);

  const { serie } = useSelector((state: RootState) => state.serieToView);
  const [startDateFilter, setStartDateFilter] = useState<number>();
  const [endDateFilter, setEndDateFilter] = useState<number>();

  const [indicatorActive, setIndicatorActive] = useState<boolean>(true);

  const chartRef = useRef<any>(null);
  const { t: translate } = useTranslation();

  const navigate = useNavigate();
  const navType = useNavigationType();
  const location = useLocation();
  const dispatch = useDispatch();
  const { language } = useSelector((state: RootState) => state.auth.user);
  const { id: idSerieToView } = useSelector(
    (state: RootState) => state.serieToView,
  );

  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  );

  const { openIndicatorFilters, openPremiumFilters } = useContext(
    FeatureStoreSidebarContext,
  );

  const { id } = useParams<ParamsType>();

  const {
    data: dataIndicatorsInfo,
    isLoading: isLoadingIndicatorInfo,
    isError: isErrorIndicatorInfo,
  } = useQuery<DataIndicatorInfo, AxiosError>(
    ['data indicators info', id],
    async () => {
      const { data } = await api.get(`/indicators/${id}/`);

      return data;
    },
    {
      staleTime: 1000 * 60 * 20,
      onSuccess: (data) =>
        data.is_active === false && setIndicatorActive(false),
      onError: ({ response }) =>
        response?.status === 404 && setIndicatorActive(false),
    },
  );

  const {
    data: dataIndicators,
    isLoading: isLoadingIndicator,
    isError: isErrorIndicator,
  } = useQuery(
    ['data indicators', id],
    async () => {
      const limit = 4000;
      const dataAux = [];
      let skip = 0;
      let countTimesRequested = 1;

      const response = await api.get<DataIndicatorsSeries>(
        `/indicators/${id}/series?skip=${skip}&limit=${limit}`,
      );
      const total = response.data.total;

      if (total > limit) {
        dataAux.push(...response.data.data);
        let remaining = total;

        while (remaining > 0) {
          skip = countTimesRequested * limit;
          countTimesRequested += 1;
          const { data } = await api.get<DataIndicatorsSeries>(
            `/indicators/${id}/series?skip=${skip}&limit=${limit}`,
          );
          dataAux.push(...data.data);
          remaining -= limit;
        }
      } else {
        dataAux.push(...response.data.data);
      }

      return dataAux;
    },
    {
      staleTime: 1000 * 60 * 20,
    },
  );

  const searchCombination = useCallback(
    (
      region: string,
      aggregation: string,
      primary: string,
      second: string,
    ): SeriesIndicator | null => {
      if (dataIndicators) {
        const exists = dataIndicators.find(
          (indicator) =>
            indicator.region['en-us'] === region &&
            indicator.aggregation['en-us'] === aggregation &&
            indicator.primary_transformation['en-us'] === primary &&
            indicator.second_transformation['en-us'] === second,
        );

        if (exists) {
          return exists;
        }
      }
      return null;
    },
    [dataIndicators],
  );

  useEffect(() => {
    const hasParams =
      !!searchParams.get('region') &&
      !!searchParams.get('aggregation') &&
      !!searchParams.get('primary') &&
      !!searchParams.get('second');

    if (!hasParams) {
      return;
    }

    const region = String(searchParams.get('region'));
    const aggregation = String(searchParams.get('aggregation'));
    const primary = String(searchParams.get('primary'));
    const second = String(searchParams.get('second'));

    const exists = !!searchCombination(region, aggregation, primary, second);

    if (exists) {
      setSerieSelectedByParams(true);
    }
  }, [searchCombination, searchParams]);

  useEffect(() => {
    if (
      dataIndicators &&
      serieActive === false &&
      selectedRegion &&
      selectedAggregation &&
      selectedPrimaryTransformation &&
      selectedSecondaryTransformation &&
      !idSerieToView
    ) {
      const isActive = dataIndicators.find(
        (active) => active.status === 'active',
      );
      if (isActive) {
        setSelectedRegion({
          label: isActive.region,
          value: isActive.region['en-us'],
        });
        setSelectedAggregation({
          label: isActive.aggregation,
          value: isActive.aggregation['en-us'],
        });
        setSelectedPrimaryTransformation({
          label: isActive.primary_transformation,
          value: isActive.primary_transformation['en-us'],
        });
        setSelectedSecondaryTransformation({
          label: isActive.second_transformation,
          value: isActive.second_transformation['en-us'],
        });
        setSerieActive(true);
      }
    }
  }, [
    dataIndicators,
    idSerieToView,
    searchParams,
    selectedAggregation,
    selectedPrimaryTransformation,
    selectedRegion,
    selectedSecondaryTransformation,
    serieActive,
    serieSelectedByParams,
  ]);

  useEffect(
    () => () => {
      if (navType === Action.Pop) {
        if (serie) {
          dispatch(clearSerieInfo());
        }
        if (location.pathname === '/feature-store/indicators') {
          navigate(location.pathname, {
            state: {
              isReturningFromPageIndicatorId: true,
            },
            replace: true,
          });
        }
      }
    },
    [navigate, location, serie, dispatch, navType],
  );

  useEffect(() => {
    if (dataIndicators) {
      setDataIndicatorsSeries(dataIndicators);
    }
  }, [dataIndicators]);

  useEffect(() => {
    if (dataIndicators) {
      const allRegion: Region[] = [];

      dataIndicators.forEach((key) => {
        if (
          !allRegion.some(
            (region) => region.name['en-us'] === key.region['en-us'],
          )
        ) {
          allRegion.push({
            access_type: key.access_type,
            name: {
              'en-us': key.region['en-us'],
              'pt-br': key.region['pt-br'],
            },
          });
        }
      });

      const allRegionOptionsAux = Array.from(allRegion).map((key) => ({
        label: key.name,
        value: key.name['en-us'],
        access_type: key.access_type,
      }));

      setAllRegionOptions(allRegionOptionsAux);

      const allAggregation: Aggregation[] = [];

      dataIndicators.forEach((key) => {
        if (
          !allAggregation.some(
            (aggregation) =>
              aggregation.name['en-us'] === key.aggregation['en-us'],
          )
        ) {
          allAggregation.push({
            access_type: key.access_type,
            name: {
              'en-us': key.aggregation['en-us'],
              'pt-br': key.aggregation['pt-br'],
            },
          });
        }
      });

      const allAggregationOptionsAux = Array.from(allAggregation).map(
        (key) => ({
          label: key.name,
          value: key.name['en-us'],
          access_type: key.access_type,
        }),
      );

      setAllAggregationOptions(allAggregationOptionsAux);

      const allPrimaryTransformation: PrimaryTransformation[] = [];

      dataIndicators.forEach((key) => {
        if (
          !allPrimaryTransformation.some(
            (primaryTransformation) =>
              primaryTransformation.name['en-us'] ===
              key.primary_transformation['en-us'],
          )
        ) {
          allPrimaryTransformation.push({
            access_type: key.access_type,
            name: {
              'en-us': key.primary_transformation['en-us'],
              'pt-br': key.primary_transformation['pt-br'],
            },
          });
        }
      });

      const allPrimaryTransformationOptionsAux = Array.from(
        allPrimaryTransformation,
      ).map((key) => ({
        label: key.name,
        value: key.name['en-us'],
        access_type: key.access_type,
      }));

      setAllTransformationPrimaryOptions(allPrimaryTransformationOptionsAux);

      const allSecondaryTransformation: SecondaryTransformation[] = [];

      dataIndicators.forEach((key) => {
        if (
          !allSecondaryTransformation.some(
            (secondaryTransformation) =>
              secondaryTransformation.name['en-us'] ===
              key.second_transformation['en-us'],
          )
        ) {
          allSecondaryTransformation.push({
            access_type: key.access_type,
            name: {
              'en-us': key.second_transformation['en-us'],
              'pt-br': key.second_transformation['pt-br'],
            },
          });
        }
      });

      const allSecondaryTransformationOptionsAux = Array.from(
        allSecondaryTransformation,
      ).map((key) => ({
        label: key.name,
        value: key.name['en-us'],
        access_type: key.access_type,
      }));

      setAllTransformationSecondaryOptions(
        allSecondaryTransformationOptionsAux,
      );
    }
  }, [dataIndicators]);

  useEffect(() => {
    if (dataIndicators) {
      const regions: ValueByLanguage[] = [];

      dataIndicators.forEach((key) => {
        if (
          !regions.some((region) => region['en-us'] === key.region['en-us'])
        ) {
          regions.push(key.region);
        }
      });

      const regionOptionsAux = Array.from(regions).map((key) => ({
        label: key,
        value: key['en-us'],
      }));

      setRegionOptions(regionOptionsAux);
    }
  }, [dataIndicators]);

  useEffect(() => {
    if (dataIndicators && selectedRegion) {
      const getAggregationOptions: ValueByLanguage[] = [];

      dataIndicators
        ?.filter((key) => key.region['en-us'] === selectedRegion.value)
        .forEach((key) => {
          if (
            !getAggregationOptions.some(
              (aggregationOption) =>
                aggregationOption['en-us'] === key.aggregation['en-us'],
            )
          ) {
            getAggregationOptions.push(key.aggregation);
          }
        });

      const aggregationOptionsAux = Array.from(getAggregationOptions).map(
        (key) => ({
          label: key,
          value: key['en-us'],
        }),
      );

      setAggregationOptions(aggregationOptionsAux);

      if (
        serie &&
        aggregationOptionsAux.some(
          (frequency) => frequency.value === serie.frequency,
        )
      ) {
        setSelectedAggregation(
          aggregationOptionsAux.find(
            (aggregationOptAux) => aggregationOptAux.value === serie.frequency,
          ),
        );
      } else if (
        serieSelectedByParams &&
        aggregationOptionsAux.some(
          (frequency) => frequency.value === searchParams.get('aggregation'),
        )
      ) {
        setSelectedAggregation(
          aggregationOptionsAux.find(
            (aggregationOptAux) =>
              aggregationOptAux.value === searchParams.get('aggregation'),
          ),
        );
      } else {
        setSelectedAggregation(aggregationOptionsAux[0]);
      }
    }
  }, [
    dataIndicators,
    searchParams,
    selectedRegion,
    serie,
    serieSelectedByParams,
  ]);

  useEffect(() => {
    if (dataIndicators && selectedRegion && selectedAggregation) {
      const transformationPrimarys: ValueByLanguage[] = [];
      dataIndicators
        .filter(
          (key) =>
            key.region['en-us'] === selectedRegion.value &&
            key.aggregation['en-us'] === selectedAggregation.value,
        )
        .forEach((key) => {
          if (
            !transformationPrimarys.some(
              (transformationPrimary) =>
                transformationPrimary['en-us'] ===
                key.primary_transformation['en-us'],
            )
          ) {
            transformationPrimarys.push(key.primary_transformation);
          }
        });

      const transformationPrimaryAux = Array.from(transformationPrimarys).map(
        (key) => ({
          label: key,
          value: key['en-us'],
        }),
      );

      setTransformationPrimaryOptions(transformationPrimaryAux);

      if (
        serie &&
        transformationPrimaryAux.some(
          (primaryTransformation) =>
            primaryTransformation.value === serie.primaryTransformation,
        )
      ) {
        setSelectedPrimaryTransformation(
          transformationPrimaryAux.find(
            (transfPrimaryAux) =>
              transfPrimaryAux.value === serie.primaryTransformation,
          ),
        );
      } else if (
        serieSelectedByParams &&
        transformationPrimaryAux.some(
          (primaryTransformation) =>
            primaryTransformation.value === searchParams.get('primary'),
        )
      ) {
        setSelectedPrimaryTransformation(
          transformationPrimaryAux.find(
            (transfPrimaryAux) =>
              transfPrimaryAux.value === searchParams.get('primary'),
          ),
        );
      } else {
        setSelectedPrimaryTransformation(transformationPrimaryAux[0]);
      }
    }
  }, [
    dataIndicators,
    searchParams,
    selectedAggregation,
    selectedRegion,
    serie,
    serieSelectedByParams,
  ]);

  useEffect(() => {
    if (regionOptions && regionOptions[0]) {
      if (serie) {
        setSelectedRegion(
          regionOptions.find((region) => region.value === serie.region),
        );
      } else if (serieSelectedByParams) {
        setSelectedRegion(
          regionOptions.find(
            (region) => region.value === searchParams.get('region'),
          ),
        );
      } else {
        setSelectedRegion(regionOptions[0]);
      }
    }
  }, [regionOptions, searchParams, serie, serieSelectedByParams]);

  useEffect(() => {
    if (
      dataIndicators &&
      selectedRegion &&
      selectedAggregation &&
      selectedPrimaryTransformation
    ) {
      const transformationSecondarys: ValueByLanguage[] = [];
      dataIndicators
        .filter(
          (key) =>
            key.region['en-us'] === selectedRegion.value &&
            key.aggregation['en-us'] === selectedAggregation.value &&
            key.primary_transformation['en-us'] ===
              selectedPrimaryTransformation.value,
        )
        .forEach((key) => {
          if (
            !transformationSecondarys.some(
              (transformationSecondary) =>
                transformationSecondary['en-us'] ===
                key.second_transformation['en-us'],
            )
          ) {
            transformationSecondarys.push(key.second_transformation);
          }
        });

      const transformationSecondaryAux = Array.from(
        transformationSecondarys,
      ).map((key) => ({
        label: key,
        value: key['en-us'],
      }));

      setTransformationSecondaryOptions(transformationSecondaryAux);

      if (
        serie &&
        transformationSecondaryAux.some(
          (secondaryTransformation) =>
            secondaryTransformation.value === serie.secondaryTransformation,
        )
      ) {
        setSelectedSecondaryTransformation(
          transformationSecondaryAux.find(
            (transfSecondaryAux) =>
              transfSecondaryAux.value === serie.secondaryTransformation,
          ),
        );
      } else if (
        serieSelectedByParams &&
        transformationSecondaryAux.some(
          (secondaryTransformation) =>
            secondaryTransformation.value === searchParams.get('second'),
        )
      ) {
        setSelectedSecondaryTransformation(
          transformationSecondaryAux.find(
            (transfSecondaryAux) =>
              transfSecondaryAux.value === searchParams.get('second'),
          ),
        );
      } else {
        setSelectedSecondaryTransformation(transformationSecondaryAux[0]);
      }
    }
  }, [
    dataIndicators,
    searchParams,
    selectedAggregation,
    selectedPrimaryTransformation,
    selectedRegion,
    serie,
    serieSelectedByParams,
  ]);

  useEffect(() => {
    if (dataIndicatorsSeries) {
      const plotGraph = dataIndicatorsSeries.filter(
        (eachIndicator) =>
          eachIndicator.region['en-us'] === selectedRegion?.value &&
          eachIndicator.aggregation['en-us'] === selectedAggregation?.value &&
          eachIndicator.primary_transformation['en-us'] ===
            selectedPrimaryTransformation?.value &&
          eachIndicator.second_transformation['en-us'] ===
            selectedSecondaryTransformation?.value,
      );
      setForPlotGraph(plotGraph);
      setCodeSerieGraph(plotGraph[0]?.code);
    }
  }, [
    dataIndicatorsSeries,
    selectedRegion,
    selectedAggregation,
    selectedPrimaryTransformation,
    selectedSecondaryTransformation,
  ]);

  useEffect(() => {
    openIndicatorFilters(false);
    openPremiumFilters(false);
  }, [openIndicatorFilters, openPremiumFilters]);

  const {
    data: dataIndicatorsSeriesData,
    isFetching: isFetchingIndicatorData,
    isLoading: isLoadingIndicatorSerie,
    isError: isErrorIndicatorSerie,
    error: errorObservationProjection,
  } = useQuery<DataGraph[][], ErrorObservationProjectionProps>(
    ['data indicator series observations', codeSerieGraph, id],
    async () => {
      const allDataObservation: DataGraph[] = [];
      const allDataProjections: DataGraph[] = [];

      const { data: observationData } = await api.get<DataIndicatorType>(
        `/indicators/${id}/series/${codeSerieGraph}/observations?limit=1000`,
      );

      allDataObservation.push(...observationData.data);

      for (
        let i = observationData.limit;
        i < observationData.total;
        i += 1000
      ) {
        const { data: observationAux } = await api.get<DataIndicatorType>(
          `/indicators/${id}/series/${codeSerieGraph}/observations?limit=1000&skip=${i}`,
        );

        allDataObservation.push(...observationAux.data);
      }

      const startDateProjection = format(
        addDays(
          parseISO(
            allDataObservation[allDataObservation.length - 1].date.slice(0, 10),
          ),
          1,
        ),
        'yyyy-MM-dd',
      );

      if (startDateProjection) {
        const yearOfLastHistoricalDate = Number(
          allDataObservation[allDataObservation.length - 1].date.slice(0, 4),
        );

        const { data: projectionData } = await api.get<DataIndicatorType>(
          `/indicators/${id}/series/${codeSerieGraph}/projections?limit=1000&start=${startDateProjection}&end=${
            yearOfLastHistoricalDate + 4
          }-12-31`,
        );

        allDataProjections.push(...projectionData.data);
        for (
          let i = projectionData.limit;
          i < projectionData.total;
          i += 1000
        ) {
          const { data: projectionAux } = await api.get<DataIndicatorType>(
            `/indicators/${id}/series/${codeSerieGraph}/projections?limit=1000&skip=${i}&start=${startDateProjection}&end=${
              yearOfLastHistoricalDate + 4
            }-12-31`,
          );

          allDataProjections.push(...projectionAux.data);
        }
      }

      return [allDataObservation, allDataProjections];
    },
    {
      staleTime: 1000 * 60,
      enabled:
        codeSerieGraph?.length > 1 &&
        !!regionOptions &&
        !!aggregationOptions &&
        !!selectedPrimaryTransformation &&
        !!selectedSecondaryTransformation,
    },
  );

  const {
    data: serieDataInfo,
    isLoading: isLoadingSerieDataInfo,
    isError: isErrorSerieDataInfo,
  } = useQuery(
    ['serie data info', codeSerieGraph],
    async () => {
      const { data } = await api.get<SerieInfo>(`/series/${codeSerieGraph}`);

      return data;
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled: codeSerieGraph?.length >= 1,
    },
  );

  useEffect(() => {
    if (dataIndicatorsSeriesData) {
      const auxChart: ChartDataType[] = [];

      if (dataIndicatorsSeriesData[1].length) {
        auxChart.push({
          x: dataIndicatorsSeriesData[1].map((data) => data.date),
          y: dataIndicatorsSeriesData[1].map((data) => data.value),
          name: translate('viewFeatureProjection'),
        });
      }

      if (dataIndicatorsSeriesData[0].length) {
        auxChart.push({
          x: dataIndicatorsSeriesData[0]?.map((data) => data.date),
          y: dataIndicatorsSeriesData[0]?.map((data) => data.value),
          name: translate('viewFeatureHistorical'),
        });
      }

      if (
        dataIndicatorsSeriesData[0].length &&
        dataIndicatorsSeriesData[1].length
      ) {
        if (type !== 'column') {
          auxChart[0].x = [
            auxChart[1].x[auxChart[1].x.length - 1],
            ...auxChart[0].x,
          ];

          auxChart[0].y = [
            auxChart[1].y[auxChart[1].y.length - 1],
            ...auxChart[0].y,
          ];
        }
      }

      setChartData(auxChart);

      return () => {
        setChartData([]);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataIndicatorsSeriesData, language, type]);

  // eslint-disable-next-line func-names
  const tooltipFormatter = function (this: any) {
    /* eslint-disable react/no-this-in-sfc */
    const formattedDate = format(new Date(this.x), 'dd/MM/yyyy');

    return `
    <p><b>${translate('date')}:</b> ${formattedDate}</p>  
    <p><b>${this.points[0].series.name}:</b> ${formatCompactNotation(
      this.points[0].y,
    )}</p>
    `;
  };

  useEffect(() => {
    if (!serieDataInfo?.data?.date_end) return;

    const start = new Date(serieDataInfo?.data?.date_end);
    const end = new Date(serieDataInfo?.data?.date_end);
    start.setFullYear(start.getFullYear() - 3);
    end.setFullYear(end.getFullYear() + 2);
    const startFilter = start.getTime();
    const endFilter = end.getTime();

    setStartDateFilter(startFilter);
    setEndDateFilter(endFilter);

    if (chartRef.current && chartRef.current.chart) {
      loadChart(chartRef.current.chart);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serieDataInfo?.data?.date_end]);

  const loadChart = (chart: any) => {
    if (startDateFilter !== null && endDateFilter !== null) {
      chart.xAxis[0].setExtremes(startDateFilter, endDateFilter);
    }
  };

  function zoomChart(
    event: Highcharts.AxisSetExtremesEventObject,
    chart: Highcharts.Axis,
  ) {
    //@ts-expect-error: nao reconhe a propriedade chart
    if (event.target.chart?.yAxis?.length) {
      //@ts-expect-error: nao reconhe a propriedade chart
      const min = event.target.chart?.yAxis[0]?.dataMin;
      //@ts-expect-error: nao reconhe a propriedade chart
      const max = event.target.chart?.yAxis[0]?.dataMax;

      if (typeof min === 'number' && typeof max === 'number') {
        chart.chart.yAxis[0].setExtremes(min > 0 ? min - 0.0001 : min, max);
      }
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  let series: Highcharts.SeriesOptionsType[] = [];

  if (chartData.length === 1 && chartData[0]?.x && chartData[0]?.y) {
    if (
      chartData[0].name === 'Historical' ||
      chartData[0].name === 'Histórico'
    ) {
      series.push({
        name: translate('viewFeatureHistorical'),
        data: chartData[0].x.map((date, index) => {
          const previousValueIsNullOrUndefined =
            chartData[0].y[index - 1] === null ||
            chartData[0].y[index - 1] === undefined;

          const nextValueIsNullOrUndefined =
            chartData[0].y[index + 1] === null ||
            chartData[0].y[index + 1] === undefined;

          const hasMarker =
            previousValueIsNullOrUndefined && nextValueIsNullOrUndefined;

          return {
            x: new Date(date).getTime(),
            y: chartData[0].y[index],
            marker: hasMarker
              ? {
                  enabled: true,
                  radius: 2,
                }
              : undefined,
          };
        }),
        color: getChartColor(0),
        type,
        dataGrouping: {
          enabled: false, // Desativa o agrupamento automático
        },
      });
    }
  }

  if (chartData.length === 2) {
    series.push({
      name: translate('viewFeatureHistorical'),
      data: chartData[1].x.map((date, index) => {
        const previousValueIsNullOrUndefined =
          chartData[1].y[index - 1] === null ||
          chartData[1].y[index - 1] === undefined;

        const nextValueIsNullOrUndefined =
          chartData[1].y[index + 1] === null ||
          chartData[1].y[index + 1] === undefined;

        const hasMarker =
          previousValueIsNullOrUndefined && nextValueIsNullOrUndefined;

        return {
          x: new Date(date).getTime(),
          y: chartData[1].y[index],
          marker: hasMarker
            ? {
                enabled: true,
                radius: 2,
              }
            : undefined,
        };
      }),
      color: getChartColor(0),
      type,
      dataGrouping: {
        enabled: false, // Desativa o agrupamento automático
      },
    });

    series.push({
      name: translate('viewFeaturesProjection'),
      data: chartData[0].x.map((date, index) => {
        const previousValueIsNullOrUndefined =
          chartData[0].y[index - 1] === null ||
          chartData[0].y[index - 1] === undefined;

        const nextValueIsNullOrUndefined =
          chartData[0].y[index + 1] === null ||
          chartData[0].y[index + 1] === undefined;

        const hasMarker =
          previousValueIsNullOrUndefined && nextValueIsNullOrUndefined;

        return {
          x: new Date(date).getTime(),
          y: chartData[0].y[index],
          marker: hasMarker
            ? {
                enabled: true,
                radius: 2,
              }
            : undefined,
        };
      }),
      color: getChartColor(1),
      type,
      dataGrouping: {
        enabled: false, // Desativa o agrupamento automático
      },
    });
  }

  if (type === 'area') {
    //@ts-expect-error: o typescript do hightcharts nao reconhce
    series = series.map((serieAux, index) => ({
      ...serieAux,
      fillColor: {
        linearGradient: {
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1,
        },
        stops: [
          [0, `${getChartColor(index)}EE`],
          [1, `${getChartColor(index)}25`],
        ],
      },
    }));
  }

  useEffect(() => {
    const hasProjection = chartData.length === 2;

    if (chartRef.current && hasProjection && ['line', 'area'].includes(type)) {
      const historicalIsVisible = chartRef.current.chart.series[0].visible;

      if (!historicalIsVisible) {
        chartRef.current.chart.series[1].removePoint(0, true);
      }
    }
  }, [type, chartData, series]);

  useEffect(() => {
    const chart = chartRef?.current?.chart;

    if (chart) {
      const min = chart?.yAxis[0]?.dataMin;
      const max = chart?.yAxis[0]?.dataMax;

      if (min !== undefined && max !== undefined) {
        chart.yAxis[0].setExtremes(min, max);
      }
    }
  }, [
    selectedRegion,
    selectedAggregation,
    selectedPrimaryTransformation,
    selectedSecondaryTransformation,
    chartData,
  ]);

  const options: Highcharts.Options = {
    time: {
      useUTC: true,
      timezoneOffset: -1 * -180, //UTC-3:00 time zone
    },
    lang: {
      numericSymbols: ['k', 'M', 'B', 'T', 'P', 'E'],
    },
    chart: {
      events: {
        load() {
          loadChart(this);
          this.redraw(false);
        },
      },
      spacingTop: 32,
      spacingLeft: 1,
      type,
      zooming: {
        type: 'xy',
        resetButton: {
          theme: {
            stroke: '#f1f7ff',
            'stroke-width': 1,
            padding: 8,
            fill: '#f1f7ff',
            style: {
              color: light.colors.primary,
              fontStyle: 'normal',
              fontSize: '0.75rem',
              fontWeight: '500',
              fontFamily: "'Inter', sans-serif",
            },
            states: {
              hover: {
                fill: '#edf4ff',
                stroke: '#edf4ff',
                style: {
                  color: light.colors.primaryDark,
                  fontWeight: '500',
                },
              },
            },
          },
        },
      },
    },
    credits: {
      enabled: false,
    },
    yAxis: {
      lineWidth: 0,
      gridLineWidth: 1,
      gridLineColor: light.colors.gray2,
      showLastLabel: true,
      labels: {
        style: {
          fontSize: '0.75rem',
          fontFamily: "'Inter', sans-serif",
        },
      },
      opposite: false,
    },
    xAxis: {
      events: {
        afterSetExtremes(event) {
          zoomChart(event, this);
        },
      },
      type: 'datetime',
      minTickInterval:
        selectedAggregation?.label['en-us'].toLowerCase().includes('daily') ||
        selectedAggregation?.label['en-us'].toLowerCase().includes('weekly')
          ? 24 * 3600 * 1000
          : selectedAggregation?.label['en-us'].toLowerCase().includes('yearly')
          ? 365 * 24 * 3600 * 1000
          : 30 * 24 * 3600 * 1000,
      labels: {
        style: {
          fontSize: '0.75rem',
          fontFamily: "'Inter', sans-serif",
          textAlign: 'center',
        },
        y: 26,
      },
      dateTimeLabelFormats: {
        day: '%b %d <br/> %Y',
        week: '%b %d <br/> %Y',
        month: '%b %Y',
        year: '%Y',
      },
    },
    title: {
      text: '',
    },
    tooltip: {
      valueDecimals: 2,
      formatter: tooltipFormatter,
      style: {
        fontStyle: 'normal',
        fontSize: '0.875rem',
        fontWeight: '400',
        color: light.colors.gray7,
        fontFamily: "'Inter', sans-serif",
      },
      borderRadius: 8,
      useHTML: true,
    },
    legend: {
      enabled: true,
      align: 'center',
      verticalAlign: 'bottom',
      layout: 'horizontal',
      itemStyle: {
        marginTop: '0.625rem',
        fontStyle: 'normal',
        fontSize: '0.875rem',
        fontWeight: '400',
        fontFamily: "'Inter', sans-serif",
      },
    },
    rangeSelector: {
      inputEnabled: false,
      enabled: false,
      labelStyle: {
        display: 'none',
      },
      buttonSpacing: 8,
      buttonTheme: {
        stroke: light.colors.primary, // basic
        'stroke-width': 1, // hyphenated
        padding: 4,
        fill: light.colors.white,
        style: {
          color: light.colors.primary,
          fontStyle: 'normal',
          fontSize: '0.75rem',
          fontWeight: '500',
          fontFamily: "'Inter', sans-serif",
        },
        states: {
          hover: {
            fill: light.colors.white, // basic
            stroke: light.colors.primaryDark,
            style: {
              color: light.colors.primaryDark,
              fontWeight: '500',
            },
          },
          select: {
            fill: light.colors.primary, // basic
            stroke: light.colors.primary,
            style: {
              color: light.colors.white,
              fontWeight: '500',
            },
          },
          disabled: {
            fill: light.colors.white, // basic
            stroke: light.colors.gray3,
            style: {
              color: light.colors.gray3,
              fontWeight: '500',
            },
          },
        },
      },
      buttons: [
        {
          type: 'month',
          count: 3,
          text: '3M',
        },
        {
          type: 'month',
          count: 6,
          text: '6M',
        },

        {
          type: 'year',
          count: 1,
          text: '1Y',
        },
        {
          type: 'year',
          count: 3,
          text: '3Y',
        },
        {
          type: 'year',
          count: 5,
          text: '5Y',
        },
        {
          type: 'year',
          count: 10,
          text: '10Y',
        },
        {
          type: 'all',
          dataGrouping: {
            enabled: false,
          },
          text: 'MAX',
        },
      ],
    },
    scrollbar: {
      enabled: false,
    },
    navigator: {
      xAxis: {
        gridLineWidth: 0,
      },
      series: {
        type: 'line',
      },
    },
    series,
    plotOptions: {
      line: {
        lineWidth: 3,
      },
      area: {
        lineWidth: 3,
      },
      column: {
        pointPadding: 0.1,
        grouping: false,
      },
      series: {
        turboThreshold: 0,
        showInNavigator: true,
        events: {
          legendItemClick() {
            const legendClicked = this.name;
            const isShowing = this.visible;
            const data = chartData[0];

            if (
              legendClicked === translate('viewFeatureHistorical') &&
              data.name === translate('viewFeatureProjection') &&
              ['line', 'area'].includes(type)
            ) {
              if (isShowing) {
                this.chart.series[1].removePoint(0, false);
              } else {
                const lastHistoricalDate = chartData[1].x.at(-1);
                const lastHistoricalValue = chartData[1].y.at(-1);

                if (lastHistoricalDate && lastHistoricalValue) {
                  this.chart.series[1].addPoint(
                    {
                      x: new Date(lastHistoricalDate).getTime(),
                      y: lastHistoricalValue,
                    },
                    false,
                  );
                }
              }
            }
          },
        },
      },
    },
  };

  const ptBrLang: Highcharts.LangOptions = {
    numericSymbols: ['k', 'M', 'B', 'T', 'P', 'E'],
    shortMonths: [
      'Jan',
      'Fev',
      'Mar',
      'Abr',
      'Mai',
      'Jun',
      'Jul',
      'Ago',
      'Set',
      'Out',
      'Nov',
      'Dez',
    ],
    decimalPoint: '.',
    thousandsSep: ',',
  };

  const enUsLang: Highcharts.LangOptions = {
    numericSymbols: ['k', 'M', 'B', 'T', 'P', 'E'],
    shortMonths: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
    decimalPoint: '.',
    thousandsSep: ',',
  };

  const labelName = () => {
    const pattern = /BRANB/;
    const indicatorCode = id ?? '';
    const isAnbima = pattern.test(indicatorCode);
    return isAnbima
      ? translate('viewFeaturesVariable')
      : translate('viewFeaturesRegion');
  };

  Highcharts.setOptions({ lang: language === 'pt-br' ? ptBrLang : enUsLang });

  return (
    <Container>
      <Head title={translate('viewFeaturesTitle')} />

      {indicatorActive ? (
        <>
          {isErrorIndicator && isErrorIndicatorInfo ? (
            <>
              <Header>
                <BackToHome />
              </Header>

              <div className="containerLinear" style={{ marginTop: '1rem' }}>
                <ContainerErrorChart>
                  <ContainerMaintenance
                    content="data"
                    text={translate('viewFeaturesUnableSerie')}
                  />
                </ContainerErrorChart>
              </div>
            </>
          ) : isLoadingIndicatorInfo ? (
            <>
              <div className="containerLinear">
                <ContainerSkeleton
                  withLoading={false}
                  style={{
                    height: '9.375rem',
                  }}
                />
              </div>
              <div className="containerLinear">
                <ContainerSkeleton
                  withLoading={false}
                  style={{
                    height: '31.25rem',
                  }}
                />
              </div>
            </>
          ) : dataIndicatorsInfo ? (
            <>
              <Header>
                <BackToHome />

                <h4>
                  {dataIndicatorsInfo.name[language] ??
                    dataIndicatorsInfo.name['en-us']}
                </h4>

                <ActionButtonContainer>
                  <ActionButton
                    onClick={() => {
                      setModalFilters(true);
                    }}
                    data-cy="button-send-to-group"
                    data-testid="button-send-to-group"
                    disabled={chartData.length === 0}
                  >
                    <Heart /> {translate('viewFeaturesSaveToGroup')}
                  </ActionButton>

                  <ActionButton
                    onClick={() => {
                      setModalExportSeries(true);
                    }}
                    data-testid="button-export"
                    data-cy="button-export"
                    disabled={isErrorIndicator || isLoadingIndicator}
                  >
                    <DownloadSimple /> {translate('export')}
                  </ActionButton>
                </ActionButtonContainer>
              </Header>

              <GridContainer>
                <ChartContainer>
                  {selectedRegion &&
                  regionOptions &&
                  selectedAggregation &&
                  aggregationOptions &&
                  selectedPrimaryTransformation &&
                  transformationPrimaryOptions &&
                  selectedSecondaryTransformation &&
                  language &&
                  transformationSecondaryOptions ? (
                    <SelectContainer>
                      <Select
                        label={labelName()}
                        defaultValue={{
                          label:
                            regionOptions[0].label[language] ??
                            regionOptions[0].label['en-us'],
                          value: regionOptions[0].value,
                        }}
                        options={regionOptions.map((regionOption) => ({
                          label:
                            regionOption.label[language] ??
                            regionOption.label['en-us'],
                          value: regionOption.value,
                        }))}
                        onChange={(option: any) => {
                          if (option.value !== selectedRegion.value) {
                            setSelectedRegion(
                              regionOptions.find(
                                (regionOption) =>
                                  regionOption.value === option.value,
                              ),
                            );
                            setSelectedAggregation(undefined);
                            setSelectedPrimaryTransformation(undefined);
                            setSelectedSecondaryTransformation(undefined);
                          }
                        }}
                        value={{
                          label:
                            selectedRegion.label[language] ??
                            selectedRegion.label['en-us'],
                          value: selectedRegion.value,
                        }}
                        style={{ width: '15.625rem' }}
                      />
                      <Select
                        label={translate('viewFeaturesFrequency')}
                        defaultValue={{
                          label:
                            aggregationOptions[0].label[language] ??
                            aggregationOptions[0].label['en-us'],
                          value: aggregationOptions[0].value,
                        }}
                        options={aggregationOptions.map(
                          (aggregationOption) => ({
                            label:
                              aggregationOption.label[language] ??
                              aggregationOption.label['en-us'],
                            value: aggregationOption.value,
                          }),
                        )}
                        onChange={(option: any) => {
                          if (option.value !== selectedAggregation.value) {
                            setSelectedAggregation(
                              aggregationOptions.find(
                                (aggregationOption) =>
                                  aggregationOption.value === option.value,
                              ),
                            );
                            setSelectedPrimaryTransformation(undefined);
                            setSelectedSecondaryTransformation(undefined);
                          }
                        }}
                        value={{
                          label:
                            selectedAggregation.label[language] ??
                            selectedAggregation.label['en-us'],
                          value: selectedAggregation.value,
                        }}
                        style={{ width: '15.625rem' }}
                      />
                      <Select
                        label={translate('viewFeaturesPrimaryTr')}
                        defaultValue={{
                          label:
                            transformationPrimaryOptions[0].label[language] ??
                            transformationPrimaryOptions[0].label['en-us'],
                          value: transformationPrimaryOptions[0].value,
                        }}
                        options={transformationPrimaryOptions.map(
                          (transformationPrimaryOption) => ({
                            label:
                              transformationPrimaryOption.label[language] ??
                              transformationPrimaryOption.label['en-us'],
                            value: transformationPrimaryOption.value,
                          }),
                        )}
                        onChange={(option: any) => {
                          if (
                            option.value !== selectedPrimaryTransformation.value
                          ) {
                            setSelectedPrimaryTransformation(
                              transformationPrimaryOptions.find(
                                (transformationPrimaryOption) =>
                                  transformationPrimaryOption.value ===
                                  option.value,
                              ),
                            );
                            setSelectedSecondaryTransformation(undefined);
                          }
                        }}
                        value={{
                          label:
                            selectedPrimaryTransformation.label[language] ??
                            selectedPrimaryTransformation.label['en-us'],
                          value: selectedPrimaryTransformation.value,
                        }}
                        style={{ width: '15.625rem' }}
                      />
                      <Select
                        label={translate('viewFeaturesSecondaryTr')}
                        defaultValue={{
                          label:
                            transformationSecondaryOptions[0].label[language] ??
                            transformationSecondaryOptions[0].label['en-us'],
                          value: transformationSecondaryOptions[0].value,
                        }}
                        options={transformationSecondaryOptions.map(
                          (transformationSecondaryOption) => ({
                            label:
                              transformationSecondaryOption.label[language] ??
                              transformationSecondaryOption.label['en-us'],
                            value: transformationSecondaryOption.value,
                          }),
                        )}
                        onChange={(option: any) => {
                          if (
                            option.value !==
                            selectedSecondaryTransformation.value
                          ) {
                            setSelectedSecondaryTransformation(
                              transformationSecondaryOptions.find(
                                (transformationSecondaryOption) =>
                                  transformationSecondaryOption.value ===
                                  option.value,
                              ),
                            );
                          }
                        }}
                        value={{
                          label:
                            selectedSecondaryTransformation.label[language] ??
                            selectedSecondaryTransformation.label['en-us'],
                          value: selectedSecondaryTransformation.value,
                        }}
                        style={{ width: '15.625rem' }}
                      />
                    </SelectContainer>
                  ) : (
                    isLoadingIndicator ||
                    (isErrorIndicator && (
                      <SelectContainer>
                        {[
                          labelName(),
                          'Frequency',
                          'Primary transformation',
                          'Primary secondary',
                        ].map((label) => (
                          <Select
                            label={label}
                            isDisabled
                            style={{ width: '15.625rem' }}
                            key={`select-disable-${label}`}
                          />
                        ))}
                      </SelectContainer>
                    ))
                  )}

                  {errorObservationProjection?.response?.status === 402 ? (
                    <ChartContent>
                      <BlurChart />
                    </ChartContent>
                  ) : errorObservationProjection?.response?.status === 503 ? (
                    <ContainerErrorChart>
                      <ContainerMaintenance
                        content="data"
                        text={translate('viewFeaturesUpdateInfo')}
                      />
                    </ContainerErrorChart>
                  ) : isErrorIndicator ||
                    isErrorIndicatorSerie ||
                    isErrorSerieDataInfo ? (
                    // eslint-disable-next-line react/jsx-indent
                    <ContainerErrorChart>
                      <ContainerMaintenance
                        content="data"
                        text={translate('viewFeaturesUnableSerie')}
                      />
                    </ContainerErrorChart>
                  ) : chartData &&
                    chartData.length > 0 &&
                    forPlotGraph.length ? (
                    // eslint-disable-next-line react/jsx-indent
                    <>
                      <ChartContent data-cy="serie-chart">
                        <HeaderChart>
                          <h4 data-cy="unit-chart">
                            {forPlotGraph[0]?.unit[language] ??
                              forPlotGraph[0]?.unit['en-us']}
                          </h4>

                          <TypeChartContainer>
                            <ChartTypeButton
                              onClick={() => {
                                setType('line');
                              }}
                              active={type === 'line'}
                              icon={<ChartLine />}
                              data-cy="button-line"
                              data-testid="button-line"
                            />
                            <ChartTypeButton
                              onClick={() => {
                                setType('column');
                              }}
                              active={type === 'column'}
                              icon={<ChartBar />}
                              data-cy="button-column"
                              data-testid="button-column"
                            />
                            <ChartTypeButton
                              onClick={() => {
                                setType('area');
                              }}
                              active={type === 'area'}
                              icon={<ChartArea />}
                              data-cy="button-area"
                              data-testid="button-area"
                            />
                          </TypeChartContainer>
                        </HeaderChart>

                        <HighchartsReact
                          highcharts={Highcharts}
                          options={options}
                          constructorType="stockChart"
                          ref={chartRef}
                        />
                      </ChartContent>
                    </>
                  ) : (
                    <ContainerSkeleton
                      withLoading={false}
                      style={{
                        marginTop: '1.25rem',
                        height: '28.125rem',
                        marginBottom: '0.625rem',
                      }}
                    />
                  )}
                </ChartContainer>

                <IndicatorInfo
                  description={
                    dataIndicatorsInfo.description[language] ??
                    dataIndicatorsInfo.description['en-us']
                  }
                  source={
                    dataIndicatorsInfo.source[language] ??
                    dataIndicatorsInfo.source['en-us']
                  }
                  startDateObservation={serieDataInfo?.data.date_start}
                  endDateObservation={serieDataInfo?.data.date_end}
                  endDateProjection={serieDataInfo?.data.date_end_projection}
                  lastUpdateObservation={
                    serieDataInfo?.data.last_observation_updated
                  }
                  lastUpdateProjection={
                    serieDataInfo?.data.last_projection_updated
                  }
                  buttonCopyIsDisabled={isErrorIndicator || isLoadingIndicator}
                  seriesCodes={codeSerieGraph}
                  projection={dataIndicatorsInfo?.projections}
                />
              </GridContainer>
            </>
          ) : isLoadingIndicatorSerie ||
            isFetchingIndicatorData ||
            isLoadingSerieDataInfo ? (
            // eslint-disable-next-line react/jsx-indent
            <ContainerSkeleton
              withLoading={false}
              style={{
                height: '28.125rem',
                marginBottom: '0.625rem',
                marginTop: '1.25rem',
              }}
            />
          ) : (
            <>
              <div className="containerLinear">
                <ContainerSkeleton
                  withLoading={false}
                  style={{
                    height: '9.375rem',
                  }}
                />
              </div>
              <div className="containerLinear">
                <ContainerSkeleton
                  withLoading={false}
                  style={{
                    height: '31.25rem',
                  }}
                />
              </div>
            </>
          )}

          {modalFilters && (
            <FilterIndicatorSeries
              visible={modalFilters}
              setVisible={setModalFilters}
              series={dataIndicatorsSeries}
              regionOption={allRegionOptions}
              aggregationOption={allAggregationOptions}
              transformationPrimaryOption={allTransformationPrimaryOptions}
              transformationSecondaryOption={allTransformationSecondaryOptions}
              defaultOptions={{
                region: selectedRegion?.value,
                frequency: selectedAggregation?.value,
                primary: selectedPrimaryTransformation?.value,
                secondary: selectedSecondaryTransformation?.value,
              }}
            />
          )}

          {modalExportSeries && (
            <ExportSeries
              visible={modalExportSeries}
              setVisible={setModalExportSeries}
              series={dataIndicatorsSeries}
              idIndicator={id ?? ''}
              regionOption={allRegionOptions}
              aggregationOption={allAggregationOptions}
              transformationPrimaryOption={allTransformationPrimaryOptions}
              transformationSecondaryOption={allTransformationSecondaryOptions}
              defaultOptions={{
                region: selectedRegion?.value,
                frequency: selectedAggregation?.value,
                primary: selectedPrimaryTransformation?.value,
                secondary: selectedSecondaryTransformation?.value,
              }}
            />
          )}
        </>
      ) : (
        <NotFound />
      )}
    </Container>
  );
};
