import React, { useContext, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from 'src/components/Select';
import {
  changeProjectRequestError,
  changeSelectedYAndModel,
  Model,
  ModelTypes,
  Y,
} from 'src/models/redux/reducers/Project';
import api from 'src/models/service/api';
import { RootState } from 'src/redux/store';
import {
  FilterOption,
  clearFilters,
  deletedAllFilters,
  setInitialFilters,
} from 'src/models/redux/reducers/DependentVariablesConfig';
import { useLocation, useNavigate } from 'react-router-dom';
import { GearSix, MagnifyingGlass } from 'phosphor-react';
import { Tooltip } from 'react-tooltip';

import {
  ContainerDependentVariable,
  ContentFeatureStoreVariable,
  Header,
  Tag,
} from './styles';
import { FeatureStoreVariablesContext } from '../../contexts/FeatureStoreVariableContext';
import { LevelProps, SelectedOption, VariableYSelected } from './types';

export const DependentVariableOnSidebar: React.FC = () => {
  const { t: translate } = useTranslation();

  const {
    project,
    dependentVariablesConfig: {
      filters,
      quantity,
      deletedAllFilters: allFiltersAreDeleted,
    },
    sideBar: { isExpanded },
  } = useSelector((state: RootState) => state);
  const [yOptions, setYOptions] = useState<Y[]>([]);
  const [ySelected, setYSelected] = useState<VariableYSelected | undefined>();

  const [filter2Options, setFilter2Options] = useState<FilterOption[]>([]);
  const [filter3Options, setFilter3Options] = useState<FilterOption[]>([]);
  const [filter1Selected, setFilter1Selected] = useState<SelectedOption>({});
  const [filter2Selected, setFilter2Selected] = useState<SelectedOption>({});
  const [filter3Selected, setFilter3Selected] = useState<SelectedOption>({});

  const [firstTimeOnPage, setFirstTimeOnPage] = useState(true);

  const [filterIsLoading, setFilterIsLoading] = useState(true);

  const existsFilter1 = filters.level1.name !== undefined;
  const existsFilter2 = filters.level2.name !== undefined;
  const existsFilter3 = filters.level3.name !== undefined;

  const { open, canDeleteElement, total, isError, isLoading } = useContext(
    FeatureStoreVariablesContext,
  );

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const onConfigPage = !!location.pathname.includes(
    '/config/dependent-variables',
  );

  useEffect(() => {
    if (firstTimeOnPage) {
      setFilterIsLoading(true);

      const setFilters = async () => {
        dispatch(clearFilters());
        dispatch(setInitialFilters({ filters: project.filters, y: project.y }));
      };

      setFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project.y, project.id, firstTimeOnPage, dispatch, project.filters]);

  useEffect(() => {
    setFilterIsLoading(true);
    setFirstTimeOnPage(true);
  }, [project.id]);

  useEffect(() => {
    const setSelectedFilters = (updatedFilters: LevelProps) => {
      let variableYSelected: VariableYSelected | undefined;
      let level1Selected: FilterOption | undefined;
      let level2Selected: FilterOption | undefined;
      let level3Selected: FilterOption | undefined;

      const variableAux = project.y.find(
        (y) => y.id === project.selectedY?.id && y.status === 'success',
      );

      if (variableAux) {
        variableYSelected = {
          id: variableAux.id,
          label: variableAux.label,
          name: variableAux.name,
          info: variableAux.info,
        };
      } else {
        const defaultProject = project.y.find((y) => y.status === 'success');

        if (defaultProject) {
          variableYSelected = {
            id: defaultProject.id,
            name: defaultProject.name,
            label: defaultProject.label,
            info: defaultProject.info,
          };
        }
      }

      const { level_1, level_2, level_3 } = project.selectedFilters;

      level1Selected = updatedFilters.level1.options.find(
        (option) => option.name === level_1,
      );

      if (!level1Selected) {
        level1Selected = updatedFilters.level1.options.find((option) =>
          option.yIds.includes(variableYSelected?.id ?? ''),
        );
      }

      level2Selected = updatedFilters.level2.options.find(
        (option) => option.name === level_2,
      );

      if (updatedFilters.level2.name !== undefined) {
        level2Selected = updatedFilters.level2.options.find((option) =>
          option.yIds.includes(variableYSelected?.id ?? ''),
        );
      }

      level3Selected = updatedFilters.level3.options.find(
        (option) => option.name === level_3,
      );

      if (updatedFilters.level3.name !== undefined) {
        level3Selected = updatedFilters.level3.options.find((option) =>
          option.yIds.includes(variableYSelected?.id ?? ''),
        );
      }

      setFilter1Selected({
        name: level1Selected?.name,
        id: level1Selected?.id,
      });
      setFilter2Selected({
        name: level2Selected?.name,
        id: level2Selected?.id,
      });
      setFilter3Selected({
        name: level3Selected?.name,
        id: level3Selected?.id,
      });

      setYSelected(variableYSelected);
    };

    const existsFilters = project.filters?.level_1?.name;

    if (
      firstTimeOnPage &&
      ((existsFilters && filters.level1.name) ||
        (!existsFilters && project.y.length))
    ) {
      setSelectedFilters(filters);

      setFirstTimeOnPage(false);
      setFilterIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project.id, filters, project.y]);

  const { data: projectData } = useQuery(
    ['project', project.id, ySelected],
    async () => {
      const response = await api.get<Model>(
        `/projects/${project.id}/${ySelected?.id}/models`,
      );

      return response.data;
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled: !!ySelected?.id,
      onError: () => dispatch(changeProjectRequestError(true)),
    },
  );

  useEffect(() => {
    if (projectData && ySelected) {
      dispatch(
        changeSelectedYAndModel({
          y: {
            ...ySelected,
            status: 'success',
          },
          model: projectData,
          selectedFilters: {
            level_1: filter1Selected.name,
            level_2: filter2Selected.name,
            level_3: filter3Selected.name,
          },
        }),
      );

      const modelsExplorer: ModelTypes[] = [
        'Forecast Combination',
        'ARIMA',
        'Regularized Regression',
        'Elementary',
        'Random Forest',
      ];

      const url = location.pathname
        .replace('/models/time-series/', '')
        .slice(24);

      if (url.includes('model-explorer')) {
        for (const model of modelsExplorer) {
          if (url.includes(model.toLowerCase().replace(' ', '-'))) {
            const disabled = !projectData?.model_types.includes(model);

            if (disabled) {
              if (projectData && !project.projectError && disabled) {
                if (
                  project.y.filter((y) => y.status === 'success').length > 1
                ) {
                  navigate(
                    `/models/time-series/${project.id}/project-overview`,
                  );
                } else {
                  navigate(`/models/time-series/${project.id}/AI-selection`);
                }
              }
            }
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    projectData,
    ySelected,
    location,
    project.projectError,
    project.y,
    project.id,
    navigate,
  ]);

  const renameOptionSelected = (
    options: FilterOption[],
    selectedId: string,
  ) => {
    const index = options.findIndex((option) => option.id === selectedId);

    return index === -1 ? undefined : options[index].name;
  };

  useEffect(() => {
    if (filter1Selected.id && onConfigPage) {
      const updatedName = renameOptionSelected(
        filters.level1.options,
        filter1Selected.id,
      );

      setFilter1Selected({
        id: updatedName === undefined ? '' : filter1Selected.id,
        name: updatedName,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onConfigPage, filters.level1.options]);

  useEffect(() => {
    if (filter2Selected.id && onConfigPage) {
      const updatedName = renameOptionSelected(
        filters.level2.options,
        filter2Selected.id,
      );

      setFilter2Selected({
        id: updatedName === undefined ? '' : filter2Selected.id,
        name: updatedName,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onConfigPage, filters.level2.options]);

  useEffect(() => {
    if (filter3Selected.id && onConfigPage) {
      const updatedName = renameOptionSelected(
        filters.level3.options,
        filter3Selected.id,
      );

      setFilter3Selected({
        id: updatedName === undefined ? '' : filter3Selected.id,
        name: updatedName,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onConfigPage, filters.level3.options]);

  const getCommonYs = (firstYs?: string[], secondYs?: string[]) => {
    if (!firstYs || !secondYs) return [];

    const biggestArr = firstYs.length >= secondYs.length ? firstYs : secondYs;

    const smallestArr = firstYs.length < secondYs.length ? firstYs : secondYs;

    return biggestArr.filter((y) => smallestArr.includes(y));
  };

  const changeFilterOptions = (
    options: FilterOption[],
    ysThatShouldExistsInCommon?: string[],
  ) => {
    const newOptions: FilterOption[] = [];

    options.forEach((option) => {
      const commonYs = getCommonYs(option.yIds, ysThatShouldExistsInCommon);

      if (commonYs.length) {
        newOptions.push(option);
      }
    });

    return newOptions;
  };

  const optionSelectedExists = (
    options: FilterOption[],
    selectedId?: string,
  ) => {
    for (const option of options) {
      if (option.id === selectedId) {
        return true;
      }
    }

    return false;
  };

  const changeVariableOptions = (ys: string[]) => {
    const options = project.y.filter((y) => ys.includes(y.id));
    setYOptions(options);
  };

  useEffect(() => {
    if (quantity !== 0 && !firstTimeOnPage) {
      const ysFilter1 = filters.level1.options.find(
        (option) => option.id === filter1Selected.id,
      )?.yIds;

      const updatedFilter2Options = changeFilterOptions(
        filters.level2.options,
        ysFilter1,
      );
      setFilter2Options(updatedFilter2Options);

      const ysFilter2 = updatedFilter2Options.find(
        (option) => option.id === filter2Selected.id,
      )?.yIds;

      const commonYsFilter1And2 = getCommonYs(ysFilter1, ysFilter2);

      const updatedFilter3Options = changeFilterOptions(
        filters.level3.options,
        commonYsFilter1And2,
      );

      setFilter3Options(updatedFilter3Options);

      const ysFilter3 =
        updatedFilter3Options.find((option) => option.id === filter3Selected.id)
          ?.yIds ?? [];

      const commonYsFilter1And2And3 = getCommonYs(
        commonYsFilter1And2,
        ysFilter3,
      );

      const updatedYOptions =
        (existsFilter1 && existsFilter2 && existsFilter3
          ? commonYsFilter1And2And3
          : existsFilter2
          ? commonYsFilter1And2
          : ysFilter1) ?? [];

      changeVariableOptions(updatedYOptions);

      if (ySelected?.id && !updatedYOptions.includes(ySelected?.id)) {
        setYSelected(undefined);
      }

      if (
        existsFilter2 &&
        !optionSelectedExists(updatedFilter2Options, filter2Selected.id)
      ) {
        setYOptions([]);
        setYSelected(undefined);
        setFilter2Selected({ id: '', name: '' });
        setFilter3Options([]);
        setFilter3Selected({ id: '', name: '' });
      }

      if (
        existsFilter3 &&
        !optionSelectedExists(updatedFilter3Options, filter3Selected.id)
      ) {
        setYOptions([]);
        setYSelected(undefined);
        setFilter3Selected({ id: '', name: '' });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filter1Selected.id,
    filter2Selected.id,
    filter3Selected.id,
    filters.level1.options,
    filters.level2.options,
    filters.level3.options,
    quantity,
  ]);

  useEffect(() => {
    if (existsFilter1 && !filter1Selected.id && !firstTimeOnPage) {
      setYOptions([]);
      setYSelected(undefined);
    }
    if (existsFilter2 && !filter2Selected.id && !firstTimeOnPage) {
      setYOptions([]);
      setYSelected(undefined);
    }
    if (existsFilter3 && !filter3Selected.id && !firstTimeOnPage) {
      setYOptions([]);
      setYSelected(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    existsFilter1,
    existsFilter2,
    existsFilter3,
    filter1Selected.id,
    filter2Selected.id,
    filter3Selected.id,
  ]);

  useEffect(() => {
    if (quantity === 0) {
      setYOptions(project.y);
    }
  }, [quantity, project.y]);

  useEffect(() => {
    if (!onConfigPage && allFiltersAreDeleted) {
      dispatch(deletedAllFilters(false));
    }
  }, [onConfigPage, allFiltersAreDeleted, dispatch]);

  function handleOpenFeatureStoreVariables() {
    open();
  }

  const isBergmanAndVersionGreaterThan40 =
    project.appInfo?.engine.name === 'bergman' &&
    Number(project.appInfo?.engine.version.replaceAll('.', '')) >= 40;

  return (
    <ContainerDependentVariable data-testid="container-sidebar-dependent-variable">
      {isExpanded ? (
        <>
          <Header>
            <div>
              <h2>{translate('sideBarDependentVariable')}</h2>

              {project.y.filter((y) => y.status === 'success').length >= 5 && (
                <button
                  type="button"
                  onClick={() =>
                    navigate(
                      `/models/time-series/${project.id}/config/dependent-variables`,
                    )
                  }
                  data-testid="dependent-variables-config"
                  aria-label="access dependent variables config"
                >
                  <GearSix />
                </button>
              )}
            </div>

            {onConfigPage && (
              <Tag data-testid="see-filter-configurations-tag">
                {translate('sideBarCheckDependentVariableConfig')}
              </Tag>
            )}
          </Header>

          {filterIsLoading ? (
            <Select
              label={translate('sideBarSelectVariable')}
              placeholder={`${translate('loading')}...`}
              isDisabled
              dataTestid="dependent-variable"
            />
          ) : (
            <>
              {existsFilter1 && (
                <Select
                  label={
                    filters.level1.name || translate('configFiltersFilterName')
                  }
                  placeholder={translate('sideBarFilterPlaceholder')}
                  onChange={(option: any) => {
                    setFilter1Selected({
                      id: option.value,
                      name: option.label,
                    });
                    if (option.value !== filter1Selected.id) {
                      setFilter2Selected({ id: '', name: '' });
                      setFilter3Selected({ id: '', name: '' });
                      setYSelected(undefined);
                    }
                  }}
                  value={
                    !filter1Selected.id
                      ? null
                      : {
                          value: filter1Selected.id || '',
                          label: filter1Selected.name || '',
                        }
                  }
                  options={filters.level1.options
                    .filter((option) => option.yIds.length > 0)
                    .map((option) => ({
                      value: option.id,
                      label: option.name,
                    }))}
                  className={!filters.level1.name ? 'filter-name-error' : ''}
                  dataTestid="select-filter-1"
                />
              )}

              {existsFilter2 && (
                <Select
                  label={
                    filters.level2.name || translate('configFiltersFilterName')
                  }
                  placeholder={translate('sideBarFilterPlaceholder')}
                  onChange={(option: any) => {
                    setFilter2Selected({
                      id: option.value,
                      name: option.label,
                    });
                    if (option.value !== filter2Selected.id) {
                      setFilter3Selected({ id: '', name: '' });
                      setYSelected(undefined);
                    }
                  }}
                  value={
                    !filter2Selected.id
                      ? null
                      : {
                          value: filter2Selected.id || '',
                          label: filter2Selected.name || '',
                        }
                  }
                  options={filter2Options.map((option) => ({
                    value: option.id,
                    label: option.name,
                  }))}
                  className={!filters.level2.name ? 'filter-name-error' : ''}
                  dataTestid="select-filter-2"
                />
              )}

              {existsFilter3 && (
                <Select
                  label={
                    filters.level3.name || translate('configFiltersFilterName')
                  }
                  placeholder={translate('sideBarFilterPlaceholder')}
                  onChange={(option: any) => {
                    setFilter3Selected({
                      id: option.value,
                      name: option.label,
                    });
                    if (option.value !== filter3Selected.id) {
                      setYSelected(undefined);
                    }
                  }}
                  value={
                    !filter3Selected.id
                      ? null
                      : {
                          value: filter3Selected.id || '',
                          label: filter3Selected.name || '',
                        }
                  }
                  options={filter3Options.map((option) => ({
                    value: option.id,
                    label: option.name,
                  }))}
                  className={!filters.level3.name ? 'filter-name-error' : ''}
                  dataTestid="select-filter-3"
                />
              )}

              <Select
                label={translate('sideBarSelectVariable')}
                placeholder={translate('sideBarDependentVariablePlaceholder')}
                onChange={(option: any) => {
                  setYSelected({
                    id: option.value,
                    label: option.label,
                    name:
                      project.y.find((y) => y.id === option.value)?.name ??
                      option.label,
                    info: project.y.find((y) => y.id === option.value)?.info,
                  });

                  dispatch(
                    changeSelectedYAndModel({
                      y: null,
                      model: null,
                      selectedFilters: {},
                    }),
                  );
                }}
                value={
                  !ySelected
                    ? null
                    : {
                        value: ySelected.id,
                        label: ySelected.label,
                      }
                }
                options={yOptions.map((y) => ({
                  value: y.id,
                  label: y.label,
                  isDisabled: y.status !== 'success',
                }))}
                dataTestid="dependent-variable"
              />
            </>
          )}

          <Tooltip
            id="feature-store-variable-error-load"
            className={
              !isLoading && total === 0
                ? 'customTooltipWhite'
                : 'customTooltipTheme'
            }
          />

          <ContentFeatureStoreVariable
            data-tooltip-id={
              (isBergmanAndVersionGreaterThan40 && isError) ||
              (!isLoading && total === 0 && !isError)
                ? 'feature-store-variable-error-load'
                : undefined
            }
            data-tooltip-html={
              isBergmanAndVersionGreaterThan40 && isError
                ? translate('featureStoreVariablesUnableLoad')
                : !isLoading && total === 0 && !isError
                ? translate('featureStoreVariablesNoVariables')
                : undefined
            }
          >
            <button
              type="button"
              onClick={handleOpenFeatureStoreVariables}
              disabled={total === 0 || canDeleteElement === false}
              data-cy="feature-store-variables-button-open"
            >
              <MagnifyingGlass size={20} />
              {translate('featureStoreVariablesTitle')}
            </button>
          </ContentFeatureStoreVariable>
        </>
      ) : null}
    </ContainerDependentVariable>
  );
};
