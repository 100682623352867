import React, { useContext, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Card } from 'src/components/Card';
import { WorkspaceConfigContext } from 'src/models/contexts/WorkspaceConfigContext';
import { Label } from 'src/components/Label';
import { Info } from 'phosphor-react';
import { Input } from 'src/components/Input';
import { TextArea } from 'src/components/TextArea';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ModalIcons } from 'src/components/Modal/ModalIcons';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import * as Yup from 'yup';

import {
  PlanningFlowButton,
  PlanningFlowContainer,
  Container,
  FieldsContainer,
  FormContainer,
  IconButton,
  IconContainer,
  LabelContainer,
} from './styles';
import { Step1Form, Step1Props } from './types';
import { StepFooter } from '../StepFooter';

export const Step1: React.FC<Step1Props> = ({ setStep }) => {
  const [showModalIcon, setShowModalIcon] = useState(false);
  const [, setIcon] = useState<string | null>(null);

  const {
    workspaceId,
    isEdition,
    name,
    description,
    iconUrl,
    saveBasicInformations,
    isLoadingWorkspace,
    enablePlanningFlow,
    setEnablePlanningFlow,
    isLoadingStagingArea,
    frequency,
  } = useContext(WorkspaceConfigContext);
  const { t: translate } = useTranslation();
  const navigate = useNavigate();

  const regexNoSpecialCharacters = /^[a-zA-Z0-9_-\s]*$/;
  const regexFirstCharacter = /^[a-zA-Z]/;
  const regexLastCharacter = /[a-zA-Z0-9_-]$/;

  const formSchema = Yup.object().shape({
    iconUrl: Yup.string().required('requiredField'),
    name: Yup.string()
      .matches(regexNoSpecialCharacters, 'projectErrorNoSpecialCharacters')
      .matches(regexFirstCharacter, 'projectErrorFirstCharacter')
      .matches(regexLastCharacter, 'projectErrorLastCharacter')
      .min(3, 'projectErrorMinCharacters')
      .max(50, 'projectErrorMaxCharacters')
      .required('requiredField'),
    description: Yup.string()
      .min(3, 'projectErrorMinCharacters')
      .max(350, 'createWorkspaceDescriptionErrorMaxCharacters')
      .required('requiredField'),
  });

  const {
    control,
    getValues,
    setValue,
    trigger,
    handleSubmit,
    formState: { errors },
  } = useForm<Step1Form>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      name,
      description,
      iconUrl,
    },
  });

  const nextStep = () => {
    const basicInformations = getValues();

    saveBasicInformations(
      basicInformations.name,
      basicInformations.description,
      basicInformations.iconUrl,
    );

    setStep(2);
  };

  useEffect(() => {
    if (isEdition && !isLoadingWorkspace && name && description && iconUrl) {
      setValue('name', name);
      setValue('description', description);
      setValue('iconUrl', iconUrl);
    }
  }, [isEdition, isLoadingWorkspace, name, description, iconUrl, setValue]);

  const planningFlowButtonDisabled = frequency
    ? frequency !== 'monthly'
    : false;

  return (
    <Container className="containerLinear" data-testid="container-step-1">
      <Card
        textCard={translate(
          isEdition ? 'editWorkspaceEditTitle' : 'createWorkspaceCreateTitle',
        )}
        textDescription={translate('createWorkspaceCreateDescription')}
      />

      <FormContainer onSubmit={(e) => e.preventDefault()}>
        <IconContainer>
          <LabelContainer>
            <Label>{translate('createWorkspaceIcon')}</Label>

            <Info
              size="1.125rem"
              data-tooltip-id="config-workspace-tooltip"
              data-tooltip-html={translate('createWorkspaceIconTooltip')}
              data-testid="icon-info"
            />
          </LabelContainer>

          {isEdition &&
          (isLoadingWorkspace ||
            !getValues('iconUrl') ||
            getValues('iconUrl') !== iconUrl) ? (
            // eslint-disable-next-line react/jsx-indent
            <ContainerSkeleton
              withLoading={false}
              style={{ width: '8.25rem', height: '8.25rem' }}
            />
          ) : (
            <IconButton
              data-testid="button-icon"
              onClick={() => setShowModalIcon(true)}
              disabled={isEdition}
              type="button"
            >
              <img
                data-testid="selected-icon"
                src={getValues('iconUrl')}
                alt="Selected icon"
              />
            </IconButton>
          )}
        </IconContainer>

        <FieldsContainer>
          <Controller
            name="name"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Input
                label={translate('createWorkspaceName')}
                placeholder={translate('createWorkspaceNamePlaceholder')}
                onChange={({ target: { value: val } }) => {
                  onChange(val);

                  if (
                    val.length <= 50 &&
                    val.match(regexFirstCharacter) &&
                    val.match(regexLastCharacter) &&
                    val.match(regexNoSpecialCharacters) &&
                    !errors.name?.message
                  ) {
                    return;
                  }

                  trigger('name');
                }}
                value={value}
                error={
                  errors.name?.message ? translate(errors.name?.message) : ''
                }
                loading={
                  isEdition && (isLoadingWorkspace || !getValues('name'))
                }
                disabled={isEdition}
                data-testid="input-name"
              />
            )}
          />

          <Controller
            name="description"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextArea
                label={translate('createWorkspaceDescription')}
                placeholder={translate('createWorkspaceDescriptionPlaceholder')}
                onChange={({ target: { value: val } }) => {
                  onChange(val);

                  if (val.length <= 350 && !errors.description?.message) {
                    return;
                  }

                  trigger('description');
                }}
                value={value}
                error={
                  errors.description?.message
                    ? translate(errors.description?.message)
                    : ''
                }
                loading={
                  isEdition && (isLoadingWorkspace || !getValues('description'))
                }
                disabled={isEdition}
                data-testid="textarea-description"
              />
            )}
          />

          <PlanningFlowContainer data-testid="container-enable-planning-flow">
            <div>
              <Label>{translate('workspaceConfigEnableApprovalFlow')}</Label>
              <Info
                size="1.125rem"
                data-tooltip-id="config-workspace-tooltip"
                data-tooltip-html={translate(
                  'workspaceConfigEnableApprovalFlowInfoTooltip',
                )}
                data-testid="approval-flow-tooltip"
              />
            </div>

            <div>
              {isEdition && isLoadingStagingArea ? (
                <ContainerSkeleton
                  withLoading={false}
                  style={{ width: '100%', height: '2.75rem' }}
                  data-testid="loading-planning-flow"
                />
              ) : (
                <>
                  <PlanningFlowButton
                    selected={!enablePlanningFlow}
                    onClick={() => setEnablePlanningFlow(false)}
                    data-testid="button-no-planning-flow"
                  >
                    {translate('no')}
                  </PlanningFlowButton>
                  <PlanningFlowButton
                    selected={enablePlanningFlow}
                    onClick={() => setEnablePlanningFlow(true)}
                    disabled={planningFlowButtonDisabled}
                    data-testid="button-yes-planning-flow"
                  >
                    {translate('yes')}
                  </PlanningFlowButton>
                </>
              )}
            </div>
          </PlanningFlowContainer>
        </FieldsContainer>

        <Controller
          name="iconUrl"
          control={control}
          render={({ field: { onChange, value } }) => (
            <ModalIcons
              visible={showModalIcon}
              setIcon={setIcon}
              setVisible={setShowModalIcon}
              onChangHookForm={onChange}
              value={value}
              content={{
                title: translate('createWorkspaceIconModalTitle'),
                description: translate('createWorkspaceIconModalDescription'),
              }}
            />
          )}
        />
      </FormContainer>

      <StepFooter
        stepQtty={3}
        selectedStep={1}
        backStep={{
          text: translate('back'),
          onClick: () =>
            isEdition
              ? navigate(`/models/workspaces/${workspaceId}/control-panel`)
              : navigate('/models/projects'),
          disabled: false,
        }}
        nextStep={{
          text: translate('step2'),
          onClick: isEdition ? () => setStep(2) : handleSubmit(nextStep),
          disabled: false,
        }}
      />

      <Tooltip id="config-workspace-tooltip" className="customTooltipTheme" />
    </Container>
  );
};
