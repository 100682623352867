import React, { useRef } from 'react';

import HighchartsReact from 'highcharts-react-official';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { HCharts, HChartsSeries } from 'src/components/HCharts';
import { RootState } from 'src/redux/store';
import light from 'src/styles/themes/light';
import { convertQuarterlyToDate } from 'src/utils/dates/convertQuarterlyToDate';
import { formatCompactNotation } from 'src/utils/numbers/formatCompactNotation';
import { getChartColor } from 'src/utils/colors/getChartColor';
import { convertDateToQuarterly } from 'src/utils/dates/convertDateToQuarterly';
import { format, subDays } from 'date-fns';

import { ResponseVariables } from '..';

interface ExplanatoryVariablesChartProps {
  dataLevel: ResponseVariables;
  dataVariation: ResponseVariables;
}

export const ExplanatoryVariablesChart: React.FC<
  ExplanatoryVariablesChartProps
> = ({ dataLevel, dataVariation }) => {
  const {
    workspace,
    workspaceProjectionsOptions: { frequency, transformations },
  } = useSelector((state: RootState) => state);

  const { t: translate } = useTranslation();

  const chartRef = useRef<HighchartsReact.RefObject>(null);

  function getVariationLegendNameThroughFrequency() {
    const dailyWeeklyFortnightlyArr = ['daily', 'weekly', 'fortnightly'];

    const isOriginal = frequency === 'original';
    const isOriginalFrequencyAnnual = workspace.frequency === 'annual';
    const originalFrequencyIsDailyWeeklyFortnightly =
      dailyWeeklyFortnightlyArr.includes(workspace.frequency ?? '');

    if ((isOriginal && isOriginalFrequencyAnnual) || frequency === 'yearly') {
      return translate(
        'workspaceProjectionsExplanatoryVariablesVariationAnnual',
      );
    }

    if (
      (isOriginal && originalFrequencyIsDailyWeeklyFortnightly) ||
      dailyWeeklyFortnightlyArr.includes(frequency)
    ) {
      return translate(
        'workspaceProjectionsExplanatoryVariablesVariationOriginal',
      );
    }

    return translate(
      'workspaceProjectionsExplanatoryVariablesVariationMonthlyQuarterly',
    );
  }

  const dataChart: HChartsSeries[] = [];

  const shouldShowNOfDay =
    workspace.frequency === 'daily' && frequency !== 'original';

  if (transformations.includes('level') && dataLevel) {
    dataChart.push(
      {
        data: dataLevel.historical.date.map((date, index) => ({
          x:
            frequency !== 'quarterly'
              ? new Date(`${date}T00:00`).getTime()
              : convertQuarterlyToDate(String(date)).getTime(),
          y: dataLevel.historical.value[index],
          custom: {
            tooltipTitle: translate('level'),
            keyValue: translate('Historical'),
            value: `${formatCompactNotation(
              dataLevel.historical.value[index],
            )}`,
            date:
              frequency !== 'quarterly' && frequency !== 'yearly'
                ? format(new Date(`${date}T00:00`), 'dd/MM/yyyy')
                : date,
            nOfDays: shouldShowNOfDay
              ? dataLevel?.historical?.aggregation_count?.[index]
              : null,
          },
        })),
        name: translate('level'),
        type: 'column',
        yAxis: 0,
        showInLegend: true,
        color: getChartColor(0),
        zIndex: -1,
        cursor: 'pointer',
      },
      {
        data: dataLevel.forecast.date.map((date, index) => ({
          x:
            frequency !== 'quarterly'
              ? new Date(`${date}T00:00`).getTime()
              : convertQuarterlyToDate(String(date)).getTime(),
          y: dataLevel.forecast.value[index],
          custom: {
            tooltipTitle: translate('level'),
            keyValue: translate('Forecast'),
            value: `${formatCompactNotation(dataLevel.forecast.value[index])}`,
            date:
              frequency !== 'quarterly' && frequency !== 'yearly'
                ? format(new Date(`${date}T00:00`), 'dd/MM/yyyy')
                : date,
            nOfDays: shouldShowNOfDay
              ? dataLevel?.forecast?.aggregation_count?.[index]
              : null,
          },
        })),
        name: translate('level'),
        type: 'column',
        dashStyle: 'Solid',
        yAxis: 0,
        showInLegend: false,
        color: getChartColor(0),
        opacity: 0.7,
        zIndex: -1,
      },
    );
  }

  if (transformations.includes('variation') && dataVariation) {
    dataChart.push(
      {
        data: dataVariation.historical.date.map((date, index) => ({
          x:
            frequency !== 'quarterly'
              ? new Date(`${date}T00:00`).getTime()
              : convertQuarterlyToDate(String(date)).getTime(),
          y: dataVariation.historical.value[index],
          custom: {
            tooltipTitle: translate('variation'),
            keyValue: translate('Historical'),
            value: `${formatCompactNotation(
              dataVariation.historical.value[index],
            )}%`,
            date:
              frequency !== 'quarterly' && frequency !== 'yearly'
                ? format(new Date(`${date}T00:00`), 'dd/MM/yyyy')
                : date,
            nOfDays: shouldShowNOfDay
              ? dataVariation?.historical?.aggregation_count?.[index]
              : null,
          },
        })),
        name: getVariationLegendNameThroughFrequency(),
        type: 'line',
        showInLegend: true,
        yAxis: 1,
        color: light.colors.secondaryLight,
        marker: {
          enabledThreshold: 2,
          radius: 4,
          symbol: 'circle',
          fillColor: light.colors.white,
          lineWidth: 3,
          lineColor: light.colors.secondaryLight,
        },
        cursor: 'pointer',
      },
      {
        data: dataVariation.forecast.date.map((date, index) => ({
          x:
            frequency !== 'quarterly'
              ? new Date(`${date}T00:00`).getTime()
              : convertQuarterlyToDate(String(date)).getTime(),
          y: dataVariation.forecast.value[index],
          custom: {
            tooltipTitle: translate('variation'),
            keyValue: translate('Forecast'),
            value: `${formatCompactNotation(
              dataVariation.forecast.value[index],
            )}%`,
            date:
              frequency !== 'quarterly' && frequency !== 'yearly'
                ? format(new Date(`${date}T00:00`), 'dd/MM/yyyy')
                : date,
            nOfDays: shouldShowNOfDay
              ? dataVariation?.forecast?.aggregation_count?.[index]
              : null,
          },
        })),
        name: getVariationLegendNameThroughFrequency(),
        type: 'line',
        showInLegend: false,
        yAxis: 1,
        color: light.colors.secondaryLight,
        dashStyle: 'Dash',
        marker: {
          enabledThreshold: 2,
          radius: 4,
          symbol: 'circle',
          fillColor: light.colors.white,
          lineWidth: 3,
          lineColor: light.colors.secondaryLight,
        },
      },
    );
  }

  const quarterlyXaxis =
    frequency === 'quarterly'
      ? dataLevel?.historical.date
          .map((date) => String(date))
          .concat(dataLevel.forecast.date.map((date) => String(date)))
      : [];

  const chartOptions: Highcharts.Options = {
    xAxis:
      frequency === 'quarterly'
        ? {
            min: quarterlyXaxis?.length
              ? convertQuarterlyToDate(quarterlyXaxis[0]).getTime()
              : undefined,
            labels: {
              formatter() {
                // eslint-disable-next-line react/no-this-in-sfc
                return convertDateToQuarterly(subDays(new Date(this.value), 1));
              },
            },
          }
        : {
            min: undefined,
            labels: {
              formatter: undefined,
            },
          },
    yAxis: [
      {
        visible: transformations.includes('level'),
        lineWidth: 0,
        gridLineWidth: 1,
        gridLineColor: light.colors.gray2,
        showLastLabel: true,
        labels: {
          format: '{text}',
          style: {
            fontSize: '12px',
            fontFamily: "'Inter', sans-serif",
          },
        },
        title: {
          text: '',
        },
        opposite: false,
        zIndex: 0,
      },
      {
        visible: transformations.includes('variation'),
        lineWidth: 0,
        gridLineWidth: 1,
        gridLineColor: light.colors.gray2,
        showLastLabel: true,
        labels: {
          format: '{text}%',
          style: {
            fontSize: '12px',
            fontFamily: "'Inter', sans-serif",
          },
        },
        title: {
          text: '',
        },
        opposite: true,
      },
    ],
    tooltip: {
      headerFormat: '',
      pointFormat:
        '<table><tr><th colspan="2">{point.custom.tooltipTitle}</th></tr>' +
        `<tr><td><b>${translate('date')}:</b> </td>` +
        `<td style="text-align: right">{point.custom.date}</td></tr>` +
        `<tr><td><b>{point.custom.keyValue}:</b> </td>` +
        `<td style="text-align: right">{point.custom.value}</td></tr>` +
        `${
          shouldShowNOfDay
            ? `<tr><td><b>${translate(
                'workspaceProjectionsNOfDays',
              )}:</b> </td>` +
              `<td style="text-align: right">{point.custom.nOfDays}</td></tr>`
            : ''
        }`,
    },
    plotOptions: {
      series: {
        events: {
          legendItemClick: (event) => {
            const yAxisIndex = event.target.name === translate('level') ? 0 : 1;
            chartRef.current?.chart.series.forEach((serie) => {
              if (serie.name === event.target.name) {
                if (serie.visible) {
                  //@ts-expect-error:ignora
                  serie.update({ visible: false }, true);
                  chartRef.current?.chart.yAxis[yAxisIndex].update({
                    visible: false,
                  });
                } else {
                  //@ts-expect-error:ignora
                  serie.update({ visible: true }, true);
                  chartRef.current?.chart.yAxis[yAxisIndex].update({
                    visible: true,
                  });
                }
              }
            });

            return false; // Impede o comportamento padrão do clique na legenda
          },
        },
      },
    },
  };

  return (
    <HCharts
      series={dataChart}
      options={chartOptions}
      ref={chartRef}
      frequency={
        frequency === 'original' ? workspace.frequency ?? undefined : frequency
      }
      immutable={false}
      dataCy="explanatory-variables-chart"
      resizeWidthWithSidebar
    />
  );
};
