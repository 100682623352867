import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type WorkspaceProjectionsFrequency =
  | 'original'
  | 'monthly'
  | 'quarterly'
  | 'yearly';

export type WorkspaceProjectionsTransformation = 'variation' | 'level';

export type WorkspaceProjectionsInflation = 'real' | 'nominal';

export type WorkspaceProjectionsForecastType = 'original' | 'adjusted';

export interface PayloadWorkspaceProjectionsOptions {
  frequency: WorkspaceProjectionsFrequency;
  inflation: WorkspaceProjectionsInflation;
  transformations: WorkspaceProjectionsTransformation[];
  forecastType: WorkspaceProjectionsForecastType;
  isLatestDataActive: boolean;
  explanatoryVariable: string | null;
  transformationsEnabled: WorkspaceProjectionsTransformation[];
  latestDataEnabled: boolean;
  loadingLevelData: boolean;
  loadingVariationData: boolean;
}

const initialState: PayloadWorkspaceProjectionsOptions = {
  frequency: 'original',
  inflation: 'nominal',
  transformations: ['level'],
  forecastType: 'adjusted',
  isLatestDataActive: true,
  explanatoryVariable: null,
  transformationsEnabled: [],
  latestDataEnabled: false,
  loadingLevelData: true,
  loadingVariationData: true,
};

export const WorkspaceProjectionsOptionsState = createSlice({
  name: 'workspaceProjectionsOptions',
  initialState,
  reducers: {
    changeWorkspaceProjectionsFrequency: (
      state,
      action: PayloadAction<WorkspaceProjectionsFrequency>,
    ) => {
      state.frequency = action.payload;
    },
    changeWorkspaceProjectionsInflation: (
      state,
      action: PayloadAction<WorkspaceProjectionsInflation>,
    ) => {
      state.inflation = action.payload;
    },
    changeWorkspaceProjectionsTransformations: (
      state,
      action: PayloadAction<WorkspaceProjectionsTransformation[]>,
    ) => {
      state.transformations = action.payload;
    },
    changeWorkspaceProjectionsIsLatestDataActive: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.isLatestDataActive = action.payload;
    },
    changeWorkspaceProjectionsExplanatoryVariable: (
      state,
      action: PayloadAction<string | null>,
    ) => {
      state.explanatoryVariable = action.payload;
    },
    changeWorkspaceProjectionsTransformationsEnabled: (
      state,
      action: PayloadAction<WorkspaceProjectionsTransformation[]>,
    ) => {
      state.transformationsEnabled = action.payload;
    },
    changeWorkspaceProjectionsForecastType: (
      state,
      action: PayloadAction<WorkspaceProjectionsForecastType>,
    ) => {
      state.forecastType = action.payload;
    },
    changeWorkspaceProjectionsLatestDataEnabled: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.latestDataEnabled = action.payload;
    },
    changeWorkspaceProjectionsLoadingLevelData: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.loadingLevelData = action.payload;
    },
    changeWorkspaceProjectionsLoadingVariationData: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.loadingVariationData = action.payload;
    },
    resetWorkspaceProjectionsOptions: (state) => {
      state.frequency = 'original';
      state.inflation = 'nominal';
      state.transformations = ['level'];
      state.forecastType = 'adjusted';
      state.isLatestDataActive = true;
      state.explanatoryVariable = null;
    },
  },
});

export const {
  changeWorkspaceProjectionsFrequency,
  changeWorkspaceProjectionsInflation,
  changeWorkspaceProjectionsTransformations,
  changeWorkspaceProjectionsIsLatestDataActive,
  changeWorkspaceProjectionsExplanatoryVariable,
  changeWorkspaceProjectionsTransformationsEnabled,
  changeWorkspaceProjectionsForecastType,
  changeWorkspaceProjectionsLatestDataEnabled,
  resetWorkspaceProjectionsOptions,
  changeWorkspaceProjectionsLoadingLevelData,
  changeWorkspaceProjectionsLoadingVariationData,
} = WorkspaceProjectionsOptionsState.actions;
export default WorkspaceProjectionsOptionsState.reducer;
