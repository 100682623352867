import React, { useEffect } from 'react';

import { withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react';
import jwt_decode from 'jwt-decode';
import { hotjar } from 'react-hotjar';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import apiFeatureStore from 'src/feature-store/service/api';
import apiFaaS from 'src/models/service/api';
import { login, logout } from 'src/redux/reducers/Auth';
import { RootState } from 'src/redux/store';
import apiv2 from 'src/service/api';
import { apiAdmin } from 'src/service/apiAdmin';
import { apiAdminV2 } from 'src/service/apiAdminV2';
import * as FullStory from '@fullstory/browser';
import { apiAIChat } from 'src/feature-store/service/apiAIChat';
import apiWorkspace from 'src/models/service/apiWorkspace';
import { changeIsAccessing } from 'src/models/redux/reducers/MyWorks';
import { Layout } from 'src/components/Layout';

const fullStoryID = process.env.REACT_APP_FULLSTORY_ID;

type DataInfo = {
  aud: string[];
  nameSpace: string;
  'https://4intelligence.com.br/app_metadata': {
    roles: string[];
  };
  permissions: string[];
};

type PrivateRoute = {
  component: React.FC;
  path: string;
  roles?: string[];
  permissions?: string[];
  isRoutesComponent?: boolean;
};

export const PrivateRoutes: React.FC<PrivateRoute> = ({
  component,
  path,
  roles = [],
  permissions = [],
  isRoutesComponent,
}) => {
  const dispatch = useDispatch();
  const { isAuthenticated, getAccessTokenSilently, user } = useAuth0();
  const {
    auth: { isAuthenticated: isAuthenticatedRedux },
    myWorks: { isAccessing },
  } = useSelector((state: RootState) => state);

  useEffect(() => {
    (async () => {
      if (isAuthenticated) {
        if (!isAuthenticatedRedux) {
          const token = await getAccessTokenSilently();

          const data: DataInfo = jwt_decode(token);

          const nameSpace = 'https://4intelligence.com.br/app_metadata';

          if (data) {
            apiFaaS.defaults.headers.authorization = `Bearer ${token}`;
            apiFeatureStore.defaults.headers.authorization = `Bearer ${token}`;
            apiAdmin.defaults.headers.authorization = `Bearer ${token}`;
            apiv2.defaults.headers.authorization = `Bearer ${token}`;
            apiAdminV2.defaults.headers.authorization = `Bearer ${token}`;
            apiAIChat.defaults.headers.authorization = `Bearer ${token}`;
            apiWorkspace.defaults.headers.authorization = `Bearer ${token}`;

            let clientID: string | null = null;

            if (data[nameSpace]?.roles?.includes('isManager')) {
              try {
                const { data: dataAdmin } = await apiAdminV2.get('/clients');
                clientID = dataAdmin.records[0].id;
              } catch {
                clientID = null;
              }
            }

            if (user && user?.email && fullStoryID) {
              const isFreemium = data?.[nameSpace]?.roles?.some(
                (role) => role === 'isFreemium',
              );

              FullStory.identify(user?.email);

              isFreemium
                ? FullStory.setUserVars({
                    email: user?.email,
                    isFreemium: true,
                  })
                : FullStory.setUserVars({
                    email: user?.email,
                    isFreemium: false,
                  });
            }

            dispatch(
              login({
                id: user?.sub ?? null,
                email: user?.email ?? null,
                roles: data[nameSpace]?.roles ?? [],
                permissions: data.permissions,
                isEditor:
                  data?.[nameSpace]?.roles?.some(
                    (roleRedux) => roleRedux === 'isEditor',
                  ) ?? false,
                isOCB:
                  data?.[nameSpace]?.roles?.some(
                    (roleRedux) => roleRedux === 'isOCB',
                  ) ?? false,
                is4i:
                  data?.[nameSpace]?.roles?.some(
                    (roleRedux) => roleRedux === 'is4i',
                  ) ?? false,
                isFaaS:
                  data?.[nameSpace]?.roles?.some(
                    (roleRedux) => roleRedux === 'isFaaS',
                  ) ?? false,
                isAdmin:
                  data?.[nameSpace]?.roles?.some(
                    (roleRedux) => roleRedux === 'isAdmin',
                  ) ?? false,
                isFeatureStore:
                  data?.[nameSpace]?.roles?.some(
                    (roleRedux) => roleRedux === 'isFeatureStore',
                  ) ?? false,
                isFsAdmin:
                  data?.[nameSpace]?.roles?.some(
                    (roleRedux) => roleRedux === 'isFsAdmin',
                  ) ?? false,
                isClaaS:
                  data?.[nameSpace]?.roles?.some(
                    (roleRedux) => roleRedux === 'isClaaS',
                  ) ?? false,
                isSupport:
                  data?.[nameSpace]?.roles?.some(
                    (roleRedux) => roleRedux === 'isSupport',
                  ) ?? false,
                isFreemium:
                  data?.[nameSpace]?.roles?.some(
                    (roleRedux) => roleRedux === 'isFreemium',
                  ) ?? false,
                managerClientId: clientID,
              }),
            );
          }
        }

        if (path.includes('/workspace') && isAccessing !== 'workspaces') {
          dispatch(changeIsAccessing('workspaces'));
        }

        if (
          (path.includes('time-series') || path.includes('classification')) &&
          isAccessing !== 'projects'
        ) {
          dispatch(changeIsAccessing('projects'));
        }
      } else {
        dispatch(logout());
      }
    })();
  }, [
    dispatch,
    getAccessTokenSilently,
    isAuthenticated,
    isAuthenticatedRedux,
    user,
    isAccessing,
    path,
  ]);

  const { user: userRedux } = useSelector((state: RootState) => state.auth);
  if (roles.length) {
    if (!roles.some((role) => userRedux.roles.includes(role))) {
      return <Navigate to="/" />;
    }
  }

  if (permissions.length) {
    if (
      !permissions.every((permission) =>
        userRedux.permissions.includes(permission),
      )
    ) {
      return <Navigate to="/" />;
    }
  }

  let ComponentAux = component;
  if (!isAuthenticated) {
    ComponentAux = withAuthenticationRequired(component, {
      onRedirecting: () => <ContainerSkeleton style={{ height: '100vh' }} />,
    });
  }

  if (!isAuthenticatedRedux && isAuthenticated) {
    return <ContainerSkeleton style={{ height: '100vh' }} />;
  }

  if (process.env.REACT_APP_HOTJAR_ID && process.env.REACT_APP_HOTJAR_SV) {
    //caso nao tenha colocado a env do hotjar, senao ira quebrar o codigo
    hotjar.stateChange(path);
  }

  if (isRoutesComponent) {
    return <ComponentAux />;
  }

  return (
    <Layout>
      <ComponentAux />
    </Layout>
  );
};
