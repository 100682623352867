import React, { ReactElement, useEffect, useRef } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';

import { ClassificationSideBar } from './ClassificationSideBar';
import { ProjectSideBar } from './ProjectSideBar';
import { Content } from './styles';
import { WarningDailyProject } from './ProjectSideBar/WarningDailyProject';
import { ModelUpdateProvider } from './contexts/ModelUpdateContext';
import { WorkspaceSideBar } from './WorkspaceSideBar';

export const ModelsLayout: React.FC<{ children: ReactElement }> = ({
  children,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const contentRef = useRef<HTMLDivElement | null>(null);

  const {
    sideBar: { isExpanded },
  } = useSelector((state: RootState) => state);

  useEffect(() => {
    const actualLocation = location.pathname;

    if (
      actualLocation.includes('/models/projects') ||
      actualLocation.includes('/models/model-')
    ) {
      return;
    }

    if (
      actualLocation.includes('/models') &&
      !actualLocation.includes('/models/')
    ) {
      navigate('/models/projects');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasSideBar =
    (!location.pathname.includes('/classification') &&
      !location.pathname.includes('/workspaces') &&
      (location.pathname.includes('project/') ||
        location.pathname.includes('model-') ||
        location.pathname.includes('/project-overview') ||
        location.pathname.includes('/user-selection') ||
        location.pathname.includes('/AI-selection') ||
        location.pathname.includes('performance') ||
        location.pathname.includes('data-view'))) ||
    location.pathname.includes('config');

  const hasClassificationSideBar =
    !location.pathname.includes('/time-series') &&
    (location.pathname.includes('/predictions') ||
      location.pathname.includes('/model-explorer') ||
      location.pathname.includes('/model-in-production'));

  const showWarningDailyProject =
    location.pathname.includes('models/time-series');

  const hasWorkspaceSideBar =
    location.pathname.includes('/workspaces') &&
    !location.pathname.includes('/workspaces/new') &&
    !location.pathname.includes('/control-panel');

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.style.transition =
        hasSideBar || hasClassificationSideBar || hasWorkspaceSideBar
          ? 'width 0.75s'
          : 'unset';
    }
  }, [hasSideBar, hasClassificationSideBar, hasWorkspaceSideBar]);

  return (
    <>
      <Tooltip className="customTooltipWhite" id="menu-disabled-tooltip" />

      {hasSideBar && (
        <ModelUpdateProvider>
          <ProjectSideBar />
        </ModelUpdateProvider>
      )}
      {hasClassificationSideBar && <ClassificationSideBar />}

      {hasWorkspaceSideBar && <WorkspaceSideBar />}

      <Content
        data-testid="layout-container-div-faas"
        data-cy="layout-container-div-faas"
        id="layout-container-div-faas"
        sidebarWidth={isExpanded ? '20rem' : '7rem'}
        hasSidebar={
          hasSideBar || hasClassificationSideBar || hasWorkspaceSideBar
        }
        ref={contentRef}
      >
        {children}
        {showWarningDailyProject && <WarningDailyProject />}
      </Content>
    </>
  );
};
