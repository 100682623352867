import React from 'react';

import { Info } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { ButtonCopyToClipboard } from 'src/components/ButtonCopyToClipboard';
import { getDateInFormat } from 'src/utils/dates/getDateInFormat';

import {
  CopyCodeSeriesContainer,
  DescriptionContainer,
  DescriptionLine,
  DescriptionTitle,
} from './styles';
import { IndicatorInfoProps } from './types';

export const IndicatorInfo: React.FC<IndicatorInfoProps> = ({
  description,
  endDateObservation,
  endDateProjection,
  lastUpdateObservation,
  lastUpdateProjection,
  startDateObservation,
  source,
  seriesCodes,
  buttonCopyIsDisabled,
  projection,
}) => {
  const { t: translate } = useTranslation();

  const convertData = (data: string) => {
    const dataAux = new Date(data);
    const month = dataAux.getMonth() + 1;
    const year = dataAux.getFullYear();

    const dataFormatted = `${month}/${year}`;

    return dataFormatted;
  };

  return (
    <DescriptionContainer>
      <DescriptionTitle>
        <Info />
        <h4 style={{ wordBreak: 'break-word' }}>
          {translate('viewFeaturesDescription')}
        </h4>
      </DescriptionTitle>

      <p data-testid="text-description">{description}</p>

      <DescriptionLine>
        <DescriptionTitle>
          <h4>{translate('source')}</h4>
        </DescriptionTitle>
        <span data-testid="text-source">{source}</span>
      </DescriptionLine>

      {projection !== '' && (
        <DescriptionLine>
          <DescriptionTitle>
            <h4>{translate('indicatorsProjections')}</h4>
          </DescriptionTitle>
          <span data-testid="text-projections">{projection}</span>
        </DescriptionLine>
      )}

      <DescriptionLine>
        <DescriptionTitle>
          <h4>{translate('viewFeatureStartHistoric')}</h4>
        </DescriptionTitle>
        <span data-testid="text-start-date-observation">
          {startDateObservation ? convertData(startDateObservation) : '-'}
        </span>
      </DescriptionLine>

      <DescriptionLine>
        <DescriptionTitle>
          <h4>{translate('viewFeatureEndHistoric')}</h4>
        </DescriptionTitle>
        <span data-testid="text-end-date-observation">
          {endDateObservation ? convertData(endDateObservation) : '-'}
        </span>
      </DescriptionLine>

      <DescriptionLine>
        <DescriptionTitle>
          <h4>{translate('viewFeatureEndProjection')}</h4>
        </DescriptionTitle>
        <span data-testid="text-end-date-projections">
          {endDateProjection ? convertData(endDateProjection) : '-'}
        </span>
      </DescriptionLine>

      <DescriptionLine>
        <DescriptionTitle>
          <h4>{translate('viewFeatureLastUpdateObservation')}</h4>
        </DescriptionTitle>
        <span data-testid="text-last-update-observation">
          {lastUpdateObservation
            ? getDateInFormat(lastUpdateObservation, 'dd/MM/yyyy')
            : '-'}
        </span>
      </DescriptionLine>

      <DescriptionLine>
        <DescriptionTitle>
          <h4>{translate('viewFeatureLastUpdateProjection')}</h4>
        </DescriptionTitle>
        <span data-testid="text-last-update-projection">
          {lastUpdateProjection
            ? getDateInFormat(lastUpdateProjection, 'dd/MM/yyyy')
            : '-'}
        </span>
      </DescriptionLine>

      <CopyCodeSeriesContainer>
        <DescriptionTitle>
          <h4>{translate('indicatorSeriesSeriesCode')}</h4>
        </DescriptionTitle>
        <ButtonCopyToClipboard
          isDisabled={buttonCopyIsDisabled}
          messageBeforeCopy={translate('copy')}
          messageAfterCopy={translate('copied')}
          textToCopy={seriesCodes}
        />
      </CopyCodeSeriesContainer>
    </DescriptionContainer>
  );
};
