export const ShareWorkSpacePTBRranslations = {
  shareWorkSpaceChooseUserToShare: 'Escolha com quem compartilhar',
  shareWorkSpaceDescriptionShare:
    'Digite o email com quem deseja compartilhar o Workspace',
  shareWorkSpaceEditor: 'Pode editar',
  shareWorkSpaceVisualize: 'Pode visualizar',
  shareWorkspaceInvalidEmail: 'Email inválido',
  shareWorkspaceUserAlreadyInvited: 'Usuário já tem acesso',
  shareWorkspaceRepeatedEmail: 'Email repetido',
  shareWorkspaceError:
    'Ocorreu um erro ao tentar compartilhar com os e-mails abaixo. Tente novamente mais tarde.',
};
