import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useTranslation } from 'react-i18next';
import { Card } from 'src/components/Card';
import { WorkspaceConfigContext } from 'src/models/contexts/WorkspaceConfigContext';
import { Plus, Question } from 'phosphor-react';
import { Button } from 'src/components/Button';
import { Tooltip } from 'react-tooltip';
import { Table, Tbody, Th, Thead, Tr } from 'src/components/Table';
import { CheckBox } from 'src/components/CheckBox';
import { ToggleSwitch } from 'src/components/ToggleSwitch';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import SelectProjectsWorkpaceImg from 'src/assets/select-projects-workspace.svg';
import { RootState } from 'src/redux/store';
import { useSelector } from 'react-redux';

import {
  Container,
  Content,
  ContentInflateSeries,
  ErrorContainer,
  ImageContainer,
  ProjectContent,
  TableContainer,
  VariablesEmptyContainer,
} from './styles';
import {
  GetValueKey,
  Project,
  ProjectsName,
  Serie,
  SetValue,
  Step2Props,
  YsDisabled,
  YsError,
  YsInflate,
  YsLabel,
  YsModelId,
  YsModelUpdate,
  YsOriginalName,
  YsSelected,
  YsType,
} from './types';
import { StepFooter } from '../StepFooter';
import { SelectProjectsModal } from '../Modal/SelectProjects';
import { ProjectRow } from '../VariablesTable/ProjectRow';
import { ModalWarningCategorization } from '../Modal/WarningCategorization';

export const Step2: React.FC<Step2Props> = ({ setStep }) => {
  const [selectedProjects, setSelectedProjects] = useState<Project[]>([]);
  const [showSelectProjectModal, setShowSelectProjectModal] = useState(false);

  const [projectsName, setProjectsName] = useState<ProjectsName>({});

  const [ysKey, setYsKey] = useState<string[]>([]);
  const [ysSelected, setYsSelected] = useState<YsSelected>({});
  const [ysLabel, setYsLabel] = useState<YsLabel>({});
  const [ysOriginalName, setYsOriginalName] = useState<YsOriginalName>({});
  const [ysModelUpdate, setYsModelUpdate] = useState<YsModelUpdate>({});
  const [ysModelId, setYsModelId] = useState<YsModelId>({});
  const [ysInflate, setYsInflate] = useState<YsInflate>({});
  const [ysError, setYsError] = useState<YsError>({});
  const [ysDisabled, setYsDisabled] = useState<YsDisabled>({});
  const [ysType, setYsType] = useState<YsType>({});

  const [allYsAreSelected, setAllYsAreSelected] = useState(false);

  const [showOriginalYName, setShowOriginalYName] = useState(false);

  const [isTypingYLabel, setIsTypingYLabel] = useState(false);

  const [showWarningCategorization, setShowWarningCategorization] =
    useState(false);

  const {
    frequency,
    saveSeriesInformation,
    isEdition,
    projects,
    series,
    isErrorStagingArea,
    categorization,
    file,
  } = useContext(WorkspaceConfigContext);

  const { user } = useSelector((state: RootState) => state.auth);

  const { t: translate } = useTranslation();

  const getValue = (key: GetValueKey): SetValue[GetValueKey] => {
    switch (key) {
      case 'ysKey':
        return ysKey;
      case 'ysSelected':
        return ysSelected;
      case 'ysLabel':
        return ysLabel;
      case 'ysOriginalName':
        return ysOriginalName;
      case 'ysModelUpdate':
        return ysModelUpdate;
      case 'ysModelId':
        return ysModelId;
      case 'ysInflate':
        return ysInflate;
      case 'ysError':
        return ysError;
      default:
        return ysDisabled;
    }
  };

  const setValue = (key: GetValueKey, value: SetValue[GetValueKey]) => {
    switch (key) {
      case 'ysSelected':
        setYsSelected((prev) => ({ ...prev, ...(value as YsSelected) }));
        break;
      case 'ysLabel':
        setYsLabel((prev) => ({ ...prev, ...(value as YsLabel) }));
        break;
      case 'ysOriginalName':
        setYsOriginalName((prev) => ({
          ...prev,
          ...(value as YsOriginalName),
        }));
        break;
      case 'ysModelUpdate':
        setYsModelUpdate((prev) => ({ ...prev, ...(value as YsModelUpdate) }));
        break;
      case 'ysModelId':
        setYsModelId((prev) => ({ ...prev, ...(value as YsModelId) }));
        break;
      case 'ysInflate':
        setYsInflate((prev) => ({ ...prev, ...(value as YsInflate) }));
        break;
      case 'ysError':
        setYsError((prev) => ({ ...prev, ...(value as YsError) }));
        break;
      case 'ysDisabled':
        setYsDisabled((prev) => ({ ...prev, ...(value as YsDisabled) }));
        break;
      default:
        break;
    }
  };

  const checkAllInflateYs = (check: boolean) => {
    const updatedYsInflate = { ...ysInflate };

    ysKey.forEach((key) => {
      if (!ysDisabled[key] && ysSelected[key]) {
        updatedYsInflate[key] = check;
      }
    });

    setYsInflate(updatedYsInflate);
  };

  const allInflateYsAreChecked = useCallback(() => {
    let allChecked = true;
    let existsYSelected = false;

    for (let i = 0; i < ysKey.length; i++) {
      const key = ysKey[i];

      if (ysSelected[key]) {
        existsYSelected = true;
      }

      if (!ysDisabled[key] && ysSelected[key] && !ysInflate[key]) {
        allChecked = false;

        break;
      }
    }

    if (!existsYSelected && allChecked) {
      return false;
    }

    return allChecked;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ysKey, ysSelected, ysInflate]);

  const handleSelectAllYs = (select: boolean) => {
    const updatedAllYsSelected = { ...ysSelected };
    const updateYsInflate = { ...ysInflate };

    ysKey.forEach((key) => {
      if (!ysDisabled[key]) {
        updatedAllYsSelected[key] = select;
        if (!select) {
          updateYsInflate[key] = select;
        }
      }
    });

    setYsSelected(updatedAllYsSelected);
    setYsInflate(updateYsInflate);
  };

  const isRepeatedYLabelError = (error: string) =>
    error === 'createWorkspaceRepeatedVariable';

  const existsRepeatedYLabel = (key: string, newLabel: string) => {
    const currentYError = ysError[key] ?? '';

    let exists = false;

    ysKey.forEach((yKey) => {
      const isCurrentY = yKey === key;

      if (ysSelected[yKey] && !isCurrentY) {
        const label = ysLabel[yKey];

        if (label === newLabel) {
          const yError = ysError[yKey];

          exists = true;

          if (currentYError !== 'createWorkspaceRepeatedVariable') {
            setYsError((prev) => ({
              ...prev,
              [key]: 'createWorkspaceRepeatedVariable',
            }));
          }

          if (yError !== 'createWorkspaceRepeatedVariable') {
            setYsError((prev) => ({
              ...prev,
              [yKey]: 'createWorkspaceRepeatedVariable',
            }));
          }
        }
      }
    });

    if (exists) return true;

    if (isRepeatedYLabelError(currentYError)) {
      setYsError((prev) => ({
        ...prev,
        [key]: '',
      }));
    }

    return false;
  };

  const validateNewLabel = (
    projectId: string,
    yId: string,
    newLabel: string,
  ) => {
    setIsTypingYLabel(true);

    const key = `${projectId}-${yId}`;

    const yError = ysError[key] ?? '';

    const regex = new RegExp('^[áàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑA-Za-z0-9_-]*$');

    if (!newLabel?.trim()) {
      if (yError !== 'requiredField') {
        setYsError((prev) => ({
          ...prev,
          [key]: 'requiredField',
        }));
      }

      return;
    }

    const allWords = newLabel.split(' ');
    for (const word of allWords) {
      if (!regex.test(word)) {
        if (yError !== 'projectErrorNoSpecialCharacters') {
          setYsError((prev) => ({
            ...prev,
            [key]: 'projectErrorNoSpecialCharacters',
          }));
        }

        return;
      }
    }

    if (newLabel.trim().length < 3) {
      if (yError !== 'projectErrorMinCharacters') {
        setYsError((prev) => ({
          ...prev,
          [key]: 'projectErrorMinCharacters',
        }));
      }
      return;
    }

    if (newLabel.trim().length > 50) {
      if (yError !== 'projectErrorMaxCharacters') {
        setYsError((prev) => ({
          ...prev,
          [key]: 'projectErrorMaxCharacters',
        }));
      }

      return;
    }

    if (existsRepeatedYLabel(key, newLabel)) {
      return;
    }

    if (yError) {
      setYsError((prev) => ({
        ...prev,
        [key]: '',
      }));
    }
  };

  const checkAllYLabel = useCallback(
    () => {
      ysKey.forEach((key) => {
        const errorMessage = ysError[key];

        if (ysSelected[key]) {
          const label = ysLabel[key];

          const ids = key.split('-');

          validateNewLabel(ids[0], ids.slice(1).join('-'), label);
        } else if (
          errorMessage &&
          (isRepeatedYLabelError(errorMessage ?? '') ||
            errorMessage === 'requiredField')
        ) {
          setYsError((prev) => ({
            ...prev,
            [key]: '',
          }));
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ysKey, existsRepeatedYLabel, ysError],
  );

  const saveInformations = () => {
    const allSeries: Serie[] = [];

    ysKey.forEach((key) => {
      const ids = key.split('-');

      const projectId = ids[0];
      const yId = ids.slice(1).join('-');

      allSeries.push({
        projectId,
        projectName: projectsName[projectId],
        yLabel: ysLabel[key],
        y: yId,
        modelUpdate: ysModelUpdate[key],
        modelId: ysModelId[key],
        isInflated: ysInflate[key],
        yName: ysOriginalName[key],
        selected: ysSelected[key],
        error: ysError[key],
        isDisabled: ysDisabled[key],
        type: ysType[key] ?? 'others',
      });
    });

    saveSeriesInformation(selectedProjects, allSeries, ysLabel);
  };

  const nextStep = () => {
    saveInformations();

    setStep(3);
  };

  const backStep = () => {
    saveInformations();

    setStep(1);
  };

  useEffect(() => {
    let timer: NodeJS.Timer | undefined;

    if (isTypingYLabel) {
      clearTimeout(timer);

      timer = setTimeout(() => {
        setIsTypingYLabel(false);
      }, 1000);
    }

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTypingYLabel]);

  useEffect(() => {
    if (!isTypingYLabel) {
      ysKey.forEach((key) => {
        if (isRepeatedYLabelError(ysError[key] ?? '')) {
          existsRepeatedYLabel(key, ysLabel[key]);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTypingYLabel, ysLabel]);

  useEffect(() => {
    let selected = true;

    for (let i = 0; i < ysKey.length; i++) {
      const key = ysKey[i];

      if (!ysDisabled[key] && !ysSelected[key] && !ysModelId[key].error) {
        selected = false;

        break;
      }
    }

    setAllYsAreSelected(selected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ysSelected]);

  useEffect(() => {
    checkAllYLabel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ysKey, ysSelected]);

  useEffect(() => {
    if (selectedProjects.length) {
      const updatedProjectsName: ProjectsName = {};

      const updatedYsKey: string[] = [];
      const updatedYsSelected: YsSelected = {};
      const updatedYsLabel: YsLabel = {};
      const updatedysOriginalName: YsOriginalName = {};
      const updatedYsModelUpdate: YsModelUpdate = {};
      const updatedYsModelId: YsModelId = {};
      const updatedYsInflate: YsInflate = {};
      const updatedYsError: YsError = {};
      const updatedYsDisabled: YsDisabled = {};
      const updatedYsType: YsType = {};

      let updatedAllYsSelected = true;

      if (series.length && !ysKey.length) {
        series.forEach((serie) => {
          const key = `${serie.projectId}-${serie.y}`;

          updatedProjectsName[serie.projectId] = serie.projectName;

          updatedYsKey.push(key);

          updatedYsSelected[key] = serie.selected;
          updatedYsLabel[key] = serie.yLabel;
          updatedysOriginalName[key] = serie.yName;
          updatedYsModelUpdate[key] = serie.modelUpdate;
          updatedYsModelId[key] = serie.modelId;
          updatedYsInflate[key] = serie.isInflated;
          updatedYsError[key] = serie.error;
          updatedYsDisabled[key] = serie.isDisabled;
          updatedYsType[key] = serie.type ?? 'others';

          if (serie.selected === false) {
            updatedAllYsSelected = false;
          }
        });
      } else {
        selectedProjects.forEach(({ id, ys, name }) => {
          updatedProjectsName[id] = name;

          ys.forEach(({ id: yId, name: yName, label, status }) => {
            const key = `${id}-${yId}`;
            const hasError = status !== 'success' || !!ysModelId[key]?.error;

            updatedYsKey.push(key);

            updatedYsSelected[key] = hasError ? false : ysSelected[key] ?? true;
            updatedYsLabel[key] = ysLabel[key] ?? label;
            updatedysOriginalName[key] = yName;

            if (ysModelUpdate[key]) {
              updatedYsModelUpdate[key] = ysModelUpdate[key];
            }

            updatedYsModelId[key] = ysModelId[key] ?? {
              model_id: hasError ? '--' : 'ai-selection',
              model_label: hasError ? 'others' : 'ai-selection',
              error: hasError,
            };
            updatedYsInflate[key] = ysInflate[key] ?? false;
            updatedYsError[key] = ysError[key] ?? null;
            updatedYsDisabled[key] = hasError ?? false;

            if (ysSelected[key] === false) {
              updatedAllYsSelected = false;
            }
          });
        });
      }

      setProjectsName(updatedProjectsName);

      setYsSelected(updatedYsSelected);
      setYsLabel(updatedYsLabel);
      setYsOriginalName(updatedysOriginalName);
      setYsModelUpdate(updatedYsModelUpdate);
      setYsModelId(updatedYsModelId);
      setYsInflate(updatedYsInflate);
      setYsError(updatedYsError);
      setYsDisabled(updatedYsDisabled);
      setYsType(updatedYsType);
      setYsKey(updatedYsKey);

      setAllYsAreSelected(updatedAllYsSelected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProjects]);

  useEffect(() => {
    if (projects.length && series.length) {
      setSelectedProjects(projects);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [series]);

  useEffect(() => {
    if (categorization?.hierarchies.length || file) {
      setShowWarningCategorization(true);
    }
  }, [categorization, file]);

  const yErrorMessage = useMemo(() => {
    let quantityYsSelected = 0;

    for (let i = 0; i < ysKey.length; i++) {
      const key = ysKey[i];

      if (ysSelected[key] && ysError[key]) {
        return 'createWorkspaceVariableNameError';
      }

      if (ysSelected[key] && ysModelId[key].error) {
        return 'createWorkspaceVariableModelIDError';
      }

      if (ysSelected[key]) {
        quantityYsSelected++;
      }
    }

    if (quantityYsSelected > 1000) {
      return 'createWorkspaceVariableSelectMoreThan1000';
    }

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ysSelected, ysError, ysModelId, allYsAreSelected]);

  const atLeastOneYSelected = useMemo(
    () => ysKey.some((key) => ysSelected[key]),
    [ysKey, ysSelected],
  );

  const hasInflateYs = frequency === 'monthly';

  const isMessageMoreThan1000VariablesEN =
    yErrorMessage === 'createWorkspaceVariableSelectMoreThan1000' &&
    user.language === 'en-us';

  const isMessageMoreThan1000VariablesPT =
    yErrorMessage === 'createWorkspaceVariableSelectMoreThan1000' &&
    user.language === 'pt-br';

  return (
    <Container className="containerLinear" data-testid="container-step-2">
      <Card
        textCard={translate('createWorkspaceSelectVariablesTitle')}
        textDescription={translate('createWorkspaceSelectVariablesDescription')}
      />

      {isErrorStagingArea ? (
        <ContainerMaintenance
          content="content"
          style={{ marginTop: '3rem' }}
          data-testid="staging-area-error"
        />
      ) : (isEdition && !selectedProjects.length) ||
        (series.length && !ysKey.length) ? (
        // eslint-disable-next-line react/jsx-indent
        <ContainerSkeleton
          style={{ height: '30rem' }}
          data-testid="loading-variables"
        />
      ) : (
        <>
          {!!selectedProjects.length && (
            <ProjectContent>
              <div>
                <Button
                  buttonType="naked"
                  icon={<Plus />}
                  onClick={() => setShowSelectProjectModal(true)}
                  data-testid="add-new-project-button"
                >
                  {translate('createWorkspaceAddNewProject')}
                </Button>
              </div>

              <ToggleSwitch
                label={translate('createWorkspaceShowOriginalVariableName')}
                checked={showOriginalYName}
                onChange={(e) => setShowOriginalYName(e.target.checked)}
                data-testid="toggle-show-original-variable-name"
              />
            </ProjectContent>
          )}

          <Content>
            {yErrorMessage && (
              <ErrorContainer
                visible
                data-testid="variable-error"
                isMessageMoreThan1000VariablesEN={
                  isMessageMoreThan1000VariablesEN
                }
                isMessageMoreThan1000VariablesPT={
                  isMessageMoreThan1000VariablesPT
                }
              >
                {translate(yErrorMessage)}
              </ErrorContainer>
            )}

            {!selectedProjects.length ? (
              <VariablesEmptyContainer data-testid="container-no-projects-selected">
                <ImageContainer>
                  <img
                    src={SelectProjectsWorkpaceImg}
                    alt="Select the projects to compose your results"
                  />

                  <div />
                </ImageContainer>

                <Button
                  buttonType="tertiary"
                  icon={<Plus weight="bold" />}
                  disabled
                  className="plus-button"
                />

                <h2>{translate('createWorkspaceNoProjectSelectedTitle')}</h2>
                <p>
                  {translate('createWorkspaceNoProjectSelectedDescription')}
                </p>

                <Button
                  buttonType="tertiary"
                  onClick={() => setShowSelectProjectModal(true)}
                  data-testid="button-select-project"
                >
                  {translate('createWorkspaceSelectProjectsButton')}
                </Button>
              </VariablesEmptyContainer>
            ) : (
              <TableContainer id="workspace-table-container">
                <Table data-testid="table-variables-info">
                  <Thead>
                    <Tr>
                      <Th
                        style={{
                          minWidth: '10rem',
                        }}
                      >
                        {translate('createWorkspaceProject')}
                      </Th>
                      <Th width="1.5rem" style={{ paddingRight: 0 }}>
                        <CheckBox
                          checked={allYsAreSelected}
                          onChange={({ target: { checked } }) => {
                            setAllYsAreSelected(checked);

                            handleSelectAllYs(checked);
                          }}
                          className="checkbox-select-all"
                          data-testid="checkbox-select-all-variables"
                        />
                      </Th>
                      <Th
                        className="rename-variable-column"
                        style={{ paddingLeft: 0 }}
                      >
                        {translate('createWorkspaceVariable')}
                      </Th>
                      <Th
                        style={{
                          minWidth: '9rem',
                          display: showOriginalYName ? 'table-cell' : 'none',
                        }}
                      >
                        {translate('createWorkspaceOriginalVariable')}
                      </Th>

                      <Th style={{ minWidth: '10rem' }}>
                        {translate('createWorkspaceModelUpdate')}
                      </Th>
                      <Th style={{ minWidth: '8rem' }}>
                        {translate('createWorkspaceModelId')}
                      </Th>
                      {hasInflateYs && (
                        <Th style={{ width: '9rem' }}>
                          <ContentInflateSeries>
                            <CheckBox
                              label={translate('createWorkspaceInflateSerie')}
                              className="checkboxInflation"
                              onChange={({ target }) =>
                                checkAllInflateYs(target.checked)
                              }
                              checked={allInflateYsAreChecked()}
                            />
                            <Question
                              size={16}
                              data-tooltip-id="config-workspace-tooltip"
                              data-tooltip-html={translate(
                                'createWorkspaceInflateSerieTooltip',
                              )}
                            />
                          </ContentInflateSeries>
                        </Th>
                      )}
                    </Tr>
                  </Thead>
                  <Tbody>
                    {selectedProjects.map((proj) => (
                      <ProjectRow
                        key={proj.id}
                        project={proj}
                        showOriginalYName={showOriginalYName}
                        getValue={getValue}
                        setValue={setValue}
                        validateNewLabel={validateNewLabel}
                      />
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            )}
          </Content>
        </>
      )}

      <StepFooter
        stepQtty={3}
        selectedStep={2}
        backStep={{
          text: translate('step1'),
          onClick: backStep,
          disabled: false,
        }}
        nextStep={{
          text: translate('step3'),
          onClick: nextStep,
          disabled:
            !selectedProjects.length ||
            !!yErrorMessage ||
            !!ysKey.find((key) => !ysModelUpdate[key]?.value)?.length ||
            !atLeastOneYSelected,
        }}
      />

      {showSelectProjectModal && (
        <SelectProjectsModal
          visible={showSelectProjectModal}
          setVisible={setShowSelectProjectModal}
          selectedProjects={selectedProjects}
          setSelectedProjects={setSelectedProjects}
        />
      )}

      {showWarningCategorization && (
        <ModalWarningCategorization
          visible={showWarningCategorization}
          setVisible={setShowWarningCategorization}
        />
      )}

      <Tooltip id="workspace-table" className="customTooltipTheme" />
      <Tooltip id="config-workspace-tooltip" className="customTooltipTheme" />
    </Container>
  );
};
