const workspaceSideBarENUSTranslations = {
  workspaceSideBarOverviewLabel: 'Overview',
  workspaceSideBarPerformanceLabel: 'Performance',
  workspaceSideBarProjectionsLabel: 'Projections',
  workspaceSideBarResults: 'RESULTS',
  workspaceSelectAnotherProject: 'Click to select another workspace',
  workspaceSidebarUpdateTitle: 'Update History',
  workspaceSidebarUpdateDescription:
    'See the history of updates and the original version of your workspace.',
  workspaceSideBarDependentVariable: 'Dependent Variable',
  workspaceSideBarLatestVersion: 'latest version',
  workspaceSideBarPerformanceTooltip:
    'Update at least one series to view your performance.',
};

export default workspaceSideBarENUSTranslations;
