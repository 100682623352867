import React, { memo } from 'react';

import { ArrowRight } from 'phosphor-react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from 'src/redux/store';
import { addThousandSeparator } from 'src/utils/numbers/addThousandSeparator';
import { formatCompactNotation } from 'src/utils/numbers/formatCompactNotation';

import { Container } from './styles';
import { ValueCellProps } from './types';

const MAX_VALUE = 999999999.99;

export const ValueCell: React.FC<ValueCellProps> = memo(
  ({
    id,
    baselineValue,
    adjustedValue,
    isForecast,
    isMarketShare,
    showBaselineValue,
    showValueError,
  }) => {
    const { t: translate } = useTranslation();

    const fontWeight = isMarketShare ? 500 : 400;

    const { language } = useSelector((state: RootState) => state.auth.user);

    const formatValue = (value: number) => {
      if (value >= MAX_VALUE * -1 && value <= MAX_VALUE) {
        return addThousandSeparator(value, language);
      }

      return formatCompactNotation(value, 2, language);
    };

    const tooltipContent = (value?: number) => {
      if (!value || (value >= MAX_VALUE * -1 && value <= MAX_VALUE)) {
        return undefined;
      }

      return addThousandSeparator(value, language);
    };

    const dataTestid = id.replaceAll(' ', '-').toLowerCase();

    const adjustedValueFormatted = formatValue(adjustedValue ?? 0);
    const baselineValueFormatted = formatValue(baselineValue);

    if (
      (!adjustedValue && adjustedValue !== 0) ||
      baselineValueFormatted === adjustedValueFormatted ||
      !showBaselineValue ||
      !isForecast
    ) {
      return (
        <p
          data-tooltip-id="results-table"
          data-tooltip-content={tooltipContent(adjustedValue)}
          data-testid={`${
            isForecast ? 'baseline' : 'historical'
          }-value-${dataTestid}`}
          style={{ fontWeight }}
        >
          {adjustedValue === undefined ? '-' : adjustedValueFormatted}
        </p>
      );
    }

    return (
      <Container
        error={
          adjustedValue <= 0 && showValueError && !id.startsWith('row-group-')
        }
        data-tooltip-id="results-table"
        data-tooltip-html={
          !!tooltipContent(baselineValue) || !!tooltipContent(adjustedValue)
            ? `<div class="baseline-adjusted-value-tooltip"><p><b>${translate(
                'original',
              )}:</b> ${addThousandSeparator(
                baselineValue,
                language,
              )}</p><p><b>${translate('adjusted')}:</b> ${addThousandSeparator(
                adjustedValue,
                language,
              )}</p></div>`
            : undefined
        }
        data-testid={`${
          isForecast ? 'baseline' : 'historical'
        }-adjusted-value-${dataTestid}`}
      >
        <div
          className="baseline-value"
          data-testid={`${
            isForecast ? 'baseline' : 'historical'
          }-value-${dataTestid}`}
        >
          {baselineValueFormatted} <ArrowRight />
        </div>
        <p
          className="adjusted-value"
          data-testid={`adjusted-value-${dataTestid}`}
        >
          {adjustedValueFormatted}
        </p>
      </Container>
    );
  },
  (prevProps, nextProps) =>
    prevProps.id === nextProps.id &&
    prevProps.baselineValue === nextProps.baselineValue &&
    prevProps.adjustedValue === nextProps.adjustedValue &&
    prevProps.isForecast === nextProps.isForecast &&
    prevProps.showBaselineValue === nextProps.showBaselineValue &&
    prevProps.isMarketShare === nextProps.isMarketShare &&
    prevProps.showValueError === nextProps.showValueError,
);
