import React, { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Card } from 'src/components/Card';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import api from 'src/models/service/api';
import { RootState } from 'src/redux/store';
import { getChartColor } from 'src/utils/colors/getChartColor';
import { CheckBox } from 'src/components/CheckBox';
import { HCharts, HChartsOptions, HChartsSeries } from 'src/components/HCharts';
import { format } from 'date-fns';
import { formatCompactNotation } from 'src/utils/numbers/formatCompactNotation';
import { Tooltip } from 'react-tooltip';

import { Options } from './styles';

interface ErrorPercentage {
  data: string[][2];
  type: string;
  x: number[];
  y: number[];
}

type Accuracy = 'MASE' | 'MASEs';

interface ScaledErrorMetricsProps {
  type:
    | 'arima'
    | 'regularized-regression'
    | 'forecast-combination'
    | 'random-forest';
  modelId: number;
  isLoadingModelInfo: boolean;
  isMASEDisabled: boolean;
  isMASEsDisabled: boolean;
  isErrorModelInfo: boolean;
}

export const ScaledErrorMetrics: React.FC<ScaledErrorMetricsProps> = ({
  type,
  modelId,
  isLoadingModelInfo,
  isMASEDisabled,
  isMASEsDisabled,
  isErrorModelInfo,
}) => {
  const { project } = useSelector((state: RootState) => state);

  const [accuracySelected, setAccuracySelected] = useState<Accuracy[]>([]);

  const { t: translate } = useTranslation();

  useEffect(() => {
    if (accuracySelected.length === 0) {
      if (!isMASEDisabled) {
        setAccuracySelected(['MASE']);
      } else if (!isMASEsDisabled) {
        setAccuracySelected(['MASEs']);
      }
    }
  }, [accuracySelected.length, isMASEDisabled, isMASEsDisabled]);

  const {
    data: dataErrorScaleMASE,
    isLoading: isLoadingErrorScaleMASE,
    isFetching: isFetchingErrorScaleMASE,
    isError: isErrorErrorScaleMASE,
  } = useQuery(
    [`${type} scale error`, project.id, project.selectedY?.id, modelId, 'MASE'],
    async () => {
      const { data } = await api.get<ErrorPercentage>(
        `/projects/${project.id}/${project.selectedY?.id}/models/${type}/${modelId}/accuracy/MASE`,
      );

      return data;
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled:
        !!project.id && !!project.selectedY?.id && !!modelId && !!project.model,
    },
  );

  const {
    data: dataErrorScaleMASEs,
    isLoading: isLoadingErrorScaleMASEs,
    isFetching: isFetchingErrorScaleMASEs,
    isError: isErrorErrorScaleMASEs,
  } = useQuery(
    [
      `${type} scale error`,
      project.id,
      project.selectedY?.id,
      modelId,
      'MASEs',
    ],
    async () => {
      const { data } = await api.get<ErrorPercentage>(
        `/projects/${project.id}/${project.selectedY?.id}/models/${type}/${modelId}/accuracy/MASEs`,
      );

      return data;
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled:
        !!project.id && !!project.selectedY?.id && !!modelId && !!project.model,
    },
  );

  useEffect(() => {
    if (isMASEDisabled || isErrorErrorScaleMASE) {
      setAccuracySelected((state) =>
        state.filter((accuracy) => accuracy !== 'MASE'),
      );
    } else if (isMASEsDisabled || isErrorErrorScaleMASEs) {
      setAccuracySelected((state) =>
        state.filter((accuracy) => accuracy !== 'MASEs'),
      );
    }
  }, [
    isMASEDisabled,
    isMASEsDisabled,
    isErrorErrorScaleMASE,
    isErrorErrorScaleMASEs,
  ]);

  useEffect(() => {
    if (accuracySelected.length === 0) {
      if (!isMASEDisabled && !isErrorErrorScaleMASE) {
        setAccuracySelected(['MASE']);
      } else if (!isMASEsDisabled && !isErrorErrorScaleMASEs) {
        setAccuracySelected(['MASEs']);
      }
    }
  }, [
    accuracySelected.length,
    isMASEDisabled,
    isErrorErrorScaleMASE,
    isMASEsDisabled,
    isErrorErrorScaleMASEs,
  ]);

  function handleAccuracy(value: Accuracy) {
    if (accuracySelected.includes(value)) {
      setAccuracySelected((state) =>
        state.filter((accuracy) => accuracy !== value),
      );
    } else {
      setAccuracySelected((state) => [...state, value]);
    }
  }

  function checkIsLoading() {
    if (isLoadingModelInfo) {
      return true;
    }

    if (accuracySelected.includes('MASE')) {
      if (
        isLoadingErrorScaleMASE ||
        isFetchingErrorScaleMASE ||
        !dataErrorScaleMASE
      ) {
        return true;
      }
    }

    if (accuracySelected.includes('MASEs')) {
      if (
        isLoadingErrorScaleMASEs ||
        isFetchingErrorScaleMASEs ||
        !dataErrorScaleMASEs
      ) {
        return true;
      }
    }

    return false;
  }

  function checkIsError() {
    if (project.projectError || isErrorModelInfo) {
      return true;
    }

    if (isLoadingModelInfo) {
      return false;
    }

    if (isMASEDisabled && isMASEsDisabled) {
      return true;
    }

    if (isErrorErrorScaleMASE && isErrorErrorScaleMASEs) {
      return true;
    }

    if (accuracySelected.includes('MASE') && isErrorErrorScaleMASE) {
      return true;
    }

    if (accuracySelected.includes('MASEs') && isErrorErrorScaleMASEs) {
      return true;
    }

    return false;
  }

  const series: HChartsSeries[] = useMemo(() => {
    const seriesAux: HChartsSeries[] = [];

    if (accuracySelected.includes('MASE')) {
      seriesAux.push({
        type: 'line',
        name: 'MASE',
        color: getChartColor(0),
        data: dataErrorScaleMASE?.x.map((x, xIndex) => ({
          x,
          y: dataErrorScaleMASE.y[xIndex],

          custom: {
            start: format(
              new Date(`${dataErrorScaleMASE.data[xIndex][0]}T00:00`),
              project.selectedY?.info?.frequency === 'annual'
                ? 'yyyy'
                : 'dd/MM/yyyy',
            ),
            end: format(
              new Date(`${dataErrorScaleMASE.data[xIndex][1]}T00:00`),
              project.selectedY?.info?.frequency === 'annual'
                ? 'yyyy'
                : 'dd/MM/yyyy',
            ),
            value: formatCompactNotation(dataErrorScaleMASE.y[xIndex]),
          },
        })),
        marker: {
          symbol: 'Circle',
        },
      });
    }

    if (accuracySelected.includes('MASEs')) {
      seriesAux.push({
        type: 'line',
        name: 'MASEs',
        color: getChartColor(1),
        data: dataErrorScaleMASEs?.x.map((x, xIndex) => ({
          x,
          y: dataErrorScaleMASEs.y[xIndex],
          custom: {
            start: format(
              new Date(`${dataErrorScaleMASEs.data[xIndex][0]}T00:00`),
              project.selectedY?.info?.frequency === 'annual'
                ? 'yyyy'
                : 'dd/MM/yyyy',
            ),
            end: format(
              new Date(`${dataErrorScaleMASEs.data[xIndex][1]}T00:00`),
              project.selectedY?.info?.frequency === 'annual'
                ? 'yyyy'
                : 'dd/MM/yyyy',
            ),
            value: formatCompactNotation(dataErrorScaleMASEs.y[xIndex]),
          },
        })),
        marker: {
          symbol: 'Circle',
        },
      });
    }

    return seriesAux;
  }, [
    accuracySelected,
    dataErrorScaleMASE,
    dataErrorScaleMASEs,
    project.selectedY?.info?.frequency,
  ]);

  const options: HChartsOptions = {
    chart: {
      height: 300,
    },
    xAxis: {
      type: 'linear',
      minTickInterval: undefined,
      title: {
        text: translate('crossValidationXAXISTitleWindow'),
      },
    },
    tooltip: {
      pointFormat:
        `<tr><td><b>${translate('start')}:</b> </td>` +
        `<td style="text-align: right">{point.custom.start}</td></tr>` +
        `<tr><td><b>${translate('end')}:</b> </td>` +
        '<td style="text-align: right">{point.custom.end}</td></tr>' +
        `<tr><td><b>${translate('value')}:</b> </td>` +
        '<td style="text-align: right">{point.custom.value}</td></tr>',
    },
  };

  const isLoading = checkIsLoading();
  const isError = checkIsError();

  const canDisableCheckbox = accuracySelected.length <= 1;

  const isCheckboxMASEDisabled =
    (accuracySelected.includes('MASE') && canDisableCheckbox) ||
    isMASEDisabled ||
    isErrorErrorScaleMASE;

  const isCheckboxMASESDisabled =
    (accuracySelected.includes('MASEs') && canDisableCheckbox) ||
    isMASEsDisabled ||
    isErrorErrorScaleMASEs;

  return (
    <div
      className="containerLinear"
      data-testid="scaled-error-metrics-container"
      data-cy="scaled-error-metrics-container"
    >
      <Card
        textCard={translate('crossValidationScaledErrorMetrics')}
        textDescription={translate(
          'crossValidationScaledErrorMetricsDescription',
        )}
      />

      <Tooltip
        id="scaled-error-metrics-tooltip"
        className="customTooltipTheme"
      />

      <Options>
        <span>{translate('crossValidationMetrics')}</span>
        <div>
          <div
            data-tooltip-id="scaled-error-metrics-tooltip"
            data-tooltip-html={
              isMASEDisabled || isErrorErrorScaleMASE
                ? translate('notAvailable')
                : undefined
            }
          >
            <CheckBox
              label="MASE"
              onChange={() => handleAccuracy('MASE')}
              checked={accuracySelected.includes('MASE')}
              disabled={isCheckboxMASEDisabled}
              data-cy="scaled-error-metrics-checkbox-mase"
              data-testid="scaled-error-metrics-checkbox-mase"
            />
          </div>

          <div
            data-tooltip-id="scaled-error-metrics-tooltip"
            data-tooltip-html={
              isMASEsDisabled || isErrorErrorScaleMASEs
                ? translate('notAvailable')
                : undefined
            }
          >
            <CheckBox
              label="MASEs"
              onChange={() => handleAccuracy('MASEs')}
              checked={accuracySelected.includes('MASEs')}
              disabled={isCheckboxMASESDisabled}
              data-cy="scaled-error-metrics-checkbox-mases"
              data-testid="scaled-error-metrics-checkbox-mases"
            />
          </div>
        </div>
      </Options>

      {isError ? (
        <ContainerMaintenance
          content="chart"
          data-testid="scaled-error-metrics-error"
          data-cy="scaled-error-metrics-error"
        />
      ) : isLoading ? (
        <ContainerSkeleton
          data-cy="scaled-error-metrics-loading"
          data-testid="scaled-error-metrics-loading"
        />
      ) : (
        <HCharts
          series={series}
          options={options}
          dataCy="scaled-error-metrics-chart"
          resizeWidthWithSidebar
        />
      )}
    </div>
  );
};
