import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  margin: 2rem 0;
  padding-top: 2rem;

  border-top: 1px solid ${({ theme }) => theme.colors.gray2};

  .select__menu {
    z-index: 100000000;
  }

  h4 {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.6875rem;

    color: ${({ theme }) => theme.colors.gray6};
  }
`;
