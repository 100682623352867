import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: center;

  > h1 {
    color: ${({ theme }) => theme.colors.gray6};
    font-size: 2.5rem;
    font-weight: 700;

    margin-top: 2rem;
  }

  > p {
    max-width: 30rem;

    opacity: 0.6;

    color: ${({ theme }) => theme.colors.gray5};
    font-size: 1.125rem;
    line-height: 150%;

    margin-top: 1rem;
  }
`;
