import React, { useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { CaretDown, FunnelSimple, X } from 'phosphor-react';
import { Tooltip } from 'react-tooltip';

import { CloseButton, Container, FiltersContent, OpenButton } from './styles';
import { Card } from '../Card';
import { FiltersSidebarProps } from './types';
import { CheckboxFilter } from './CheckboxFilter';
import { RadioButtonFilter } from './RadioButtonFilter';
import { ToggleSwitch } from '../ToggleSwitch';
import { RangeDatePicker } from '../RangeDatePicker';

export const FiltersSidebar: React.FC<FiltersSidebarProps> = ({
  filters,
  onOpenOrCloseSidebar,
}) => {
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  const { t: translate } = useTranslation();

  useEffect(() => {
    function isElementVisible(element: HTMLElement) {
      const rect = element.getBoundingClientRect();
      return rect.bottom >= 0 && rect.top <= window.innerHeight;
    }

    function handleScroll() {
      const footer = document.getElementById('footer');

      if (footer) {
        if (isElementVisible(footer)) {
          if (containerRef.current) {
            containerRef.current.style.height = `calc(100vh - (100vh - ${
              footer.getBoundingClientRect().top / 16
            }rem) - 4.625rem)`;
          }
        } else if (containerRef.current) {
          containerRef.current.style.height = `calc(100vh - 4.625rem)`;
        }
      }
    }

    handleScroll();

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  function handleOpenOrCloseSidebar(isOpen: boolean) {
    setSidebarIsOpen(isOpen);
    if (onOpenOrCloseSidebar) {
      onOpenOrCloseSidebar(isOpen);
    }
  }

  return (
    <>
      <OpenButton
        type="button"
        onClick={() => handleOpenOrCloseSidebar(true)}
        data-testid="open-filters-sidebar-button"
      >
        <FunnelSimple />
        {translate('filters')}
        <CaretDown />
      </OpenButton>

      <Container
        ref={containerRef}
        isOpen={sidebarIsOpen}
        data-testid="filters-sidebar"
      >
        <CloseButton
          type="button"
          onClick={() => handleOpenOrCloseSidebar(false)}
          data-testid="close-filters-sidebar-button"
        >
          <X />
        </CloseButton>

        <Card
          textCard={translate('filters')}
          textDescription={translate('filtersSidebarDescription')}
          className="filter-title-description"
        />

        <FiltersContent>
          {filters.map((filter) => {
            switch (filter.type) {
              case 'checkbox':
                return (
                  <CheckboxFilter
                    key={filter.name}
                    name={filter.name}
                    options={filter.options}
                    onChange={filter.onChange}
                  />
                );
              case 'radio':
                return (
                  <RadioButtonFilter
                    key={filter.name}
                    name={filter.name}
                    options={filter.options}
                    onChange={filter.onChange}
                    tooltip={filter.tooltip}
                  />
                );
              case 'toggle':
                return (
                  <div
                    key={filter.label}
                    data-tooltip-id="filters-sidebar-tooltip"
                    data-tooltip-html={filter.tooltipInfo}
                  >
                    <ToggleSwitch
                      label={filter.label}
                      checked={filter.checked}
                      disabled={filter.disabled}
                      onChange={filter.onChange}
                      className={filter.loading ? 'loading-toggle' : ''}
                    />
                  </div>
                );

              default:
                return (
                  <RangeDatePicker
                    label={filter.name}
                    frequency={filter.frequency}
                    dateRange={filter.selectedDate}
                    onChange={filter.onChange}
                    inputTestId={`filter-${filter.name
                      ?.replaceAll(' ', '-')
                      .toLowerCase()}`}
                  />
                );
            }
          })}
        </FiltersContent>
      </Container>

      <Tooltip id="filters-sidebar-tooltip" className="customTooltipTheme" />
    </>
  );
};
