import React, { useCallback, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { ArrowRight, GearSix, UserGear } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Head } from 'src/components/Head';
import { LanguageMenu } from 'src/components/LanguageMenu';
import { UserConfigs } from 'src/components/Layout/UserConfigs';
import { logout } from 'src/redux/reducers/Auth';
import { RootState } from 'src/redux/store';

import CubeLightBlue from '../../assets/Cube_light_blue.svg';
import CubeRose from '../../assets/Cube_rose.svg';
import Logo from '../../assets/logo_without_text.svg';
import {
  ButtonLogout,
  Container,
  ContainerAdmin,
  ContainerProducts,
  Content,
  DivAccessGroupPremium,
  DivButtonHeader,
  DivContentButtons,
  Header,
  Product,
} from './styles';

export const Home: React.FC = () => {
  const { user } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  const auth0 = useAuth0();
  const { t: translate } = useTranslation();

  const [menuVisible, setMenuVisible] = useState(false);

  const handleLogout = useCallback(() => {
    dispatch(logout());
    auth0.logout({ logoutParams: { returnTo: window.location.origin } });
  }, [auth0, dispatch]);

  return (
    <Container>
      <Head title="4intelligence" />
      <Header>
        <img src={Logo} alt="logo" />

        <DivContentButtons>
          <LanguageMenu />
          <button
            type="button"
            data-testid="button-user-settings"
            style={{ marginRight: '1rem' }}
            onClick={() => setMenuVisible(true)}
            aria-label="user settings"
          >
            <UserGear size="1.5rem" />
          </button>
          {/* {user.isSupport ? (
            <Link
              to="/user-module/customers-list"
              id="link-admin"
              data-testid="link-admin"
              data-cy="link-admin"
              style={{ marginRight: '1rem' }}
            >
              <UserCircleGear size="1.5rem" />
            </Link>
          ) : (
            user.managerClientId && (
              <Link
                to={`/user-module/customers-list/${user.managerClientId}`}
                id="link-admin"
                data-testid="link-admin"
                data-cy="link-admin"
                style={{ marginRight: '1rem' }}
              >
                <UserCircleGear size="1.5rem" />
              </Link>
            )
          )} */}
          {user?.roles?.includes('isAdmin') && (
            <DivButtonHeader>
              <Link
                to="/admin"
                data-testid="link-admin"
                data-cy="link-admin-4casthub"
              >
                <GearSix size="1.25rem" />
                <p>ADMIN</p>
              </Link>
            </DivButtonHeader>
          )}
          <ButtonLogout
            type="button"
            onClick={() => handleLogout()}
            data-testid="button-logout"
            data-cy="button-logout"
          >
            {translate('logout')}
          </ButtonLogout>
        </DivContentButtons>
        <UserConfigs
          visible={menuVisible}
          setVisible={setMenuVisible}
          isHomePage
        />
      </Header>
      <Content>
        <h2>{translate('our_products')}</h2>
        <ContainerProducts>
          <Product
            noAccess={!user.isFaaS && !user.isClaaS}
            image={CubeRose}
            data-testid="container-faas"
            data-cy="container-faas"
          >
            <div>
              {/*image*/}
              <span />
              <span />
              <span />
              <span />
            </div>
            <h3>Models</h3>
            <p>{translate('homeModelsDescription')}</p>
            <div className="links">
              <Link to="/models/projects" data-testid="link-faas">
                {translate('access')} <ArrowRight size="1rem" />
              </Link>
            </div>
          </Product>
          <Product
            noAccess={!user.isFeatureStore}
            image={CubeLightBlue}
            data-testid="container-feature-store"
            data-cy="container-feature-store"
          >
            <div>
              {/*image*/}
              <span />
              <span />
              <span />
              <span />
            </div>
            <h3>Feature Store</h3>
            <p>{translate('homeFSDescription')}</p>
            {user?.permissions?.includes('edit:access-groups') && (
              <DivAccessGroupPremium>
                <Link
                  to="/feature-store/admin/groups-premium"
                  data-testid="link-group-access"
                  data-cy="link-group-access"
                >
                  <GearSix size="1.25rem" />
                  <p>ACCESS GROUP</p>
                </Link>
              </DivAccessGroupPremium>
            )}

            <div className="links">
              <Link to="/feature-store/home" data-testid="link-feature-store">
                {translate('access')} <ArrowRight size="1rem" />
              </Link>
            </div>
            {user?.roles?.includes('isFsAdmin') && (
              <ContainerAdmin>
                <Link
                  to="/feature-store/admin"
                  data-testid="link-admin"
                  data-cy="link-admin-fs"
                >
                  <GearSix size="1.25rem" />
                  <p>ADMIN</p>
                </Link>
              </ContainerAdmin>
            )}
          </Product>
        </ContainerProducts>
      </Content>
    </Container>
  );
};
