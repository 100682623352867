import React, { ReactElement, createContext, useEffect, useState } from 'react';

import { useQuery } from 'react-query';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { queryClient } from 'src/service/queryClient';
import { useTranslation } from 'react-i18next';
import ms from 'ms';
import ExcelJS from 'exceljs';

import apiWorkspace from '../service/apiWorkspace';
import { useQueryWorkspaceData } from '../pages/Workspace/hooks/useQueryWorkspaceData';

type Frequency =
  | 'daily'
  | 'weekly'
  | 'fortnightly'
  | 'monthly'
  | 'bimonthly'
  | 'quarterly'
  | 'half-year'
  | 'annual'
  | 'yearly';

type Y = {
  id: string;
  name: string;
  label: string;
  status: string;
  info?: {
    frequency: Frequency;
  };
};

type Project = {
  id: string;
  name: string;
  parent_id: string | null;
  created: string;
  ys: Y[];
};

type Serie = {
  projectId: string;
  projectName: string;
  yLabel: string;
  y: string;
  modelUpdate: {
    label: string;
    value: string;
    y: string | null;
    isDisabled: boolean;
  };
  modelId: {
    model_id: string | 'ai-selection' | 'user-selection';
    model_label: 'ai-selection' | 'user-selection' | 'others' | null;
    error: boolean;
  };
  isInflated: boolean;
  type?: string | null;

  yName: string;
  selected: boolean;
  error: string | null;
  isDisabled: boolean;
};

type YHierarchiesProps = {
  name: string; // Nome do filtro
  value: string | null; // Opção do filtro
};

type YsHierarchiesProps = {
  [yLabel: string]: YHierarchiesProps[];
};

type Categorization = {
  ys: YsHierarchiesProps;
  hierarchies: string[];
};

type ParamsProps = {
  id: string;
};

type Filter = {
  name: string;
  options: string[];
};

type Filters = {
  [levelNumber: string]: Filter | null;
};

type YFilters = {
  [yLabel: string]: {
    [levelNumber: string]: string;
  };
};

type Ys = {
  project_id: string;
  y: string;
  y_label: string;
  model_id: string;
  model_label: 'ai-selection' | 'user-selection' | null;
  is_inflated: boolean;
  type?: null | string;
};

type Enable = {
  enable: boolean;
};

type StagingArea = {
  id: string;
  created_at: string;
  updated_at: string;
  data: {
    ys: Ys[];
    filters: Filters;
    y_filters: YFilters;
    market_share: Enable | null;
    aggregation: Enable | null;
    approval_flow: Enable | null;
  };
};

type StagingAreaHierarchies = {
  aggregation: Enable | null;
  hierarchies: string[];
  market_share: Enable | null;
  ys: {
    hierarchy: YHierarchiesProps[];
    type: string | null;
    y_label: string;
  }[];
};

type ProjectProps = {
  id: string;
  name: string;
  parent_id: string | null;
  created: string;
  ys: {
    id: string;
    name: string;
    label: string;
    status: string;
    frequency: Frequency;
  }[];
};

type UpdateParentId = {
  [key: string]: string;
};

type SeriesLabel = {
  [key: string]: string;
};

export type WorkspaceConfigContextType = {
  workspaceId?: string;
  isEdition: boolean;
  name: string;
  description: string;
  iconUrl: string;
  enablePlanningFlow: boolean;
  frequency?: Frequency;
  projects: Project[];
  updateParentId?: UpdateParentId;
  series: Serie[];
  categorization?: Categorization;
  file?: File;
  enableAggregation: boolean;
  enableMarketShare: boolean;
  stagingAreaData?: StagingArea;
  isLoadingWorkspace?: boolean;
  isLoadingStagingArea?: boolean;
  isErrorStagingArea?: boolean;
  isLoadingStagingAreaHierarchies?: boolean;
  isErrorStagingAreaHierarchies?: boolean;
  hasSeriesChanges: boolean;
  updatingCategorization?: boolean;
  setEnablePlanningFlow: (enable: boolean) => void;
  setFrequency: (freq?: Frequency) => void;
  setFile: (file?: File) => void;
  setEnableAggregation: (enable: boolean) => void;
  setEnableMarketShare: (enable: boolean) => void;
  saveBasicInformations: (
    workspaceName: string,
    workspaceDescription: string,
    workspaceIconUrl: string,
  ) => void;
  saveSeriesInformation: (
    projects: Project[],
    series: Serie[],
    variablesLabel: SeriesLabel,
  ) => void;
  saveCategorization: (categorization?: Categorization) => void;
};

export const WorkspaceConfigContext = createContext(
  {} as WorkspaceConfigContextType,
);

export const WorkspaceConfigProvider: React.FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [iconUrl, setIconUrl] = useState('');

  const [enablePlanningFlow, setEnablePlanningFlow] = useState(false);

  const [frequency, setFrequency] = useState<Frequency>();

  const [projects, setProjects] = useState<Project[]>([]);
  const [updateParentId, setUpdateParentId] = useState<UpdateParentId>();
  const [series, setSeries] = useState<Serie[]>([]);
  const [seriesLabel, setSeriesLabel] = useState<SeriesLabel>({});

  const [categorization, setCategorization] = useState<Categorization>();
  const [file, setFile] = useState<File>();

  const [updatingCategorization, setUpdatingCategorization] = useState(false);

  const [enableAggregation, setEnableAggregation] = useState(false);
  const [enableMarketShare, setEnableMarketShare] = useState(false);

  const [hasSeriesChanges, setHasSeriesChanges] = useState(false);

  const location = useLocation();

  const { id: workspaceId } = useParams<ParamsProps>();

  const { t: translate } = useTranslation();

  const {
    auth: {
      user: { email },
    },
    workspace,
  } = useSelector((state: RootState) => state);

  const isEdition =
    location.pathname.includes('/control-panel/edition') || !!workspace?.id;

  const { isLoading, isFetching } = useQueryWorkspaceData(
    workspaceId ?? '',
    !!isEdition && !workspace?.id && !!workspaceId && !!email,
    email ?? '',
  );

  const {
    data: stagingAreaData,
    isLoading: isLoadingStagingArea,
    isFetching: isFetchingStagingArea,
    isError: isErrorStagingArea,
  } = useQuery(
    ['workspace staging area', workspaceId],
    async () => {
      const { data } = await apiWorkspace.get<StagingArea>(
        `/workspaces/${workspaceId}/staging-area`,
      );

      return data;
    },
    {
      staleTime: ms('3 min'),
      enabled: !!workspaceId,
    },
  );

  const {
    data: stagingAreaHierarchiesData,
    isLoading: isLoadingStagingAreaHierarchies,
    isFetching: isFetchingStagingAreaHierarchies,
    isError: isErrorStagingAreaHierarchies,
  } = useQuery(
    ['workspace staging area hierarchies', workspaceId],
    async () => {
      const { data } = await apiWorkspace.get<StagingAreaHierarchies>(
        `/workspaces/${workspaceId}/staging-area/hierarchies`,
      );

      return data;
    },
    {
      staleTime: ms('3 min'),
      enabled: !!workspaceId,
    },
  );

  const saveBasicInformations = (
    workspaceName: string,
    workspaceDescription: string,
    workspaceIconUrl: string,
  ) => {
    setName(workspaceName);
    setDescription(workspaceDescription);
    setIconUrl(workspaceIconUrl);
  };

  const serieWasChanged = (newSerie: Serie[]) => {
    const newSerieSelected: Serie[] = [];

    newSerie.forEach((y) => {
      if (y.selected) {
        newSerieSelected.push({
          ...y,
          modelUpdate: {
            label: '',
            value: y.modelUpdate.value,
            y: null,
            isDisabled: false,
          },
          modelId: {
            model_id: y.modelId.model_id,
            model_label: y.modelId.model_label,
            error: false,
          },
        });
      }
    });

    const seriesSelected: Serie[] = [];

    series.forEach((y) => {
      if (y.selected) {
        seriesSelected.push({
          ...y,
          modelUpdate: {
            label: '',
            value: y.modelUpdate.value,
            y: null,
            isDisabled: false,
          },
          modelId: {
            model_id: y.modelId.model_id,
            model_label: y.modelId.model_label,
            error: false,
          },
        });
      }
    });

    return JSON.stringify(newSerieSelected) !== JSON.stringify(seriesSelected);
  };

  const removeExcelRows = async (
    updatedFile: File,
    rows: string[],
  ): Promise<Blob | undefined> => {
    if (file) {
      const workbook = new ExcelJS.Workbook();
      const reader = new FileReader();

      reader.readAsArrayBuffer(updatedFile);

      return new Promise((resolve, reject) => {
        reader.onload = async () => {
          const buffer = reader.result;

          await workbook.xlsx.load(buffer as Buffer);

          const worksheet = workbook.worksheets[0];

          for (let i = worksheet.rowCount; i >= 1; i--) {
            const row = worksheet.getRow(i);

            if (rows.includes(row.getCell(1).value as string)) {
              worksheet.spliceRows(i, 1);
            }
          }

          const modifiedBuffer = await workbook.xlsx.writeBuffer();

          const modifiedFile = new Blob([modifiedBuffer], { type: file.type });

          resolve(modifiedFile);
        };

        reader.onerror = (error) => {
          reject(error);
        };
      });
    }

    return undefined;
  };

  const addRowsToExcel = async (
    newRowData: { label: string; project: string }[],
  ): Promise<Blob | undefined> => {
    if (file) {
      const workbook = new ExcelJS.Workbook();
      const reader = new FileReader();

      reader.readAsArrayBuffer(file);

      return new Promise((resolve, reject) => {
        reader.onload = async () => {
          const buffer = reader.result;

          await workbook.xlsx.load(buffer as Buffer);

          const worksheet = workbook.worksheets[0];

          newRowData.forEach((data) => {
            worksheet.addRow([
              data.label,
              data.project,
              translate('createWorkspaceTemplateOthers').toLowerCase(),
              translate('createFiltersNotDefined'),
            ]);
          });

          const modifiedBuffer = await workbook.xlsx.writeBuffer();

          const modifiedFile = new Blob([modifiedBuffer], { type: file.type });

          resolve(modifiedFile);
        };

        reader.onerror = (error) => {
          reject(error);
        };
      });
    }

    return undefined;
  };

  const checkCategorization = async (
    newSerie: Serie[],
  ): Promise<Serie[] | undefined> => {
    let discardCategorization = false;

    const updatedYsCategorization = { ...categorization?.ys };
    const categorizationYsKey = Object.keys(categorization?.ys ?? {});
    const selectedLabels: string[] = [];

    const isManualCategorization = !!categorization?.hierarchies.length;

    const ysAdded: { label: string; project: string }[] = [];

    const updatedSeries = [...newSerie];

    for (let i = 0; i < newSerie.length; i++) {
      const y = newSerie[i];

      const key = `${y.projectId}-${y.y}`;

      if (y.selected) {
        if (seriesLabel[key] && seriesLabel[key] !== y.yLabel) {
          discardCategorization = true;
          break;
        }

        if (!seriesLabel[key]) {
          ysAdded.push({ label: y.yLabel, project: y.projectName });
        } else if (file) {
          const yExists = series.find(
            (serie) =>
              serie.projectId === y.projectId &&
              serie.y === y.y &&
              serie.selected,
          );

          if (!yExists) {
            ysAdded.push({ label: y.yLabel, project: y.projectName });
          }
        }

        if (isManualCategorization && !categorizationYsKey.includes(y.yLabel)) {
          const newYCategorization = [
            {
              name: categorization.hierarchies[0],
              value: translate('createFiltersNotDefined'),
            },
          ];

          if (enableMarketShare || isEdition) {
            newYCategorization[0].name = categorization.hierarchies[1];

            newYCategorization.unshift({
              name: translate('createWorkspaceTemplateVariableType'),
              value: translate('createWorkspaceTemplateOthers').toLowerCase(),
            });

            const serieIndex = series.findIndex(
              (serie) => serie.projectId === y.projectId && serie.y === y.y,
            );

            if (serieIndex !== -1) {
              updatedSeries[serieIndex].type = translate(
                'createWorkspaceTemplateOthers',
              ).toLowerCase();
            }
          }

          updatedYsCategorization[y.yLabel] = newYCategorization;
        }

        selectedLabels.push(y.yLabel);
      }
    }

    if (discardCategorization) {
      setCategorization(undefined);
      setFile(undefined);
    } else if (isManualCategorization) {
      categorizationYsKey.forEach((key) => {
        if (!selectedLabels.includes(key)) {
          delete updatedYsCategorization[key];
        }
      });

      setSeries(updatedSeries);
      setCategorization({ ...categorization, ys: updatedYsCategorization });

      return updatedSeries;
    } else {
      const ysRemoved: string[] = [];

      series.forEach((serie) => {
        if (serie.selected && !selectedLabels.includes(serie.yLabel)) {
          ysRemoved.push(serie.yLabel);
        }
      });

      let updatedFile = file;

      if (ysAdded.length && updatedFile) {
        try {
          const blob = await addRowsToExcel(ysAdded);

          if (blob) {
            updatedFile = new File([blob], updatedFile.name, {
              type: updatedFile.type,
              lastModified: Date.now(),
            });
          }

          // eslint-disable-next-line no-empty
        } catch {}
      }

      if (ysRemoved.length && updatedFile) {
        try {
          const blob = await removeExcelRows(updatedFile, ysRemoved);

          if (blob) {
            updatedFile = new File([blob], updatedFile.name, {
              type: updatedFile.type,
              lastModified: Date.now(),
            });
          }

          // eslint-disable-next-line no-empty
        } catch {}
      }

      setFile(updatedFile);
    }

    return undefined;
  };

  const saveSeriesInformation = async (
    projs: Project[],
    variables: Serie[],
    variablesLabel: SeriesLabel,
  ) => {
    let hasChanges = true;

    if (!hasSeriesChanges) {
      hasChanges = serieWasChanged(variables);

      setHasSeriesChanges(hasChanges);
    }

    let updatedVariables: Serie[] | undefined = [...variables];

    if (hasChanges && (file || categorization?.hierarchies.length)) {
      setUpdatingCategorization(true);

      updatedVariables = await checkCategorization(variables);

      setUpdatingCategorization(false);
    } else {
      setSeries(variables);
    }

    setProjects(projs);

    if (!updatedVariables?.length) {
      setSeries(variables);
    }

    setSeriesLabel(variablesLabel);
  };

  const saveCategorization = (filter?: Categorization) => {
    if (filter) {
      const keys = Object.keys(filter.ys);

      keys.forEach((key) => {
        if (!filter.ys[key][0].value) {
          filter.ys[key][0].value = translate('createFiltersNotDefined');
        }
      });
    }

    setCategorization(filter);
  };

  useEffect(() => {
    if (
      !isLoading &&
      !isFetching &&
      workspace?.name &&
      workspace?.description &&
      workspace?.icon
    ) {
      setName(workspace.name);
      setDescription(workspace.description);
      setIconUrl(workspace.icon);
    }
  }, [isLoading, isFetching, workspace]);

  useEffect(() => {
    if (stagingAreaData && !isLoadingStagingArea && !isFetchingStagingArea) {
      setEnablePlanningFlow(!!stagingAreaData.data.approval_flow?.enable);

      const loadProjectData = async (
        projectId: string,
      ): Promise<ProjectProps> => {
        let projectResponse: ProjectProps;

        const response = queryClient.getQueryData<ProjectProps>([
          'workspace project',
          workspaceId,
          projectId,
        ]);

        try {
          if (response) {
            projectResponse = response;
          } else {
            projectResponse = await queryClient.fetchQuery<ProjectProps>(
              ['workspace project', workspaceId, projectId],
              async () => {
                const { data } = await apiWorkspace.get(
                  `/workspaces/${workspaceId}/users/projects/${projectId}`,
                );

                return data;
              },
              {
                staleTime: ms('3 min'),
              },
            );
          }
        } catch {
          return {
            id: '',
            name: '',
            parent_id: '',
            ys: [],
            created: '',
          };
        }

        return projectResponse;
      };

      const getSeriesInformation = async () => {
        const ys = stagingAreaData.data.ys;

        const allSeries: Serie[] = [];

        const updateParentIds: UpdateParentId = {};
        const allSelectedProjects: ProjectProps[] = [];
        const allProjectIds: string[] = [];
        const allSeriesLabel: SeriesLabel = {};

        for (let i = 0; i < ys.length; i++) {
          let projectId = ys[i].project_id;
          let updatedProject = {} as ProjectProps;
          let yId = ys[i].y;

          if (!allProjectIds.includes(projectId)) {
            let projectData = await loadProjectData(ys[i].project_id);

            if (projectData.parent_id) {
              updatedProject = projectData;
              projectData = await loadProjectData(projectData.parent_id);
            }

            projectId = projectData.id;

            if (updatedProject?.id) {
              yId =
                projectData.ys.find(
                  (y) =>
                    y.id === yId ||
                    (y.id.endsWith(`_${yId}`) && y.id.startsWith('forecast_')),
                )?.id ?? '';

              updateParentIds[updatedProject.id] = projectId;
            } else {
              updateParentIds[projectId] = projectId;
            }

            if (!allProjectIds.includes(projectId)) {
              projectData.ys.forEach((y) => {
                const isSelected = y.id === yId;
                const isUpdate = updatedProject?.id;

                const isDisabled = y.status !== 'success';

                if (!frequency && !isDisabled) {
                  setFrequency(y.frequency);
                }

                allSeries.push({
                  projectId,
                  projectName: projectData.name,
                  yLabel: y.label,
                  y: isSelected ? yId : y.id,
                  modelUpdate: {
                    label:
                      isSelected && isUpdate
                        ? ''
                        : `Original\n${projectData.created}`,
                    value:
                      isSelected && isUpdate
                        ? updatedProject.id
                        : projectData.id,
                    y: null,
                    isDisabled: false,
                  },
                  modelId: {
                    model_id: isDisabled ? '--' : 'ai-selection',
                    error: isDisabled,
                    model_label: isDisabled ? 'others' : 'ai-selection',
                  },
                  isInflated: false,
                  yName: y.name,
                  selected: false,
                  error: '',
                  isDisabled,
                  type: 'others',
                });

                const serieKey = `${projectId}-${isSelected ? yId : y.id}`;
                allSeriesLabel[serieKey] = y.label;
              });

              allSelectedProjects.push(projectData);
              allProjectIds.push(projectId);
            }

            const yIndex = allSeries.findIndex(
              (serie) => serie.y === yId && serie.projectId === projectId,
            );

            if (yIndex !== -1) {
              allSeries[yIndex].modelUpdate = {
                label: '',
                value: ys[i].project_id,
                isDisabled: false,
                y: ys[i].y,
              };
              allSeries[yIndex].yLabel = ys[i].y_label;
              allSeries[yIndex].selected = true;
              allSeries[yIndex].isInflated = ys[i].is_inflated;
              allSeries[yIndex].modelId = {
                model_label: ys[i].model_label ?? 'others',
                model_id: ys[i].model_label ?? ys[i].model_id,
                error: false,
              };
              allSeries[yIndex].type = ys[i].type;

              const serieKey = `${allSeries[yIndex].projectId}-${allSeries[yIndex].y}`;
              allSeriesLabel[serieKey] = ys[i].y_label;
            }
          } else {
            const yIndex = allSeries.findIndex(
              (serie) => serie.y === yId && serie.projectId === projectId,
            );

            if (yIndex !== -1) {
              allSeries[yIndex].yLabel = ys[i].y_label;
              allSeries[yIndex].selected = true;
              allSeries[yIndex].isInflated = ys[i].is_inflated;
              allSeries[yIndex].modelId = {
                model_label: ys[i].model_label ?? 'others',
                model_id: ys[i].model_label ?? ys[i].model_id,
                error: false,
              };
              allSeries[yIndex].type = ys[i].type;
              allSeries[yIndex].modelUpdate = {
                label: '',
                value: ys[i].project_id,
                isDisabled: false,
                y: ys[i].y,
              };

              const serieKey = `${allSeries[yIndex].projectId}-${allSeries[yIndex].y}`;
              allSeriesLabel[serieKey] = ys[i].y_label;
            }
          }
        }

        setProjects(allSelectedProjects);
        setUpdateParentId(updateParentIds);
        setSeries(allSeries);
        setSeriesLabel(allSeriesLabel);
      };

      getSeriesInformation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    stagingAreaData,
    isLoadingStagingArea,
    isFetchingStagingArea,
    workspaceId,
  ]);

  useEffect(() => {
    if (
      stagingAreaHierarchiesData &&
      !isLoadingStagingAreaHierarchies &&
      !isFetchingStagingAreaHierarchies
    ) {
      setEnableAggregation(
        stagingAreaHierarchiesData.aggregation?.enable ?? false,
      );
      setEnableMarketShare(
        stagingAreaHierarchiesData.market_share?.enable ?? false,
      );

      const newCategorization: Categorization = {
        ys: {},
        hierarchies: [...stagingAreaHierarchiesData.hierarchies],
      };

      if (stagingAreaHierarchiesData.hierarchies.length) {
        newCategorization.hierarchies.unshift(
          translate('createWorkspaceTemplateVariableType'),
        );
      }

      const ys = stagingAreaHierarchiesData.ys;

      ys.forEach(({ y_label, hierarchy, type }) => {
        const yHierarchy = [...hierarchy];

        if (yHierarchy.length) {
          yHierarchy.unshift({
            name: translate('createWorkspaceTemplateVariableType'),
            value: type,
          });
        }

        newCategorization.ys[y_label] = yHierarchy;
      });

      setCategorization(newCategorization);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    stagingAreaHierarchiesData,
    isLoadingStagingAreaHierarchies,
    isFetchingStagingAreaHierarchies,
  ]);

  useEffect(() => {
    if (
      stagingAreaData?.data &&
      (enableAggregation !== stagingAreaData?.data.aggregation?.enable ||
        enableMarketShare !== stagingAreaData.data.market_share?.enable ||
        enablePlanningFlow !== stagingAreaData.data.approval_flow?.enable)
    ) {
      setHasSeriesChanges(true);
    }
  }, [
    stagingAreaData,
    enableAggregation,
    enableMarketShare,
    enablePlanningFlow,
  ]);

  return (
    <WorkspaceConfigContext.Provider
      value={{
        workspaceId: workspaceId ?? workspace.id ?? '',
        isEdition,
        name,
        description,
        iconUrl,
        enablePlanningFlow,
        frequency,
        projects,
        updateParentId,
        series,
        categorization,
        file,
        enableAggregation,
        enableMarketShare,
        stagingAreaData,
        isLoadingWorkspace: isLoading || isFetching,
        isLoadingStagingArea: isLoadingStagingArea || isFetchingStagingArea,
        isErrorStagingArea,
        isLoadingStagingAreaHierarchies:
          isLoadingStagingAreaHierarchies || isFetchingStagingAreaHierarchies,
        isErrorStagingAreaHierarchies,
        updatingCategorization,
        setEnablePlanningFlow,
        setFrequency,
        setFile,
        setEnableAggregation,
        setEnableMarketShare,
        saveBasicInformations,
        saveSeriesInformation,
        saveCategorization,
        hasSeriesChanges,
      }}
    >
      {children}
    </WorkspaceConfigContext.Provider>
  );
};
