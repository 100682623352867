import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  padding: 1rem;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.gray2};

  margin-bottom: 2rem;

  &:last-child {
    margin-bottom: 0rem;
  }
`;

export const CardTitle = styled.span`
  color: ${({ theme }) => theme.colors.gray6};
  font-size: 0.875rem;
  font-weight: 500;

  margin: 0.625rem 0;
`;

type ChangesProps = {
  maxHeight: number | null;
};

export const Changes = styled.div<ChangesProps>`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  p {
    color: ${({ theme }) => theme.colors.gray5};
    font-size: 0.75rem;
    font-weight: 400;
    overflow-wrap: break-word;
    white-space: pre-line;
  }

  transition: max-height 0.5s ease-in-out;

  ${({ maxHeight }) =>
    !!maxHeight &&
    css`
      max-height: ${maxHeight}px;
      overflow: hidden;
    `}
`;

export const DateText = styled.span`
  color: ${({ theme }) => theme.colors.gray4};
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 8%;
`;

type ShowMoreButtonProps = {
  showMore: boolean;
};

export const ShowMoreButton = styled.button<ShowMoreButtonProps>`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  margin-top: 0.625rem;

  color: ${({ theme }) => theme.colors.primary};
  font-size: 0.75rem;
  font-weight: 600;

  transition: color 0.2s;

  svg {
    transition: transform 0.5s ease-in-out;
    transform: ${({ showMore }) => (showMore ? 'scale(-1)' : 'scale(1)')};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.primaryDark};
  }
`;
