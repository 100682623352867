export const WorkspaceControlPanelPTBRTranslations = {
  workspaceControlPanelHeadTitle: 'Workspace - Painel de Controle',
  workspaceControlPanelShareTitle: 'Compartilhamento',
  workspaceControlPanelShareDescription:
    'Veja os usuários que possuem acesso a esse Workspace e suas permissões.',
  workspaceControlPanelEdit: 'Editar Workspace',
  workspaceControlPanelCreator: 'Criador',
  workspaceControlPanelGuests: 'Convidados',
  workspaceControlPanelInviteUser: 'Convide um usuário',
  workspaceControlPanelInviteUserDescription:
    'Compartilhe esse Workspace com outro usuário para que ele também possa ver seus resultados!',
  workspaceControlPanelCanView: 'Pode visualizar',
  workspaceControlPanelCanEdit: 'Pode editar',
  workspaceControlPanelCanManager: 'Pode gerenciar',
  workspaceControlPanelRemove: 'Remover',
  workspaceControlPanelUserUpdateErrorTitle: 'Falha ao atualizar',
  workspaceControlPanelUserUpdateErrorDescription:
    'Ocorreu um erro ao atualizar a permissão do usuário. Por favor, tente novamente mais tarde.',
  workspaceControlPanelUserRemoveErrorTitle: 'Falha ao remover',
  workspaceControlPanelUserRemoveErrorDescription:
    'Ocorreu um erro ao remover o usuário. Por favor, tente novamente mais tarde.',
  workspaceControlPanelNoPermissionDelete:
    'Você é o último gestor, remova todos os visualizadores antes de deletar o workspace da sua conta.',
  workspaceControlPanelNoPermissionUpdateToReader:
    'Não é possível alterar para visualizador, cada workspace precisa de pelo menos um gestor para gerenciar mudanças.',
  workspaceControlPanelNoPermissionUpdateToEditor:
    'Não é possível alterar para editor, cada workspace precisa de pelo menos um gestor para gerenciar mudanças.',
  workspaceControlPanelUserRemoveTitle: 'Você tem certeza?',
  workspaceControlPanelUserRemoveDescription:
    'Isso irá remover o <b>XXX</b> desse workspace.',
  workspaceControlPanelOwnerWithoutAccess: '(Sem acesso)',
  workspaceControlPanelInvite: 'Convidar',
  workspaceControlPanelProjectList: 'Lista de Projetos',
  workspaceControlPanelReturnHomePage: 'Voltar para a tela inicial',
  workspaceControlPanelEditionErrorDescription:
    '<b>XXX</b> está editando este workspace, por favor, volte mais tarde.',
  workspaceControlPanelCreatePreview: 'Gerar prévia',
  workspaceControlPanelPreview: 'Visualizar prévia',
  workspaceControlPanelPublish: 'Publicar',
  workspaceControlPanelPublishErrorTitle: 'Algo deu errado',
  workspaceControlPanelPublishErrorDescription: 'Ocorreu um erro ao XXX.',
  workspaceControlPanelPublishErrorPublishType: 'publicar seu workspace',
  workspaceControlPanelPublishErrorPreviewType: 'criar uma prévia',
  workspaceControlPanelPublishLocked:
    'O Workspace esta travado para edição para',
  workspaceControlPanelPublishingNewVersion:
    'Você não pode executar esta ação, o Workspace está publicando uma nova versão.',
  workspaceControlPanelPublishTitle: 'Processando...',
  workspaceControlPanelPreviewDescription:
    'Estamos gerando a prévia do seu workspace. Esse processo pode levar alguns minutos, por favor volte mais tarde.',
  workspaceControlPanelPublishDescription:
    'Estamos publicando seu workspace. Esse processo pode levar alguns minutos, por favor volte mais tarde.',
  workspaceControlPanelReleaseErrorTitle: 'Algo deu errado',
  workspaceControlPanelReleaseErrorDescription:
    'Ocorreu um erro ao criar a <b>XXX</b> requerida por <b>YYY</b>',
  workspaceControlPanelReleaseErrorPreview: 'prévia',
  workspaceControlPanelReleaseErrorCurrent: 'publicação',
  workspaceControlPanelReleaseYsErrorDescription:
    'Ocorreu um erro nas seguintes variáveis ao criar a <b>XXX</b> requerida por <b>YYY</b>:',
  workspaceControlPanelAccess: 'Acessar Workspace',
};
