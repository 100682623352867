import React from 'react';

import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { ClaaSModelExplorer } from 'src/models/pages/Classification/ModelExplorer';
import { ClaaSModelInProduction } from 'src/models/pages/Classification/ModelInProduction';
import { CorrelationMatrix } from 'src/models/pages/DataView/CorrelationMatrix';
import { Overview } from 'src/models/pages/DataView/Overview';
import { VariableImportance } from 'src/models/pages/DataView/VariableImportance';
import { ModellingStart } from 'src/models/pages/Modelling';
import { ClassificationModelling } from 'src/models/pages/Modelling/Classification';
import { TimeSeriesModelling } from 'src/models/pages/Modelling/TimeSeries';
import { MyProjects } from 'src/models/pages/MyProjects';
import { ArimaCrossValidation } from 'src/models/TimeSeries/ModelExplorer/CrossValidation/ARIMA';
import { FCCrossValidation } from 'src/models/TimeSeries/ModelExplorer/CrossValidation/ForecastCombination';
import { RFCrossValidation } from 'src/models/TimeSeries/ModelExplorer/CrossValidation/RandomForest';
import { RRCrossValidation } from 'src/models/TimeSeries/ModelExplorer/CrossValidation/RegularizedRegression';
import { ARIMAModelDispersion } from 'src/models/TimeSeries/ModelExplorer/ModelDispersion/ARIMA';
import { FCModelDispersion } from 'src/models/TimeSeries/ModelExplorer/ModelDispersion/ForecastCombination';
import { ModelOverview } from 'src/models/TimeSeries/ModelExplorer/ModelOverview';
import { ArimaModelSpecifics } from 'src/models/TimeSeries/ModelExplorer/ModelSpecifics/ARIMA';
import { ElementaryModelSpecifics } from 'src/models/TimeSeries/ModelExplorer/ModelSpecifics/Elementary';
import { FCModelSpecifics } from 'src/models/TimeSeries/ModelExplorer/ModelSpecifics/ForecastCombination';
import { RFModelSpecifics } from 'src/models/TimeSeries/ModelExplorer/ModelSpecifics/RandomForest';
import { RegularRegrModelSpecifics } from 'src/models/TimeSeries/ModelExplorer/ModelSpecifics/RegularizedRegression';
import { ModelInProduction } from 'src/models/TimeSeries/ModelInProduction';
import { ProjectOverview } from 'src/models/TimeSeries/ProjectOverview';
import NotFound from 'src/pages/404';
import { RootState } from 'src/redux/store';
import { AISelection } from 'src/models/TimeSeries/AISelection';
import { DependentVariablesConfig } from 'src/models/TimeSeries/DependentVariablesConfig';
import { OverallPerformance } from 'src/models/TimeSeries/OverAllPerformance';
import { VariablePerformance } from 'src/models/TimeSeries/VariablePerformance';
import { CreateWorkspace } from 'src/models/pages/Workspace/Create';
import { WorkspaceOverview } from 'src/models/pages/Workspace/Overview';
import { ControlPanel } from 'src/models/pages/Workspace/ControlPanel';
import { WorkspaceProjections } from 'src/models/pages/Workspace/Projections';
import { WorkspaceOverallPerformance } from 'src/models/pages/Workspace/OverallPerformance';
import { WorkspaceVariablePerformance } from 'src/models/pages/Workspace/VariablePerformance';

import { PrivateRoutes } from '../PrivateRoute';

const ModelsRoutes = (): React.ReactElement => {
  const { user } = useSelector((state: RootState) => state.auth);
  return (
    <Routes>
      {/* ---------------------------------------------------------------------- */}

      <Route
        caseSensitive
        path="/projects"
        element={
          <PrivateRoutes
            path="/models/projects"
            component={MyProjects}
            roles={['isClaaS', 'isFaaS']}
          />
        }
      />
      {/* ---------------------------------------------------------------------- */}
      {user.isFaaS && user.isClaaS && (
        <Route
          caseSensitive
          path="/projects/new"
          element={
            <PrivateRoutes
              path="/models/projects/new"
              component={ModellingStart}
              roles={['isClaaS', 'isFaaS']}
              permissions={['create:projects', 'create:project-claas']}
            />
          }
        />
      )}

      {/* ---------------------------------------------------------------------- */}
      <Route
        caseSensitive
        path="/projects/new/time-series"
        element={
          <PrivateRoutes
            path="/models/projects/new/time-series"
            component={TimeSeriesModelling}
            roles={['isFaaS']}
            permissions={['create:projects']}
          />
        }
      />
      {/* ---------------------------------------------------------------------- */}
      <Route
        caseSensitive
        path="/projects/new/classification"
        element={
          <PrivateRoutes
            path="/models/projects/new/classification"
            component={ClassificationModelling}
            roles={['isClaaS']}
            permissions={['create:project-claas']}
          />
        }
      />
      {/* ---------------------------------------------------------------------- */}

      <Route
        caseSensitive
        path="/workspaces/new"
        element={
          <PrivateRoutes
            path="/models/workspaces/new"
            component={CreateWorkspace}
            roles={['isFaaS']}
            permissions={['create:workspaces']}
          />
        }
      />

      <Route
        caseSensitive
        path="/workspaces/:id/control-panel"
        element={
          <PrivateRoutes
            path="/models/workspaces/:id/control-panel"
            component={ControlPanel}
            roles={['isFaaS']}
            permissions={['read:workspaces']}
          />
        }
      />

      <Route
        caseSensitive
        path="/workspaces/:id/control-panel/edition"
        element={
          <PrivateRoutes
            path="/models/workspaces/:id/control-panel/edition"
            component={CreateWorkspace}
            roles={['isFaaS']}
            permissions={['read:workspaces']}
          />
        }
      />

      <Route
        caseSensitive
        path="/data-view/overview"
        element={
          <PrivateRoutes
            path="/models/data-view/overview"
            component={Overview}
            roles={['isFaaS']}
          />
        }
      />
      <Route
        caseSensitive
        path="/data-view/correlation-matrix"
        element={
          <PrivateRoutes
            path="/models/data-view/correlation-matrix"
            component={CorrelationMatrix}
            roles={['isFaaS']}
          />
        }
      />
      <Route
        caseSensitive
        path="/data-view/variable-importance"
        element={
          <PrivateRoutes
            path="/models/data-view/variable-importance"
            component={VariableImportance}
            roles={['isFaaS']}
          />
        }
      />
      {/* ---------------------------------------------------------------------- */}
      <Route
        caseSensitive
        path="/time-series/:id/user-selection"
        element={
          <PrivateRoutes
            path="/models/time-series/:id/user-selection"
            component={ModelInProduction}
            roles={['isFaaS']}
          />
        }
      />
      <Route
        caseSensitive
        path="/time-series/:id/AI-selection"
        element={
          <PrivateRoutes
            path="/models/time-series/:id/AI-selection"
            component={AISelection}
            roles={['isFaaS']}
          />
        }
      />

      {/* <PrivateRoutes path="/models/time-series/model-in-production" component={BestModel} /> */}
      {/* <PrivateRoutes path="/models/time-series/model-in-production" component={ManualAjustments} /> */}
      {/* ---------------------------------------------------------------------- */}
      <Route
        caseSensitive
        path="/time-series/:id/model-explorer/overview"
        element={
          <PrivateRoutes
            path="/models/time-series/:id/model-explorer/overview"
            component={ModelOverview}
            roles={['isFaaS']}
          />
        }
      />
      <Route
        caseSensitive
        path="/time-series/:id/project-overview"
        element={
          <PrivateRoutes
            path="/models/time-series/:id/project-overview"
            component={ProjectOverview}
            roles={['isFaaS']}
          />
        }
      />
      <Route
        caseSensitive
        path="/time-series/:id/model-explorer/arima/specifics"
        element={
          <PrivateRoutes
            path="/models/time-series/:id/model-explorer/arima/specifics"
            component={ArimaModelSpecifics}
            roles={['isFaaS']}
          />
        }
      />
      <Route
        caseSensitive
        path="/time-series/:id/model-explorer/arima/dispersion"
        element={
          <PrivateRoutes
            path="/models/time-series/:id/model-explorer/arima/dispersion"
            component={ARIMAModelDispersion}
            roles={['isFaaS']}
          />
        }
      />
      <Route
        caseSensitive
        path="/time-series/:id/model-explorer/arima/cross-validation"
        element={
          <PrivateRoutes
            path="/models/time-series/:id/model-explorer/arima/cross-validation"
            component={ArimaCrossValidation}
            roles={['isFaaS']}
          />
        }
      />
      <Route
        caseSensitive
        path="/time-series/:id/model-explorer/regularized-regression/specifics"
        element={
          <PrivateRoutes
            path="/models/time-series/:id/model-explorer/regularized-regression/specifics"
            component={RegularRegrModelSpecifics}
            roles={['isFaaS']}
          />
        }
      />
      <Route
        caseSensitive
        path="/time-series/:id/model-explorer/regularized-regression/cross-validation"
        element={
          <PrivateRoutes
            path="/models/time-series/:id/model-explorer/regularized-regression/cross-validation"
            component={RRCrossValidation}
            roles={['isFaaS']}
          />
        }
      />
      <Route
        caseSensitive
        path="/time-series/:id/model-explorer/random-forest/specifics"
        element={
          <PrivateRoutes
            path="/models/time-series/:id/model-explorer/random-forest/specifics"
            component={RFModelSpecifics}
            roles={['isFaaS']}
          />
        }
      />
      <Route
        caseSensitive
        path="/time-series/:id/model-explorer/elementary/specifics"
        element={
          <PrivateRoutes
            path="/models/time-series/:id/model-explorer/elementary/specifics"
            component={ElementaryModelSpecifics}
            roles={['isFaaS']}
          />
        }
      />
      <Route
        caseSensitive
        path="/time-series/:id/model-explorer/random-forest/cross-validation"
        element={
          <PrivateRoutes
            path="/models/time-series/:id/model-explorer/random-forest/cross-validation"
            component={RFCrossValidation}
            roles={['isFaaS']}
          />
        }
      />
      <Route
        caseSensitive
        path="/time-series/:id/model-explorer/forecast-combination/specifics"
        element={
          <PrivateRoutes
            path="/models/time-series/:id/model-explorer/forecast-combination/specifics"
            component={FCModelSpecifics}
            roles={['isFaaS']}
          />
        }
      />
      <Route
        caseSensitive
        path="/time-series/:id/model-explorer/forecast-combination/dispersion"
        element={
          <PrivateRoutes
            path="/models/time-series/:id/model-explorer/forecast-combination/dispersion"
            component={FCModelDispersion}
            roles={['isFaaS']}
          />
        }
      />
      <Route
        caseSensitive
        path="/time-series/:id/model-explorer/forecast-combination/cross-validation"
        element={
          <PrivateRoutes
            path="/models/time-series/:id/model-explorer/forecast-combination/cross-validation"
            component={FCCrossValidation}
            roles={['isFaaS']}
          />
        }
      />

      <Route
        caseSensitive
        path="/time-series/:id/config/dependent-variables"
        element={
          <PrivateRoutes
            path="/models/time-series/:id/config/dependent-variables"
            component={DependentVariablesConfig}
            roles={['isFaaS']}
          />
        }
      />

      <Route
        caseSensitive
        path="/time-series/:id/overall-performance"
        element={
          <PrivateRoutes
            path="/models/time-series/:id/overall-performance"
            component={OverallPerformance}
            roles={['isFaaS']}
          />
        }
      />

      <Route
        caseSensitive
        path="/time-series/:id/variable-performance"
        element={
          <PrivateRoutes
            path="/models/time-series/:id/variable-performance"
            component={VariablePerformance}
            roles={['isFaaS']}
          />
        }
      />

      <Route
        caseSensitive
        path="/classification/:id/model-explorer"
        element={
          <PrivateRoutes
            path="/models/classification/:id/model-explorer"
            component={ClaaSModelExplorer}
            roles={['isClaaS']}
          />
        }
      />

      <Route
        caseSensitive
        path="/classification/:id/model-in-production"
        element={
          <PrivateRoutes
            path="/models/classification/:id/model-in-production"
            component={ClaaSModelInProduction}
            roles={['isClaaS']}
          />
        }
      />

      <Route
        caseSensitive
        path="/workspaces/:id/overview"
        element={
          <PrivateRoutes
            path="/workspaces/:id/overview"
            component={WorkspaceOverview}
            roles={['isFaaS']}
            permissions={['read:workspaces']}
          />
        }
      />

      <Route
        caseSensitive
        path="/workspaces/:id/projections"
        element={
          <PrivateRoutes
            path="/workspaces/:id/projections"
            component={WorkspaceProjections}
            roles={['isFaaS']}
            permissions={['read:workspaces']}
          />
        }
      />

      <Route
        caseSensitive
        path="/workspaces/:id/overall-performance"
        element={
          <PrivateRoutes
            path="/workspaces/:id/overall-performance"
            component={WorkspaceOverallPerformance}
            roles={['isFaaS']}
          />
        }
      />

      <Route
        caseSensitive
        path="/workspaces/:id/variable-performance"
        element={
          <PrivateRoutes
            path="/workspaces/:id/variable-performance"
            component={WorkspaceVariablePerformance}
            roles={['isFaaS']}
          />
        }
      />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default ModelsRoutes;
