import styled, { css } from 'styled-components';

import { Status } from './types';

type ContainerProps = {
  hasPaddingRight: boolean;
  hasPaddingLeft: boolean;
};

export const Container = styled.div<ContainerProps>`
  width: fit-content;
  height: fit-content;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding: 1rem 1.25rem;
  margin-top: 0.825rem;
  margin-bottom: 0.1rem;

  border: 1px solid ${({ theme }) => theme.colors.gray1};
  border-radius: 8px;

  position: relative;

  ${({ hasPaddingRight }) =>
    !hasPaddingRight &&
    css`
      padding-right: 0;
    `}

  ${({ hasPaddingLeft }) =>
    !hasPaddingLeft &&
    css`
      padding-left: 0;
    `}

  .button-rounded {
    position: absolute;
    top: -0.813rem;
    right: -0.813rem;

    padding: 0.25rem;

    background: white;

    svg {
      width: 1rem;
      height: 1rem;
    }
  }
`;

export const StepNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;

  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);

  padding: 0.125rem 1rem;

  background: ${({ theme }) => theme.colors.gray1};
  border-radius: 999px;

  > p {
    max-width: 19rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    font-size: 0.625rem;
    font-weight: 500;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.gray5};
  }
`;

type StateContainerProps = {
  status: Status;
  selected: boolean;
  isGreenBackground?: boolean;
};

export const StateContainer = styled.div<StateContainerProps>`
  width: 2rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 0.5rem;

  > div {
    /* transition: all 1s; */

    background: ${({ theme, isGreenBackground, status }) =>
      isGreenBackground
        ? 'rgba(56, 178, 172, 0.16)'
        : status === 'done'
        ? 'rgba(76, 148, 255, 0.16)'
        : status === 'in progress'
        ? 'rgba(159, 122, 234, 0.16)'
        : theme.colors.gray1};
  }

  svg {
    /* transition: all 1s; */

    color: ${({ theme, isGreenBackground, status }) =>
      isGreenBackground
        ? theme.colors.teal4
        : status === 'done'
        ? theme.colors.primary
        : status === 'in progress'
        ? theme.colors.purple4
        : theme.colors.gray4};
  }

  > p {
    /* transition: all 1s; */

    font-size: 0.875rem;
    font-weight: ${({ selected }) => (selected ? 600 : 400)};
    line-height: 150%;
    color: ${({ theme, selected }) =>
      selected ? theme.colors.gray6 : theme.colors.gray4};
  }
`;

export const StateIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 0.5rem;

  border-radius: 5px;

  svg {
    width: 1rem;
    height: 1rem;
  }
`;

export const StateButton = styled.button`
  line-height: 0;
  padding: 0.5rem;

  svg {
    width: 1rem !important;
    height: 1rem !important;

    transition: all 0.2s;

    &:hover {
      color: ${({ theme }) => theme.colors.primaryDark};
    }
  }
`;

export const Line = styled.div`
  position: relative;

  width: 7rem;
  height: 2px;

  margin-top: 0.7rem;

  background: ${({ theme }) => theme.colors.gray1};

  transition: all 0.2s;
`;

type BlueLineProps = {
  show: boolean;
};

export const BlueLine = styled.div<BlueLineProps>`
  position: absolute;
  top: 0;
  left: 0;

  width: ${({ show }) => (show ? '100%' : '0px')};
  height: 2px;

  background: ${({ theme }) => theme.colors.primary};

  /* transition: all 1s; */
`;

export const AwaitingIcon = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 0.5rem;
  margin-top: 0.7rem;

  background: #ffffeb;
  border-radius: 100%;
  border: 1px dashed ${({ theme }) => theme.colors.yellow5};

  svg {
    width: 0.75rem;
    height: 0.75rem;

    color: ${({ theme }) => theme.colors.yellow5};
  }

  transition: all 0.2s;

  &:hover {
    border: 1px dashed ${({ theme }) => theme.colors.yellow6};

    svg {
      color: ${({ theme }) => theme.colors.yellow6};
    }
  }
`;

export const CreatingNewStepContent = styled.div`
  width: 18.125rem;
  height: 6.313rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > div {
    height: fit-content;
  }

  > div > div > div {
    width: 3rem !important;
    height: 3rem !important;
  }

  p {
    margin-top: 1rem;

    font-size: 0.875rem;
    font-weight: 400;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.gray6};
  }
`;
