import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type WorksType = 'projects' | 'workspaces';

export type PayloadMyWorks = {
  isAccessing: WorksType;
  hasWorkspaceAccess: boolean | null;
};

const initialState: PayloadMyWorks = {
  isAccessing: 'projects',
  hasWorkspaceAccess: null,
};

export const MyWorksState = createSlice({
  name: 'myWorks',
  initialState,
  reducers: {
    changeIsAccessing: (state, action: PayloadAction<WorksType>) => {
      state.isAccessing = action.payload;
    },
    changeHasWorkspaceAccess: (
      state,
      action: PayloadAction<boolean | null>,
    ) => {
      state.hasWorkspaceAccess = action.payload;
    },
  },
});

export const { changeIsAccessing, changeHasWorkspaceAccess } =
  MyWorksState.actions;

export default MyWorksState.reducer;
