const indicatorsPTBRTranslate = {
  indicatorsTitle: 'Indicadores',
  indicatorsDescription:
    'Selecione um indicador para enviar séries para seus grupos',
  indicatorsFilters: 'Filtros',
  indicatorsSource: 'Fonte',
  indicatorsProjections: 'Projeção',
  indicatorsPaginationName: 'indicadores',
  indicatorsUnableToLoadIndicators: 'Não foi possível carregar os indicadores',
  indicatorSearchFail: 'Não foi possível encontrar nenhum indicador contendo',
  indicatorFindAnyCategory:
    'Não foi possível encontrar nenhum indicador nesta categoria',
  indicatorTryBrowsingCategoriesOrSearch:
    'Tente navegar pelas categorias ou pesquise outro termo',
  indicatorsQuickSearchTitle: 'Pesquisa Rápida',
  indicatorsQuickSearchDescription:
    'Selecione um filtro ou digite um texto para encontrar o que deseja mais rapidamente.',
  indicatorQuickSearchInputDefaultPlaceholders:
    'Pesquise entre mais de 1312 indicadores',
  indicatorQuickSearchInputSelectOneCategoryPlaceholders: 'Pesquisar em',
  indicatorsQuickSearchClearFilters: 'Limpar filtros',
  indicatorsQuickSearchInputErrorLeast3Characters:
    'Pesquisa deve ter pelo menos 3 caracteres.',
  indicatorsQuickSearchInputErrorMaxCharacters:
    'Pesquisa não deve ter mais do que 50 caracteres',
  indicatorsQuickSearchNoSubCategories: 'Nenhuma subcategoria disponível.',
  indicatorsQuickSearchUnableLoadCategories:
    'Não foi possível carregar as categorias.',
  modalInfoGroupError: 'Não foi possível carregar este grupo.',
};

export default indicatorsPTBRTranslate;
