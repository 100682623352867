import React, { useContext, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { clear as clearModelling } from 'src/models/redux/reducers/Modelling';
import { RootState } from 'src/redux/store';
import { CaretDown, CaretLeft } from 'phosphor-react';
import { changeIsExpanded } from 'src/models/redux/reducers/SideBar';

import { DependentVariableOnSidebar } from './DependentVariableOnSidebar';
import { Menu } from './menus';
import {
  menuProject,
  menuDataView,
  menuUserSelection,
  menuResults,
} from './menus/models';
import { ProjectDetailOnSideBar } from './ProjectDetailOnSidebar';
import {
  Container,
  ContentOptionSeeAllUserSelectionOptions,
  ExpandSidebarButton,
  Hr,
  Item,
  MenuSectionTitleText,
  SidebarContent,
  DivItem,
  ULExpand,
} from './styles';
import { ModelUpdateSidebar } from '../ModelUpdateSidebar';
import { FeatureStoreVariablesProvider } from '../contexts/FeatureStoreVariableContext';
import { FeatureStoreVariables } from '../FeatureStoreVariables';
import { ModelUpdateDocsModal } from '../Modals/ModelUpdateDocsModal';
import { ModelUpdateContext } from '../contexts/ModelUpdateContext';
import { SidebarProps } from '../types';

export const ProjectSideBar: React.FC<SidebarProps> = () => {
  const [seeAllUserSelectionOptions, setSeeAllUserSelectionOptions] =
    useState(true);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const {
    project,
    modelling,
    sideBar: { isExpanded },
  } = useSelector((state: RootState) => state);
  const { updateHistory, parentProject } = useContext(ModelUpdateContext);
  const [activeMenus, setActiveMenus] = useState<string[]>([
    'sidebar-menu-1-0',
    'sidebar-menu-1-1',
    'sidebar-menu-1-2',
    'sidebar-menu-1-3',
  ]);

  if ((modelling.form || modelling.step) && project.id) {
    dispatch(clearModelling());
  }

  const checkIfSubMenuIsActive = (subMenu: Menu[] | undefined): boolean => {
    if (subMenu) {
      return subMenu.some((page) => {
        if (location.pathname === page?.url?.replace(':id', project.id ?? '')) {
          return true;
        }
        if (page.submenu) {
          return checkIfSubMenuIsActive(page.submenu);
        }
        return false;
      });
    }
    return false;
  };

  const handleIsActive = (
    url: string | undefined,
    subMenu: Menu[] | undefined,
  ): boolean => {
    if (subMenu) {
      return checkIfSubMenuIsActive(subMenu);
    }

    return location.pathname === url?.replace(':id', project.id ?? '');
  };

  const handleMenuClick = (url: string | undefined, modelsMenu?: boolean) => {
    if (url) {
      navigate(url.replace(':id', project.id ?? ''));
    }

    if (modelsMenu && !isExpanded) {
      let getFirstUserSelectionOption = '';

      menuUserSelection[1].submenu?.forEach((option) => {
        option.submenu?.forEach(({ url: menuUrl, dependents }) => {
          if (
            project.model?.model_types.some((value) =>
              dependents?.includes(value),
            ) &&
            !getFirstUserSelectionOption
          ) {
            getFirstUserSelectionOption = menuUrl ?? '';
          }
        });
      });

      if (getFirstUserSelectionOption) {
        navigate(getFirstUserSelectionOption.replace(':id', project.id ?? ''));
      }
    }
  };

  const handleArrowClick = (menuName: string) => {
    const newActiveMenus = [...activeMenus];

    if (location.pathname.includes('data-view') && !isExpanded) return;

    if (menuName === 'sidebar-menu-1-1' && !isExpanded) return;

    if (newActiveMenus.includes(menuName)) {
      const index = newActiveMenus.findIndex(
        (activeMenu) => activeMenu === menuName,
      );

      newActiveMenus.splice(index, 1);
      setActiveMenus(newActiveMenus);
    } else {
      const newActiveMenusAux = [
        'sidebar-menu-1-0',
        'sidebar-menu-1-1',
        'sidebar-menu-1-2',
        'sidebar-menu-1-3',
      ];
      if (!newActiveMenusAux.includes(menuName)) {
        newActiveMenusAux.push(menuName);
      }
      setActiveMenus(newActiveMenusAux);
    }
  };

  type ListMenuProps = {
    dept: number;
    data: Menu;
    hasSubMenu: Menu[] | undefined;
    menuName: string;
    menuIndex: number;
  };

  function handleSeeAllUserSelectionOptions() {
    setSeeAllUserSelectionOptions((state) => !state);
  }

  const ListMenu = ({
    dept,
    data,
    hasSubMenu,
    menuName,
    menuIndex,
  }: ListMenuProps) => {
    const { Icon } = data;
    const isActive = handleIsActive(data?.url, data?.submenu);

    const disabled = data.dependents
      ? !project.model?.model_types.some((value) =>
          data.dependents?.includes(value),
        )
      : false;

    if (
      project.model &&
      !project.projectError &&
      isActive &&
      data.isDisabled &&
      disabled
    ) {
      if (project.y.filter((y) => y.status === 'success').length > 1) {
        navigate(`/models/time-series/${project.id}/project-overview`);
      } else {
        navigate(`/models/time-series/${project.id}/AI-selection`);
      }
    }

    if (data.label === 'labelAISelection' && data.isDisabled && isActive) {
      if (project.y.filter((y) => y.status === 'success').length > 1) {
        navigate(`/models/time-series/${project.id}/project-overview`);
      } else {
        navigate(`/models/time-series/${project.id}/user-selection`);
      }
    }

    return (
      <>
        <Item
          data-tooltip-id="menu-disabled-tooltip"
          data-tooltip-html={data.tooltip}
          data-testid={`li-${data.label
            ?.replaceAll(' ', '-')
            .toLocaleLowerCase()}`}
          dept={dept}
          disabled={data.isDisabled || disabled}
          onClick={() => {
            if (!data.isDisabled && !disabled) {
              handleMenuClick(data?.url, data.label === 'labelModelExplorer');
              if (hasSubMenu) {
                handleArrowClick(menuName);
              }
              if (
                data.label === 'labelProjectOverview' ||
                data.label === 'labelAISelection' ||
                data.label === 'labelUserSelection'
              ) {
                setActiveMenus([
                  'sidebar-menu-1-0',
                  'sidebar-menu-1-1',
                  'sidebar-menu-1-2',
                  'sidebar-menu-1-3',
                ]);
              }
            }
          }}
        >
          <DivItem
            dept={dept}
            hasSubMenu={!!hasSubMenu}
            isToggled={activeMenus.includes(menuName)}
            isActive={isActive}
            loading={
              !project.model?.model_types &&
              data.label !== 'labelOverview' &&
              data.label !== 'labelOverview' &&
              data.label !== 'labelCorrelationMatrix' &&
              data.label !== 'labelVariableImportance' &&
              !project.projectError
            }
            isDataView={location.pathname.includes('data-view')}
            disabled={data.isDisabled || disabled}
            expanded={isExpanded}
          >
            {Icon && <Icon size="1.5rem" />}
            <p>{translate(data.label)}</p>
          </DivItem>
        </Item>

        {hasSubMenu && isExpanded && (
          <SubMenu
            dept={dept}
            data={data.submenu}
            isToggled={activeMenus.includes(menuName)}
            menuIndex={menuIndex}
          />
        )}
      </>
    );
  };

  type SubMenu = {
    dept: number;
    data: Menu[] | undefined;
    isToggled: boolean;
    menuIndex: number;
  };

  const SubMenu = ({ dept, data, isToggled, menuIndex }: SubMenu) => {
    if (!isToggled) {
      return null;
    }

    return (
      <ul>
        {data?.map((menus, index) => {
          const menuName = `sidebar-submenu-${dept + 1}-${menuIndex}-${index}`;

          return (
            <ListMenu
              key={menuName}
              dept={dept + 1}
              data={menus}
              hasSubMenu={menus.submenu}
              menuName={menuName}
              menuIndex={index}
            />
          );
        })}
      </ul>
    );
  };

  if (location.pathname.includes('data-view')) {
    return (
      <Container
        id="sidebar"
        expanded={isExpanded}
        className="sidebar"
        data-testid="sidebar-container"
        data-cy="sidebar-container"
      >
        <ExpandSidebarButton
          expanded={isExpanded}
          onClick={() => dispatch(changeIsExpanded(!isExpanded))}
          style={{ left: isExpanded ? '20rem' : '7rem' }}
          data-testid="expand-sidebar-button"
        >
          <CaretLeft />
        </ExpandSidebarButton>

        <SidebarContent expanded={isExpanded}>
          <ul>
            {menuDataView?.map((menus: Menu, index: number) => {
              const dept = +1;
              const menuName = `sidebar-menu-${dept}-${index}`;
              return (
                <ListMenu
                  dept={dept}
                  data={menus}
                  hasSubMenu={menus.submenu}
                  menuName={menuName}
                  key={menuName}
                  menuIndex={index}
                />
              );
            })}
          </ul>
        </SidebarContent>
      </Container>
    );
  }

  const monthlyProject = project.y.some((y) => y.info?.frequency === 'monthly');

  const hasProjectOverview =
    project.y.filter((y) => y.status === 'success').length > 1 ||
    (parentProject?.id !== project?.id &&
      parentProject?.ys.filter((y) => y.status === 'success').length > 1);

  const hasPerformanceModule =
    monthlyProject &&
    !!updateHistory?.updates.filter((y) => y.status === 'success').length &&
    parentProject?.id !== project?.id;

  const menuProjectAdjusted: Menu[] = menuProject
    .filter((menuProj) =>
      monthlyProject
        ? menuProject
        : !menuProj.url?.includes('/overall-performance'),
    )
    .map((menuProj) => {
      const isDisabledPerformance =
        !hasPerformanceModule && menuProj.url?.includes('/overall-performance');

      const disabledAux =
        ((!updateHistory || !updateHistory.updates.length) &&
          !menuProj?.url?.includes('/project-overview')) ||
        (project.y.filter((y) => y.status === 'success').length < 2 &&
          parentProject?.id === project?.id) ||
        (parentProject?.id !== project?.id &&
          (parentProject?.ys.filter((y) => y.status === 'success').length < 2 ||
            !parentProject?.ys.filter((y) => y.status === 'success').length) &&
          menuProj?.url?.includes('/project-overview'));

      return {
        ...menuProj,
        isDisabled: isDisabledPerformance || disabledAux,
        tooltip: isDisabledPerformance
          ? translate('workspaceSideBarPerformanceTooltip')
          : undefined,
      };
    });

  const menuResultsAdjusted: Menu[] = menuResults
    .filter((menuResult) =>
      monthlyProject
        ? menuResult
        : !menuResult.url?.includes('/variable-performance'),
    )
    .map((menuResult) => {
      const variablePerformanceDisabled = menuResult.url?.includes(
        '/variable-performance',
      )
        ? !updateHistory ||
          updateHistory?.updates.length === 0 ||
          !hasPerformanceModule
        : false;

      const aiSelectionDisabled =
        menuResult.label === 'labelAISelection'
          ? project.yBusiness.length === 0 ||
            project.yBusiness.find((yBus) => yBus.y === project.selectedY?.id)
              ?.status === 'error'
          : false;

      const modelTypeDisabled = menuResult.dependents
        ? !project.model?.model_types.some((value) =>
            menuResult.dependents?.includes(value),
          )
        : false;

      const isDisabled =
        variablePerformanceDisabled || aiSelectionDisabled || modelTypeDisabled;

      return {
        ...menuResult,
        isDisabled,
        tooltip: variablePerformanceDisabled
          ? translate('workspaceSideBarPerformanceTooltip')
          : aiSelectionDisabled
          ? translate('sideBarAISelectionDisabled')
          : undefined,
      };
    });

  return (
    <FeatureStoreVariablesProvider>
      <>
        <FeatureStoreVariables />
        <ModelUpdateSidebar />
        <ModelUpdateDocsModal />
        <Container
          id="sidebar"
          expanded={isExpanded}
          className="sidebar"
          data-testid="sidebar-container"
          data-cy="sidebar-container"
        >
          <ExpandSidebarButton
            expanded={isExpanded}
            onClick={() => dispatch(changeIsExpanded(!isExpanded))}
            style={{ left: isExpanded ? '20rem' : '7rem' }}
            data-testid="expand-sidebar-button"
          >
            <CaretLeft />
          </ExpandSidebarButton>

          <SidebarContent expanded={isExpanded}>
            <ProjectDetailOnSideBar />

            {(hasProjectOverview || hasPerformanceModule) && (
              <>
                {isExpanded && (
                  <MenuSectionTitleText className="menu-section">
                    {translate('sideBarProject')}
                  </MenuSectionTitleText>
                )}

                <ul>
                  {menuProjectAdjusted?.map((menus: Menu, index: number) => {
                    const dept = +1;
                    const menuName = `sidebar-menu-${dept}-${index}`;
                    return (
                      <ListMenu
                        dept={dept}
                        data={menus}
                        hasSubMenu={menus.submenu}
                        menuName={menuName}
                        key={menuName}
                        menuIndex={index}
                      />
                    );
                  })}
                </ul>
                {(hasProjectOverview || !!updateHistory?.updates.length) && (
                  <Hr />
                )}
              </>
            )}

            {isExpanded && (
              <MenuSectionTitleText className="menu-section">
                {translate('sideBarVariable')}
              </MenuSectionTitleText>
            )}

            <ul>
              {menuResultsAdjusted?.map((menus: Menu, index: number) => {
                const dept = +1;
                const menuName = `sidebar-menu-${dept}-${index}`;
                return (
                  <ListMenu
                    dept={dept}
                    data={menus}
                    hasSubMenu={menus.submenu}
                    menuName={menuName}
                    key={menuName}
                    menuIndex={index}
                  />
                );
              })}
            </ul>

            {isExpanded && (
              <ContentOptionSeeAllUserSelectionOptions
                seeAllUserSelectionOptions={seeAllUserSelectionOptions}
                className="menu-section"
              >
                <button
                  type="button"
                  onClick={handleSeeAllUserSelectionOptions}
                  data-testid="button-model-portfolio"
                  data-cy="button-model-portfolio"
                >
                  <MenuSectionTitleText className="menu-section">
                    {translate('sideBarUserSelection')}
                  </MenuSectionTitleText>
                  {isExpanded && <CaretDown size="0.75rem" weight="bold" />}
                </button>
              </ContentOptionSeeAllUserSelectionOptions>
            )}

            <ULExpand expand={seeAllUserSelectionOptions}>
              {menuUserSelection?.map((menus: Menu, index: number) => {
                const dept = +1;
                const menuName = `sidebar-menu-${dept}-${index}`;
                return (
                  <ListMenu
                    dept={dept}
                    data={menus}
                    hasSubMenu={menus.submenu}
                    menuName={menuName}
                    key={menuName}
                    menuIndex={index}
                  />
                );
              })}
            </ULExpand>

            {isExpanded && <Hr />}

            <DependentVariableOnSidebar />
          </SidebarContent>
        </Container>
      </>
    </FeatureStoreVariablesProvider>
  );
};
