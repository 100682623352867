import styled, { css, keyframes } from 'styled-components';

export const Container = styled.div`
  .categorizationTooltip.categorizationTooltip {
    max-width: 30rem !important;
  }

  .naked-button:not(:disabled) {
    color: ${({ theme }) => theme.colors.primary};

    :hover {
      color: ${({ theme }) => theme.colors.primaryDark};
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
`;

export const CategorizationActions = styled.div`
  position: absolute;
  top: 4.5rem;
  right: 1.5rem;

  transform: translate(0, -50%);

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  gap: 0.5rem;
`;

export const ConfigContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;

  width: 100%;
`;

export const ConfigOptionContainer = styled.div`
  width: 100%;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  > div:first-of-type {
    justify-content: space-between;
  }

  div + div {
    margin-top: 0.5rem;
  }

  button {
    width: 100%;
  }

  button + button {
    margin-left: 1rem;
  }

  svg {
    width: 1.25rem;
    height: 1.25rem;

    color: ${({ theme }) => theme.colors.gray4};

    transition: all 0.2s;
  }
`;

type SelectOptionButtonProps = {
  selected: boolean;
};

export const SelectOptionButton = styled.button<SelectOptionButtonProps>`
  height: fit-content;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0.75rem 1rem;
  border: 1px solid ${({ theme }) => theme.colors.gray2};
  border-radius: 5px;

  font-size: 0.875rem;
  font-weight: ${({ selected }) => (selected ? '600' : '400')};
  color: ${({ theme }) => theme.colors.gray4};

  transition: all 0.2s ease-in;

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  ${({ selected }) =>
    selected
      ? css`
          border-color: transparent;
          background: rgba(76, 148, 255, 0.12);

          color: ${({ theme }) => theme.colors.primary};
        `
      : css`
          &:not(:disabled):hover {
            border-color: ${({ theme }) => theme.colors.gray3};
            color: ${({ theme }) => theme.colors.gray5};
          }
        `}
`;

export const UploadContainer = styled.div`
  width: 100%;

  > div > div:last-of-type {
    margin-top: 0;
  }

  img {
    margin-bottom: 0 !important;
  }

  p,
  h1 {
    width: 20rem;
  }

  .selected-file {
    width: 60%;
  }
`;

export const FileButtons = styled.div`
  display: flex;
  gap: 1rem;

  margin-top: 1rem;

  z-index: 2;

  button:first-of-type:hover {
    color: ${({ theme }) => theme.colors.gray6};
  }
`;

export const ConfigButtons = styled.div`
  width: 100%;

  display: flex;
  justify-content: end;
  gap: 1.5rem;
`;

export const TableContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  gap: 1rem;

  > div {
    flex: 1;

    z-index: 2001;
  }

  .ag-theme-quartz {
    z-index: 1 !important;
  }

  .ag-grid-table-container,
  .ag-root-wrapper {
    max-height: 20rem;
  }

  .ag-cell-value {
    text-align: start;

    > div {
      word-break: break-all;
      white-space: break-spaces;
      line-height: 150%;
    }
  }

  .ag-header-cell-label {
    justify-content: start !important;
  }

  .ag-cell {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
`;

type ModalContainerProps = {
  visible: boolean;
};

export const ModalContainer = styled.div<ModalContainerProps>`
  width: 100vw;
  height: 100vh;

  position: fixed;
  top: 0;
  left: 0;

  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  justify-content: start;
  align-items: center;

  z-index: 2001;
`;

const start = keyframes`
  from{
    opacity: 0;
    transform: translate3d(-85%,0,0);
  }
  to{
    opacity:1;
    transform:translate3d(0,0,0);
  }
`;

type ModalContentProps = {
  visible: boolean;
};

export const ModalContent = styled.div<ModalContentProps>`
  width: 85%;
  height: 100vh;

  position: relative;
  display: flex;
  flex-direction: column;

  padding: 1.5rem 0;

  background: ${({ theme }) => theme.colors.white};
  z-index: 2002;

  animation: ${start} 1.5s;

  ${({ visible }) =>
    visible === false &&
    css`
      display: none;
    `};

  .categorization-modal-footer {
    width: calc(100% + 1.5rem);
    border-radius: 0;
  }
`;

export const ActionButtons = styled.div`
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  > button {
    padding: 0;

    height: fit-content;

    svg {
      width: 1.25rem;
      height: 1.25rem;

      margin: 0;
    }
  }
`;

export const CreateFilterContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;

  margin: 5rem 0 3.5rem 0;

  > div {
    width: 25rem;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  p {
    font-size: 1rem;
    font-weight: 400;
    line-height: 150%;

    text-align: center;

    color: ${({ theme }) => theme.colors.gray5};

    margin-bottom: 1.5rem;
  }

  img {
    width: 10rem;
  }

  button {
    padding: 0.75rem 3rem;
  }
`;

export const CategorizationContainer = styled.div`
  min-height: calc(100vh - 7.375rem - 4rem - 3rem);

  padding: 0 1.5rem;

  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.313rem;
    padding: 0.063rem;
  }

  > div + div {
    margin-top: 3rem;

    border-top: 1px solid ${({ theme }) => theme.colors.gray2};
  }

  > div:not(:first-of-type) {
    padding-top: 3rem;
  }
`;

export const ConfirmCloseCategorizationContainer = styled.div`
  width: 30rem;
  padding: 2.313rem 2rem 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  > svg {
    color: ${({ theme }) => theme.colors.yellow4};
    margin-bottom: 1.875rem;
  }

  > h1 {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.gray6};

    margin-bottom: 0.75rem;
    margin-top: -0.5rem;
  }

  > p {
    color: ${({ theme }) => theme.colors.gray5};
    font-weight: normal;
    font-size: 1rem;
    line-height: 150%;
    margin-bottom: 3rem;
  }
`;
