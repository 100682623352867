import React, { useState, useEffect, useCallback } from 'react';

import { format, isMatch, parse } from 'date-fns';
import { MagnifyingGlass } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { Button } from 'src/components/Button';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { Pagination } from 'src/components/Pagination';
import { useFormatDateLanguage } from 'src/hooks/useFormatDateLanguage';
import { RootState } from 'src/redux/store';
import { AutoComplete } from 'src/components/AutoComplete';
import {
  clearMyProjectOptions,
  saveMyProjectOptions,
} from 'src/models/redux/reducers/MyProjectsOptions';
import {
  AllCardsContainer,
  CardBody,
  CardContainer,
  CardContent,
  CardHead,
} from 'src/components/ProjectCard/styles';
import { Column, LevelFilters, Y } from 'src/models/redux/reducers/Project';
import api from 'src/models/service/api';
import { Status } from 'src/components/Status';

import {
  ActionsContainer,
  ContainerWithoutGroups,
  DivSearchBox,
} from '../styles';
import { ProjectCard } from '../../ProjectCard';

export type Project = {
  id: string;
  name: string;
  last_updated: string;
  created: string;
  icon_url: string;
  status: string;
  ys: Y[];
  type: string;
  parent_id: string;
  overview: {
    columns: Column[];
  };
  app_info: {
    engine: {
      name: string;
      version: string;
    };
  } | null;
  model_spec: {
    original?: {
      accuracy_crit: string[];
    };
  };
  business: {
    y_status?: {
      [key: string]: 'processing' | 'success' | 'error';
    };
  };
  filters?: LevelFilters;
};
export interface Projects {
  total: number;
  records: Project[];
}

interface ErrorProps {
  message: string;
  quantityLetters: number;
}

declare global {
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean;
  }
}

const QUANTITY_ITEMS_PAGE = 8;

export const ListProjects: React.FC = () => {
  const [projects, setProjects] = useState<Projects>();
  const [page, setPage] = useState(1);
  const [oldProjectNames, setOldProjectNames] = useState<string[]>([]);

  const {
    auth: { user },
    myProjectsOptions,
  } = useSelector((state: RootState) => state);
  const { t: translate } = useTranslation();
  const translateFormat = useFormatDateLanguage();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [searchValue, setSearchValue] = useState<string>('');
  const [searchError, setSearchError] = useState<ErrorProps>();
  const [searchTimer, setSearchTimer] = useState(1000);
  const [timeOutActive, setTimeOutActive] = useState(false);
  const [requestAllowed, setRequestAllowed] = useState(true);
  const [lastSearch, setLastSearch] = useState('');

  useEffect(() => {
    if (myProjectsOptions.page || myProjectsOptions.search) {
      if (myProjectsOptions.page) {
        setPage(myProjectsOptions.page);
      }
      if (myProjectsOptions.search) {
        setSearchValue(myProjectsOptions.search);
      }

      dispatch(clearMyProjectOptions());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const {
    data: projectData,
    isLoading,
    isFetching,
    isError,
  } = useQuery(
    ['projects', searchValue, page],
    async () => {
      let searchQuery = searchValue;
      if (isMatch(searchValue, translateFormat)) {
        searchQuery = format(
          parse(searchValue, translateFormat, new Date()),
          'MM/dd/yyyy',
        );
      }
      const { data } = await api.get<Projects>(
        searchValue.length >= 3
          ? isMatch(searchQuery, 'MM/dd/yyyy')
            ? `/projects?last_updated=${searchQuery}&skip=${
                (page - 1) * QUANTITY_ITEMS_PAGE
              }&limit=${QUANTITY_ITEMS_PAGE}`
            : `/projects?project_name=${encodeURIComponent(
                searchValue,
              )}&y_series=${encodeURIComponent(searchValue)}&skip=${
                (page - 1) * QUANTITY_ITEMS_PAGE
              }&limit=${QUANTITY_ITEMS_PAGE}`
          : `/projects?skip=${
              (page - 1) * QUANTITY_ITEMS_PAGE
            }&limit=${QUANTITY_ITEMS_PAGE}`,
      );

      return data;
    },
    {
      onSuccess(data) {
        if (searchValue && data.total) {
          saveProjectNameInLocalStorage();
        }
      },
      staleTime: 1000 * 60,
      enabled: requestAllowed || searchValue === '',
    },
  );

  const getProjectNamesInLocalStorage = useCallback((): string[] => {
    const allProjectNames = localStorage.getItem('projectNames');

    if (allProjectNames && user.email) {
      const userProjectNames = JSON.parse(allProjectNames)?.[user.email];

      if (userProjectNames) {
        return userProjectNames;
      }
    }

    return [];
  }, [user.email]);

  useEffect(() => {
    setOldProjectNames(getProjectNamesInLocalStorage());
  }, [getProjectNamesInLocalStorage]);

  useEffect(() => {
    if (projectData) {
      setProjects(projectData);
    }
    setRequestAllowed(false);
    setTimeOutActive(false);
    setSearchTimer(1000);
    setLastSearch(searchValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectData]);

  useEffect(() => {
    if (timeOutActive && searchValue.length >= 3) {
      setTimeout(() => {
        if (searchTimer > 0) {
          setSearchTimer(searchTimer - 1000);
        } else {
          setTimeOutActive(false);
        }
      }, 1000);
    } else {
      searchTimer === 0 && setRequestAllowed(true);
    }
  }, [searchTimer, searchValue, timeOutActive]);

  function saveProjectNameInLocalStorage() {
    if (!oldProjectNames.includes(searchValue)) {
      const userProjectNames = [searchValue, ...oldProjectNames];

      const allProjects = JSON.parse(
        localStorage.getItem('projectNames') ?? '{}',
      );

      if (user.email) {
        localStorage.setItem(
          'projectNames',
          JSON.stringify({
            ...allProjects,
            [user.email]: userProjectNames,
          }),
        );

        setOldProjectNames(userProjectNames);
      }
    }
  }

  function deleteProjectNameInLocalStorage(index: number) {
    const userProjectNames = [...oldProjectNames];

    userProjectNames.splice(index, 1);

    const allProjects = JSON.parse(
      localStorage.getItem('projectNames') ?? '{}',
    );

    if (user.email) {
      localStorage.setItem(
        'projectNames',
        JSON.stringify({
          ...allProjects,
          [user.email]: userProjectNames,
        }),
      );

      setOldProjectNames(userProjectNames);
    }
  }

  function saveSearchAndPageInRedux() {
    if (page !== 1 || searchValue) {
      dispatch(
        saveMyProjectOptions({
          page,
          search: searchValue,
        }),
      );
    }
  }

  function handleSearchProject(value: string) {
    setSearchValue(value);

    if (value.length > 50) {
      // setSearchError(('searchMaxCharactersError').replace('XX', '50'));
      setSearchError({
        message: 'searchMaxCharactersError',
        quantityLetters: 50,
      });
      return;
    }

    if (value.length < 3 && value.length > 0) {
      setSearchError({
        message: 'searchMinCharactersError',
        quantityLetters: 3,
      });
      return;
    }
    setSearchError({
      message: '',
      quantityLetters: 0,
    });

    if (value !== searchValue) {
      setSearchTimer(1000);
      setTimeOutActive(true);

      value.length >= 3 && setPage(1);
    }
  }

  const handleChangePage = (pageAux: number): void => {
    setRequestAllowed(true);
    setPage(pageAux);
  };

  const redirectToCreateProject = () => {
    if (
      user.isClaaS &&
      user.isFaaS &&
      user.permissions.includes('create:project-claas') &&
      user.permissions.includes('create:projects')
    ) {
      navigate('/models/projects/new');
      return;
    }

    if (user.isClaaS && user.permissions.includes('create:project-claas')) {
      navigate('/models/projects/new/classification');
      return;
    }

    if (user.isFaaS && user.permissions.includes('create:projects')) {
      navigate('/models/projects/new/time-series');
    }
  };

  const oldProjectNamesShow = oldProjectNames.filter(
    (oldProjectNamesAux) =>
      searchValue !== oldProjectNamesAux &&
      oldProjectNamesAux.includes(searchValue),
  );

  const userHasPermissionToCreateProject =
    user.permissions.includes('create:projects') ||
    user.permissions.includes('create:project-claas');

  return (
    <>
      <DivSearchBox>
        <ActionsContainer>
          <AutoComplete
            icon={<MagnifyingGlass size="1.25rem" />}
            testid="searchProject"
            placeholder={translate('searchProject')}
            onChange={(event) => {
              handleSearchProject(event);
              setPage(1);
            }}
            error={
              searchError?.quantityLetters === 3
                ? translate(searchError.message).replace('XX', '3')
                : searchError?.quantityLetters === 50
                ? translate(searchError.message).replace('XX', '50')
                : undefined
            }
            options={oldProjectNamesShow}
            value={searchValue}
            onDelete={deleteProjectNameInLocalStorage}
          />

          <div
            data-tooltip-id="models-my-projects-create-project"
            data-tooltip-content={
              !userHasPermissionToCreateProject
                ? translate('myProjectDoNotHasPermissionToCreateProject')
                : undefined
            }
          >
            <Button
              buttonType="primary"
              onClick={() => redirectToCreateProject()}
              data-cy="button-create-project"
              data-testid="button-create-project"
              disabled={!userHasPermissionToCreateProject}
            >
              <p>{translate('createProjectButton')}</p>
            </Button>
          </div>
        </ActionsContainer>
        <Tooltip
          id="models-my-projects-create-project"
          className="customTooltipTheme"
        />
      </DivSearchBox>

      {isError ? (
        <ContainerMaintenance
          content="projects"
          text={translate('fetchProjectsError')}
        />
      ) : projectData?.total === 0 && searchValue.length >= 1 ? (
        <ContainerWithoutGroups>
          <Status
            type="noSearchResults"
            title={`${translate('searchProjectFail')} "${lastSearch}".`}
          />
        </ContainerWithoutGroups>
      ) : isLoading || isFetching || !projects ? (
        <>
          <AllCardsContainer data-testid="content-projects">
            {Array.from({ length: 8 }, (_, number) => number).map((number) => (
              <CardContainer
                key={`card-loading-${number}`}
                data-testid={`loading-project-${number}`}
              >
                <CardContent style={{ cursor: 'default' }}>
                  <CardHead projectType="loading">
                    <ContainerSkeleton
                      withLoading={false}
                      style={{
                        width: '4rem',
                        height: '4rem',
                        position: 'absolute',
                        top: '1.5rem',
                        left: '1rem',
                      }}
                    />
                  </CardHead>

                  <CardBody>
                    <ContainerSkeleton
                      withLoading={false}
                      style={{
                        width: '80%',
                        height: '1.688rem',
                      }}
                    />

                    <span>{translate('projectHeadDependVar')}</span>

                    <ContainerSkeleton
                      withLoading={false}
                      style={{
                        width: '90%',
                        height: '1.313rem',
                        marginTop: '0.5rem',
                      }}
                    />

                    <span>{translate('projectHeadLastUpdated')}</span>
                    <ContainerSkeleton
                      withLoading={false}
                      style={{
                        width: '65%',
                        height: '1.313rem',
                        marginTop: '0.5rem',
                      }}
                    />
                  </CardBody>
                </CardContent>
              </CardContainer>
            ))}
          </AllCardsContainer>

          {projects?.total && (
            <Pagination
              page={page}
              setPage={handleChangePage}
              total={projects.total}
              quantityItemsPerPage={QUANTITY_ITEMS_PAGE}
              name={translate('paginationText')}
              style={{ marginTop: '0px' }}
            />
          )}
        </>
      ) : projects.total > 0 ? (
        <>
          <AllCardsContainer data-testid="content-projects">
            {projects.records.map((project, index) => (
              <ProjectCard
                key={`list-project-${index.toString()}`}
                id={project.id}
                name={project.name}
                iconUrl={project.icon_url}
                updatedAt={project.last_updated}
                created={project.created}
                type={project.type}
                dependentVar={project.ys}
                status={project.status}
                columns={project.overview.columns}
                parentID={project.parent_id}
                accuracyCrit={
                  project.model_spec.original?.accuracy_crit?.[0] ?? null
                }
                businessYsStatus={project.business.y_status ?? {}}
                saveSearchAndPage={saveSearchAndPageInRedux}
                filters={project.filters}
                appInfo={project.app_info}
              />
            ))}
          </AllCardsContainer>

          {projects?.total && (
            <Pagination
              page={page}
              setPage={handleChangePage}
              total={projects.total}
              quantityItemsPerPage={QUANTITY_ITEMS_PAGE}
              name={translate('paginationText')}
              style={{ marginTop: '0px' }}
            />
          )}
        </>
      ) : (
        <ContainerMaintenance
          content="projects"
          text={translate('myProjectErrorNoProjectFound')}
          data-testid="container-without-projects"
        />
      )}
    </>
  );
};
