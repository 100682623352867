import React, { useCallback, useContext, useEffect, useState } from 'react';

import { flushSync } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { ChartLine, GitFork, MagnifyingGlass } from 'phosphor-react';
import { Tooltip } from 'react-tooltip';
import { format, isMatch, parse } from 'date-fns';
import { useSelector } from 'react-redux';
import { Modal } from 'src/components/Modal';
import { Button } from 'src/components/Button';
import { ModalFooter } from 'src/components/Modal/Footer/styles';
import { Card } from 'src/components/Card';
import { useQuery } from 'react-query';
import api from 'src/models/service/api';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import {
  AllCardsContainer,
  CardBody,
  CardContainer,
  CardContent,
  CardHead,
  CardProjectTypeContent,
} from 'src/components/ProjectCard/styles';
import { arrayIcon, checkIconURL } from 'src/utils/icons/handleProjectIcon';
import { useFormatDateLanguage } from 'src/hooks/useFormatDateLanguage';
import { Pagination } from 'src/components/Pagination';
import { AutoComplete } from 'src/components/AutoComplete';
import { RootState } from 'src/redux/store';
import { Status } from 'src/components/Status';
import { WorkspaceConfigContext } from 'src/models/contexts/WorkspaceConfigContext';
import { ContainerWarning } from 'src/models/components/ContainerWarning';

import {
  ErrorProps,
  ProjectProps,
  ProjectsResponse,
  SelectProjectsModalProps,
} from './types';
import { Container, ContentFooter, SearchContainer } from './styles';

const QUANTITY_ITEMS_PAGE = 8;

export const SelectProjectsModal: React.FC<SelectProjectsModalProps> = ({
  visible,
  setVisible,
  selectedProjects,
  setSelectedProjects,
}) => {
  const [projects, setProjects] = useState<ProjectsResponse>();
  const [page, setPage] = useState(1);

  const [selected, setSelected] = useState<ProjectProps[]>([]);

  const [searchValue, setSearchValue] = useState('');
  const [searchError, setSearchError] = useState<ErrorProps>();
  const [lastSearch, setLastSearch] = useState('');
  const [searchTimer, setSearchTimer] = useState(1000);
  const [requestAllowed, setRequestAllowed] = useState(true);
  const [timeOutActive, setTimeOutActive] = useState(false);
  const [oldSearches, setOldSearches] = useState<string[]>([]);

  const { t: translate } = useTranslation();

  const translateFormat = useFormatDateLanguage();

  const { isEdition, frequency, enablePlanningFlow, setFrequency } = useContext(
    WorkspaceConfigContext,
  );

  const {
    auth: { user },
  } = useSelector((state: RootState) => state);
  const {
    data: projectData,
    isLoading,
    isFetching,
    isError,
  } = useQuery(
    ['projects', 'modelling', 'bergman', '0.5.2', searchValue, page],
    async () => {
      let searchQuery = searchValue;
      if (isMatch(searchValue, translateFormat)) {
        searchQuery = format(
          parse(searchValue, translateFormat, new Date()),
          'MM/dd/yyyy',
        );
      }

      const projectTypeEngineAndVersion =
        'project_type=modelling&engine_name=bergman&engine_version_gte=0.5.2';

      const { data } = await api.get<ProjectsResponse>(
        searchValue.length >= 3
          ? isMatch(searchQuery, 'MM/dd/yyyy')
            ? `/projects?${projectTypeEngineAndVersion}&last_updated=${searchQuery}&skip=${
                (page - 1) * QUANTITY_ITEMS_PAGE
              }&limit=${QUANTITY_ITEMS_PAGE}`
            : `/projects?${projectTypeEngineAndVersion}&project_name=${encodeURIComponent(
                searchValue,
              )}&y_series=${encodeURIComponent(searchValue)}&skip=${
                (page - 1) * QUANTITY_ITEMS_PAGE
              }&limit=${QUANTITY_ITEMS_PAGE}`
          : `/projects?${projectTypeEngineAndVersion}&skip=${
              (page - 1) * QUANTITY_ITEMS_PAGE
            }&limit=${QUANTITY_ITEMS_PAGE}`,
      );

      return data;
    },
    {
      onSuccess(data) {
        if (searchValue && data.total) {
          saveSearchInLocalStorage();
        }
      },
      staleTime: 1000 * 60,
      enabled: requestAllowed || searchValue === '',
    },
  );

  useEffect(() => {
    if (selected.length >= 1) {
      const yStatusIsSuccess = selected[0].ys.find(
        (y) => y.status === 'success',
      );
      if (yStatusIsSuccess?.info?.frequency)
        setFrequency(yStatusIsSuccess?.info?.frequency);
    } else if (selected.length === 0 && !isEdition) {
      setFrequency(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, isEdition]);

  const projectIsSelected = useCallback(
    (id: string) => selected.findIndex((project) => project.id === id) !== -1,
    [selected],
  );

  const handleSelectProject = (project: ProjectProps) => {
    const projectAlreadySelected = projectIsSelected(project.id);

    let updatedSelectedArr = [...selected];

    if (projectAlreadySelected) {
      updatedSelectedArr = updatedSelectedArr.filter(
        (element) => element.id !== project.id,
      );
    } else {
      updatedSelectedArr.push(project);
    }

    setSelected(updatedSelectedArr);
  };

  const handleChangePage = (pageAux: number) => {
    setRequestAllowed(true);
    setPage(pageAux);
  };

  const handleSearchProject = (word: string) => {
    setSearchValue(word);

    if (word.length > 50) {
      setSearchError({
        message: 'searchMaxCharactersError',
        quantityLetters: 50,
      });
      return;
    }

    if (word.length > 0 && word.length < 3) {
      setSearchError({
        message: 'searchMinCharactersError',
        quantityLetters: 3,
      });
      return;
    }

    setSearchError({
      message: '',
      quantityLetters: 0,
    });

    if (word !== searchValue) {
      setSearchTimer(1000);
      setTimeOutActive(true);

      setPage(1);
    }
  };

  const handleConfirm = () => {
    setSelectedProjects(selected.sort((a, b) => a.name.localeCompare(b.name)));
    setVisible(false);
  };

  function deleteSearchInLocalStorage(index: number) {
    const userSearches = [...oldSearches];

    userSearches.splice(index, 1);

    const allProjects = JSON.parse(
      localStorage.getItem('projectNames') ?? '{}',
    );

    if (user.email) {
      localStorage.setItem(
        'projectNames',
        JSON.stringify({
          ...allProjects,
          [user.email]: userSearches,
        }),
      );

      setOldSearches(userSearches);
    }
  }

  const saveSearchInLocalStorage = () => {
    if (!oldSearches.includes(searchValue)) {
      const userSearches = [searchValue, ...oldSearches];

      const allProjects = JSON.parse(
        localStorage.getItem('projectNames') ?? '{}',
      );

      if (user.email) {
        localStorage.setItem(
          'projectNames',
          JSON.stringify({
            ...allProjects,
            [user.email]: userSearches,
          }),
        );

        setOldSearches(userSearches);
      }
    }
  };

  const oldSearchesToShow = oldSearches.filter(
    (oldSearchesAux) =>
      searchValue !== oldSearchesAux && oldSearchesAux.includes(searchValue),
  );

  useEffect(() => {
    const allSearches = localStorage.getItem('projectNames');

    let userProjectNames: string[] = [];

    if (allSearches && user.email) {
      userProjectNames = JSON.parse(allSearches)?.[user.email] || [];
    }

    setOldSearches(userProjectNames);
  }, [user]);

  useEffect(() => {
    if (projectData) {
      setProjects(projectData);
      setTimeOutActive(false);
    }

    setRequestAllowed(false);
    setSearchTimer(1000);
    setLastSearch(searchValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectData]);

  useEffect(() => {
    if (timeOutActive && searchValue.length >= 3) {
      setTimeout(() => {
        flushSync(() => {
          if (searchTimer > 0) {
            setSearchTimer(searchTimer - 1000);
          } else {
            setTimeOutActive(false);
          }
        });
      }, 1000);
    } else {
      searchTimer === 0 &&
        flushSync(() => {
          setRequestAllowed(true);
        });
    }
  }, [searchTimer, searchValue, timeOutActive]);

  useEffect(() => {
    setSelected(selectedProjects);
  }, [selectedProjects, setSelectedProjects]);

  const projectsAdjusted =
    projects?.records.map((project) => {
      const isStatusSuccess =
        project.status === 'success' || project.status === 'partial_success';

      const isClassification = project.type === 'classification';

      const projectFrequency = project.ys.find((y) => y.status === 'success')
        ?.info?.frequency;

      const isDifferentFrequency = frequency
        ? projectFrequency !== frequency
        : false;

      let errorMessage: string | null = null;

      if (enablePlanningFlow && projectFrequency !== 'monthly') {
        errorMessage = translate(
          'selectProjectWorkspaceIsDisabledApprovalFlow',
        );
      } else if (isDifferentFrequency && isStatusSuccess) {
        if (isEdition) {
          errorMessage = translate(
            'selectProjectWorkspaceIsDisabledDifferentFrequencyOfWorkspace',
          );
        } else {
          errorMessage = translate(
            'selectProjectWorkspaceIsDisabledDifferentFrequency',
          );
        }
      }

      return {
        ...project,
        errorMessage,
        isStatusSuccess,
        isClassification,
        isDifferentFrequency,
      };
    }) || [];

  const variablesSelected = selected.reduce(
    (acc, current) =>
      acc + current.ys.filter((y) => y.status === 'success').length,
    0,
  );

  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      style={{ display: 'flex', flexDirection: 'column' }}
      dataCy="select-project-modal"
    >
      <Container>
        <Card
          textCard={translate('selectProjectsWorkspaceTitle')}
          textDescription={translate('selectProjectsWorkspaceDescription')}
        />

        <SearchContainer
          error={!!searchError && searchError?.quantityLetters !== 0}
        >
          <AutoComplete
            icon={<MagnifyingGlass />}
            testid="search-project"
            placeholder={translate('searchProject')}
            onChange={(event) => {
              handleSearchProject(event);
            }}
            error={
              searchError?.quantityLetters === 3
                ? translate(searchError.message).replace('XX', '3')
                : searchError?.quantityLetters === 50
                ? translate(searchError.message).replace('XX', '50')
                : undefined
            }
            options={oldSearchesToShow}
            value={searchValue}
            onDelete={deleteSearchInLocalStorage}
          />
        </SearchContainer>

        {isError ? (
          <ContainerMaintenance
            content="projects"
            text={translate('fetchProjectsError')}
            data-testid="project-error"
            className="project-maintenance"
          />
        ) : projectData?.total === 0 && searchValue.length >= 1 ? (
          <Status
            type="noSearchResults"
            title={`${translate('searchProjectFail')} "${lastSearch}".`}
            dataCy="project-not-found"
          />
        ) : isLoading || isFetching || !projects || timeOutActive ? (
          <div className="projects-container">
            <AllCardsContainer>
              {Array.from({ length: 8 }, (_, number) => number).map(
                (number) => (
                  <CardContainer
                    key={`card-loading-${number}`}
                    data-testid={`loading-project-${number}`}
                  >
                    <CardContent style={{ cursor: 'default' }}>
                      <CardHead projectType="loading">
                        <ContainerSkeleton
                          withLoading={false}
                          style={{
                            width: '4rem',
                            height: '4rem',
                            position: 'absolute',
                            top: '1.5rem',
                            left: '1rem',
                          }}
                        />
                      </CardHead>

                      <CardBody>
                        <ContainerSkeleton
                          withLoading={false}
                          style={{
                            width: '80%',
                            height: '1.688rem',
                          }}
                        />

                        <span>{translate('projectHeadDependVar')}</span>

                        <ContainerSkeleton
                          withLoading={false}
                          style={{
                            width: '90%',
                            height: '1.313rem',
                            marginTop: '0.5rem',
                          }}
                        />

                        <span>{translate('projectHeadLastUpdated')}</span>
                        <ContainerSkeleton
                          withLoading={false}
                          style={{
                            width: '65%',
                            height: '1.313rem',
                            marginTop: '0.5rem',
                          }}
                        />
                      </CardBody>
                    </CardContent>
                  </CardContainer>
                ),
              )}
            </AllCardsContainer>

            {projects?.total && (
              <Pagination
                page={page}
                setPage={handleChangePage}
                total={projects.total}
                quantityItemsPerPage={QUANTITY_ITEMS_PAGE}
                name={translate('paginationText')}
              />
            )}
          </div>
        ) : projects.total > 0 ? (
          <div className="projects-container">
            <AllCardsContainer>
              {projectsAdjusted.map(
                (
                  {
                    id,
                    type,
                    name,
                    icon_url,
                    ys,
                    last_updated,
                    isStatusSuccess,
                    isClassification,
                    errorMessage,
                  },
                  index,
                ) => (
                  <React.Fragment key={id}>
                    <Tooltip
                      id="workspace-select-project"
                      className="customTooltipTheme customTooltipMyProjects"
                    />

                    <CardContainer
                      role="button"
                      disabled={
                        !isStatusSuccess || isClassification || !!errorMessage
                      }
                      data-testid={`project-${id}`}
                      data-cy={`project-${name
                        .replaceAll(' ', '-')
                        .toLowerCase()}`}
                    >
                      <CardContent
                        onClick={() =>
                          isStatusSuccess &&
                          !isClassification &&
                          !errorMessage &&
                          handleSelectProject(projects.records[index])
                        }
                        disabled={
                          !isStatusSuccess || isClassification || !!errorMessage
                        }
                        className={
                          projectIsSelected(id)
                            ? 'selected-project'
                            : 'unselected-project'
                        }
                        data-testid={`select-project-${id}`}
                        data-cy={`access-project-${name
                          .replaceAll(' ', '-')
                          .toLowerCase()}`}
                      >
                        <img
                          src={checkIconURL(icon_url) ? icon_url : arrayIcon[0]}
                          alt="project-icon"
                          data-testid={`project-${id}-icon`}
                          data-cy={`project-${name
                            .replaceAll(' ', '-')
                            .toLowerCase()}-icon`}
                        />
                        <CardHead projectType={type}>
                          <CardProjectTypeContent
                            projectType={type}
                            style={{ margin: '0rem' }}
                            data-testid={`project-${id}-type`}
                            data-cy={`project-${name
                              .replaceAll(' ', '-')
                              .toLowerCase()}-type`}
                          >
                            {isClassification ? (
                              <GitFork
                                size="1rem"
                                data-testid={`project-${id}-classification-icon`}
                                data-cy={`project-${name
                                  .replaceAll(' ', '-')
                                  .toLowerCase()}-classification-icon`}
                              />
                            ) : (
                              <ChartLine
                                size="1rem"
                                data-testid={`project-${id}-time-series-icon`}
                                data-cy={`project-${name
                                  .replaceAll(' ', '-')
                                  .toLowerCase()}-time-series-icon`}
                              />
                            )}
                            <p>
                              {translate(
                                isClassification
                                  ? 'classification'
                                  : 'timeSeries',
                              )}
                            </p>
                          </CardProjectTypeContent>
                        </CardHead>

                        <CardBody
                          data-tooltip-id="workspace-select-project"
                          data-tooltip-html={errorMessage}
                        >
                          <h5
                            data-testid={`project-${id}-name`}
                            data-cy={`project-${name
                              .replaceAll(' ', '-')
                              .toLowerCase()}-name`}
                          >
                            {name}
                          </h5>
                          <span>{translate('projectHeadDependVar')}</span>
                          <div
                            data-testid={`project-${id}-dependent-variable`}
                            data-cy={`project-${name.replaceAll(
                              ' ',
                              '-',
                            )}-dependent-variable`}
                          >
                            {ys.length > 1 ? (
                              (() => (
                                <p>
                                  {`${
                                    ys[0].name.length <= 50
                                      ? ys[0].name
                                      : `${ys[0].name.slice(0, 47)}...`
                                  } `}
                                  <span
                                    data-tooltip-id="workspace-projects-dependent-variables"
                                    data-tooltip-content={ys
                                      .slice(1, ys.length)
                                      .map((y) => y.name)
                                      .toString()}
                                    data-testid={`project-${id}-dependent-variable-tooltip`}
                                    data-cy={`project-${name
                                      .replaceAll(' ', '-')
                                      .toLowerCase()}-dependent-variable-tooltip`}
                                  >
                                    {`+${ys.length - 1} ${translate(
                                      'projectTooltip',
                                    )}`}
                                  </span>
                                </p>
                              ))()
                            ) : ys[0].name.length <= 50 ? (
                              <p>{ys[0].name}</p>
                            ) : (
                              <p>{`${ys[0].name.slice(0, 47)}...`}</p>
                            )}
                          </div>
                          <span>{translate('projectHeadLastUpdated')}</span>
                          <p
                            data-testid={`project-${id}-last-update`}
                            data-cy={format(
                              new Date(last_updated),
                              `${translateFormat}, HH:mm`,
                            ).slice(0, 10)}
                          >
                            {format(
                              new Date(last_updated),
                              `${translateFormat}, HH:mm`,
                            )}
                          </p>
                        </CardBody>
                      </CardContent>
                    </CardContainer>
                  </React.Fragment>
                ),
              )}
            </AllCardsContainer>

            <ContentFooter>
              {variablesSelected > 1000 && (
                <ContainerWarning
                  visible
                  text={translate(
                    'createWorkspaceVariableSelectMoreThan1000Warning',
                  )}
                />
              )}
              <Pagination
                page={page}
                setPage={handleChangePage}
                total={projects.total}
                quantityItemsPerPage={QUANTITY_ITEMS_PAGE}
                name={translate('paginationText')}
                style={{ marginLeft: 'auto' }}
              />
            </ContentFooter>
          </div>
        ) : (
          <ContainerMaintenance
            content="projects"
            text={translate('myProjectErrorNoProjectFound')}
            className="project-maintenance"
            data-testid="without-projects"
          />
        )}

        <Tooltip
          id="workspace-projects-dependent-variables"
          className="customTooltipTheme customTooltipMyProjects"
        />
      </Container>

      <ModalFooter>
        <Button
          buttonType="naked"
          onClick={() => setVisible(false)}
          data-testid="select-projects-cancel-button"
        >
          {translate('back')}
        </Button>
        <Button
          buttonType="primary"
          onClick={() => handleConfirm()}
          disabled={!selected.length}
          data-testid="select-projects-confirm-button"
        >
          {translate('confirm')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
