import styled from 'styled-components';

export const DivSearchBox = styled.div`
  display: flex;

  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 53.125rem) {
    div:first-child {
      flex: 1;
    }
  }

  input {
    width: 15.625rem;
  }
`;

export const ActionsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 1.5rem;

  height: 3.125rem;
`;

export const ContainerWithoutGroups = styled.div`
  width: 100%;
  height: 37.188rem;
  padding: 1.5rem 0;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const List = styled.div`
  min-height: 31.5rem;

  display: grid;
  grid-template-columns: repeat(3, 1fr);

  gap: 1.5rem;

  margin-top: 1.5rem;

  @media (max-width: 1140px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 700px) {
    grid-template-columns: 1fr;
  }
`;
