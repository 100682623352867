export const WorkspaceCardENUSTranslations = {
  workspaceCardCanView: 'You can view',
  workspaceCardCanEdit: 'You can edit',
  workspaceCardCanManager: 'You can manager',
  workspaceCardDelete: 'Delete from your account',
  workspaceCardDeleteDescription:
    'This will permanently remove this workspace from your account. Please type <b>XXX</b> to confirm.',
  workspaceCardDeleteWrongNameError: 'Must write the correct workspace name',
  workspaceCardDeleteErrorTitle: 'Failed to delete',
  workspaceCardDeleteErrorDescription:
    'An error occurred while deleting the workspace from your account. Please try again later.',
  workspaceCardNoPermissionToDeleteTitle: 'Last manager',
  workspaceCardNoPermissionToDeleteDescription:
    'You are the last manager, remove all viewers before deleting the workspace from your account.',
};
