/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useState } from 'react';

export const useDebounce = (value: any, delay = 500) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const [isLoadingDebounce, setIsLoadingDebounce] = useState(false);

  useEffect(() => {
    setIsLoadingDebounce(true);
    const timeout = setTimeout(() => {
      setDebouncedValue(value);
      setIsLoadingDebounce(false);
    }, delay);

    return () => clearTimeout(timeout);
  }, [delay, value]);

  return { debouncedValue, isLoadingDebounce };
};
