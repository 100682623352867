import { Action, combineReducers, configureStore } from '@reduxjs/toolkit';
import thunk, { ThunkAction } from 'redux-thunk';
import groupNavigationReducer, {
  PayloadGroupNavigation,
} from 'src/feature-store/redux/reducers/Groups';
import indicatorsPageReducer, {
  PayloadIndicatorsPage,
} from 'src/feature-store/redux/reducers/IndicatorsPage';
import serieToViewReducer, {
  PayloadSerieToView,
} from 'src/feature-store/redux/reducers/SerieToView';
import claasProjectReducer, {
  ClaasProject,
} from 'src/models/redux/reducers/ClaasProject';
import predictStatusReducer, {
  PredictStatusProps,
} from 'src/models/redux/reducers/PredictStatus';
import projectOverviewExportTimeSeriesReducer, {
  PayloadProjectOverviewExportTimeSeries,
} from 'src/models/redux/reducers/ProjectOverviewExportTimeSeries';
import projectWarningsReducer, {
  ProjectWarningsProps,
} from 'src/models/redux/reducers/ProjectWarnings';
import groupPremiumReducer, {
  PayloadGroupPremium,
} from 'src/feature-store/redux/reducers/GroupPremiumIndicator';
import AIUserSelectionOptionsReducer, {
  PayloadAIUserSelectionOptions,
} from 'src/models/redux/reducers/AIUserSelectionOptions';
import ModelExplorerOptionsReducer, {
  PayloadModelExplorerOptions,
} from 'src/models/redux/reducers/ModelExplorer';
import myProjectsOptionsReducer, {
  PayloadMyProjectsOptions,
} from 'src/models/redux/reducers/MyProjectsOptions';
import dependentVariablesConfigReducer, {
  PayloadDependentVariablesConfig,
} from 'src/models/redux/reducers/DependentVariablesConfig';
import WorkspaceReducer, {
  PayloadWorkspace,
} from 'src/models/redux/reducers/Workspace';
import myWorksReducer, {
  PayloadMyWorks,
} from 'src/models/redux/reducers/MyWorks';
import workspaceProjectionsOptions, {
  PayloadWorkspaceProjectionsOptions,
} from 'src/models/redux/reducers/WorkspaceProjectionsOptions';
import workspaceOverviewOptions, {
  PayloadWorkspaceOverviewOptions,
} from 'src/models/redux/reducers/WorkspaceOverviewOptions';
import sideBarReducer, {
  PayloadSideBar,
} from 'src/models/redux/reducers/SideBar';

import dataSetReducer, { DataSetState } from '../models/redux/reducers/Dataset';
import modellingReducer, {
  PayloadDataModelling,
} from '../models/redux/reducers/Modelling';
import ProjectReducer, {
  PayloadProject,
} from '../models/redux/reducers/Project';
import authReducer, { AuthState } from './reducers/Auth';
import ModelProdNavigationReducer, {
  ModelProdNavigationProps,
} from '../models/redux/reducers/ModelProdNavigation';

type CombineReducer = {
  auth: AuthState;
  dataset: DataSetState;
  modelling: PayloadDataModelling;
  project: PayloadProject;
  groupNavigation: PayloadGroupNavigation;
  serieToView: PayloadSerieToView;
  indicatorsPage: PayloadIndicatorsPage;
  claasProject: ClaasProject;
  projectOverviewExportTimeSeries: PayloadProjectOverviewExportTimeSeries;
  predictStatus: PredictStatusProps;
  projectWarnings: ProjectWarningsProps;
  AIUserSelectionOptions: PayloadAIUserSelectionOptions;
  modelProdNavigation: ModelProdNavigationProps;
  modelExplorerOptions: PayloadModelExplorerOptions;
  groupPremium: PayloadGroupPremium;
  myProjectsOptions: PayloadMyProjectsOptions;
  dependentVariablesConfig: PayloadDependentVariablesConfig;
  workspace: PayloadWorkspace;
  myWorks: PayloadMyWorks;
  workspaceProjectionsOptions: PayloadWorkspaceProjectionsOptions;
  workspaceOverviewOptions: PayloadWorkspaceOverviewOptions;
  sideBar: PayloadSideBar;
};

const reducers = combineReducers({
  auth: authReducer,
  dataset: dataSetReducer,
  modelling: modellingReducer,
  project: ProjectReducer,
  groupNavigation: groupNavigationReducer,
  serieToView: serieToViewReducer,
  indicatorsPage: indicatorsPageReducer,
  claasProject: claasProjectReducer,
  projectOverviewExportTimeSeries: projectOverviewExportTimeSeriesReducer,
  predictStatus: predictStatusReducer,
  projectWarnings: projectWarningsReducer,
  AIUserSelectionOptions: AIUserSelectionOptionsReducer,
  modelProdNavigation: ModelProdNavigationReducer,
  modelExplorerOptions: ModelExplorerOptionsReducer,
  groupPremium: groupPremiumReducer,
  myProjectsOptions: myProjectsOptionsReducer,
  dependentVariablesConfig: dependentVariablesConfigReducer,
  workspace: WorkspaceReducer,
  myWorks: myWorksReducer,
  workspaceProjectionsOptions,
  workspaceOverviewOptions,
  sideBar: sideBarReducer,
});

export const store = configureStore<CombineReducer>({
  //@ts-expect-error:ignora
  reducer: reducers,
  middleware: [thunk],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
