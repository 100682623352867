import React, { createContext, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { CaretLeft } from 'phosphor-react';
import { RootState } from 'src/redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { changeIsExpanded } from 'src/models/redux/reducers/SideBar';
import {
  changeEditionModeEnabled,
  changeReviewModeEnabled,
} from 'src/models/redux/reducers/WorkspaceOverviewOptions';

import { Menu } from './menus';
import { workspacesSideBarResultsMenus } from './menus/menus';
import {
  WorkspaceSideBarContainer,
  Item,
  MenuSectionTitleText,
  ExpandSidebarButton,
  SidebarContent,
} from './styles';
import { WorkspaceInfo } from './WorkspaceInfo';
import { DependentVariable } from './DependentVariable';
import { ReleaseMenu } from './ReleaseMenu';
import { SidebarProps } from '../types';

interface WorkspaceSideBarContextProps {
  menuReleaseVisible: boolean;
  openMenuSelectRelease: () => void;
  closeMenuSelectRelease: () => void;
}
export const WorkspaceSideBarContext = createContext(
  {} as WorkspaceSideBarContextProps,
);

export const WorkspaceSideBar: React.FC<SidebarProps> = () => {
  const [menuReleaseVisible, setMenuReleaseVisible] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  const { id } = useParams();

  const {
    sideBar: { isExpanded },
    workspace: { releaseSelected },
    workspaceOverviewOptions: { editionModeEnabled, reviewModeEnabled },
  } = useSelector((state: RootState) => state);

  const checkIfSubMenuIsActive = (subMenu: Menu[] | undefined): boolean => {
    if (subMenu) {
      return subMenu.some((page) => {
        if (location.pathname === page.url) {
          return true;
        }
        if (page.submenu) {
          return checkIfSubMenuIsActive(page.submenu);
        }
        return false;
      });
    }
    return false;
  };

  const handleIsActive = (
    url: string | undefined,
    subMenu: Menu[] | undefined,
  ): boolean => {
    if (subMenu) {
      return checkIfSubMenuIsActive(subMenu);
    }

    return location.pathname.replace(id ?? '', ':id') === url;
  };

  const handleMenuClick = (url: string | undefined) => {
    if (url) {
      if (editionModeEnabled) {
        dispatch(changeEditionModeEnabled(false));
      }
      if (reviewModeEnabled) {
        dispatch(changeReviewModeEnabled(false));
      }

      navigate(url.replace(':id', id ?? ''));
    }
  };

  type ListMenuProps = {
    dept: number;
    data: Menu;
    hasSubMenu: Menu[] | undefined;
    menuName: string;
    menuIndex: number;
  };

  const showDependentVariable =
    location.pathname.includes('/projections') ||
    location.pathname.includes('variable-performance');

  const ListMenu = ({ dept, data, hasSubMenu }: ListMenuProps) => {
    const { Icon } = data;
    const isActive = handleIsActive(data?.url, data?.submenu);

    return (
      <>
        <Item
          data-testid={`li-label${data.label
            .toLocaleLowerCase()
            .replaceAll('label', '')}`}
          dept={dept}
          hasSubMenu={!!hasSubMenu}
          isToggled
          isActive={isActive}
          disabled={!!data.isDisabled}
          onClick={() => {
            if (data.isDisabled || isActive) {
              return;
            }
            handleMenuClick(data?.url);
          }}
          expanded={isExpanded}
          data-tooltip-id="menu-disabled-tooltip"
          data-tooltip-html={data.tooltip}
        >
          <span>
            {Icon && <Icon size="1.5rem" style={{ marginRight: '0.5rem' }} />}
            <p>{translate(data.label)}</p>
          </span>
        </Item>
      </>
    );
  };

  function openMenuSelectRelease() {
    setMenuReleaseVisible(true);
  }

  function closeMenuSelectRelease() {
    setMenuReleaseVisible(false);
  }

  const hasPerformance = !!releaseSelected?.performances.length;

  const workspacesSideBarResultsMenusAdjusted: Menu[] = hasPerformance
    ? workspacesSideBarResultsMenus
    : workspacesSideBarResultsMenus.map((menuAux) => ({
        ...menuAux,
        isDisabled: menuAux.label.includes('Performance'),
        tooltip: menuAux.label.includes('Performance')
          ? translate('workspaceSideBarPerformanceTooltip')
          : undefined,
      }));

  if (
    location.pathname.includes('performance') &&
    !hasPerformance &&
    workspacesSideBarResultsMenusAdjusted[0].url
  ) {
    navigate(
      workspacesSideBarResultsMenusAdjusted[0].url.replace(':id', id ?? ''),
    );
  }

  return (
    <WorkspaceSideBarContext.Provider
      value={{
        menuReleaseVisible,
        openMenuSelectRelease,
        closeMenuSelectRelease,
      }}
    >
      <WorkspaceSideBarContainer
        className="sidebar"
        data-testid="sidebar-container"
        data-cy="sidebar-container"
        expanded={isExpanded}
      >
        <ExpandSidebarButton
          expanded={isExpanded}
          onClick={() => dispatch(changeIsExpanded(!isExpanded))}
          style={{ left: isExpanded ? '20rem' : '7rem' }}
          data-testid="expand-sidebar-button"
        >
          <CaretLeft />
        </ExpandSidebarButton>

        <SidebarContent expanded={isExpanded}>
          <WorkspaceInfo workspaceId={id ?? ''} />

          <ul>
            {isExpanded && (
              <MenuSectionTitleText expanded={isExpanded}>
                {translate('workspaceSideBarResults')}
              </MenuSectionTitleText>
            )}
            {workspacesSideBarResultsMenusAdjusted?.map(
              (menu: Menu, index: number) => {
                const dept = +1;
                const menuName = `sidebar-menu-${dept}-${index}`;
                return (
                  <>
                    <ListMenu
                      dept={dept}
                      data={menu}
                      hasSubMenu={menu.submenu}
                      menuName={menuName}
                      key={menuName}
                      menuIndex={index}
                    />
                  </>
                );
              },
            )}
          </ul>

          {showDependentVariable && <DependentVariable />}
        </SidebarContent>
      </WorkspaceSideBarContainer>
      <ReleaseMenu workspaceId={id ?? ''} />
    </WorkspaceSideBarContext.Provider>
  );
};
