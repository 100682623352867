import styled from 'styled-components';

export const Container = styled.div`
  position: relative;

  width: 70rem;
  flex: 1;

  #workspace-projects-dependent-variables {
    max-width: 90vw !important;

    z-index: 10000 !important;

    word-break: break-all;

    font-size: 0.75rem;
    line-height: 150%;
  }

  .project-maintenance {
    strong {
      margin-top: 0;
    }
  }

  .projects-container {
    padding-right: 0.5rem;

    overflow-y: auto;

    > div {
      padding: 0;
    }
  }

  .projects-container,
  .project-maintenance {
    min-height: 30rem !important;
    height: 30rem !important;
  }

  .selected-project {
    border-radius: 8px;

    border: 4px solid ${({ theme }) => theme.colors.primary};

    > div:first-of-type {
      min-height: 3.813rem;

      border-radius: 4px 4px 0px 0px;
      padding: 1.313rem 0.813rem;
    }

    > div:last-of-type {
      padding: 2.25rem 0.813rem 1.313rem;
    }
  }

  @media (max-width: 1366px) {
    width: calc(100vw - 10rem);

    .projects-container,
    .project-maintenance {
      min-height: 22rem !important;
      height: 22rem !important;
    }
  }
`;

type SearchContainer = {
  error: boolean;
};

export const SearchContainer = styled.div<SearchContainer>`
  position: absolute;
  top: ${({ error }) => (error ? '0rem' : '1.35rem')};
  right: 0;
`;

export const ContentFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
