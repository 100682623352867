import { Binoculars, Target, TrendUp } from 'phosphor-react';

import { Menu } from '.';
import { ReactComponent as logo } from '../../../../../assets/logo-sidebar.svg';

export const workspacesSideBarResultsMenus: Menu[] = [
  {
    label: 'workspaceSideBarOverviewLabel',
    Icon: Binoculars,
    url: '/models/workspaces/:id/overview',
  },
  {
    label: 'labelOverallPerformance',
    Icon: TrendUp,
    url: '/models/workspaces/:id/overall-performance',
  },
  {
    label: 'workspaceSideBarProjectionsLabel',
    Icon: logo,
    url: '/models/workspaces/:id/projections',
  },
  {
    label: 'labelVariablePerformance',
    Icon: Target,
    url: '/models/workspaces/:id/variable-performance',
  },
];
