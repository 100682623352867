import React, { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ArrowUpRight,
  CaretLeft,
  Cube,
  PaperPlaneTilt,
  PencilSimple,
  Plus,
  UserCircle,
  Warning,
} from 'phosphor-react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from 'src/components/Card';
import { Head } from 'src/components/Head';
import { Button } from 'src/components/Button';
import apiWorkspace from 'src/models/service/apiWorkspace';
import { RootState } from 'src/redux/store';
import { arrayIcon, checkIconURL } from 'src/utils/icons/handleProjectIcon';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import InviteUserImg from 'src/assets/send.svg';
import { getUserColor } from 'src/utils/colors/getUserColor';
import { Select } from 'src/components/Select';
import { getTextWidth } from 'src/utils/getTextWidth';
import { queryClient } from 'src/service/queryClient';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { Tooltip } from 'react-tooltip';
import { AxiosError } from 'axios';
import {
  updateReleaseSelected,
  updateStatus,
} from 'src/models/redux/reducers/Workspace';
import { FailedModal } from 'src/components/Modal/Failed';
import { Modal } from 'src/components/Modal';
import { ModalFooter } from 'src/components/Modal/Footer/styles';
import ProcessingImg from 'src/assets/performing-our-magic.svg';
import { sleep } from 'src/utils/sleep';
import ms from 'ms';

import {
  Container,
  EmptyGuests,
  NoAccessContainer,
  ShareContainer,
  Subtitle,
  ShareSubtitleContainer,
  UserContent,
  UserInfo,
  WorkspaceInfoContainer,
  WorkspaceInfoHeader,
  ProjectListContainer,
  ProjectListContent,
  ProjectInfo,
  Footer,
  WorkspaceInfoContent,
  ContainerModal,
} from './styles';
import {
  ParamsProps,
  ProjectsResponse,
  ReleaseData,
  ReleasesResponse,
  SelectOption,
  StagingArea,
  WorkspaceData,
  WorkspaceRelease,
  WorkspaceUser,
} from './types';
import { UserRoleOptionLabel } from './components/UserRoleOptionLabel';
import { RemoveUserModal } from './components/Modal/RemoveUser';
import { ErrorModal } from './components/Modal/Error';
import { ShareWorkSpace } from './components/Modal/ShareWorkSpace';
import { useQueryWorkspaceData } from '../hooks/useQueryWorkspaceData';
import { NoPermissionToEditModal } from '../components/NoPermissionToEdit';
import { ReleaseErrorModal } from './components/Modal/ReleaseError';

export const ControlPanel: React.FC = () => {
  const [getWorkspaceInfo, setGetWorkspaceInfo] = useState(false);
  const [userUpdateError, setUserUpdateError] = useState(false);
  const [userUpdateLoading, setUserUpdateLoading] = useState(false);
  const [invitedUsers, setInvitedUsers] = useState<WorkspaceUser[]>();
  const [removeUser, setRemoveUser] = useState('');
  const [showShareWorkspaceModal, setShowShareWorkspaceModal] = useState(false);

  const [showUserIsEditingModal, setShowUserEditingModal] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loadingEditionPermission, setLoadingEditionPermission] =
    useState(false);

  const [publishLoading, setPublishLoading] = useState(false);
  const [publishSuccess, setPublishSuccess] = useState(false);
  const [publishError, setPublishError] = useState(false);
  const [publishIsPreview, setPublishIsPreview] = useState<boolean | undefined>(
    undefined,
  );

  const [currentReleaseWithError, setCurrentReleaseWithError] =
    useState<WorkspaceRelease>();

  const [showReleaseError, setShowReleaseError] = useState(false);
  const [showReleaseErrorButton, setShowReleaseErrorButton] = useState(false);

  const { id: workspaceId } = useParams<ParamsProps>();

  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userRoleOptions = [
    { label: translate('workspaceControlPanelCanView'), value: 'reader' },
    { label: translate('workspaceControlPanelCanEdit'), value: 'editor' },
    { label: translate('workspaceControlPanelCanManager'), value: 'manager' },
    { label: translate('workspaceControlPanelRemove'), value: 'remove' },
  ];

  const {
    auth: {
      user: { email },
    },
    workspace,
  } = useSelector((state: RootState) => state);

  const {
    data: stagingAreaData,
    isLoading: isLoadingStagingArea,
    isFetching: isFetchingStagingArea,
    isError: isErrorStagingArea,
  } = useQuery(
    ['workspace staging area', workspaceId],
    async () => {
      const { data } = await apiWorkspace.get<StagingArea>(
        `/workspaces/${workspaceId}/staging-area`,
      );

      return data;
    },
    {
      staleTime: ms('3 min'),
      enabled: !!workspace.id,
    },
  );

  const {
    data: userData,
    isLoading: isLoadingUser,
    isFetching: isFetchingUser,
    isError: isErrorUser,
  } = useQuery(
    ['workspace users', workspaceId],
    async () => {
      const response = await apiWorkspace.get<WorkspaceUser[]>(
        `/workspaces/${workspaceId}/users`,
      );

      return response.data;
    },
    {
      staleTime: ms('3 min'),
      enabled: !!workspace.createdBy,
      onError: ({ response }) =>
        response?.status === 403 && navigate('/models/projects'),
    },
  );

  const {
    data: releasesData,
    isLoading: isLoadingReleases,
    isFetching: isFetchingReleases,
  } = useQuery(
    ['workspace', workspaceId, 'releases'],
    async () => {
      const response = await apiWorkspace.get<ReleasesResponse>(
        `/workspaces/${workspaceId}/releases`,
      );

      return response.data;
    },
    {
      staleTime: ms('3 min'),
      enabled: !!workspaceId,
    },
  );

  const {
    data: projectsData,
    isLoading: isLoadingProjects,
    isFetching: isFetchingProjects,
    isError: isErrorProjects,
  } = useQuery(
    ['workspace staging area', workspaceId, 'projects'],
    async () => {
      const { data } = await apiWorkspace.get<ProjectsResponse>(
        `/workspaces/${workspaceId}/staging-area/projects`,
      );

      return data.projects;
    },
    {
      staleTime: ms('3 min'),
    },
  );

  const { isLoading: isLoadingWorkspace, isFetching: isFetchingWorkspace } =
    useQueryWorkspaceData(workspaceId ?? '', getWorkspaceInfo, email ?? '');

  const {
    data: currentWorkspaceData,
    isLoading: isLoadingCurrentWorkspace,
    isFetching: isFetchingCurrentWorkspace,
    isError: isErrorCurrentWorkspace,
  } = useQuery(
    ['workspace', workspaceId, 'releases', workspace.releaseCurrent],
    async () => {
      const { data } = await apiWorkspace.get<ReleaseData>(
        `/workspaces/${workspaceId}/releases/${workspace.releaseCurrent}`,
      );

      return data;
    },
    {
      staleTime: ms('3 min'),
      enabled: !!workspace.releaseCurrent,
    },
  );

  const {
    data: previewWorkspaceData,
    isLoading: isLoadingPreviewWorkspace,
    isFetching: isFetchingPreviewWorkspace,
    isError: isErrorPreviewWorkspace,
  } = useQuery(
    ['workspace', workspaceId, 'releases', workspace.releasePreview],
    async () => {
      const { data } = await apiWorkspace.get<ReleaseData>(
        `/workspaces/${workspaceId}/releases/${workspace.releasePreview}`,
      );

      return data;
    },
    {
      staleTime: ms('3 min'),
      enabled: !!workspace.releasePreview,
    },
  );

  const {
    data: releaseWithErrorData,
    isLoading: isLoadingReleaseWithError,
    isFetching: isFetchingReleaseWithError,
    isError: isErrorReleaseWithError,
  } = useQuery(
    ['workspace', workspaceId, 'releases', currentReleaseWithError?.id],
    async () => {
      const { data } = await apiWorkspace.get<ReleaseData>(
        `/workspaces/${workspaceId}/releases/${currentReleaseWithError?.id}`,
      );

      return data;
    },
    {
      staleTime: ms('3 min'),
      enabled: !!currentReleaseWithError?.id,
    },
  );

  const handleChangeUserRole = async (user: string, role: string) => {
    setUserUpdateLoading(true);
    setUserUpdateError(false);

    try {
      await apiWorkspace.put(`/workspaces/${workspaceId}/users`, {
        user,
        role,
      });

      await queryClient.invalidateQueries(['workspace users', workspaceId]);
      await queryClient.invalidateQueries(['workspaces']);

      if (email === user && role === 'reader') {
        navigate('/models/projects');
      }
    } catch {
      setUserUpdateError(true);
    }

    setUserUpdateLoading(false);
  };

  const hasMoreThanOneManager = useMemo(
    () =>
      userData &&
      userData.reduce((managerSum, user) => {
        if (user.role === 'manager') return managerSum + 1;
        return managerSum;
      }, 0) > 1,
    [userData],
  );

  const handleEditWorkspace = async () => {
    if (!loadingEditionPermission) {
      setLoadingEditionPermission(true);

      try {
        await apiWorkspace.patch(`/workspaces/${workspaceId}/edit`);

        navigate('edition');
      } catch (err) {
        const error = err as AxiosError;

        if (
          error.response?.status === 400 &&
          error.response?.data?.detail?.detail?.startsWith(
            'Workspace already locked for editing by ',
          )
        ) {
          setShowUserEditingModal(
            error.response.data.detail.detail.replace(
              'Workspace already locked for editing by ',
              '',
            ),
          );
        }
      }

      setLoadingEditionPermission(false);
    }
  };

  const handlePublishWorkspace = async (isPreview: boolean) => {
    setPublishIsPreview(isPreview);
    setPublishLoading(true);

    try {
      await apiWorkspace.post(`/workspaces/${workspaceId}/publish`, {
        preview: isPreview,
      });

      dispatch(updateStatus('publishing'));
      queryClient.invalidateQueries(['workspace', workspaceId]);
      queryClient.invalidateQueries([
        'workspace',
        'releases on sidebar',
        workspaceId,
      ]);

      if (previewDiffStaging) {
        setPublishSuccess(true);
      }
    } catch (err) {
      const error = err as AxiosError;

      const workspaceLockedMessage = 'Workspace already locked for editing by ';
      const WorkspaceIsPublishingNewVersion =
        'You cannot perform this action, Workspace is publishing a new version.';

      if (
        error.response?.status === 400 &&
        error.response?.data?.detail?.detail?.startsWith(workspaceLockedMessage)
      ) {
        const userEmailPublishing = error.response.data.detail.detail.replace(
          workspaceLockedMessage,
          '',
        );

        const errorMessageWorkspaceLocked = `${translate(
          'workspaceControlPanelPublishLocked',
        )} ${userEmailPublishing}`;

        setErrorMessage(errorMessageWorkspaceLocked);
      }

      if (
        error.response?.status === 400 &&
        error.response?.data?.detail?.detail?.startsWith(
          WorkspaceIsPublishingNewVersion,
        )
      ) {
        setErrorMessage(translate('workspaceControlPanelPublishingNewVersion'));
      }

      setPublishError(true);
    }

    setPublishLoading(false);
  };

  useEffect(() => {
    setInvitedUsers(
      userData?.filter(({ user }) => user !== workspace.createdBy),
    );
  }, [userData, workspace.createdBy]);

  useEffect(() => {
    if (workspace.userRole === 'reader') {
      navigate('/models/projects');
    }

    if (!workspace.id) {
      setGetWorkspaceInfo(true);
    }
  }, [workspace, navigate]);

  useEffect(() => {
    const checkStatus = async () => {
      let status = 'publishing';

      while (status === 'publishing') {
        try {
          const { data } = await apiWorkspace.get<WorkspaceData>(
            `/workspaces/${workspaceId}`,
          );

          status = data?.status;

          if (status !== 'publishing') {
            dispatch(updateStatus(status));

            await queryClient.invalidateQueries(['workspaces']);
          } else {
            await sleep(ms('1 min'));
          }
          // eslint-disable-next-line no-empty
        } catch {}
      }

      queryClient.removeQueries([
        ['workspace', 'releases on sidebar', workspaceId],
      ]);
      queryClient.removeQueries(['workspace', workspaceId, 'releases']);
      queryClient.removeQueries(['workspace data', workspaceId]);
      setGetWorkspaceInfo(true);

      if (!publishIsPreview) {
        queryClient.removeQueries([
          'workspace',
          workspaceId,
          'releases',
          workspace.releaseCurrent,
        ]);
      }

      queryClient.removeQueries([
        'workspace',
        workspaceId,
        'releases',
        workspace.releasePreview,
      ]);
    };

    if (workspace.status === 'publishing') {
      checkStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    workspace.status,
    dispatch,
    workspaceId,
    workspace.releaseCurrent,
    workspace.releasePreview,
  ]);

  useEffect(() => {
    if (!isLoadingReleases && !isFetchingReleases && releasesData?.records) {
      const release = releasesData.records.find(
        ({ status }) => status === 'processing',
      );

      if (release) {
        setPublishIsPreview(release.preview);
      } else if (publishIsPreview === undefined) {
        setPublishIsPreview(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [releasesData, isLoadingReleases, isFetchingReleases]);

  useEffect(() => {
    if (
      releasesData?.records?.[0]?.status === 'error' &&
      email &&
      workspaceId
    ) {
      const workspaceReleaseId = `${workspaceId}-${releasesData.records[0].id}`;

      const worskpaceErrosShowed = JSON.parse(
        localStorage.getItem('workspace-releases-errors') ?? '{}',
      );

      const worskpaceErrosShowedEmail = worskpaceErrosShowed?.[email] ?? [];

      if (!worskpaceErrosShowedEmail.includes(workspaceReleaseId)) {
        localStorage.setItem(
          'workspace-releases-errors',
          JSON.stringify({
            ...worskpaceErrosShowed,
            [email]: [workspaceReleaseId, ...worskpaceErrosShowedEmail],
          }),
        );

        setShowReleaseError(true);
      }
    }
  }, [releasesData, workspaceId, email]);

  const handleAccessWorkspace = () => {
    if (currentWorkspaceData) {
      dispatch(
        updateReleaseSelected({
          releaseSelected: currentWorkspaceData,
          ySelected: currentWorkspaceData.data.ys[0],
        }),
      );

      navigate(`/models/workspaces/${workspaceId}/overview`);
    }
  };

  const releaseCurrentEmpty = !workspace.releaseCurrent && workspace.id;
  const releasePreviewEmpty = !workspace.releasePreview && workspace.id;

  const currentWorkspaceLoading =
    isLoadingCurrentWorkspace || isFetchingCurrentWorkspace;

  const currentWorkspaceWithoutStatus = useMemo(() => {
    const updatedData: ReleaseData = JSON.parse(
      JSON.stringify(currentWorkspaceData ?? {}),
    );

    updatedData?.data?.ys.forEach((y) => {
      delete y.status;
    });

    return updatedData;
  }, [currentWorkspaceData]);

  const currentWorkspaceWithoutStatusDataAdjusted =
    currentWorkspaceWithoutStatus.data
      ? {
          ...currentWorkspaceWithoutStatus.data,
          ys: currentWorkspaceWithoutStatus.data.ys.map((y) => ({
            model_id: y.model_id,
            project_id: y.project_id,
            y: y.y,
            y_label: y.y_label,
            is_inflated: y.is_inflated,
          })),
        }
      : null;

  const stagingAreaDataDataAdjusted = stagingAreaData
    ? {
        ...stagingAreaData?.data,
        ys: stagingAreaData?.data.ys.map((y) => ({
          model_id: y.model_id,
          project_id: y.project_id,
          y: y.y,
          y_label: y.y_label,
          is_inflated: y.is_inflated,
        })),
      }
    : null;

  const currentDiffStaging =
    !isErrorCurrentWorkspace &&
    !isErrorStagingArea &&
    JSON.stringify(currentWorkspaceWithoutStatusDataAdjusted) !==
      JSON.stringify(stagingAreaDataDataAdjusted);

  const previewWorkspaceLoading =
    isLoadingPreviewWorkspace || isFetchingPreviewWorkspace;

  const previewWorkspaceDataAdjusted = previewWorkspaceData?.data
    ? {
        ...previewWorkspaceData.data,
        ys: previewWorkspaceData.data.ys.map((y) => ({
          model_id: y.model_id,
          project_id: y.project_id,
          y: y.y,
          y_label: y.y_label,
          is_inflated: y.is_inflated,
        })),
      }
    : null;

  const previewDiffStaging =
    !isErrorStagingArea &&
    !isErrorPreviewWorkspace &&
    JSON.stringify(previewWorkspaceDataAdjusted) !==
      JSON.stringify(stagingAreaDataDataAdjusted);

  const canPublish = releaseCurrentEmpty || currentDiffStaging;

  const canCreatePreview = releasePreviewEmpty || previewDiffStaging;
  const canViewPreview = !previewDiffStaging && previewWorkspaceData?.data;

  const stagingAreaLoading = isLoadingStagingArea || isFetchingStagingArea;

  useEffect(() => {
    if (releasesData?.records) {
      const releaseWithErrorIndex = releasesData.records.findIndex(
        (release) => release.status === 'error',
      );

      const releaseWithError =
        releaseWithErrorIndex === -1
          ? undefined
          : releasesData.records[releaseWithErrorIndex];

      const hasNoReleaseWithSuccessAfterError =
        releaseWithError &&
        !releasesData?.records?.find(
          (release, index) =>
            release.status === 'success' &&
            release.preview === releaseWithError.preview &&
            index < releaseWithErrorIndex,
        );

      setCurrentReleaseWithError(
        hasNoReleaseWithSuccessAfterError ? releaseWithError : undefined,
      );
    }
  }, [releasesData]);

  useEffect(() => {
    if (releaseWithErrorData && stagingAreaData) {
      const ysWithError = releaseWithErrorData.data.ys
        .filter((y) => y.status === 'error')
        .map((y) => {
          const updatedY = { ...y };

          delete updatedY.status;

          return JSON.stringify(updatedY);
        });

      let hasYWithError = false;

      stagingAreaData.data.ys.forEach((y) => {
        if (ysWithError.includes(JSON.stringify(y))) {
          hasYWithError = true;
        }
      });

      if (hasYWithError) {
        setShowReleaseErrorButton(true);
      }
    }
  }, [releaseWithErrorData, stagingAreaData]);

  return (
    <>
      <Head title={translate('workspaceControlPanelHeadTitle')} />

      <Container style={{ cursor: userUpdateLoading ? 'wait' : 'auto' }}>
        <WorkspaceInfoContainer className="containerLinear">
          <WorkspaceInfoContent>
            {(isLoadingWorkspace || isFetchingWorkspace) && !workspace.id ? (
              <WorkspaceInfoHeader data-testid="workspace-info-loading">
                <ContainerSkeleton
                  withLoading={false}
                  style={{ width: '4rem', height: '4rem' }}
                />

                <div>
                  <ContainerSkeleton
                    withLoading={false}
                    style={{
                      width: '60%',
                      height: '1.5rem',
                      marginBottom: '0.5rem',
                    }}
                  />
                  <ContainerSkeleton
                    withLoading={false}
                    style={{
                      width: '100%',
                      height: '1rem',
                    }}
                  />
                </div>
              </WorkspaceInfoHeader>
            ) : (
              <WorkspaceInfoHeader data-testid="workspace-info">
                <img
                  src={
                    workspace.icon && checkIconURL(workspace.icon)
                      ? workspace.icon
                      : arrayIcon[0]
                  }
                  alt="Workspace icon"
                  data-testid="workspace-icon"
                />

                <div>
                  <div>
                    <h2 data-testid="workspace-name">{workspace.name}</h2>
                    <Button
                      buttonType="naked"
                      icon={<PencilSimple />}
                      disabled={
                        workspace.status === undefined ||
                        workspace.status === 'publishing' ||
                        publishLoading
                      }
                      onClick={handleEditWorkspace}
                      style={
                        loadingEditionPermission
                          ? { cursor: 'wait' }
                          : undefined
                      }
                      data-testid="edit-workspace-button"
                      className="naked-button-control-panel"
                    >
                      {translate('workspaceControlPanelEdit')}
                    </Button>
                  </div>

                  <p data-testid="workspace-description">
                    {workspace.description}
                  </p>
                </div>
              </WorkspaceInfoHeader>
            )}

            <Subtitle>
              <Cube size="1rem" />
              <h3>{translate('workspaceControlPanelProjectList')}</h3>
            </Subtitle>

            <ProjectListContainer>
              {isErrorProjects ? (
                <ContainerMaintenance
                  content=""
                  data-testid="project-list-error"
                />
              ) : isLoadingProjects || isFetchingProjects || !projectsData ? (
                Array.from({ length: 3 }, (_, number) => number).map(
                  (number) => (
                    <ProjectListContent
                      key={number}
                      data-testid={`workspace-projects-loading-${number}`}
                    >
                      <ContainerSkeleton
                        withLoading={false}
                        style={{ width: '2rem', height: '2rem' }}
                      />

                      <ProjectInfo>
                        <ContainerSkeleton
                          withLoading={false}
                          style={{ width: '20%', height: '1.5rem' }}
                        />

                        <ContainerSkeleton
                          withLoading={false}
                          style={{
                            width: '30%',
                            height: '1.375rem',
                            marginTop: '0.25rem',
                          }}
                        />
                      </ProjectInfo>
                    </ProjectListContent>
                  ),
                )
              ) : (
                projectsData.map(({ project_id, icon_url, name, y_labels }) => (
                  <ProjectListContent
                    key={project_id}
                    data-testid={`project-${project_id}`}
                  >
                    <img
                      src={
                        icon_url && checkIconURL(icon_url)
                          ? icon_url
                          : arrayIcon[0]
                      }
                      alt="Project icon"
                      data-testid={`project-icon-${project_id}`}
                    />

                    <ProjectInfo>
                      <h3 data-testid={`project-name-${project_id}`}>{name}</h3>
                      <p
                        data-tooltip-html={
                          y_labels.length > 1
                            ? y_labels.slice(1).toString()
                            : ''
                        }
                        data-tooltip-id="workspace-control-panel-variable-tooltip"
                        data-testid={`project-variables-${project_id}`}
                      >
                        {y_labels[0]}{' '}
                        {y_labels.length > 1 &&
                          `+${y_labels.length - 1} ${translate('variables')}`}
                      </p>
                    </ProjectInfo>
                  </ProjectListContent>
                ))
              )}
            </ProjectListContainer>
          </WorkspaceInfoContent>

          {!!workspace.releaseCurrent && (
            <Button
              buttonType="naked"
              onClick={handleAccessWorkspace}
              data-testid="access-workspace-button"
              disabled={
                ((workspace.status === 'publishing' || publishLoading) &&
                  publishIsPreview === false) ||
                currentWorkspaceLoading ||
                isErrorCurrentWorkspace
              }
              loading={currentWorkspaceLoading}
              icon={<ArrowUpRight />}
              className="naked-button-control-panel"
              style={{
                marginTop: '1rem',
                width: 'fit-content',
                alignSelf: 'self-end',
              }}
            >
              {translate('workspaceControlPanelAccess')}
            </Button>
          )}

          <Footer>
            <Button
              buttonType="naked"
              icon={<CaretLeft />}
              onClick={() => navigate('/models/projects')}
              className="naked-button-blue"
            >
              {translate('workspaceControlPanelReturnHomePage')}
            </Button>

            <div>
              {showReleaseErrorButton && (
                <Button
                  className="warning-button"
                  buttonType="naked"
                  icon={<Warning weight="duotone" />}
                  onClick={() => setShowReleaseError(true)}
                />
              )}

              {!canViewPreview ||
              previewWorkspaceLoading ||
              stagingAreaLoading ? (
                <Button
                  buttonType="tertiary"
                  onClick={() => handlePublishWorkspace(true)}
                  disabled={
                    workspace.status === undefined ||
                    workspace.status === 'publishing' ||
                    publishLoading ||
                    stagingAreaLoading ||
                    previewWorkspaceLoading ||
                    !canCreatePreview ||
                    !currentDiffStaging ||
                    currentWorkspaceLoading
                  }
                  loading={
                    (workspace.status === 'publishing' || publishLoading) &&
                    publishIsPreview
                  }
                  data-testid="create-preview-button"
                >
                  {translate('workspaceControlPanelCreatePreview')}
                </Button>
              ) : (
                <Button
                  buttonType="tertiary"
                  onClick={() => {
                    dispatch(
                      updateReleaseSelected({
                        releaseSelected: previewWorkspaceData,
                        ySelected: previewWorkspaceData.data.ys[0],
                      }),
                    );

                    navigate(`/models/workspaces/${workspaceId}/overview`);
                  }}
                  data-testid="visualize-preview-button"
                >
                  {translate('workspaceControlPanelPreview')}
                </Button>
              )}

              <Button
                buttonType="primary"
                onClick={() => handlePublishWorkspace(false)}
                disabled={
                  workspace.status === undefined ||
                  workspace.status === 'publishing' ||
                  publishLoading ||
                  stagingAreaLoading ||
                  currentWorkspaceLoading ||
                  !canPublish
                }
                loading={
                  (workspace.status === 'publishing' || publishLoading) &&
                  publishIsPreview === false
                }
                data-testid="publish-button"
              >
                {translate('workspaceControlPanelPublish')}
              </Button>
            </div>
          </Footer>
        </WorkspaceInfoContainer>

        <ShareContainer className="containerLinear">
          <Card
            textCard={translate('workspaceControlPanelShareTitle')}
            textDescription={translate('workspaceControlPanelShareDescription')}
          />

          <ShareSubtitleContainer>
            <Subtitle>
              <UserCircle size="1.25rem" />
              <h3>{translate('workspaceControlPanelCreator')}</h3>
            </Subtitle>
          </ShareSubtitleContainer>

          {(isLoadingWorkspace || isFetchingWorkspace) &&
          !workspace.createdBy ? (
            <UserContent data-testid="workspace-owner-loading">
              <UserInfo>
                <ContainerSkeleton
                  withLoading={false}
                  style={{
                    width: '1.75rem',
                    height: '1.75rem',
                    borderRadius: '100%',
                  }}
                />

                <ContainerSkeleton
                  withLoading={false}
                  style={{
                    width: '60%',
                    height: '1rem',
                  }}
                />
              </UserInfo>
            </UserContent>
          ) : (
            <UserContent data-testid="workspace-owner">
              <UserInfo>
                <div>
                  <p data-testid="workspace-initial-owner-email">
                    {workspace?.createdBy?.[0]}
                  </p>
                </div>

                <p data-testid="workspace-owner-email">
                  {workspace?.createdBy}
                </p>
              </UserInfo>

              {userData &&
                !userData?.some(
                  ({ user }) => user === workspace?.createdBy,
                ) && (
                  <NoAccessContainer data-testid="owner-without-acess">
                    <p>
                      {translate('workspaceControlPanelOwnerWithoutAccess')}
                    </p>
                  </NoAccessContainer>
                )}
            </UserContent>
          )}

          <ShareSubtitleContainer style={{ marginTop: '2rem' }}>
            <Subtitle>
              <PaperPlaneTilt size="1rem" />
              <h3>{translate('workspaceControlPanelGuests')}</h3>
            </Subtitle>

            <Button
              buttonType="naked"
              icon={<Plus />}
              onClick={() => setShowShareWorkspaceModal(true)}
              data-testid="invite-users-button"
            >
              {translate('workspaceControlPanelInvite')}
            </Button>
          </ShareSubtitleContainer>

          {isErrorUser ? (
            <ContainerMaintenance
              content="content"
              className="container-maintenance"
              data-testid="users-error"
            />
          ) : isLoadingUser || isFetchingUser || !invitedUsers ? (
            Array.from({ length: 3 }, (_, number) => number).map((number) => (
              <UserContent
                key={number}
                data-testid={`workspace-guest-loading-${number}`}
              >
                <UserInfo>
                  <ContainerSkeleton
                    withLoading={false}
                    style={{
                      width: '1.75rem',
                      height: '1.75rem',
                      borderRadius: '100%',
                    }}
                  />

                  <ContainerSkeleton
                    withLoading={false}
                    style={{
                      width: '60%',
                      height: '1rem',
                    }}
                  />
                </UserInfo>
              </UserContent>
            ))
          ) : invitedUsers.length ? (
            invitedUsers.map(({ user, role }, index) => (
              <UserContent
                key={user}
                data-testid={`workspace-guest-${user}`}
                selectedRoleWidth={
                  getTextWidth(
                    role === 'manager'
                      ? translate('workspaceControlPanelCanManager')
                      : role === 'editor'
                      ? translate('workspaceControlPanelCanEdit')
                      : translate('workspaceControlPanelCanView'),
                    '500 14px Inter',
                  ) /
                    16 +
                  3.75
                }
                isLoading={userUpdateLoading}
              >
                <UserInfo>
                  <div style={{ background: getUserColor(index) }}>
                    <p>{user[0]}</p>
                  </div>

                  <p>{user}</p>
                </UserInfo>

                <Select
                  value={{
                    value: role,
                    label:
                      role === 'manager'
                        ? translate('workspaceControlPanelCanManager')
                        : role === 'editor'
                        ? translate('workspaceControlPanelCanEdit')
                        : translate('workspaceControlPanelCanView'),
                  }}
                  options={userRoleOptions.map((option) => {
                    const canChangeToReaderOrEdit = hasMoreThanOneManager;

                    const canRemove =
                      hasMoreThanOneManager ||
                      (!hasMoreThanOneManager && userData!.length === 1);

                    if (role !== 'manager') return option;

                    if (
                      ((option.value === 'reader' ||
                        option.value === 'editor') &&
                        !canChangeToReaderOrEdit) ||
                      (option.value === 'remove' && !canRemove)
                    ) {
                      return {
                        ...option,
                        isDisabled: true,
                      };
                    }

                    return option;
                  })}
                  menuPlacement="top"
                  isSearchable={false}
                  formatOptionLabel={({ label, value, isDisabled }: any) => {
                    let tooltipMessage: string | undefined;

                    if (isDisabled) {
                      if (value === 'remove') {
                        tooltipMessage = translate(
                          'workspaceControlPanelNoPermissionDelete',
                        );
                      } else if (value === 'reader') {
                        tooltipMessage = translate(
                          'workspaceControlPanelNoPermissionUpdateToReader',
                        );
                      } else if (value === 'editor') {
                        tooltipMessage = translate(
                          'workspaceControlPanelNoPermissionUpdateToEditor',
                        );
                      }
                    }

                    return UserRoleOptionLabel({
                      label,
                      value,
                      tooltipMessage,
                    });
                  }}
                  onChange={(option) => {
                    const value = (option as SelectOption).value;

                    if (value !== role && value !== 'remove') {
                      handleChangeUserRole(user, value);
                    }

                    if (value === 'remove') {
                      setRemoveUser(user);
                    }
                  }}
                  dataTestid={`workspace-guest-role-${user}`}
                />
              </UserContent>
            ))
          ) : (
            <EmptyGuests data-testid="empty-guests">
              <img src={InviteUserImg} alt="Invite users" />

              <h2>{translate('workspaceControlPanelInviteUser')}</h2>
              <p>{translate('workspaceControlPanelInviteUserDescription')}</p>
            </EmptyGuests>
          )}
        </ShareContainer>
      </Container>

      <Tooltip
        id="workspace-control-panel-tooltip"
        className="customTooltipTheme"
      />
      <Tooltip
        id="workspace-control-panel-variable-tooltip"
        className="customTooltipTheme customTooltipThemeMaxWidth50"
        style={{ wordBreak: 'break-all' }}
      />

      {userUpdateError && (
        <ErrorModal
          setVisible={() => setUserUpdateError(false)}
          title={translate('workspaceControlPanelUserUpdateErrorTitle')}
          description={translate(
            'workspaceControlPanelUserUpdateErrorDescription',
          )}
        />
      )}

      {!!removeUser && (
        <RemoveUserModal
          setVisible={() => setRemoveUser('')}
          workspaceId={workspaceId ?? ''}
          user={removeUser}
        />
      )}

      {showShareWorkspaceModal && (
        <ShareWorkSpace
          setVisible={() => setShowShareWorkspaceModal(false)}
          workspaceId={workspaceId ?? ''}
          users={userData?.map(({ user }) => user) ?? []}
        />
      )}

      {!!showUserIsEditingModal && (
        <NoPermissionToEditModal
          setVisible={() => setShowUserEditingModal('')}
          emailEditing={showUserIsEditingModal}
        />
      )}

      {publishError && (
        <FailedModal
          errorInfo={{
            title: translate('workspaceControlPanelPublishErrorTitle'),
            description: errorMessage,
          }}
          visible
          setVisible={setPublishError}
        />
      )}

      {showReleaseError && (
        <ReleaseErrorModal
          setVisible={() => setShowReleaseError(false)}
          isPreview={!!releasesData?.records?.[0]?.preview}
          publishedBy={releasesData?.records?.[0]?.published_by ?? ''}
          ys={
            releaseWithErrorData?.data?.ys?.filter(
              (y) => y?.status === 'error',
            ) ?? []
          }
          loadingError={
            isLoadingReleaseWithError ||
            isFetchingReleaseWithError ||
            (!releaseWithErrorData && !isErrorReleaseWithError)
          }
        />
      )}

      {publishSuccess && (
        <Modal visible setVisible={setPublishSuccess}>
          <ContainerModal data-testid="publishing-creating-preview-workspace">
            <img src={ProcessingImg} alt="performing-our-magic" />

            <h1>{translate('workspaceControlPanelPublishTitle')}</h1>
            <p>
              {publishIsPreview
                ? translate('workspaceControlPanelPreviewDescription')
                : translate('workspaceControlPanelPublishDescription')}
            </p>
          </ContainerModal>

          <ModalFooter>
            <Button
              buttonType="primary"
              onClick={() => setPublishSuccess(false)}
              data-testid="button-ok"
            >
              Ok
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};
