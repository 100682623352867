import React, { useContext, useEffect, useMemo } from 'react';

import { Card } from 'src/components/Card';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { useTranslation } from 'react-i18next';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { AxiosError } from 'axios';
import { Select } from 'src/components/Select';
import { translateSomeMessagesFromBackend } from 'src/i18n';
import {
  frequencyLatestData,
  getLatestData,
} from 'src/utils/charts/getLatestData';
import { WorkspaceProjectionsContext } from 'src/models/contexts/WorkspaceProjectionsContext';
import {
  changeWorkspaceProjectionsFrequency,
  changeWorkspaceProjectionsIsLatestDataActive,
  changeWorkspaceProjectionsExplanatoryVariable,
} from 'src/models/redux/reducers/WorkspaceProjectionsOptions';
import { SelectedFilters } from 'src/components/SelectedFilters';
import { SelectedFilterOptions } from 'src/components/SelectedFilters/types';
import apiWorkspace from 'src/models/service/apiWorkspace';
import { Tooltip } from 'react-tooltip';

import {
  ContainerChart,
  ContentSelectExplanatoryVariable,
  ExplanatoryVariableContainer,
  Info,
} from './styles';
import { ExplanatoryVariablesChart } from './ExplanatoryVariablesChart';

interface Chart {
  date: (string | number)[];
  value: number[];
  aggregation_count: number[];
}
export interface ResponseVariables {
  forecast: Chart;
  historical: Chart;
  simulated: Chart;
}

interface Error {
  detail?: {
    description?: string;
    detail?: string;
  };
}

export const ExplanatoryVariables: React.FC = () => {
  const {
    auth: { user },
    workspace,
    workspaceProjectionsOptions: {
      frequency,
      isLatestDataActive,
      transformations,
      explanatoryVariable,
      inflation,
    },
  } = useSelector((state: RootState) => state);

  const { listOfVariables, isLoadingListOfVariables, errorListOfVariables } =
    useContext(WorkspaceProjectionsContext);

  const dispatch = useDispatch();

  const { t: translate } = useTranslation();

  useEffect(() => {
    if (frequency === 'monthly' && workspace.frequency !== 'daily') {
      dispatch(changeWorkspaceProjectionsFrequency('original'));
    }
  }, [frequency, dispatch, workspace.frequency]);

  useEffect(() => {
    function setFirstOption() {
      if (listOfVariables && listOfVariables.values.length > 0) {
        if (
          !listOfVariables.values.some(
            (explanatory) => explanatory.value === explanatoryVariable ?? '',
          )
        ) {
          dispatch(
            changeWorkspaceProjectionsExplanatoryVariable(
              listOfVariables.values[0].value,
            ),
          );
        }
      } else {
        dispatch(changeWorkspaceProjectionsExplanatoryVariable(null));
      }
    }

    setFirstOption();
  }, [dispatch, listOfVariables, explanatoryVariable]);

  const {
    data: dataVariation,
    isLoading: isLoadingVariation,
    error: errorVariation,
  } = useQuery<ResponseVariables, AxiosError<Error>>(
    [
      'workspace',
      'explanatory-variables',
      workspace.id,
      workspace.releaseSelected?.id,
      workspace.ySelected?.y_label,
      workspace.ySelected?.model_id,
      explanatoryVariable,
      frequency,
      'variation',
    ],
    async () => {
      const response = await apiWorkspace.get<ResponseVariables>(
        `/workspaces/${workspace.id}/releases/${workspace.releaseSelected?.id}/ys/${workspace.ySelected?.y_label}/models/${workspace.ySelected?.model_id}/business/variables/${explanatoryVariable}?frequency=${frequency}&transformation=variation`,
      );

      const historical = response.data.historical;
      const forecast = response.data.forecast;

      if (forecast.date.length && forecast.value.length) {
        if (historical.date.length && historical.date.length) {
          forecast.date = [
            historical.date[historical.date.length - 1],
            ...forecast.date,
          ];

          forecast.value = [
            historical.value[historical.value.length - 1],
            ...forecast.value,
          ];
        }
      }

      return {
        historical,
        forecast,
        simulated: response?.data?.simulated ?? [],
      };
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled:
        !!workspace.id &&
        !!workspace.ySelected &&
        !!listOfVariables &&
        !!explanatoryVariable,
    },
  );

  const {
    data: dataLevel,
    isLoading: isLoadingLevel,
    error: errorLevel,
  } = useQuery<ResponseVariables, AxiosError<Error>>(
    [
      'workspace',
      'explanatory-variables',
      workspace.id,
      workspace.releaseSelected?.id,
      workspace.ySelected?.y_label,
      workspace.ySelected?.model_id,
      explanatoryVariable,
      frequency,
      'level',
    ],
    async () => {
      const response = await apiWorkspace.get<ResponseVariables>(
        `/workspaces/${workspace.id}/releases/${workspace.releaseSelected?.id}/ys/${workspace.ySelected?.y_label}/models/${workspace.ySelected?.model_id}/business/variables/${explanatoryVariable}?frequency=${frequency}&transformation=level`,
      );

      const historical = response.data.historical;
      const forecast = response.data.forecast;

      return {
        historical,
        forecast,
        simulated: response.data.simulated,
      };
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled:
        !!workspace.id &&
        !!workspace.ySelected &&
        !!listOfVariables &&
        !!explanatoryVariable,
    },
  );

  const isLatestDataDisabled = useMemo(() => {
    const total =
      (dataLevel?.historical.value.length ?? 0) +
      (dataLevel?.forecast.value.length ?? 0);

    if (frequency === 'original') {
      return (
        total <=
        frequencyLatestData[
          workspace.frequency as keyof typeof frequencyLatestData
        ]
      );
    }
    if (frequency === 'monthly') {
      return total <= frequencyLatestData.monthly;
    }
    if (frequency === 'quarterly') {
      return total <= frequencyLatestData.quarterly;
    }

    if (frequency === 'yearly') {
      return total <= frequencyLatestData.annual;
    }

    return true;
  }, [
    dataLevel?.forecast.value.length,
    dataLevel?.historical.value.length,
    frequency,
    workspace.frequency,
  ]);

  const error = useMemo(
    () =>
      (!!errorLevel && !!errorVariation) ||
      !!errorListOfVariables ||
      listOfVariables?.values.length === 0,
    [
      errorLevel,
      errorListOfVariables,
      errorVariation,
      listOfVariables?.values.length,
    ],
  );

  const isLoadingChart = useMemo(
    () =>
      isLoadingLevel ||
      isLoadingVariation ||
      isLoadingListOfVariables ||
      (!dataLevel && !dataVariation),
    [
      dataLevel,
      dataVariation,
      isLoadingLevel,
      isLoadingListOfVariables,
      isLoadingVariation,
    ],
  );

  useEffect(() => {
    if ((!isLoadingChart && isLatestDataDisabled) || error) {
      dispatch(changeWorkspaceProjectionsIsLatestDataActive(false));
    }
  }, [dispatch, error, isLatestDataDisabled, isLoadingChart]);

  function handleSelectOption(value: string | null) {
    dispatch(changeWorkspaceProjectionsExplanatoryVariable(value));
  }

  function returnMessageError() {
    let messageError = errorLevel?.response?.data?.detail?.detail;

    if (
      messageError ===
        'Annual series summary is only available for series with at least 1 year of observation.' ||
      messageError ===
        'Quarterly series summary is only available for series with at least 1 quarter of observation.' ||
      messageError ===
        'Monthly aggregation is not available for dataset with missing values.'
    ) {
      return messageError;
    }

    messageError =
      errorListOfVariables?.response?.data?.detail?.description ??
      errorLevel?.response?.data?.detail?.description ??
      errorVariation?.response?.data?.detail?.description;

    if (
      messageError === 'No data is available.' ||
      messageError === 'The requested resource does not exist.'
    ) {
      if (frequency === 'quarterly') {
        return 'Quarterly Series Rate is not available for this model';
      }
      if (frequency === 'yearly') {
        return 'Annual Series Rate is not available for this model';
      }
    }

    return messageError;
  }

  const { dataLevelAdjusted, dataVariationAdjusted } = useMemo(() => {
    let dataLevelAdjustedAux: ResponseVariables = {
      historical: {
        date: [],
        value: [],
        aggregation_count: [],
      },
      forecast: {
        date: [],
        value: [],
        aggregation_count: [],
      },
      simulated: {
        date: [],
        value: [],
        aggregation_count: [],
      },
    };

    let dataVariationAdjustedAux: ResponseVariables = {
      historical: {
        date: [],
        value: [],
        aggregation_count: [],
      },
      forecast: {
        date: [],
        value: [],
        aggregation_count: [],
      },
      simulated: {
        date: [],
        value: [],
        aggregation_count: [],
      },
    };

    if (!isLatestDataActive) {
      if (dataLevel) {
        dataLevelAdjustedAux = dataLevel;
      }
      if (dataVariation) {
        dataVariationAdjustedAux = dataVariation;
      }
    } else if (frequency === 'original') {
      switch (workspace.frequency) {
        case 'daily':
          dataLevelAdjustedAux = getLatestData(dataLevel, 'daily');
          dataVariationAdjustedAux = getLatestData(
            dataVariation,
            'daily',
            true,
            dataLevelAdjustedAux.historical.date[0],
          );
          break;
        case 'weekly':
          dataLevelAdjustedAux = getLatestData(dataLevel, 'weekly');
          dataVariationAdjustedAux = getLatestData(
            dataVariation,
            'weekly',
            true,
            dataLevelAdjustedAux.historical.date[0],
          );
          break;
        case 'fortnightly':
          dataLevelAdjustedAux = getLatestData(dataLevel, 'fortnightly');
          dataVariationAdjustedAux = getLatestData(
            dataVariation,
            'fortnightly',
            true,
            dataLevelAdjustedAux.historical.date[0],
          );
          break;
        case 'monthly':
          dataLevelAdjustedAux = getLatestData(dataLevel, 'monthly');
          dataVariationAdjustedAux = getLatestData(
            dataVariation,
            'monthly',
            true,
            dataLevelAdjustedAux.historical.date[0],
          );
          break;
        case 'bimonthly':
          dataLevelAdjustedAux = getLatestData(dataLevel, 'bimonthly');
          dataVariationAdjustedAux = getLatestData(
            dataVariation,
            'bimonthly',
            true,
            dataLevelAdjustedAux.historical.date[0],
          );
          break;
        case 'quarterly':
          dataLevelAdjustedAux = getLatestData(dataLevel, 'quarterly');
          dataVariationAdjustedAux = getLatestData(
            dataVariation,
            'quarterly',
            true,
            dataLevelAdjustedAux.historical.date[0],
          );
          break;
        case 'half-year':
          dataLevelAdjustedAux = getLatestData(dataLevel, 'half-year');
          dataVariationAdjustedAux = getLatestData(
            dataVariation,
            'half-year',
            true,
            dataLevelAdjustedAux.historical.date[0],
          );
          break;
        case 'annual':
          dataLevelAdjustedAux = getLatestData(dataLevel, 'annual');
          dataVariationAdjustedAux = getLatestData(
            dataVariation,
            'annual',
            true,
            dataLevelAdjustedAux.historical.date[0],
          );
          break;
        default:
          if (dataLevel) {
            dataLevelAdjustedAux = dataLevel;
          }
          if (dataVariation) {
            dataVariationAdjustedAux = dataVariation;
          }
          break;
      }
    } else if (frequency === 'monthly') {
      dataLevelAdjustedAux = getLatestData(dataLevel, 'monthly');
      dataVariationAdjustedAux = getLatestData(
        dataVariation,
        'monthly',
        true,
        dataLevelAdjustedAux.historical.date[0],
      );
    } else if (frequency === 'quarterly') {
      dataLevelAdjustedAux = getLatestData(dataLevel, 'quarterly');
      dataVariationAdjustedAux = getLatestData(
        dataVariation,
        'quarterly',
        true,
        dataLevelAdjustedAux.historical.date[0],
      );
    } else {
      dataLevelAdjustedAux = getLatestData(dataLevel, 'annual');
      dataVariationAdjustedAux = getLatestData(
        dataVariation,
        'annual',
        true,
        dataLevelAdjustedAux.historical.date[0],
      );
    }

    return {
      dataLevelAdjusted: dataLevelAdjustedAux,
      dataVariationAdjusted: dataVariationAdjustedAux,
    };
  }, [
    dataLevel,
    dataVariation,
    frequency,
    isLatestDataActive,
    workspace.frequency,
  ]);

  const options = listOfVariables
    ? listOfVariables.values.map((explanatory) => ({
        value: explanatory.value,
        label: explanatory.label[user.language],
      }))
    : [];

  const optionsSelected = listOfVariables?.values.find(
    (value) => value.value === explanatoryVariable,
  );

  const selectDisable =
    !listOfVariables ||
    isLoadingListOfVariables ||
    !!errorListOfVariables ||
    error;

  const selectedFilterInflation =
    user.language === 'pt-br' ? `Valor real` : `Real value`;

  const selectedFilters: SelectedFilterOptions[] = [
    { type: 'frequency', selected: translate(frequency) },
  ];

  const hasInflationLabel = workspace.releaseSelected?.data.ys.some(
    (y) => y.is_inflated,
  );

  hasInflationLabel &&
    selectedFilters.push({
      type: 'inflation',
      selected: selectedFilterInflation,
    });

  if (isLatestDataActive) {
    selectedFilters.push({
      type: 'latest-data',
      selected: translate('latestData'),
    });
  }

  return (
    <ExplanatoryVariableContainer className="containerLinear">
      <Card
        textCard={translate('workspaceProjectionsExplanatoryVariablesTitle')}
      />

      <Tooltip
        id="workspace-projections-explanatories-variables"
        className="customTooltipTheme"
      />

      {inflation === 'nominal' && (
        <Info
          size="1.25rem"
          data-testid="workspace-projections-explanatories-variables-tooltip"
          data-tooltip-id="workspace-projections-explanatories-variables"
          data-tooltip-html={translate(
            'workspaceProjectionsExplanatoryVariableTooltipInfo',
          )}
        />
      )}

      <SelectedFilters
        filters={selectedFilters}
        data-testid="selected-filters-explanatory-variables"
      />

      <ContentSelectExplanatoryVariable>
        <Select
          label={translate('workspaceProjectionsExplanatoryVariablesVariable')}
          options={options}
          isLoading={isLoadingListOfVariables}
          isDisabled={selectDisable}
          //@ts-expect-error:ignora
          onChange={(option) => handleSelectOption(option.value ?? null)}
          value={
            optionsSelected
              ? {
                  label: optionsSelected.label[user.language],
                  value: optionsSelected.value,
                }
              : null
          }
        />
      </ContentSelectExplanatoryVariable>

      {error ? (
        <ContainerMaintenance
          content="chart"
          text={
            listOfVariables?.explanatories.length === 0
              ? translate('workspaceProjectionsExplanatoryNoVariables')
              : translateSomeMessagesFromBackend(
                  returnMessageError() ?? '',
                  user.language,
                )
          }
          data-testid="explanatory-variables-chart-error"
          size="sm"
          style={{
            marginTop: '40px',
          }}
        />
      ) : isLoadingChart ? (
        // eslint-disable-next-line react/jsx-indent
        <ContainerSkeleton
          data-testid="explanatory-variables-loading"
          style={{
            marginTop: '40px',
            height: '400px',
          }}
        />
      ) : (
        <ContainerChart data-testid="explanatory-variables-container-chart">
          <div>
            <h4>
              {transformations.includes('level') ? translate('level') : ''}
            </h4>

            {transformations.includes('variation') && (
              <h4>{translate('variation')}</h4>
            )}
          </div>

          <ExplanatoryVariablesChart
            dataLevel={dataLevelAdjusted}
            dataVariation={dataVariationAdjusted}
          />
        </ContainerChart>
      )}
    </ExplanatoryVariableContainer>
  );
};
