import styled, { css } from 'styled-components';

export const ContainerLabel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.colors.gray5};
  margin-bottom: 0.5rem;
  label {
    font-weight: 500;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.gray5};
  }

  svg {
    color: ${({ theme }) => theme.colors.gray4};
  }
`;

type PropsContainerSelect = {
  error: boolean;
  isMulti?: boolean;
  disabled?: boolean;
};

export const ContainerSelect = styled.div<PropsContainerSelect>`
  position: relative;
  > p {
    color: ${({ theme }) => theme.colors.red2};
    line-height: 1rem;
    font-style: normal;
    font-size: 0px;
    font-size: ${({ error }) => (error ? '0.875rem' : '0')};
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    transition: margin-top 0.3s ease-in-out;
    ${({ error }) =>
      error &&
      css`
        margin-top: 0.5rem;
        font-size: 0.875rem;
        opacity: 1;
      `}
  }
  > svg {
    position: absolute;
    top: 1rem;
    right: 1rem;
    color: ${({ theme }) => theme.colors.red2};
  }

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'auto')};

  ${({ isMulti }) =>
    isMulti &&
    css`
      .css-2b097c-container {
        position: unset;
      }
      .select__control {
        max-height: 19.75rem;
        overflow-y: auto;
      }
      /* .select__control {
        > div:nth-child(2) {
          align-self: baseline !important;
          position: sticky !important;
          top: calc(50% - 16px) !important;
        }
      } */
    `}
  //React-Select
    .select__indicator-separator {
    // sepadador lado esquerdo da arrow bottom |
    display: none;
  }
  .css-2b097c-container {
    // Container do select
    width: 100%;
  }
  .select__placeholder {
    color: ${({ theme }) => theme.colors.gray4} !important;
  }
  .select__control {
    ///container do select
    width: 100%;
    border-radius: 8px;
    border: ${({ theme, error }) =>
      `1px solid ${error ? theme.colors.red2 : theme.colors.gray2}`};
  }
  .select__control:hover {
    //container select selecionado
    border: ${({ theme, error }) =>
      `1px solid ${error ? theme.colors.red2 : theme.colors.gray3}`};
  }
  .select__single-value {
    color: ${({ theme }) => theme.colors.gray6};
  }
  .select__single-value--is-disabled {
    color: ${({ theme }) => theme.colors.gray4};
  }
  .select__menu {
    ///options
    z-index: 5;
    font-weight: 400 !important;
    font-size: 0.875rem !important;
    color: ${({ theme }) => theme.colors.gray5};
    .select__option {
      /// div contendo o texto
      color: ${({ theme }) => theme.colors.gray5};
      padding: 0.75rem 1rem;
      background: transparent;

      &:not(.select__option--is-disabled) {
        cursor: pointer !important;
      }
    }
    .select__option:hover {
      ///div contendo texto com hover
      background: ${({ theme }) => theme.colors.gray1};
    }
  }
  .select__indicator {
    //Arrow bottom do select quando nao esta com as options aberta
    cursor: pointer;
    > svg {
      stroke-width: 0px;
      color: ${({ theme }) => theme.colors.gray4};
    }
    ${({ error }) =>
      error &&
      css`
        margin-right: 2.25rem;
      `}
  }
  .select__control--is-focused {
    .select__indicator {
      //Arrow bottom do select quando esta com as options aberta
      > svg {
        transform: rotate(180deg) !important;
      }
    }
  }
  .select__value-container {
    //container contendo o input de pesquisa
    cursor: text !important;
    color: ${({ theme }) => theme.colors.gray6} !important;
    font-weight: 400 !important;
    font-size: 0.875rem !important;
    padding: 0.75rem 1rem !important;
    .select__input {
      color: ${({ theme }) => theme.colors.gray6};
      padding: 0;
      margin: 0;
    }
  }
  .select__multi-value {
    ///container multivalorado
    border-radius: 16px;
    background: rgba(242, 125, 180, 0.12);
    padding: 0.25rem 0.5rem;
    margin-right: 0.5rem;
    .select__multi-value__remove {
      color: ${({ theme }) => theme.colors.secondary};
      cursor: pointer;
      transition: color 0.2s;
      &:hover {
        background: unset;
        color: ${({ theme }) => theme.colors.secondary};
      }
    }
    .select__multi-value__label {
      font-size: 0.75rem;
      color: ${({ theme }) => theme.colors.secondary};
    }
  }
  .select__control {
    transition: border 0.2s;
  }
  .select__control--is-focused {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.primary};
    border-color: ${({ theme }) => theme.colors.primary};
  }
  .select__option--is-selected {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.primary} !important;
    background-color: ${({ theme }) => `${theme.colors.primary}14`} !important;
  }

  .select__option--is-disabled {
    color: ${({ theme }) => theme.colors.gray4} !important;
  }

  .select__indicator.select__dropdown-indicator {
    padding-right: 1rem;
  }
`;
