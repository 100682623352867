import React, { useContext, useEffect, useState } from 'react';

import { ArrowLeft, CaretRight, Circle, X } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import {
  setBreadcumbs,
  setCategories,
  setCategoriesData,
  setPage,
  setSearch,
} from 'src/feature-store/redux/reducers/IndicatorsPage';
import api from 'src/feature-store/service/api';
import { RootState } from 'src/redux/store';

import { FeatureStoreSidebarContext } from '../../../Contexts/NavigationContext';
import {
  CategoriesContainer,
  CleanFiltersButton,
  ContainerWithoutCategories,
  IndicatorsSearch,
  NavigationContainer,
  SelectedCategory,
  SubCategory,
} from './styles';

interface Category {
  id: string;
  node: string;
  name: {
    'en-us': string;
    'pt-br': string;
  };
  children: Category[];
  length: number;
}

interface CategoriesProps {
  tree: Category[];
}

export const Categories: React.FC = () => {
  const [country, setCountry] = useState('');

  const { breadcumbs, categories } = useSelector(
    (state: RootState) => state.indicatorsPage,
  );

  const { language } = useSelector((state: RootState) => state.auth.user);
  const { indicatorFiltersVisible, setterIndicatorFilterType } = useContext(
    FeatureStoreSidebarContext,
  );

  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  const {
    data: categoriesData,
    isLoading: isLoadingCategories,
    isError: isCategoriesErrored,
  } = useQuery(['categories'], async () => {
    const { data } = await api.get<CategoriesProps>('/domains');

    return data.tree;
  });

  useEffect(() => {
    dispatch(setCategoriesData(categoriesData));

    const initialCategory = categoriesData?.find(
      (category) => category.name['en-us'] === 'Brazil',
    );

    !country && setCountry(initialCategory?.id ?? '');
  }, [categoriesData, country, dispatch]);

  useEffect(() => {
    if (categoriesData && !categories.length && breadcumbs.length === 0) {
      const initialCategory = categoriesData?.find(
        (category) => category.name['en-us'] === 'Brazil',
      );
      dispatch(setCategories(initialCategory?.children ?? categoriesData));
      initialCategory &&
        dispatch(
          setBreadcumbs([
            ...breadcumbs,
            {
              category_id: initialCategory.id,
              category_node: initialCategory.node,
              category_name: initialCategory.name,
            },
          ]),
        );
    }
  }, [
    dispatch,
    categoriesData,
    categories.length,
    breadcumbs.length,
    breadcumbs,
  ]);

  useEffect(() => {
    breadcumbs && setCountry(breadcumbs[0]?.category_id ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleSelectCategory(category: Category) {
    dispatch(setSearch(''));
    dispatch(setPage(1));

    dispatch(setCategories(category.children));

    dispatch(
      setBreadcumbs([
        ...breadcumbs,
        {
          category_id: category.id,
          category_node: category.node,
          category_name: category.name,
        },
      ]),
    );
  }

  function handleBacktoUpperCategory() {
    dispatch(setSearch(''));
    if (categoriesData) {
      let categoriesAux = categoriesData;
      for (let i = 0; i <= breadcumbs.length - 1; i++) {
        if (i < breadcumbs.length - 1) {
          categoriesAux =
            categoriesAux.find(
              (category) => category.node === breadcumbs[i].category_node,
            )?.children ?? categoriesAux;
        }
      }
      const newBreadcumbs = breadcumbs.slice(0, breadcumbs.length - 1);
      dispatch(setBreadcumbs(newBreadcumbs));
      dispatch(setCategories(categoriesAux));
    }
  }

  function handleClearFilters() {
    dispatch(setSearch(''));
    const initialCategory = categoriesData?.find(
      (category) => category.name['en-us'] === 'Brazil',
    );

    initialCategory &&
      dispatch(
        setBreadcumbs([
          {
            category_id: initialCategory.id,
            category_node: initialCategory.node,
            category_name: initialCategory.name,
          },
        ]),
      );
    initialCategory &&
      (dispatch(setCategories(initialCategory.children)),
      setCountry(initialCategory.id));
  }

  return (
    <IndicatorsSearch data-testid="container-categories">
      <CategoriesContainer>
        <div>
          {breadcumbs.length > 1 && (
            <NavigationContainer>
              <SelectedCategory
                data-testid="button-return-category"
                data-cy="button-return-category"
                onClick={handleBacktoUpperCategory}
                disabled={breadcumbs.length === 1}
              >
                <>
                  <div>
                    <ArrowLeft size="1rem" />
                  </div>
                  <div>
                    {breadcumbs[breadcumbs.length - 1].category_name[
                      language
                    ] ??
                      breadcumbs[breadcumbs.length - 1].category_name['en-us']}
                  </div>
                </>
              </SelectedCategory>
              <CleanFiltersButton
                data-testid="button-clean-filters"
                data-cy="button-clean-filters"
                onClick={() => {
                  handleClearFilters();
                  setterIndicatorFilterType(null);
                }}
              >
                <div>
                  <X size="1rem" />
                </div>
                <p>{translate('indicatorsQuickSearchClearFilters')}</p>
              </CleanFiltersButton>
            </NavigationContainer>
          )}
        </div>
        {isCategoriesErrored || categories === null ? (
          <ContainerWithoutCategories>
            <p>{translate('indicatorsQuickSearchUnableLoadCategories')}</p>
          </ContainerWithoutCategories>
        ) : isLoadingCategories && indicatorFiltersVisible ? (
          <>
            {Array.from({ length: 10 }).map((_, index) => (
              <ContainerSkeleton
                key={`${index + 1}`}
                withLoading={false}
                style={{
                  width: `${Math.random() * (180 - 100) + 100}px`,
                  height: '1.5rem',
                  marginTop: '1.5rem',
                }}
              />
            ))}
          </>
        ) : categories.length > 0 ? (
          <>
            {categories.map((category) => (
              <SubCategory
                key={category.id}
                data-testid={`sub-category-${category.node
                  .toLocaleLowerCase()
                  .replaceAll(' ', '-')}`}
                data-cy={`sub-category-${category.node
                  .toLocaleLowerCase()
                  .replaceAll(' ', '-')}`}
                onClick={() => handleSelectCategory(category)}
                category={
                  breadcumbs[1]?.category_name['en-us']?.toLowerCase() ??
                  category.name['en-us'].toLowerCase()
                }
              >
                <div>
                  <Circle size="0.5rem" weight="fill" />
                  <p>{category.name[language] ?? category.name['en-us']} </p>
                </div>
                <div>
                  <CaretRight size="1rem" />
                </div>
              </SubCategory>
            ))}
          </>
        ) : (
          categories.length === 0 && (
            <ContainerWithoutCategories>
              <p>{translate('indicatorsQuickSearchNoSubCategories')}</p>
            </ContainerWithoutCategories>
          )
        )}
      </CategoriesContainer>
    </IndicatorsSearch>
  );
};
