import React, { useEffect, useState } from 'react';

import { format, isMatch, parse } from 'date-fns';
import { ArrowRight, Info, MagnifyingGlass } from 'phosphor-react';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import ComputerSearch from 'src/assets/computer_search.svg';
import LockSimple from 'src/assets/LockSimple.svg';
import logoWhite from 'src/assets/logo_without_text_white.svg';
import Star from 'src/assets/star-yellow.svg';
import { Button } from 'src/components/Button';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { Input } from 'src/components/Input';
import { Modal } from 'src/components/Modal';
import { ModalFooter } from 'src/components/Modal/Footer/styles';
import { Pagination } from 'src/components/Pagination';
import { Table, Tbody, Td, Th, Thead, Tr } from 'src/components/Table';
import api from 'src/feature-store/service/api';
import { useFormatDateLanguage } from 'src/hooks/useFormatDateLanguage';
import { RootState } from 'src/redux/store';
import apiv2 from 'src/service/api';
import { dateToIsoStringWithTimezone } from 'src/utils/dates/dateToIsoStringWithTimezone';

import { FormUpload, ResponseFeatureStore } from '../../types';
import { GroupCard } from './GroupCards';
import { CheckBoxFeatureStore } from './GroupCards/styles';
import { ModalSeriesGroup } from './ModalSeriesGroup';
import { DivLock, PremiumDiv } from './ModalSeriesGroup/styles';
import {
  ColumnProbability,
  Container,
  ContainerPickForMe,
  ContainerPickForMeStepOne,
  ContainerPickForMeStepThree,
  ContainerPickForMeStepTwo,
  ContainerWithoutGroups,
  Content,
  DescriptionSearch,
  DivInfoSeriesPremium,
  DivPagination,
  GroupCardLoading,
  GroupsContainer,
  MessageDisable,
  ModifiedTabs,
  NameColumnPickForMe,
  SearchDiv,
} from './styles';
import {
  Group,
  GroupData,
  GroupFeatureStore,
  GroupFeatureStoreWithDummy,
  PickForMeError,
  ResponseGroup,
  ResponsePickForMe,
  ResponseSeries,
  SerieCheckedWithDummy,
  SeriePickForMe,
  SeriesCheckedProps,
  SeriesFeatureStore,
} from './types';

interface FeatureStoreProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
  datasetId: string;
  getValues: UseFormGetValues<FormUpload>;
  setValue: UseFormSetValue<any> | undefined;
  setSeriesFeatureStore: (value: SeriesFeatureStore) => void;
  seriesFeatureStore: SeriesFeatureStore;
  groupFeatureStore: GroupFeatureStore[];
  setGroupFeatureStore: (value: GroupFeatureStore[]) => void;
  setLagVariablesDisabled: (value: boolean) => void;
  setResponseIntegrationFS: (value: ResponseFeatureStore) => void;
  dummiesFromFS: string[];
  setDummiesFromFS: (value: string[]) => void;
}

export interface GroupSelectedProps {
  id: string;
  name: string;
}

const QUANTITY_ITEMS_PAGE = 6;

const FeatureStore: React.FC<FeatureStoreProps> = ({
  visible,
  setVisible,
  getValues,
  setValue,
  datasetId,
  setSeriesFeatureStore,
  seriesFeatureStore,
  groupFeatureStore,
  setGroupFeatureStore,
  setLagVariablesDisabled,
  setResponseIntegrationFS,
  dummiesFromFS,
  setDummiesFromFS,
}) => {
  const [myGroupsPage, setMyGroupsPage] = useState(1);
  const [predefinedGroupsPage, setPredefinedGroupsPage] = useState(1);
  const [tabSelected, setTabSelected] = useState<string>();
  const [groupChecked, setGroupChecked] = useState<
    GroupFeatureStoreWithDummy[]
  >([]);
  const [disableButtonCancel, setDisableButtonCancel] =
    useState<boolean>(false);

  const [totalPaginationMyGroups, setTotalPaginationMyGroups] =
    useState<number>(1);
  const [totalPaginationPredefined, setTotalPaginationPredefined] =
    useState<number>(1);

  const [stepPickForMe, setStepPickForMe] = useState(1);
  const [seriesCheckedPickForMe, setSeriesCheckedPickForMe] = useState<
    SerieCheckedWithDummy[]
  >([]);

  const [visibleModalSeriesGroup, setVisibleModalSeriesGroup] =
    useState<boolean>(false);
  const [groupSelected, setGroupSelected] = useState<Group>();

  const [searchValue, setSearchValue] = useState<string>('');
  const [searchError, setSearchError] = useState<string>('');
  const [searchTimer, setSearchTimer] = useState(1000);
  const [timeOutActive, setTimeOutActive] = useState(false);
  const [requestAllowed, setRequestAllowed] = useState(true);
  const [lastSearch, setLastSearch] = useState('');
  const [myGroupData, setMyGroupData] = useState<GroupData>();
  const [predefinedData, setPredefinedData] = useState<GroupData>();
  const [notAccessible, setNotAccessible] = useState<boolean>(false);

  const { t: translate } = useTranslation();

  const language = useSelector((state: RootState) => state.auth.user.language);
  const translateFormat = useFormatDateLanguage();

  const {
    data: myGroupsData,
    isFetching: isMyGroupsFetching,
    isLoading: isMyGroupsLoading,
    isError: isMyGroupsErrored,
  } = useQuery<GroupData>(
    ['my groups page modal feature store in faas', myGroupsPage, searchValue],
    async () => {
      let searchQuery = searchValue;
      if (isMatch(searchValue, translateFormat)) {
        searchQuery = format(
          parse(searchValue, translateFormat, new Date()),
          'yyyy-MM-dd',
        );
      }

      const { data } = await apiv2.get<ResponseGroup>(
        searchValue.length >= 3
          ? isMatch(searchQuery, 'yyyy-MM-dd')
            ? `/featurestore/groups/users?group_name=${encodeURIComponent(
                searchValue,
              )}&last_updated=${dateToIsoStringWithTimezone(
                new Date(`${searchQuery}T00:00`),
              )}&created_at=${dateToIsoStringWithTimezone(
                new Date(`${searchQuery}T00:00`),
              )}&skip=${
                (myGroupsPage - 1) * QUANTITY_ITEMS_PAGE
              }&limit=${QUANTITY_ITEMS_PAGE}`
            : `/featurestore/groups/users?group_name=${encodeURIComponent(
                searchValue,
              )}&skip=${
                (myGroupsPage - 1) * QUANTITY_ITEMS_PAGE
              }&limit=${QUANTITY_ITEMS_PAGE}`
          : `/featurestore/groups/users?skip=${
              (myGroupsPage - 1) * QUANTITY_ITEMS_PAGE
            }&limit=${QUANTITY_ITEMS_PAGE}`,
      );

      setTotalPaginationMyGroups(data.total);

      const groupData: GroupData = {
        total: data.total,
        limit: data.limit,
        data: [],
      };

      for (let i = 0; i < data.data.length; i++) {
        const { data: dataSerie } = await api.get<ResponseSeries>(
          `/groups/${data.data[i].id}/series?limit=1000`,
        );

        groupData.data.push({
          ...data.data[i],
          series: dataSerie.data,
        });
      }

      return groupData;
    },
    {
      enabled: requestAllowed || searchValue === '',
      staleTime: 1000 * 60,
      onSettled: () => {
        setRequestAllowed(false);
        setTimeOutActive(false);
        setSearchTimer(1000);
        setLastSearch(searchValue);
      },
    },
  );

  const {
    data: predefinedGroupsData,
    isFetching: isPredefinedGroupsFetching,
    isLoading: isPredefinedGroupsLoading,
    isError: isPredefinedGroupsErrored,
  } = useQuery<GroupData>(
    [
      'pre-defined groups page modal feature store in faas',
      predefinedGroupsPage,
      searchValue,
    ],
    async () => {
      let searchQuery = searchValue;
      if (isMatch(searchValue, translateFormat)) {
        searchQuery = format(
          parse(searchValue, translateFormat, new Date()),
          'yyyy-MM-dd',
        );
      }

      const { data } = await apiv2.get<ResponseGroup>(
        searchValue.length >= 3
          ? isMatch(searchQuery, 'yyyy-MM-dd')
            ? `/featurestore/groups/predefineds?category=model&group_name=${encodeURIComponent(
                searchValue,
              )}&last_updated=${dateToIsoStringWithTimezone(
                new Date(`${searchQuery}T00:00`),
              )}&created_at=${dateToIsoStringWithTimezone(
                new Date(`${searchQuery}T00:00`),
              )}&skip=${
                (myGroupsPage - 1) * QUANTITY_ITEMS_PAGE
              }&limit=${QUANTITY_ITEMS_PAGE}`
            : `/featurestore/groups/predefineds?category=model&group_name=${encodeURIComponent(
                searchValue,
              )}&skip=${
                (predefinedGroupsPage - 1) * QUANTITY_ITEMS_PAGE
              }&limit=${QUANTITY_ITEMS_PAGE}`
          : `/featurestore/groups/predefineds?category=model&skip=${
              (predefinedGroupsPage - 1) * QUANTITY_ITEMS_PAGE
            }&limit=${QUANTITY_ITEMS_PAGE}`,
      );

      setTotalPaginationPredefined(data.total);

      const groupData: GroupData = {
        total: data.total,
        limit: data.limit,
        data: [],
      };

      for (let i = 0; i < data.data.length; i++) {
        const { data: dataSerie } = await api.get<ResponseSeries>(
          `/groups/${data.data[i].id}/series?limit=1000`,
        );

        groupData.data.push({
          ...data.data[i],
          series: dataSerie.data,
        });
      }

      return groupData;
    },
    {
      enabled: requestAllowed || searchValue === '',
      staleTime: 1000 * 60,

      onSettled: () => {
        setRequestAllowed(false);
        setTimeOutActive(false);
        setSearchTimer(1000);
        setLastSearch(searchValue);
      },
    },
  );

  const {
    data: dataPickForMe,
    isError: isErrorPickForMe,
    error: errorPickForMe,
  } = useQuery<SeriePickForMe[], PickForMeError>(
    [
      'pickForMe',
      datasetId,
      getValues('dependVariable'),
      getValues('dateTimeFeature'),
    ],
    async () => {
      const allData: SeriePickForMe[] = [];

      const { data } = await apiv2.post<ResponsePickForMe>(
        `/${datasetId}/featurestore/series/recommended?skip=0&limit=100`,
        {
          var_y: getValues('dependVariable'),
          date_var: getValues('dateTimeFeature'),
          date_format: getValues('format') || undefined,
        },
      );

      allData.push(...data.data);

      for (let i = data.limit; i < data.total; i += 100) {
        const { data: dataAux } = await api.get<ResponsePickForMe>(
          `/${datasetId}/featurestore/series/recommended?skip=${i}&limit=100`,
        );

        allData.push(...dataAux.data);
      }

      return allData;
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled: stepPickForMe !== 1,
    },
  );

  useEffect(() => {
    if (dataPickForMe) {
      setStepPickForMe(3);
      setNotAccessible(
        dataPickForMe.some(
          (dataAccessible) => dataAccessible.is_accessible === false,
        ),
      );
      return;
    }
    if (isErrorPickForMe) {
      setStepPickForMe(3);
    }
  }, [dataPickForMe, isErrorPickForMe]);

  useEffect(() => {
    if (predefinedGroupsData) {
      setPredefinedData(predefinedGroupsData);
    }
  }, [predefinedGroupsData]);

  useEffect(() => {
    if (myGroupsData) {
      setMyGroupData(myGroupsData);
    }
  }, [myGroupsData]);

  useEffect(() => {
    if (timeOutActive && searchValue.length >= 3) {
      setTimeout(() => {
        if (searchTimer > 0) {
          setSearchTimer(searchTimer - 1000);
        } else {
          setTimeOutActive(false);
        }
      }, 1000);
    } else {
      searchTimer === 0 && setRequestAllowed(true);
    }
  }, [searchTimer, searchValue, timeOutActive]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchValue.length < 3 && searchValue.length > 0) {
        setSearchError('errorMessageInput3Caracteres');
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [searchValue.length, translate]);

  const handleSearchGroup = (value: string) => {
    setSearchValue(value);

    if (value.length > 30) {
      setSearchError('errorMessageInputMax30Caracteres');
      return;
    }

    if (
      value.length < lastSearch.length &&
      value.length < 3 &&
      value.length > 0
    ) {
      setSearchError('errorMessageInput3Caracteres');

      return;
    }

    setSearchError('');

    if (value !== searchValue) {
      setSearchTimer(1000);
      setTimeOutActive(true);

      if (tabSelected === translate('preDefinedTitleTab')) {
        value.length >= 3 && setPredefinedGroupsPage(1);
      } else if (tabSelected === translate('myGroupTitleTab')) {
        value.length >= 3 && setMyGroupsPage(1);
      }
    }
  };

  function handleChangePages(selectedPage: number) {
    if (tabSelected === translate('preDefinedTitleTab')) {
      setPredefinedGroupsPage(selectedPage);
      setRequestAllowed(true);
    } else {
      setMyGroupsPage(selectedPage);
      setRequestAllowed(true);
    }
  }

  const handleSendVariables = async () => {
    setDisableButtonCancel(true);

    const seriesCheckedGroup: SerieCheckedWithDummy[] = [];

    groupChecked.forEach((groupCheckedAux) => {
      groupCheckedAux.series.forEach((serie) => {
        if (
          !seriesCheckedGroup.some(
            (serieCheckedAux) =>
              serieCheckedAux.code === serie.code &&
              serieCheckedAux.tag === serie.tag,
          )
        ) {
          seriesCheckedGroup.push(serie);
        }
      });
    });

    const junctionSeriesWithoutRepetition: SerieCheckedWithDummy[] = [];

    junctionSeriesWithoutRepetition.push(...seriesCheckedGroup);

    seriesCheckedPickForMe.forEach((serieCheckedPickForMe) => {
      if (
        !junctionSeriesWithoutRepetition.some(
          (junctionSerie) =>
            junctionSerie.code === serieCheckedPickForMe.code &&
            junctionSerie.tag === serieCheckedPickForMe.tag,
        )
      ) {
        junctionSeriesWithoutRepetition.push(serieCheckedPickForMe);
      }
    });

    const sendVariablesName: string[] = [];
    try {
      const { data } = await apiv2.post(`/${datasetId}/featurestore/series`, {
        series: junctionSeriesWithoutRepetition,
      });

      if (data && junctionSeriesWithoutRepetition?.length !== 0) {
        setResponseIntegrationFS(data);
      }

      if (data?.status?.type === 'success') {
        junctionSeriesWithoutRepetition?.forEach((series: any) =>
          sendVariablesName.push(series.tag),
        );

        //update dummies variables Begin
        const dummiesFromFSAux = [...dummiesFromFS];

        junctionSeriesWithoutRepetition.forEach((junctionSerie) => {
          if (
            junctionSerie.isDummy &&
            !dummiesFromFSAux.includes(junctionSerie.tag)
          ) {
            dummiesFromFSAux.push(junctionSerie.tag);
          }
        });

        setDummiesFromFS(dummiesFromFSAux);
        // update dummies variables end

        if (setSeriesFeatureStore && setValue) {
          const oldExplanatoryVariables = getValues('explanatoryVariables');

          const newsExplanatoryVariables = oldExplanatoryVariables.concat(
            sendVariablesName.filter(
              (variable) => !oldExplanatoryVariables.includes(variable),
            ),
          );

          const newsSeriesFeatureStore = sendVariablesName.filter(
            (variable) =>
              !seriesFeatureStore.junctionWithoutRepetition.some(
                (serieFS) => serieFS.tag === variable,
              ),
          );

          const seriesGroup = [
            ...seriesFeatureStore.seriesGroup,
            ...seriesCheckedGroup,
          ];
          const seriesPickForMe = [
            ...seriesFeatureStore.seriesPickForMe,
            ...seriesCheckedPickForMe,
          ];
          const junctionWithoutRepetition: SeriesCheckedProps[] = [];

          junctionWithoutRepetition.push(...seriesGroup);

          seriesPickForMe.forEach((seriePickForMe) => {
            if (
              !junctionWithoutRepetition.some(
                (junctionSerie) =>
                  junctionSerie.code === seriePickForMe.code &&
                  junctionSerie.tag === seriePickForMe.tag,
              )
            ) {
              junctionWithoutRepetition.push(seriePickForMe);
            }
          });

          setSeriesFeatureStore({
            seriesGroup,
            seriesPickForMe,
            junctionWithoutRepetition,
          });

          const groupsFSAux = [...groupFeatureStore];

          groupChecked.forEach((groupCheckedAux) => {
            const indexGroup = groupsFSAux.findIndex(
              (groupFSAux) => groupFSAux.id === groupCheckedAux.id,
            );
            if (indexGroup !== -1) {
              groupCheckedAux.series.forEach((serie) => {
                if (
                  !groupsFSAux[indexGroup].series.some(
                    (serieAlreadyIntegrated) =>
                      serieAlreadyIntegrated.code === serie.code &&
                      serieAlreadyIntegrated.tag === serie.tag,
                  )
                ) {
                  groupsFSAux[indexGroup].series.push(serie);
                }
              });
            } else {
              groupsFSAux.push(groupCheckedAux);
            }
          });

          setGroupFeatureStore(groupsFSAux);

          setValue('explanatoryVariables', newsExplanatoryVariables);
          newsSeriesFeatureStore.length > 0 && setLagVariablesDisabled(false);
        }

        setVisible(false);
        setDisableButtonCancel(false);
      }
    } catch (err) {
      setResponseIntegrationFS({
        status: {
          type: translate('requestFailed'),
          text: translate('errorToSendVariablesMessage'),
        },
      });
      setDisableButtonCancel(false);
    }
  };

  const handleClickGroup = async (group: Group) => {
    setVisibleModalSeriesGroup(true);
    setGroupSelected(group);
  };

  const selectVariablePickForMe = (
    code: string,
    tag: string,
    isDummy: boolean,
  ) => {
    if (
      !seriesCheckedPickForMe.some(
        (serieCheckedPickForMe) => serieCheckedPickForMe.code === code,
      )
    ) {
      setSeriesCheckedPickForMe([
        ...seriesCheckedPickForMe,
        {
          code,
          tag,
          isDummy,
        },
      ]);
    } else {
      setSeriesCheckedPickForMe(
        seriesCheckedPickForMe.filter(
          (serieCheckedPickForMe) => serieCheckedPickForMe.code !== code,
        ),
      );
    }
  };

  const checkIfTheCheckBoxIsCheckedGroup = (idGroup: string) => {
    if (!myGroupData?.data || !predefinedGroupsData?.data) {
      return false;
    }

    const joinMyGroupsWithPreDefined = [
      ...myGroupData.data,
      ...predefinedGroupsData.data,
    ];

    const seriesJoinFilterMonthly = joinMyGroupsWithPreDefined
      .find((joinGroupAux) => joinGroupAux.id === idGroup)
      ?.series.filter((serie) =>
        serie.aggregation['en-us'].includes('Monthly'),
      );

    const groupFS = groupFeatureStore.find(
      (groupFSAux) => groupFSAux.id === idGroup,
    );

    if (groupFS?.series.length === seriesJoinFilterMonthly?.length) {
      return true;
    }

    const groupCheckedFind = groupChecked.find(
      (groupCheckedAux) => groupCheckedAux.id === idGroup,
    );

    if (!groupCheckedFind) {
      return false;
    }

    const joinGroupFSWithGroupCheckedAux: GroupFeatureStore = {
      id: groupCheckedFind.id,
      series: groupCheckedFind.series,
    };

    groupFS?.series.forEach((serie) => {
      if (
        !joinGroupFSWithGroupCheckedAux.series.some(
          (serieJoin) =>
            serieJoin.code === serie.code && serieJoin.tag && serie.tag,
        )
      ) {
        joinGroupFSWithGroupCheckedAux.series.push(serie);
      }
    });

    if (
      joinGroupFSWithGroupCheckedAux.series.length ===
      seriesJoinFilterMonthly?.length
    ) {
      return true;
    }

    if (groupCheckedFind.series.length === seriesJoinFilterMonthly?.length) {
      return true;
    }

    return false;
  };

  const checkIfTheGroupAlreadyIsInFaas = (idGroup: string) => {
    const groupFS = groupFeatureStore.find(
      (groupFSAux) => groupFSAux.id === idGroup,
    );

    if (!groupFS) {
      return false;
    }

    if (!myGroupData?.data || !predefinedGroupsData?.data) {
      return false;
    }

    const joinMyGroupsWithPreDefined = [
      ...myGroupData.data,
      ...predefinedGroupsData.data,
    ];

    const seriesJoinFilterMonthly = joinMyGroupsWithPreDefined
      .find((joinGroupAux) => joinGroupAux.id === idGroup)
      ?.series.filter((serie) =>
        serie.aggregation['en-us'].includes('Monthly'),
      );

    if (groupFS.series.length === seriesJoinFilterMonthly?.length) {
      return true;
    }

    return false;
  };

  const selectOrDeselectAllSeriesPickForMe = (): void => {
    if (!dataPickForMe) {
      return;
    }

    const seriesNotSelected: SerieCheckedWithDummy[] = dataPickForMe
      .filter(
        (seriePickForMe) =>
          !seriesFeatureStore.seriesPickForMe.some(
            (serieFS) =>
              serieFS.code === seriePickForMe.code &&
              serieFS.tag === seriePickForMe.tag,
          ) &&
          !seriesCheckedPickForMe.some(
            (serieCheckedPickForMe) =>
              serieCheckedPickForMe.code === seriePickForMe.code &&
              serieCheckedPickForMe.tag === seriePickForMe.tag,
          ) &&
          seriePickForMe.is_accessible,
      )
      .map((serie) => ({
        code: serie.code,
        tag: serie.tag,
        isDummy: serie.is_dummy,
      }));

    if (seriesNotSelected.length) {
      setSeriesCheckedPickForMe([
        ...seriesCheckedPickForMe,
        ...seriesNotSelected,
      ]);
    } else {
      setSeriesCheckedPickForMe([]);
    }
  };

  const checkIfAllCheckboxesAreCheckedPickForMe = (): boolean => {
    if (!dataPickForMe) {
      return false;
    }

    const allSerieCheckPickForMe = [
      ...seriesFeatureStore.seriesPickForMe,
      ...seriesCheckedPickForMe,
    ];

    return dataPickForMe.every((seriePickForMe) =>
      allSerieCheckPickForMe.some(
        (serieCheckedPickForMe) =>
          seriePickForMe.code === serieCheckedPickForMe.code &&
          seriePickForMe.tag === serieCheckedPickForMe.tag,
      ),
    );
  };

  const checkIfTheCheckBoxIsCheckedPickForMe = (
    code: string,
    tag: string,
  ): boolean => {
    if (
      seriesFeatureStore.seriesPickForMe.some(
        (serieFS) => serieFS.code === code && serieFS.tag === tag,
      )
    ) {
      return true;
    }
    if (
      seriesCheckedPickForMe.some(
        (serieCheckedPickForMe) =>
          serieCheckedPickForMe.code === code &&
          serieCheckedPickForMe.tag === tag,
      )
    ) {
      return true;
    }
    return false;
  };

  const checkIfTheSerieAlreadyIsInFaasPickForMe = (
    code: string,
    tag: string,
  ): boolean => {
    if (
      seriesFeatureStore.seriesPickForMe.some(
        (serieFS) => serieFS.code === code && serieFS.tag === tag,
      )
    ) {
      return true;
    }
    return false;
  };

  const checkIfAllSeriesAlreadyAreInFaaSPickForMe = (): boolean => {
    if (!dataPickForMe) {
      return false;
    }

    return dataPickForMe.every((seriePickForMe) =>
      seriesFeatureStore.seriesPickForMe.some(
        (serieFS) =>
          seriePickForMe.code === serieFS.code &&
          seriePickForMe.tag === serieFS.tag,
      ),
    );
  };

  return (
    <>
      <Modal visible={visible} setVisible={setVisible}>
        <Container data-testid="container-feature-store">
          <Content>
            <DescriptionSearch>
              <div>
                <h3>{translate('featureStoreIntegrationTitle')}</h3>
                <p>{translate('featureStoreIntegrationTitleDescription')}</p>
                {notAccessible &&
                  stepPickForMe === 3 &&
                  tabSelected === 'Pick4me' && (
                    <DivInfoSeriesPremium>
                      <p>
                        {translate('someVariables')}
                        <img
                          src={Star}
                          alt="star-icon"
                          data-testid="star-icon"
                        />
                        <span>Premium</span>{' '}
                        {translate('messageAboutSeriePremium')}
                      </p>
                    </DivInfoSeriesPremium>
                  )}
              </div>
              {tabSelected !== 'Pick4me' && (
                <SearchDiv>
                  <Input
                    icon={<MagnifyingGlass size="1.25rem" />}
                    testid="input-search-group"
                    onChange={({ target: { value } }) =>
                      handleSearchGroup(value)
                    }
                    placeholder={translate('placeholderInputSearchGroup')}
                    style={{ maxWidth: '17.5rem' }}
                    value={searchValue}
                    error={translate(searchError)}
                    className={
                      translate(searchError)
                        ? 'input-search-error'
                        : 'input-search'
                    }
                  />
                </SearchDiv>
              )}
            </DescriptionSearch>
            <ModifiedTabs
              getSelectedTab={(tabName: string) => {
                setTabSelected(tabName);
                setSearchValue('');
              }}
              style={{ marginTop: '0.5rem' }}
              data={[
                {
                  name: translate('pick4MeTitleTab'),
                  render: () => (
                    <ContainerPickForMe>
                      {stepPickForMe === 1 ? (
                        <ContainerPickForMeStepOne>
                          <img
                            src={ComputerSearch}
                            alt="computer_search_image"
                          />
                          <p>{translate('pick4Me1StepTitle')}</p>
                          <span>{translate('pick4Me1StepDescription')}</span>
                          <button
                            type="button"
                            onClick={() => {
                              setStepPickForMe(2);
                            }}
                            data-testid="button-start-pick-for-me"
                            data-cy="button-start-pick-for-me"
                          >
                            <img
                              src={logoWhite}
                              alt="logoWhite"
                              style={{
                                width: '2.938rem',
                                height: '3rem',
                              }}
                            />
                            {translate('pick4Me1StepButton')}
                          </button>
                        </ContainerPickForMeStepOne>
                      ) : stepPickForMe === 2 ? (
                        <ContainerPickForMeStepTwo>
                          <ContainerSkeleton />
                          <p>{translate('pick4Me2StepTitle')}</p>
                          <span>{translate('pick4Me2StepDescription')}</span>
                        </ContainerPickForMeStepTwo>
                      ) : isErrorPickForMe || !dataPickForMe ? (
                        <ContainerMaintenance
                          content="table"
                          text={
                            errorPickForMe?.response?.data?.detail?.detail ??
                            translate('pick4MeErrorRecommend')
                          }
                          data-testid="container-error-pick-for-me"
                          style={{ height: '100%' }}
                        />
                      ) : (
                        <ContainerPickForMeStepThree>
                          <Tooltip
                            id="modelling-time-series-fs-pick4me"
                            className="customTooltipTheme"
                          />
                          <Table>
                            <Thead>
                              <Tr>
                                <Th style={{ opacity: 1 }}>
                                  <NameColumnPickForMe isTitle>
                                    <CheckBoxFeatureStore
                                      label={translate(
                                        'FeatureStoreIntegrationColumnName',
                                      )}
                                      onChange={() =>
                                        selectOrDeselectAllSeriesPickForMe()
                                      }
                                      checked={checkIfAllCheckboxesAreCheckedPickForMe()}
                                      disabled={checkIfAllSeriesAlreadyAreInFaaSPickForMe()}
                                    />
                                  </NameColumnPickForMe>
                                </Th>

                                <Th>
                                  {translate(
                                    'FeatureStoreIntegrationColumnSource',
                                  )}
                                </Th>
                                <Th>
                                  {translate(
                                    'FeatureStoreIntegrationColumnLastUpdated',
                                  )}
                                </Th>
                                <Th>
                                  {translate(
                                    'FeatureStoreIntegrationColumnStartDate',
                                  )}
                                </Th>
                                <Th>
                                  {translate(
                                    'FeatureStoreIntegrationColumnEndDate',
                                  )}
                                </Th>
                                <Th>
                                  {translate(
                                    'FeatureStoreIntegrationColumnRegion',
                                  )}
                                </Th>
                                <Th>
                                  {translate(
                                    'FeatureStoreIntegrationColumnPrimary',
                                  )}
                                </Th>
                                <Th>
                                  {translate(
                                    'FeatureStoreIntegrationColumnSecondary',
                                  )}
                                </Th>
                                <Th>
                                  <ColumnProbability>
                                    {translate('pick4Me3StepColumnProbability')}
                                    <Info
                                      size="1.125rem"
                                      data-tooltip-id="modelling-time-series-fs-pick4me"
                                      data-tooltip-html={translate(
                                        'pick4Me3StepColumnProbabilityInformation',
                                      )}
                                      data-cy="information-probability"
                                      data-testid="information-probability"
                                    />
                                  </ColumnProbability>
                                </Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {dataPickForMe.map((seriePickForMe) => (
                                // eslint-disable-next-line react/jsx-indent
                                <Tr
                                  key={`tr-pickforme-${seriePickForMe.code}`}
                                  disabled={
                                    seriePickForMe.is_accessible === false
                                  }
                                >
                                  {seriePickForMe.is_accessible ? (
                                    <Td>
                                      <NameColumnPickForMe>
                                        <CheckBoxFeatureStore
                                          label={
                                            seriePickForMe.name[language] ??
                                            seriePickForMe.name['en-us']
                                          }
                                          onChange={() =>
                                            selectVariablePickForMe(
                                              seriePickForMe.code,
                                              seriePickForMe.tag,
                                              seriePickForMe.is_dummy,
                                            )
                                          }
                                          checked={checkIfTheCheckBoxIsCheckedPickForMe(
                                            seriePickForMe.code,
                                            seriePickForMe.tag,
                                          )}
                                          disabled={checkIfTheSerieAlreadyIsInFaasPickForMe(
                                            seriePickForMe.code,
                                            seriePickForMe.tag,
                                          )}
                                        />
                                      </NameColumnPickForMe>
                                    </Td>
                                  ) : (
                                    <DivLock>
                                      <img
                                        src={LockSimple}
                                        alt="serie-locked"
                                        data-testid="serie-locked"
                                        style={{
                                          width: '1.063rem',
                                          height: '1.063rem',
                                        }}
                                      />
                                      <p>
                                        {seriePickForMe.name[language] ??
                                          seriePickForMe.name['en-us']}
                                      </p>
                                    </DivLock>
                                  )}
                                  <Td>{seriePickForMe.source}</Td>
                                  <Td>
                                    {format(
                                      new Date(seriePickForMe.last_updated),
                                      'MM/yyyy',
                                    )}
                                  </Td>
                                  <Td>
                                    {format(
                                      new Date(seriePickForMe.date_start),
                                      'MM/yyyy',
                                    )}
                                  </Td>
                                  <Td>
                                    {format(
                                      new Date(seriePickForMe.date_end),
                                      'MM/yyyy',
                                    )}
                                  </Td>
                                  <Td>
                                    {seriePickForMe.region[language] ??
                                      seriePickForMe.region['en-us']}
                                  </Td>
                                  <Td>
                                    {seriePickForMe.primary_transformation[
                                      language
                                    ] ??
                                      seriePickForMe.primary_transformation[
                                        'en-us'
                                      ]}
                                  </Td>
                                  <Td>
                                    {seriePickForMe.second_transformation[
                                      language
                                    ] ??
                                      seriePickForMe.second_transformation[
                                        'en-us'
                                      ]}
                                  </Td>
                                  {seriePickForMe.access_type === 'premium' ? (
                                    <PremiumDiv>
                                      <Td
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                        }}
                                      >
                                        {`${(
                                          seriePickForMe.probability * 100
                                        ).toFixed(2)}%`}
                                        <img
                                          src={Star}
                                          alt="star-icon"
                                          data-testid="star-icon"
                                          style={{ marginLeft: '0.625rem' }}
                                        />
                                      </Td>
                                    </PremiumDiv>
                                  ) : (
                                    <Td style={{ paddingLeft: '1.25rem' }}>
                                      {`${(
                                        seriePickForMe.probability * 100
                                      ).toFixed(2)}%`}
                                    </Td>
                                  )}
                                </Tr>
                              ))}
                            </Tbody>
                          </Table>
                        </ContainerPickForMeStepThree>
                      )}
                    </ContainerPickForMe>
                  ),
                },
                {
                  name: translate('preDefinedTitleTab'),
                  render: () => (
                    <>
                      {isPredefinedGroupsErrored ? (
                        <ContainerWithoutGroups>
                          <ContainerMaintenance
                            content="data"
                            text={translate('errorLoadPreDefinedGroups')}
                          />
                        </ContainerWithoutGroups>
                      ) : isPredefinedGroupsFetching ||
                        isPredefinedGroupsLoading ? (
                        // eslint-disable-next-line react/jsx-indent
                        <>
                          <GroupsContainer>
                            {Array.from({ length: QUANTITY_ITEMS_PAGE }).map(
                              (_, index) => (
                                <GroupCardLoading
                                  key={`group-loading-${index + 1}`}
                                  data-testid={`group-loading-${index + 1}`}
                                >
                                  <ContainerSkeleton
                                    withLoading={false}
                                    style={{
                                      width: '2.5rem',
                                      height: '2.5rem',
                                      borderRadius: '4px',
                                    }}
                                  />
                                  <span>{translate('nameGroup')}</span>
                                  <ContainerSkeleton
                                    withLoading={false}
                                    style={{
                                      width: '9.375rem',
                                      height: '1.25rem',
                                      marginTop: '0.5rem',
                                    }}
                                  />
                                  <span>{translate('createdAtGroup')}</span>
                                  <ContainerSkeleton
                                    withLoading={false}
                                    style={{
                                      width: '9.625rem',
                                      height: '1.25rem',
                                      marginTop: '0.5rem',
                                    }}
                                  />
                                  <span>{translate('lastUpdatedGroup')}</span>
                                  <ContainerSkeleton
                                    withLoading={false}
                                    style={{
                                      width: '9.625rem',
                                      height: '1.25rem',
                                      marginTop: '0.5rem',
                                    }}
                                  />
                                </GroupCardLoading>
                              ),
                            )}
                          </GroupsContainer>
                          <MessageDisable>
                            <p>{translate('messageInformsIncompatible')}</p>
                          </MessageDisable>
                          {totalPaginationPredefined !== 0 && (
                            <DivPagination>
                              <Pagination
                                total={totalPaginationPredefined}
                                page={predefinedGroupsPage}
                                setPage={handleChangePages}
                                quantityItemsPerPage={QUANTITY_ITEMS_PAGE}
                                firstPageHaveDifferentElement
                                name={translate('textGroups')}
                              />
                            </DivPagination>
                          )}
                        </>
                      ) : predefinedData && predefinedData.total > 0 ? (
                        // eslint-disable-next-line react/jsx-indent
                        <>
                          <GroupsContainer>
                            {predefinedData?.data?.map((group) => (
                              <GroupCard
                                group={group}
                                key={`${group.id} ${group.name}`}
                                groupChecked={groupChecked}
                                setGroupChecked={setGroupChecked}
                                onClick={() =>
                                  group.frequencies.includes('Monthly') &&
                                  handleClickGroup(group)
                                }
                                disabled={
                                  !group.frequencies.includes('Monthly')
                                }
                                checkboxChecked={checkIfTheCheckBoxIsCheckedGroup(
                                  group.id,
                                )}
                                checkboxDisabled={checkIfTheGroupAlreadyIsInFaas(
                                  group.id,
                                )}
                              />
                            ))}
                          </GroupsContainer>
                          {predefinedData?.data?.some(
                            (group) => !group.frequencies.includes('Monthly'),
                          ) && (
                            <MessageDisable
                              data-testid="message-frequency"
                              data-cy="message-frequency"
                            >
                              <p>{translate('messageInformsIncompatible')}</p>
                            </MessageDisable>
                          )}
                          {predefinedData?.total && (
                            <DivPagination>
                              <Pagination
                                total={predefinedData?.total}
                                page={predefinedGroupsPage}
                                setPage={handleChangePages}
                                quantityItemsPerPage={QUANTITY_ITEMS_PAGE}
                                firstPageHaveDifferentElement
                                name={translate('textGroups')}
                              />
                            </DivPagination>
                          )}
                        </>
                      ) : predefinedData?.total === 0 ? (
                        <ContainerWithoutGroups>
                          <ContainerMaintenance
                            content="group"
                            text={`${`${translate(
                              'errorMessageFindAnyGroup',
                            )}"${lastSearch}`}".`}
                          />
                        </ContainerWithoutGroups>
                      ) : (
                        <ContainerWithoutGroups>
                          <ContainerMaintenance
                            content="group"
                            text={translate('noGroupsFound')}
                          />
                        </ContainerWithoutGroups>
                      )}
                    </>
                  ),
                },
                {
                  name: translate('myGroupTitleTab'),
                  render: () => (
                    <>
                      {isMyGroupsErrored ? (
                        <ContainerWithoutGroups>
                          <ContainerMaintenance
                            content="data"
                            text={translate('errorLoadMyGroups')}
                          />
                        </ContainerWithoutGroups>
                      ) : isMyGroupsFetching || isMyGroupsLoading ? (
                        <>
                          <GroupsContainer>
                            {Array.from({ length: QUANTITY_ITEMS_PAGE }).map(
                              (_, index) => (
                                <GroupCardLoading
                                  key={`group-loading-${index + 1}`}
                                  data-testid={`group-loading-${index + 1}`}
                                >
                                  <ContainerSkeleton
                                    withLoading={false}
                                    style={{
                                      width: '2.5rem',
                                      height: '2.5rem',
                                      borderRadius: '4px',
                                    }}
                                  />
                                  <span>{translate('nameGroup')}</span>
                                  <ContainerSkeleton
                                    withLoading={false}
                                    style={{
                                      width: '9.375rem',
                                      height: '1.25rem',
                                      marginTop: '0.5rem',
                                    }}
                                  />
                                  <span>{translate('createdAtGroup')}</span>
                                  <ContainerSkeleton
                                    withLoading={false}
                                    style={{
                                      width: '9.625rem',
                                      height: '1.25rem',
                                      marginTop: '0.5rem',
                                    }}
                                  />
                                  <span>{translate('lastUpdatedGroup')}</span>
                                  <ContainerSkeleton
                                    withLoading={false}
                                    style={{
                                      width: '9.625rem',
                                      height: '1.25rem',
                                      marginTop: '0.5rem',
                                    }}
                                  />
                                </GroupCardLoading>
                              ),
                            )}
                          </GroupsContainer>
                          <MessageDisable>
                            <p>{translate('messageInformsIncompatible')}</p>
                          </MessageDisable>

                          {totalPaginationMyGroups !== 0 && (
                            <DivPagination>
                              <Pagination
                                total={totalPaginationMyGroups}
                                page={myGroupsPage}
                                setPage={handleChangePages}
                                quantityItemsPerPage={QUANTITY_ITEMS_PAGE}
                                firstPageHaveDifferentElement
                                name={translate('textGroups')}
                              />
                            </DivPagination>
                          )}
                        </>
                      ) : myGroupData && myGroupData.total > 0 ? (
                        <>
                          <GroupsContainer>
                            {myGroupData?.data?.map((group) => (
                              <GroupCard
                                key={`${group.id} ${group.name}`}
                                group={group}
                                groupChecked={groupChecked}
                                setGroupChecked={setGroupChecked}
                                onClick={() =>
                                  group.frequencies.includes('Monthly') &&
                                  handleClickGroup(group)
                                }
                                disabled={
                                  !group.frequencies.includes('Monthly')
                                }
                                checkboxChecked={checkIfTheCheckBoxIsCheckedGroup(
                                  group.id,
                                )}
                                checkboxDisabled={checkIfTheGroupAlreadyIsInFaas(
                                  group.id,
                                )}
                              />
                            ))}
                          </GroupsContainer>
                          {myGroupData?.data?.some(
                            (group) => !group.frequencies.includes('Monthly'),
                          ) && (
                            <MessageDisable>
                              <p>{translate('messageInformsIncompatible')}</p>
                            </MessageDisable>
                          )}
                          {myGroupData?.total && (
                            <DivPagination>
                              <Pagination
                                total={myGroupData?.total}
                                page={myGroupsPage}
                                setPage={handleChangePages}
                                quantityItemsPerPage={QUANTITY_ITEMS_PAGE}
                                firstPageHaveDifferentElement
                                name={translate('textGroups')}
                              />
                            </DivPagination>
                          )}
                        </>
                      ) : myGroupData?.total === 0 ? (
                        <ContainerWithoutGroups>
                          <ContainerMaintenance
                            content="group"
                            text={`${`${translate(
                              'errorMessageFindAnyGroup',
                            )}"${lastSearch}`}".`}
                          />
                        </ContainerWithoutGroups>
                      ) : (
                        <ContainerWithoutGroups>
                          <ContainerMaintenance
                            content="group"
                            text={translate('noGroupsFound')}
                          />
                        </ContainerWithoutGroups>
                      )}
                    </>
                  ),
                },
              ]}
            />
          </Content>
          <ModalFooter>
            <Button
              buttonType="naked"
              onClick={() => {
                setVisible(false);
              }}
              data-testid="button-back"
              disabled={disableButtonCancel}
            >
              {translate('textButtonCancel')}
            </Button>
            <Button
              buttonType="primary"
              style={{
                width: '11.25rem',
                transition: 'none',
              }}
              icon={<ArrowRight size="1.125rem" />}
              onClick={handleSendVariables}
              data-testid="button-add"
              disabled={
                groupChecked.flatMap(
                  (groupCheckedAux) => groupCheckedAux.series,
                ).length === 0 && seriesCheckedPickForMe.length === 0
              }
            >
              {translate('textButtonSendToFaas')}
            </Button>
          </ModalFooter>
        </Container>
      </Modal>

      {visibleModalSeriesGroup && groupSelected && (
        <ModalSeriesGroup
          visible={visibleModalSeriesGroup}
          closeModals={() => {
            setVisibleModalSeriesGroup(false);
            setVisible(false);
          }}
          setVisible={setVisibleModalSeriesGroup}
          group={groupSelected}
          groupChecked={groupChecked}
          setGroupChecked={setGroupChecked}
          groupFeatureStore={groupFeatureStore}
          handleSendVariables={handleSendVariables}
          disableButtonCancel={disableButtonCancel}
          checkIfTheCheckBoxIsCheckedGroup={checkIfTheCheckBoxIsCheckedGroup}
          checkIfTheGroupAlreadyIsInFaas={checkIfTheGroupAlreadyIsInFaas}
        />
      )}
    </>
  );
};

export default FeatureStore;
