const MyProjectsPTBRTranslations = {
  projectsHeadTitle: 'Models',
  myProjectsTitleHeader: 'Meus Projetos',
  createProject: 'Criar novo projeto',
  createProjSubTitle: 'Traga seus dados para criar um novo projeto',
  createProjectButton: 'Criar projeto',
  myWorks: 'Meus Trabalhos',
  myProjects: 'Meus Projetos',
  myWorksSubtitle: 'Lista de todos os trabalhos criados e compartilhados',
  projectHeadDependVar: 'Variável dependente',
  projectHeadLastUpdated: 'Última atualização',
  searchProject: 'Procurar projeto',
  paginationText: 'projetos',
  projectTooltip: 'variáveis',
  fetchProjectsError: 'Não foi possível carregar os projetos',
  fetchWorkspacesError: 'Não foi possível carregar os workspaces',
  searchProjectFail: 'Não foi possível encontrar nenhum projeto contendo',
  downloadError: `Ocorreu um erro ao baixar o arquivo.`,
  myProjectErrorNoProjectFound: 'Nenhum projeto encontrado, comece criando um.',
  workspaceErrorNoWorkspaceFound:
    'Nenhum workspace encontrado, comece criando um.',
  myProjectDoNotHasPermissionToCreateProject:
    'Desculpe, você só tem acesso a projetos de estimação de demanda. Para poder criar projetos entre em contato com nossa equipe de vendas.',
  myProjectsDownloadProject: 'Fazendo download...',
  myProjectsDownloadUpdate: '(última atualização)',
  myProjectsCreateWorkspace: 'Criar workspace',
  myProjectsSearchWorkspace: 'Procurar workspace',
  myProjectsDoNotHasPermissionToCreateWorkspace:
    'Desculpe, você só tem acesso a workspaces. Para criar workspaces, entre em contato com nossa equipe de vendas.',
  myProjectsWorkspaceControl: 'Painel de controle',

  workspaceCreateNewProjectToStart:
    'Crie um novo projeto para começar a explorar a criação de workspaces.',
};

export default MyProjectsPTBRTranslations;
