import React, { useEffect } from 'react';

import { WorkspaceProjectionsProvider } from 'src/models/contexts/WorkspaceProjectionsContext';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { RootState } from 'src/redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { ContainerLoadingStatusSuccess } from 'src/models/TimeSeries/AISelection/styles';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import apiWorkspace from 'src/models/service/apiWorkspace';
import { queryClient } from 'src/service/queryClient';
import { sleep } from 'src/utils/sleep';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { FiltersSidebar } from 'src/components/FiltersSidebar';
import {
  WorkspaceProjectionsForecastType,
  WorkspaceProjectionsFrequency,
  WorkspaceProjectionsInflation,
  WorkspaceProjectionsTransformation,
  changeWorkspaceProjectionsForecastType,
  changeWorkspaceProjectionsFrequency,
  changeWorkspaceProjectionsInflation,
  changeWorkspaceProjectionsIsLatestDataActive,
  changeWorkspaceProjectionsTransformations,
} from 'src/models/redux/reducers/WorkspaceProjectionsOptions';
import { CheckboxFilterOptions } from 'src/components/FiltersSidebar/CheckboxFilter/types';
import { RadioButtonOptions } from 'src/components/FiltersSidebar/RadioButtonFilter/types';
import { Head } from 'src/components/Head';
import ms from 'ms';
import { Question } from 'phosphor-react';
import {
  Calendar,
  Checkbox,
  Radio,
  Toggle,
} from 'src/components/FiltersSidebar/types';

import { Container } from './styles';
import { Results } from './components/Results';
import { PotentialImpacts } from './components/PotentialImpacts';
import { AnnualSummary } from './components/AnnualSummary';
import { ExplanatoryVariables } from './components/ExplanatoryVariables';
import { StatusResponse } from './types';
import { Export } from './components/Export';
import { useQueryReleaseData } from '../hooks/useQueryReleaseData';

export const WorkspaceProjections: React.FC = () => {
  const {
    workspace,
    workspaceProjectionsOptions: {
      frequency,
      inflation,
      transformations,
      forecastType,
      isLatestDataActive,
      transformationsEnabled,
      latestDataEnabled,
      loadingLevelData,
      loadingVariationData,
    },
  } = useSelector((state: RootState) => state);

  const dispatch = useDispatch();

  const { t: translate } = useTranslation();

  const { data, isLoading, isFetching, isError } = useQuery(
    [
      'workspace',
      'projections',
      'status',
      workspace.id,
      workspace.releaseSelected?.id,
      workspace.ySelected?.y_label,
      workspace.ySelected?.model_id,
    ],
    async () => {
      const result = await apiWorkspace.get<StatusResponse>(
        `/workspaces/${workspace.id}/releases/${workspace.releaseSelected?.id}/ys/${workspace.ySelected?.y_label}/models/${workspace.ySelected?.model_id}/business/status`,
      );

      return result.data.status;
    },
    {
      staleTime: ms('1 min'),
      enabled:
        !!workspace.id && !!workspace.releaseSelected && !!workspace.ySelected,
    },
  );

  const { data: releaseData, isLoading: releaseIsLoading } =
    useQueryReleaseData(workspace?.id, workspace?.releaseSelected?.id);

  useEffect(() => {
    const checkStatus = async () => {
      if (
        workspace.id &&
        !isLoading &&
        !isFetching &&
        data !== 'success' &&
        !isError
      ) {
        await sleep(ms('1 min'));

        queryClient.removeQueries([
          'workspace',
          'projections',
          'status',
          workspace.id,
          workspace.releaseSelected?.id,
          workspace.ySelected?.y_label,
          workspace.ySelected?.model_id,
        ]);
      }
    };

    checkStatus();
  }, [workspace, isLoading, isFetching, isError, data]);

  const handleSelectFrequency = (
    frequencyAux: WorkspaceProjectionsFrequency,
  ) => {
    if (frequency !== frequencyAux) {
      dispatch(changeWorkspaceProjectionsFrequency(frequencyAux));
    }
  };

  const handleSelectInflation = (
    inflationAux: WorkspaceProjectionsInflation,
  ) => {
    if (inflation !== inflationAux) {
      dispatch(changeWorkspaceProjectionsInflation(inflationAux));
    }
  };

  const handleSelectTransformation = (
    transformationAux: WorkspaceProjectionsTransformation,
  ) => {
    if (transformations.includes(transformationAux)) {
      dispatch(
        changeWorkspaceProjectionsTransformations(
          transformations.filter(
            (transformation) => transformation !== transformationAux,
          ),
        ),
      );

      return;
    }

    dispatch(
      changeWorkspaceProjectionsTransformations([
        ...transformations,
        transformationAux,
      ]),
    );
  };

  const handleChangeForecastOption = (
    option: WorkspaceProjectionsForecastType,
  ) => {
    if (option !== forecastType) {
      dispatch(changeWorkspaceProjectionsForecastType(option));
    }
  };

  function handleActiveLatestData(value: boolean) {
    dispatch(changeWorkspaceProjectionsIsLatestDataActive(value));
  }

  useEffect(() => {
    if (frequency === 'monthly' && workspace.frequency !== 'daily') {
      handleSelectFrequency('original');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [frequency, workspace.frequency]);

  const canSelectYearly = !['yearly', 'annual'].includes(
    workspace.frequency ?? '',
  );

  const canSelectQuarterly = ![
    'bimonthly',
    'quarterly',
    'half-year',
    'yearly',
    'annual',
  ].includes(workspace.frequency ?? '');

  const frequencyOptions: RadioButtonOptions[] = [
    {
      label: translate('original'),
      value: 'original',
      checked: frequency === 'original',
    },
  ];

  if (canSelectQuarterly) {
    frequencyOptions.push({
      label: translate('quarterly'),
      value: 'quarterly',
      checked: frequency === 'quarterly',
    });
  }

  if (canSelectYearly) {
    frequencyOptions.push({
      label: translate('annual'),
      value: 'yearly',
      checked: frequency === 'yearly',
    });
  }

  const transformationOptions: CheckboxFilterOptions[] = [
    {
      label: translate('level'),
      value: 'level',
      checked: transformations.includes('level'),
      disabled:
        (transformations.includes('level') && transformations.length === 1) ||
        !transformationsEnabled.includes('level') ||
        loadingLevelData,
      loading: loadingLevelData,
    },
    {
      label: translate('variation'),
      value: 'variation',
      checked: transformations.includes('variation'),
      disabled:
        (transformations.includes('variation') &&
          transformations.length === 1) ||
        !transformationsEnabled.includes('variation') ||
        loadingVariationData,
      tooltipInfo:
        !transformationsEnabled.includes('variation') && !loadingVariationData
          ? translate('workspaceProjectionsNotEnoughObservationsVariation')
          : undefined,
      loading: loadingVariationData,
    },
  ];

  const inflationOptions: RadioButtonOptions[] = [
    {
      label: 'Real',
      value: 'real',
      checked: inflation === 'real',
    },
    {
      label: 'Nominal',
      value: 'nominal',
      checked: inflation === 'nominal',
      disabled: !workspace.ySelected?.is_inflated,
    },
  ];

  const forecastOptions: RadioButtonOptions[] = [
    {
      label: translate('original'),
      value: 'original',
      checked: forecastType === 'original',
    },
    {
      label: translate('workspaceProjectionsMostRecent'),
      value: 'adjusted',
      checked: forecastType === 'adjusted',
    },
  ];

  const canSelectAdjustedData =
    (releaseData?.data.steps &&
      (releaseData?.data.steps?.length > 1 ||
        releaseData?.data.steps?.[0].status !== 'baseline')) ||
    !releaseData?.data.approval_flow?.enable;

  forecastOptions[1].disabled = !canSelectAdjustedData;

  if (!releaseIsLoading && !canSelectAdjustedData) {
    dispatch(changeWorkspaceProjectionsForecastType('original'));
  }

  useEffect(() => {
    if (workspace.ySelected) {
      if (
        workspace.ySelected.is_inflated === false &&
        inflation === 'nominal'
      ) {
        dispatch(changeWorkspaceProjectionsInflation('real'));
      }
    }
  }, [dispatch, inflation, workspace.ySelected]);

  const filters: Array<Toggle | Radio | Checkbox | Calendar> = [
    {
      type: 'radio',
      name: translate('frequency'),
      options: frequencyOptions,
      onChange: (value) =>
        handleSelectFrequency(value as WorkspaceProjectionsFrequency),
    },
    {
      type: 'checkbox',
      name: translate('transformation'),
      options: transformationOptions,
      onChange: (value) =>
        handleSelectTransformation(value as WorkspaceProjectionsTransformation),
    },
    {
      type: 'radio',
      name: translate('value'),
      tooltip: {
        icon: Question,
        info: translate('workspaceProjectionsRadioInflationValueTooltip'),
      },
      options: inflationOptions,
      onChange: (value) =>
        handleSelectInflation(value as WorkspaceProjectionsInflation),
    },
  ];

  if (
    workspace.frequency === 'monthly' &&
    workspace.releaseSelected?.id !== workspace.releasePreview
  ) {
    filters.push({
      type: 'radio',
      name: translate('projection'),
      options: forecastOptions,
      onChange: (value) =>
        handleChangeForecastOption(value as WorkspaceProjectionsForecastType),
    });
  }

  filters.push({
    type: 'toggle',
    label: translate('latestData'),
    checked: isLatestDataActive,
    onChange: (e) => handleActiveLatestData(e.target.checked),
    disabled: !latestDataEnabled,
    loading: loadingLevelData || loadingVariationData,
  });

  return (
    <WorkspaceProjectionsProvider>
      <Container>
        <Head title={translate('workspaceSideBarProjectionsLabel')} />

        {isError || data === 'error' ? (
          <ContainerLoadingStatusSuccess className="containerLinear">
            <ContainerMaintenance
              content=""
              data-testid="projections-status-error"
            />
          </ContainerLoadingStatusSuccess>
        ) : data !== 'success' ? (
          <ContainerLoadingStatusSuccess
            className="containerLinear"
            data-testid="loading-processing-projections"
          >
            <ContainerSkeleton style={{ height: '6.25rem' }} />
            <h4>{translate('workspaceProjectionsLoadingTitle')}</h4>
            <p>{translate('workspaceProjectionsLoadingDescription')}</p>
          </ContainerLoadingStatusSuccess>
        ) : (
          <>
            <FiltersSidebar filters={filters} />

            <AnnualSummary />
            <Results />
            <PotentialImpacts />
            <ExplanatoryVariables />
            <Export />
          </>
        )}
      </Container>
    </WorkspaceProjectionsProvider>
  );
};
