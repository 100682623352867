const modelInProductionEn = {
  modelInProductionTitle: 'User Selection',
  modelInProductionDescription:
    'Boost your decision-making based on the outputs of your favorite model. Access forecasts, decompose historical results, simulate alternative scenarios, and more.',

  modelInProductionSelectedModel: 'Selected Model',
  modelInProductionSelectedModelSample: 'Sample',
  modelInProductionSelectedModelTransformation: 'Transformation',
  modelInProductionAnnualGrowth: 'Annual Growth Rate (%)',
  modelInProductionAnnualSeries: 'Annual Series',

  modelInProductionLevel: 'Level',
  modelInProductionVariation: 'Variation',

  modelInProductionActual: 'Actual',
  modelInProductionForecast: 'Forecast',

  modelInProductionExport: 'Export',
  modelInProductionExportDescription:
    'Export dataset in a .xlsx file containing the historical series and forecasts of the variable of interest and all the explanatory variables considered by the model.',

  modelInProductionDate: 'Date',
  modelInProductionValue: 'Value',
  modelInProductionVariable: 'Variable',
  modelInProductionPercentage: 'Percentage',
  modelInProductionYVariation: 'Y Variation',
  modelInProductionDataType: 'Data Type',
  modelInProductionErrorMessage:
    'Model forecasted using the behavior of the historical series itself.',

  modelInProductionEstimatedCoefficients: 'Estimated Coefficients',
  modelInProductionSimulations: 'Simulations',
  modelInProductionYear: 'Year',
  modelInProductionRestoreDefaultValues: 'Restore default values',
  modelInProductionSimulate: 'Simulate',
  modelInProductionProjectionExplanatoryVariables:
    'Projection of Explanatory Variables',

  modelInProductionExplainingResults: 'Explaining the Results',
  modelInProductionExplainingResultsDescription:
    'Decompose the historical results based on the main determinants identified by your preferred model.',
  modelInProductionExplainingInfo:
    'The charts show the series percentage variations (line and dots) broken down in main components (bars), such that the sum of the latter is equivalent to the first. Flow variables are the opposite of stock variables.<br/> Technically, flow variables are measured in a certain period of time, never transcending it.<br/> Examples: GDP is a flow variable since it is measured in quarters, semesters or years, whereas population is a stock variable, since from one month to the next one population stock is not reseted"',
  modelInProductionBreakdownMessage:
    'Breakdown is not available for this model.',
  modelInProductionBreakdownIsNotAvailableFirst3Models:
    'Breakdown is only available for the first 3 arima models.',
  modelInProductionArimaBreakdownIsNotAvailableDailyWeeklyFortnightly:
    'Breakdown is not available for daily, weekly or fortnightly data.',
  modelInProductionArimaBreakdownOnlyAvailableForMonthlyData:
    'Only available for monthly data.',

  modelInProductionDownloadError: 'There was an error downloading file.',
  modelInProductionSimulationError: 'An error occurred during simulation.',
  modelInProductionSeeMoreInformation: 'See more information about the model',
  modelInProductionMessageErrorAnnualGrowthRateNotThisModel:
    'Annual Growth Rate is not available for this model',
  modelInProductionMessageErrorAnnualSeriesNotThisModel:
    'Annual Series is not available for this model',
  modelInProductionMessageErrorAnnualSeriesRateIsNotModel:
    'Annual Series Rate is not available for this model',
  modelInProductionMessageErrorQuarterlySeriesRateIsNotModel:
    'Quarterly Series Rate is not available for this model',

  modelInProductionExplanatoryVariablesLatestData: 'Latest data',
};

export default modelInProductionEn;
