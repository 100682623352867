import React, { useMemo, useState } from 'react';

import { addDays, format, parseISO, subYears } from 'date-fns';
import { ArrowsOutSimple, Bank, CaretCircleDown } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { HCharts, HChartsOptions, HChartsSeries } from 'src/components/HCharts';
import api from 'src/feature-store/service/api';
import { getIndicatorUrlIcon } from 'src/feature-store/utils/getIndicatorUrlIcon';
import { RootState } from 'src/redux/store';
import { getChartColor } from 'src/utils/colors/getChartColor';
import { formatCompactNotation } from 'src/utils/numbers/formatCompactNotation';

import { BlurChart } from '../../VisualizeFeatures/components/BlurChart';
import {
  AtributeSerieTitle,
  AtributeSerieValue,
  ChartContainer,
  Container,
  Footer,
  Header,
  Icon,
  InfoContainer,
  InfoContent,
  NavigateToFavPage,
  SerieName,
  SeriesInfo,
  Source,
  ToggleInfoContent,
} from './styles';
import {
  DataIndicatorType,
  ErrorSerieDataType,
  SerieDataType,
  SerieInfoProps,
} from './types';

export const SerieInfo: React.FC<SerieInfoProps> = ({
  serie,
  isLoading,
  isActiveFirstSerieInfo = false,
}) => {
  const [contentIsVisible, setContentIsVisible] = useState(
    isActiveFirstSerieInfo,
  );

  const { language } = useSelector((state: RootState) => state.auth.user);
  const { t: translate } = useTranslation();

  const idIndicator = serie.indicator_code;
  const idSerie = serie.code;
  const aggregation = serie.aggregation;

  const {
    data: serieData,
    isLoading: isLoadingSerieData,
    isError: isErrorSerieData,
    error: errorSerieData,
  } = useQuery<SerieDataType, ErrorSerieDataType>(
    ['data serie chart', idIndicator, idSerie],
    async () => {
      const allDataObservation = [];
      const allDataProjections = [];

      const startObservationData = format(
        subYears(new Date(), 4),
        'yyyy-MM-dd',
      );

      const { data: observationData } = await api.get<DataIndicatorType>(
        `/indicators/${idIndicator}/series/${idSerie}/observations?limit=1000&start=${startObservationData}`,
      );

      allDataObservation.push(...observationData.data);

      for (
        let i = observationData.limit;
        i < observationData.total;
        i += 1000
      ) {
        const { data: observationAux } = await api.get<DataIndicatorType>(
          `/indicators/${idIndicator}/series/${idSerie}/observations?limit=1000&skip=${i}&start=${startObservationData}`,
        );

        allDataObservation.push(...observationAux.data);
      }

      const startDateProjection = format(
        addDays(
          parseISO(
            allDataObservation[allDataObservation.length - 1].date.slice(0, 10),
          ),
          1,
        ),
        'yyyy-MM-dd',
      );

      if (startDateProjection) {
        const yearOfLastHistoricalDate = Number(
          allDataObservation[allDataObservation.length - 1].date.slice(0, 4),
        );

        const { data: projectionData } = await api.get<DataIndicatorType>(
          `/indicators/${idIndicator}/series/${idSerie}/projections?limit=1000&start=${startDateProjection}&end=${
            yearOfLastHistoricalDate + 4
          }-12-31`,
        );

        allDataProjections.push(...projectionData.data);

        for (
          let i = projectionData.limit;
          i < projectionData.total;
          i += 1000
        ) {
          const { data: projectionAux } = await api.get<DataIndicatorType>(
            `/indicators/${idIndicator}/series/${idSerie}/projections?limit=1000&skip=${i}&start=${startDateProjection}&end=${
              yearOfLastHistoricalDate + 4
            }-12-31`,
          );

          allDataProjections.push(...projectionAux.data);
        }
      }

      return {
        observationsData: allDataObservation,
        projectionsData: [
          allDataObservation[allDataObservation.length - 1],
          ...allDataProjections,
        ],
      };
    },
    {
      staleTime: 1000 * 60,
    },
  );

  const options: HChartsOptions = useMemo(
    () => ({
      chart: {
        height: 300,
      },
      tooltip: {
        pointFormat:
          `<tr><td><b>${translate('date')}:</b> </td>` +
          `<td style="text-align: right">{point.x: ${' %d/%m/%Y'}}</td></tr>` +
          `<tr><td><b>${translate('value')}:</b> </td>` +
          '<td style="text-align: right">{point.custom.value}</td></tr>',
      },
      xAxis: {
        minTickInterval:
          aggregation?.['en-us'].toLowerCase().includes('daily') ||
          aggregation?.['en-us'].toLowerCase().includes('weekly')
            ? 24 * 3600 * 1000
            : aggregation?.['en-us'].toLowerCase().includes('yearly')
            ? 365 * 24 * 3600 * 1000
            : 30 * 24 * 3600 * 1000,
      },
    }),
    [aggregation, translate],
  );

  const series: HChartsSeries[] = useMemo(() => {
    const seriesAux: HChartsSeries[] = [];

    if (serieData?.observationsData.length) {
      seriesAux.push({
        name: translate('Historical'),
        type: 'line',
        marker: {
          enabled: false,
        },
        color: getChartColor(0),
        dashStyle: 'Solid',
        data: serieData.observationsData.map((data) => ({
          x: new Date(`${data.date}`).getTime(),
          y: data.value ?? null,
          custom: {
            value: formatCompactNotation(data.value),
          },
        })),
      });
    }

    if (serieData?.projectionsData.length) {
      seriesAux.push({
        name: translate('Forecast'),
        type: 'line',
        marker: {
          enabled: false,
        },
        color: getChartColor(1),
        dashStyle: 'Dash',
        data: serieData.projectionsData.map((data) => ({
          x: new Date(`${data.date}`).getTime(),
          y: data.value ?? null,
          custom: {
            value: formatCompactNotation(data.value),
          },
        })),
      });
    }

    return seriesAux;
  }, [serieData, translate]);

  if (isLoading) {
    return (
      <Container>
        <Header data-testid="container-info-loading">
          <Icon data-testid="icon-loading" typeIndicator="">
            <ContainerSkeleton
              withLoading={false}
              style={{
                width: '2rem',
                height: '2rem',
              }}
            />
          </Icon>

          <SerieName data-testid="serie-name">
            <ContainerSkeleton
              withLoading={false}
              style={{
                width: `${Math.random() * (430 - 270) + 270}px`,
                height: '1.1875rem',
              }}
            />
          </SerieName>

          <ToggleInfoContent data-testid="button-toggle-info-content" isVisible>
            <CaretCircleDown />
          </ToggleInfoContent>
        </Header>
      </Container>
    );
  }

  return (
    <Container>
      <Header
        data-testid="container-info"
        onClick={() => setContentIsVisible((prevState) => !prevState)}
      >
        <Icon
          typeIndicator={serie?.tree![1]?.name?.['en-us'].trim()?.toLowerCase()}
          data-testid="icon"
        >
          <img
            src={getIndicatorUrlIcon(
              serie?.tree && serie.tree.length > 1
                ? serie?.tree[1]?.name?.['en-us']
                : serie?.tree !== undefined
                ? serie?.tree[0].name?.['en-us']
                : 'controls',
            )}
            alt="Icon"
          />
        </Icon>

        <SerieName
          data-testid={`text-title-${serie.name['en-us']}`
            .replaceAll(' ', '-')
            .toLocaleLowerCase()}
        >
          {serie.name[language] ?? serie.name['en-us']}
        </SerieName>

        <ToggleInfoContent
          data-testid="button-toggle-info-content"
          isVisible={contentIsVisible}
        >
          <CaretCircleDown />
        </ToggleInfoContent>
      </Header>

      <InfoContainer isVisible={contentIsVisible}>
        <InfoContent data-testid="container-info-content">
          <SeriesInfo data-testid="series-info">
            <AtributeSerieTitle>
              {translate('serieInfoRegion')}
            </AtributeSerieTitle>
            <AtributeSerieValue
              data-testid={`text-region-${serie.code}`
                .replaceAll(' ', '-')
                .toLocaleLowerCase()}
            >
              {serie.region[language] ?? serie.region['en-us']}
            </AtributeSerieValue>

            <AtributeSerieTitle>
              {translate('serieInfoFrequency')}
            </AtributeSerieTitle>
            <AtributeSerieValue
              data-testid={`text-aggregation-${serie.code}`
                .replaceAll(' ', '-')
                .toLocaleLowerCase()}
            >
              {serie.aggregation[language] ?? serie.aggregation['en-us']}
            </AtributeSerieValue>

            <AtributeSerieTitle>
              {translate('serieInfoPrimaryTransformation')}
            </AtributeSerieTitle>
            <AtributeSerieValue
              data-testid={`text-primary-${serie.code}`
                .replaceAll(' ', '-')
                .toLocaleLowerCase()}
            >
              {serie.primary_transformation[language] ??
                serie.primary_transformation['en-us']}
            </AtributeSerieValue>

            <AtributeSerieTitle>
              {translate('serieInfoSecondaryTransformation')}
            </AtributeSerieTitle>
            <AtributeSerieValue
              data-testid={`text-second-${serie.code}`
                .replaceAll(' ', '-')
                .toLocaleLowerCase()}
            >
              {serie.second_transformation[language] ??
                serie.second_transformation['en-us']}
            </AtributeSerieValue>
          </SeriesInfo>

          <ChartContainer data-testid="container-chart">
            {isLoadingSerieData ? (
              <ContainerSkeleton
                withLoading={false}
                style={{
                  height: '100%',
                }}
              />
            ) : errorSerieData?.response?.status === 402 ? (
              <BlurChart />
            ) : errorSerieData?.response?.status === 503 ? (
              <ContainerMaintenance
                data-testid="container-serie-maintenance"
                content="data"
                text={translate('viewFeaturesUpdateInfo')}
              />
            ) : isErrorSerieData ? (
              <ContainerMaintenance
                data-testid="container-maintenance"
                content="data"
                text={translate('viewFeaturesUnableSerie')}
              />
            ) : (
              serieData && (
                <>
                  <h4 data-testid="title-unit-serie">
                    {serie.unit &&
                      (serie?.unit[language] ?? serie.unit?.['en-us'])}
                  </h4>

                  <HCharts
                    series={series}
                    options={options}
                    dataCy={`chart-home-${serie.code}`}
                  />
                </>
              )
            )}
          </ChartContainer>
        </InfoContent>

        <Footer>
          <Source>
            <Bank />
            <AtributeSerieTitle>
              {translate('serieInfoSource')}
            </AtributeSerieTitle>
            {serie.source}
          </Source>

          <Link
            to={`/feature-store/indicators/${idIndicator}/?region=${serie.region['en-us']}&aggregation=${serie.aggregation['en-us']}&primary=${serie.primary_transformation['en-us']}&second=${serie.second_transformation['en-us']}`}
            data-testid="anchor-expand-serie-info"
          >
            <NavigateToFavPage>
              <ArrowsOutSimple />
              {translate('serieInfoGoToIndicator')}
            </NavigateToFavPage>
          </Link>
        </Footer>
      </InfoContainer>
    </Container>
  );
};
