import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1.5rem;

  min-height: calc(100vh - 4.625rem - 7.875rem);

  > div {
    height: calc(100vh - 4.625rem - 7.938rem);

    @media (max-height: 768px) {
      height: 34rem;
    }
  }

  .container-maintenance {
    strong {
      margin-top: 0.5rem;
    }
  }

  .naked-button-control-panel {
    padding: 0;
    height: fit-content;

    color: ${({ theme }) => theme.colors.primary};

    &:hover {
      color: ${({ theme }) => theme.colors.primaryDark};
    }

    &:disabled {
      color: ${({ theme }) => theme.colors.gray3};

      > div > div {
        border: 3px solid ${({ theme }) => theme.colors.primaryLight};
        border-left-color: ${({ theme }) => theme.colors.gray2};
      }
    }

    svg {
      width: 1.125rem;
      height: 1.125rem;
    }

    > div > div {
      border: 3px solid ${({ theme }) => theme.colors.primary};
      border-left-color: ${({ theme }) => theme.colors.gray3};
    }
  }
`;

export const WorkspaceInfoContainer = styled.div`
  width: 70% !important;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  flex: 1;

  @media (max-width: 1366px) {
    width: 65% !important;
  }
`;

export const WorkspaceInfoHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.5rem;

  margin-bottom: 1.5rem;

  img {
    width: 4rem;
    height: 4rem;

    border-radius: 8px;
  }

  h2 {
    color: ${({ theme }) => theme.colors.gray6};
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2rem;
  }

  p {
    margin-top: 0.5rem;
    color: ${({ theme }) => theme.colors.gray5};
    font-weight: 400;
    font-size: 1rem !important;
    line-height: 150%;
    white-space: pre-wrap;
  }

  > div:last-of-type {
    width: 100%;

    flex: 1;

    > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;
    }
  }
`;

export const WorkspaceIcon = styled.div`
  svg {
    width: 4rem;
    height: 4rem;

    border-radius: 8px;
  }
`;

export const ShareContainer = styled.div`
  width: 30% !important;

  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 0.313rem;
  }

  @media (max-height: 768px) {
    width: 35% !important;
  }
`;

export const ShareSubtitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 1rem;

  button {
    height: fit-content;
    padding: 0;

    color: ${({ theme }) => theme.colors.primary};

    &:hover {
      color: ${({ theme }) => theme.colors.primaryDark};
    }

    svg {
      width: 1.125rem;
      height: 1.125rem;
    }
  }
`;

export const Subtitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  svg {
    color: ${({ theme }) => theme.colors.gray4};
  }

  h3 {
    color: ${({ theme }) => theme.colors.gray4};

    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.375rem;
    letter-spacing: 0.07rem;
    text-transform: uppercase;
  }
`;

type UserContentProps = {
  selectedRoleWidth?: number;
  isLoading?: boolean;
};

export const UserContent = styled.div<UserContentProps>`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;

  position: relative;

  & + & {
    margin-top: 1.5rem;
  }

  .select__control,
  .select__control--is-focused {
    width: ${({ selectedRoleWidth }) => `${selectedRoleWidth}rem`};

    border: none !important;
    box-shadow: none !important;

    cursor: ${({ isLoading }) => (isLoading ? 'wait' : 'pointer')};

    &:hover {
      border: none !important;
    }
  }

  .select__menu {
    width: 10.5rem;
    left: ${({ selectedRoleWidth }) => `${(selectedRoleWidth ?? 0) - 10.5}rem`};
  }

  .select__value-container {
    padding: 0 !important;
  }

  .select__indicator {
    padding: 0 !important;
    padding-left: 0.5rem !important;
  }

  .select__single-value {
    cursor: pointer;

    margin: 0 !important;

    cursor: ${({ isLoading }) => (isLoading ? 'wait' : 'pointer')};

    p,
    svg {
      color: ${({ theme }) => theme.colors.gray4};
    }
  }

  .select__dropdown-indicator {
    cursor: ${({ isLoading }) => (isLoading ? 'wait' : 'pointer')};
  }

  .select__option {
    p {
      font-weight: 400;
    }
  }

  .select__option--is-selected {
    p {
      font-weight: 600;
    }
  }

  .select__option--is-disabled {
    cursor: not-allowed;
  }

  @media (max-width: 1366px) {
    .select__control {
      width: 3.5rem;
    }

    .select__menu {
      left: -6.5rem;
    }

    .select__single-value {
      p {
        display: none;
      }

      svg {
        width: 1.25rem;
        height: 1.25rem;
      }
    }
  }
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.75rem;

  flex: 1;

  > div:first-of-type {
    width: 1.75rem;
    height: 1.75rem;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    border-radius: 100%;

    background-color: ${({ theme }) => theme.colors.primary};

    p {
      font-size: 0.75rem;
      font-weight: 700;
      line-height: 150%;
      text-transform: uppercase;

      color: ${({ theme }) => theme.colors.white};
    }
  }

  > p {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.375rem;

    color: ${({ theme }) => theme.colors.gray5};

    flex: 1;

    word-break: break-all;
  }
`;

export const EmptyGuests = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  img {
    margin-bottom: 2rem;
  }

  h2 {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.6875rem;

    color: ${({ theme }) => theme.colors.gray5};

    margin-bottom: 0.5rem;
  }

  p {
    font-size: 1rem;
    font-weight: 400;
    line-height: 150%;

    color: ${({ theme }) => theme.colors.gray4};
  }

  @media (max-width: 1366px) {
    img {
      height: 7rem;
    }
  }
`;

export const NoAccessContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1.375rem;

    color: ${({ theme }) => theme.colors.gray4};
  }
`;

export const ProjectListContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 2rem;

  flex: 1;

  overflow-y: auto;

  margin-top: 1rem;

  ::-webkit-scrollbar {
    width: 0.313rem;
  }
`;

export const ProjectListContent = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;

  > img {
    width: 2rem;
    height: 2rem;

    border-radius: 4px;
  }
`;

export const ProjectInfo = styled.div`
  width: 100%;

  flex: 1;

  h3 {
    font-size: 1rem;
    font-weight: 500;
    line-height: 150%;

    color: ${({ theme }) => theme.colors.gray6};
  }

  p {
    width: fit-content;

    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.375rem;

    color: ${({ theme }) => theme.colors.gray4};

    margin-top: 0.25rem;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  flex: 0 0 auto;

  padding-top: 1.5rem;
  margin-top: 1.5rem;

  border-top: 1px solid ${({ theme }) => theme.colors.gray1};

  .naked-button-blue {
    height: fit-content;

    padding: 0 !important;

    color: ${({ theme }) => theme.colors.primary};

    transition: all 0.2s;

    svg {
      width: 1.125rem;
      height: 1.125rem;
    }

    &:hover {
      color: ${({ theme }) => theme.colors.primaryDark};
    }
  }

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }

  .warning-button {
    padding: 0;

    svg {
      width: 1.75rem;
      height: 1.75rem;

      color: ${({ theme }) => theme.colors.yellow4};

      transition: all 0.2s;
    }

    &:hover {
      svg {
        color: ${({ theme }) => theme.colors.yellow5};
      }
    }
  }
`;

export const WorkspaceInfoContent = styled.div`
  display: flex;
  flex-direction: column;

  flex: 1;

  overflow-y: hidden;

  > div:not(:last-of-type) {
    flex: 0 0 auto;
  }
`;

export const ContainerModal = styled.div`
  width: 29.063rem;
  padding: 2rem 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  > svg {
    color: ${({ theme }) => theme.colors.red3};
  }

  > h1 {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 2rem;
    margin: 1.5rem 0 0.75rem;
    color: ${({ theme }) => theme.colors.gray6};
  }

  > p {
    color: ${({ theme }) => theme.colors.gray5};
    font-weight: 400;
    font-size: 1rem;
    line-height: 150%;
  }

  button {
    display: flex;
    justify-content: center;
    padding: 0.75rem 2rem;
    height: 2.5rem;

    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1rem;
  }
`;
