import React from 'react';

import ms from 'ms';
import { Bell } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Tooltip } from 'react-tooltip';
import { ButtonRounded } from 'src/components/ButtonRounded';
import api from 'src/feature-store/service/api';
import { queryClient } from 'src/service/queryClient';

import { ContainerWithText, SubscribeToAlertContainer } from './styles';
import { AlertProps, SubscribeAlertProps } from './types';

export const SubscribeAlert: React.FC<SubscribeAlertProps> = ({
  id,
  email,
  text,
}) => {
  const { t: translate } = useTranslation();

  const {
    data: alertData,
    isLoading,
    isFetching,
  } = useQuery(
    ['alerts', id, email],
    async () => {
      const { data } = await api.get<AlertProps>(
        `/groups/${id}/alerts?user=${email}`,
      );
      return data;
    },
    {
      staleTime: ms('5 min'),
      enabled: !!id || !!email,
    },
  );

  const userSubscribeGroup = !!alertData?.total;
  const manageSubscription = async () => {
    if (!userSubscribeGroup) {
      try {
        await api.post(`/groups/${id}/alerts`, {
          email,
        });
        queryClient.refetchQueries({ queryKey: ['alerts', id] });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    } else {
      try {
        await api.delete(`/groups/${id}/alerts/${alertData.data[0].id}`);
        queryClient.refetchQueries({ queryKey: ['alerts', id] });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    }
  };

  return (
    <SubscribeToAlertContainer
      isSubscribe={!!alertData?.total}
      onClick={(event) => {
        manageSubscription();
        event.stopPropagation();
      }}
      data-tooltip-id="tooltip-subscribe"
      data-tooltip-html={
        alertData?.total
          ? translate('subscribeToAlertUnsubscribe')
          : translate('subscribeToAlertSubscribe')
      }
    >
      {!text && (
        <ButtonRounded
          data-testid="button-subscribe-alert"
          disabled={isLoading || isFetching}
          icon={
            <Bell
              size="1.125rem"
              weight={alertData?.total ? 'fill' : 'light'}
            />
          }
        />
      )}

      {text && (
        <ContainerWithText isSubscribe={!!alertData?.total}>
          <Bell size="1rem" weight={alertData?.total ? 'fill' : 'light'} />
          {text}
        </ContainerWithText>
      )}

      <Tooltip id="tooltip-subscribe" className="customTooltipTheme" />
    </SubscribeToAlertContainer>
  );
};
