import React from 'react';

import { Label } from 'src/components/Label';
import { RadioButton } from 'src/components/RadioButton';

import { RadioButtonFilterProps } from './types';
import { Container, ContentTitle, Options } from './styles';

export const RadioButtonFilter: React.FC<RadioButtonFilterProps> = ({
  name,
  options,
  onChange,
  tooltip,
}) => {
  const TooltipIcon = tooltip ? tooltip.icon : null;
  return (
    <Container
      data-testid={`filter-${name?.toLowerCase().replaceAll(' ', '-')}`}
    >
      {name && (
        <ContentTitle>
          <Label
            data-testid={`label-${name?.toLowerCase().replaceAll(' ', '-')}`}
          >
            {name}
          </Label>
          {!!tooltip && !!TooltipIcon && (
            <TooltipIcon
              size={16}
              data-testid={`filter-${name
                ?.toLowerCase()
                .replaceAll(' ', '-')}-tooltip`}
              data-tooltip-id="filters-sidebar-tooltip"
              data-tooltip-html={tooltip.info}
            />
          )}
        </ContentTitle>
      )}

      <Options flexDirection={options.length > 2 ? 'column' : 'row'}>
        {options.map((option) => (
          <div
            key={option.label}
            data-testid={`radio-tooltip-${option.label
              .replaceAll(' ', '-')
              .toLowerCase()}`}
            data-tooltip-id="filters-sidebar-tooltip"
            data-tooltip-html={option.tooltipInfo}
            className={option.loading ? 'loading-radio-option' : ''}
          >
            <RadioButton
              label={option.label}
              disabled={option.disabled}
              checked={option.checked}
              onChange={() => onChange(option.value)}
            />
          </div>
        ))}
      </Options>
    </Container>
  );
};
