import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';
import { Head } from 'src/components/Head';
import apiWorkspace from 'src/models/service/apiWorkspace';
import { FailedModal } from 'src/components/Modal/Failed';
import { queryClient } from 'src/service/queryClient';
import {
  useLocation,
  useNavigate,
  useNavigationType,
  useParams,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  insert,
  updateReleaseSelected,
} from 'src/models/redux/reducers/Workspace';
import { RootState } from 'src/redux/store';
import { Action } from 'history';
import { AxiosError } from 'axios';
import ms from 'ms';
import { ModalLoading } from 'src/components/Modal/Loading';
import { WorkspaceConfigProvider } from 'src/models/contexts/WorkspaceConfigContext';

import { Container, StepsContainer, StepsItem, StepsNumber } from './styles';
import {
  Categorization,
  CategorizationError,
  CreateWorkspaceResponse,
  CategorizationResponse,
  Filters,
  ParamsProps,
  Serie,
  StagingArea,
  YFilters,
  Ys,
  YsHierarchiesProps,
} from './types';
import { NoPermissionToEditModal } from '../components/NoPermissionToEdit';
import { SessionExpiredModal } from './components/Modal/SessionExpired';
import { CategorizationErrorModal } from './components/Modal/CategorizationError';
import { Step1 } from './components/Step1';
import { Step2 } from './components/Step2';
import { Step3 } from './components/Step3';

export const CreateWorkspace: React.FC = () => {
  const [showModalError, setShowModalError] = useState(false);

  const [showUserEditingModal, setShowUserEditingModal] = useState('');
  const [navigateToControlPanel, setNavigateToControlPanel] = useState(false);

  const [editionExpired, setEditionExpired] = useState(false);
  const [loadingBlockEdition, setLoadingBlockEdition] = useState(false);
  const [canEdit, setCanEdit] = useState(true);

  const [isSavingWorkspace, setIsSavingWorkpsace] = useState(false);

  const [savingStep, setSavingStep] = useState<
    'variables' | 'hierarchies' | undefined
  >();

  const [categorizationError, setCategorizationError] =
    useState<CategorizationError>();

  const { t: translate } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const navType = useNavigationType();

  const { id: workspaceId } = useParams<ParamsProps>();

  const isEdition = location.pathname.includes('/control-panel/edition');

  const [step, setStep] = useState(isEdition ? 2 : 1);

  const { workspace } = useSelector((state: RootState) => state);

  const createWorkspace = async (
    name: string,
    description: string,
    iconUrl: string,
    ys: Ys[],
    enablePlanningFlow: boolean,
    enableAggregation: boolean,
    enableMarketShare: boolean,
  ): Promise<CreateWorkspaceResponse> => {
    const { data } = await apiWorkspace.post<CreateWorkspaceResponse>(
      '/workspaces',
      {
        name,
        description,
        icon_url: iconUrl,
        data: {
          ys,
          aggregation: {
            enable: enableAggregation,
          },
          market_share: {
            enable: enableMarketShare,
          },
          approval_flow: {
            enable: enablePlanningFlow,
          },
        },
      },
    );

    return data;
  };

  const updateVariablesInfo = async (
    ys: Ys[],
    enablePlanningFlow: boolean,
    enableAggregation: boolean,
    enableMarketShare: boolean,
    stagingAreaData?: StagingArea,
    categorization?: Categorization,
    file?: File,
  ) => {
    const data: any = {
      ys,
      aggregation: {
        enable: enableAggregation,
      },
      market_share: {
        enable: enableMarketShare,
      },
      approval_flow: {
        enable: enablePlanningFlow,
      },
      filters: stagingAreaData?.data.filters ?? null,
      y_filters: stagingAreaData?.data.y_filters ?? null,
    };

    if (!categorization?.hierarchies.length && !file) {
      data.filters = null;
      data.y_filters = null;
    }

    await apiWorkspace.put<CreateWorkspaceResponse>(
      `/workspaces/${workspace?.id}/staging-area`,
      {
        data,
      },
    );
  };

  const updateManualHierarchies = async (
    id: string,
    categorization?: Categorization,
  ) => {
    const filters: Filters = {};
    const yFilters: YFilters = {};

    if (categorization?.hierarchies.length) {
      const marketShareColumn = ['tipo variavel', 'variable type'];
      let marketShareIndex = -1;

      const hierarchies = categorization.hierarchies.filter((col, index) => {
        if (marketShareColumn.includes(col.toLowerCase())) {
          marketShareIndex = index;

          return false;
        }

        return true;
      });

      hierarchies.forEach((hierarchy, i) => {
        if (filters) {
          filters[`level_${i + 1}`] = { name: hierarchy, options: [] };
        }
      });

      const ysLabel = Object.keys(categorization.ys);

      const ysCategorization: YsHierarchiesProps = JSON.parse(
        JSON.stringify(categorization.ys),
      );

      if (marketShareIndex !== -1) {
        ysLabel.forEach((y) => {
          ysCategorization[y].splice(marketShareIndex, 1);
        });
      }

      for (let i = 0; i < ysLabel.length; i++) {
        const label = ysLabel[i];

        ysCategorization[label].forEach((option, index) => {
          const hierarchyOption = new Set(
            filters?.[`level_${index + 1}`]?.options,
          );

          if (option.value) {
            hierarchyOption.add(option.value);
          }

          if (filters && filters[`level_${index + 1}`]?.name) {
            filters[`level_${index + 1}`] = {
              name: filters[`level_${index + 1}`]?.name ?? '',
              options: Array.from(hierarchyOption),
            };
          }

          if (yFilters) {
            if (index === 0) {
              yFilters[label] = {
                level_1: option.value ?? '',
              };
            } else if (option.value) {
              yFilters[label][`level_${index + 1}`] = option.value;
            }
          }
        });
      }

      const { data } = await apiWorkspace.patch<CategorizationResponse>(
        `/workspaces/${id}/staging-area/hierarchies`,
        {
          data: {
            filters,
            y_filters: yFilters,
          },
        },
      );

      return data;
    }

    const { data } = await apiWorkspace.patch<CategorizationResponse>(
      `/workspaces/${id}/staging-area/hierarchies`,
      {
        data: {
          filters: null,
          y_filters: null,
        },
      },
    );

    return data;
  };

  const updateExcelHierarchies = async (id: string, file?: File) => {
    if (file) {
      const fileAux = new FormData();
      fileAux.append('file', file);

      const { data } = await apiWorkspace.patch<CategorizationResponse>(
        `/workspaces/${id}/staging-area/hierarchies/excel`,
        fileAux,
      );

      return data;
    }
  };

  const saveWorkspace = async (
    name: string,
    description: string,
    iconUrl: string,
    series: Serie[],
    enablePlanningFlow: boolean,
    enableAggregation: boolean,
    enableMarketShare: boolean,
    hasSeriesChanges: boolean,
    stagingAreaData?: StagingArea,
    file?: File,
    categorization?: Categorization,
  ) => {
    setIsSavingWorkpsace(true);
    setCategorizationError(undefined);

    const ys: Ys[] = [];

    series.forEach((serie) => {
      if (serie.selected) {
        ys.push({
          project_id: serie.modelUpdate.value,
          y: serie.modelUpdate.y ?? serie.y,
          y_label: serie.yLabel,
          model_id: serie.modelId.model_id,
          is_inflated: serie.isInflated,
          type: serie.type ?? 'others',
        });
      }
    });

    let id = workspace?.id ?? undefined;

    let categorizationResponse: CategorizationResponse | undefined;

    try {
      if (!workspace?.id) {
        setSavingStep('variables');

        const data = await createWorkspace(
          name,
          description,
          iconUrl,
          ys,
          enablePlanningFlow,
          enableAggregation,
          enableMarketShare,
        );

        id = data.id;

        dispatch(
          insert({
            id: data.id,
            name: data.name,
            description: data.description,
            icon: data.icon_url,
            userRole: 'manager',
            createdBy: data.created_by,
            lastUpdated: data.last_updated,
            frequency: null,
            releaseCurrent: null,
            releasePreview: null,
            status: data.status,
            canSyncAdjust: true,
          }),
        );

        dispatch(
          updateReleaseSelected({ releaseSelected: null, ySelected: null }),
        );

        if (id && (file || categorization?.hierarchies.length)) {
          setSavingStep('hierarchies');

          await blockEdition(false, id);

          if (file) {
            categorizationResponse = await updateExcelHierarchies(id, file);
          } else if (categorization?.hierarchies.length) {
            categorizationResponse = await updateManualHierarchies(
              id,
              categorization,
            );
          }

          if (!categorizationResponse?.warnings?.length) {
            try {
              await apiWorkspace.delete(`/workspaces/${id}/edit`);
              // eslint-disable-next-line no-empty
            } catch {}
          }
        }
      } else {
        await blockEdition(false);

        if (hasSeriesChanges) {
          setSavingStep('variables');

          await updateVariablesInfo(
            ys,
            enablePlanningFlow,
            enableAggregation,
            enableMarketShare,
            stagingAreaData,
            categorization,
            file,
          );
        }

        if (file) {
          setSavingStep('hierarchies');

          categorizationResponse = await updateExcelHierarchies(
            workspace?.id ?? '',
            file,
          );
        } else {
          setSavingStep('hierarchies');

          categorizationResponse = await updateManualHierarchies(
            workspace?.id ?? '',
            categorization,
          );
        }

        if (!categorizationResponse?.warnings?.length) {
          try {
            await apiWorkspace.delete(`/workspaces/${workspace?.id}/edit`);
            // eslint-disable-next-line no-empty
          } catch {}

          queryClient.removeQueries([
            'workspace staging area',
            workspace?.id,
            'projects',
          ]);
          queryClient.removeQueries(['workspace staging area', workspace?.id]);
          queryClient.removeQueries([
            'workspace staging area hierarchies',
            workspace?.id,
          ]);
          queryClient.removeQueries(['workspace data', workspace?.id]);
        }
      }

      queryClient.removeQueries('workspaces');

      if (categorizationResponse?.warnings?.length) {
        setCategorizationError({
          description: categorizationResponse.warnings[0],
          warning: true,
        });
      } else {
        navigate(`/models/workspaces/${id}/control-panel`);
      }
    } catch (err) {
      const error = err as AxiosError;

      const url = error?.response?.config?.url ?? error?.config?.url;

      if (
        error.response?.status === 403 &&
        error.response?.data.detail.detail[0].startsWith(
          'workspace is being edited by',
        )
      ) {
        let emailEditing = error.response?.data.detail.detail[0]
          .replace('workspace is being edited by ', '')
          .trim();

        emailEditing =
          emailEditing[emailEditing?.length - 1] === '.'
            ? emailEditing?.slice(0, -1)
            : emailEditing;

        setShowUserEditingModal(emailEditing);
      } else if (url?.endsWith('/hierarchies') || url?.endsWith('/excel')) {
        let errorDescription = `<p style="margin-bottom: 0.5rem">${translate(
          'categorizationErrorGenericDescription',
        ).replace(
          'XXX',
          translate(
            isEdition
              ? 'categorizationErrorWorkspaceEdit'
              : 'categorizationErrorWorkspaceCreation',
          ),
        )}</p>`;

        let variables = '';

        const detail: string =
          error.response?.data?.detail?.detail ??
          error.response?.data?.detail ??
          '';

        if (detail) {
          if (detail.includes(translate('missingFilter'))) {
            errorDescription += translate('missingFilter');
            variables = detail.replace(translate('missingFilter'), '').trim();
          } else if (detail.includes(translate('numberVariablesIsGreater'))) {
            errorDescription += translate('numberVariablesIsGreater');
            variables = detail
              .replace(translate('numberVariablesIsGreater'), '')
              .trim();
          } else if (detail.includes(translate('numberVariablesIsSmaller'))) {
            errorDescription += translate('numberVariablesIsSmaller');
            variables = detail
              .replace(translate('numberVariablesIsSmaller'), '')
              .trim();
          } else if (detail.includes(translate('aggregationDisabled'))) {
            errorDescription += `${translate('aggregationDisabled')}:`;
            variables = detail
              .replace(translate('aggregationDisabled'), '')
              .trim()
              .replace(':', '')
              .replaceAll("'", '')
              .replaceAll('[', '')
              .replaceAll(']', '');
          } else if (detail.includes(translate('invalidTypes'))) {
            errorDescription += `${translate('invalidTypes')}`;
            variables = detail.replace(translate('invalidTypes'), '').trim();
          } else if (detail.includes(translate('mandatoryVariables'))) {
            errorDescription += translate('mandatoryHierarchy');
            variables = detail
              .replace(translate('mandatoryVariables'), '')
              .trim();
          } else {
            errorDescription += detail;
          }
        }

        setCategorizationError({
          description: errorDescription,
          variables,
        });
      } else {
        setShowModalError(true);
      }
    }

    setSavingStep(undefined);
    setIsSavingWorkpsace(false);
  };

  const closeModalError = () => {
    setShowModalError(false);
  };

  const blockEdition = async (sendToControlPanel = true, id?: string) => {
    setLoadingBlockEdition(true);

    try {
      await apiWorkspace.patch(`/workspaces/${id ?? workspace?.id}/edit`);

      setCanEdit(true);
    } catch (err) {
      const error = err as AxiosError;
      if (
        error.response?.status === 400 &&
        error.response?.data?.detail?.detail?.startsWith(
          'Workspace already locked for editing by',
        )
      ) {
        if (sendToControlPanel) {
          setNavigateToControlPanel(true);
        }

        setCanEdit(false);
        setShowUserEditingModal(
          error.response?.data.detail.detail.replace(
            'Workspace already locked for editing by ',
            '',
          ),
        );
      }
    }

    setEditionExpired(false);
    setLoadingBlockEdition(false);
  };

  useEffect(() => {
    if (isEdition && navType === Action.Pop && workspace?.id) {
      blockEdition();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdition, navType, workspace?.id]);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (!editionExpired && isEdition && canEdit) {
      interval = setInterval(() => setEditionExpired(true), ms('5 min'));
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [editionExpired, isEdition, canEdit]);

  useEffect(() => {
    if (isEdition && workspace?.status === 'publishing') {
      navigate(`/models/workspaces/${workspace?.id}/control-panel`);
    }
  }, [isEdition, workspace?.status, workspace?.id, navigate]);

  return (
    <WorkspaceConfigProvider>
      <>
        <Head
          title={
            isEdition
              ? translate('editWorkspaceHeadTitle')
              : translate('createWorkspaceHeadTitle')
          }
        />

        <Container>
          <StepsContainer className="containerLinear">
            <StepsItem
              selected={step === 1}
              data-testid={`container-step-1${step === 1 ? '-selected' : ''}`}
            >
              <StepsNumber data-testid="step-1-number">1</StepsNumber>
              <p>{translate('step1')}</p>
            </StepsItem>

            <StepsItem
              selected={step === 2}
              data-testid={`container-step-2${step === 2 ? '-selected' : ''}`}
            >
              <StepsNumber data-testid="step-2-number">2</StepsNumber>
              <p>{translate('step2')}</p>
            </StepsItem>

            <StepsItem
              selected={step === 3}
              data-testid={`container-step-3${step === 3 ? '-selected' : ''}`}
            >
              <StepsNumber data-testid="step-3-number">3</StepsNumber>
              <p>{translate('step3')}</p>
            </StepsItem>
          </StepsContainer>

          {step === 1 ? (
            <Step1 setStep={setStep} />
          ) : step === 2 ? (
            <Step2 setStep={setStep} />
          ) : (
            <Step3 setStep={setStep} saveWorkspace={saveWorkspace} />
          )}

          <Tooltip
            id="config-workspace-tooltip"
            className="customTooltipTheme workspace-tooltip"
          />
        </Container>

        {showModalError && (
          <FailedModal
            errorInfo={{
              title: translate('createWorkspaceModalErrorTitle'),
              description: translate(
                'createWorkspaceModalErrorDescription',
              ).replace(
                'XXX',
                translate(
                  isEdition
                    ? 'createWorkspaceModalErrorDescriptionSaveText'
                    : 'createWorkspaceModalErrorDescriptionCreateText',
                ),
              ),
            }}
            visible
            setVisible={closeModalError}
          />
        )}

        {showUserEditingModal && (
          <NoPermissionToEditModal
            setVisible={() => {
              setShowUserEditingModal('');

              if (navigateToControlPanel) {
                navigate(`/models/workspaces/${workspaceId}/control-panel`);
              }
            }}
            emailEditing={showUserEditingModal}
          />
        )}

        {editionExpired && canEdit && (
          <SessionExpiredModal
            handleBlockEdition={() => blockEdition(false)}
            loadingBlockEdition={loadingBlockEdition}
            workspaceId={workspaceId ?? ''}
          />
        )}

        {isSavingWorkspace && (
          <ModalLoading
            visible
            message={`${
              !savingStep
                ? translate('loading')
                : savingStep === 'variables' && !workspace?.id
                ? translate('creatingWorkspace')
                : savingStep === 'variables'
                ? translate('savingWorkspace')
                : translate('savingHierarchies')
            }...`}
          />
        )}

        {!!categorizationError?.description && (
          <CategorizationErrorModal
            setVisible={() => setCategorizationError(undefined)}
            workspaceId={workspace?.id ?? ''}
            title={
              categorizationError.warning
                ? translate('categorizationWarningTitle')
                : translate('categorizationErrorTile')
            }
            description={categorizationError?.description}
            variables={categorizationError?.variables}
            isWarning={!!categorizationError.warning}
          />
        )}
      </>
    </WorkspaceConfigProvider>
  );
};
