import styled, { css } from 'styled-components';

export const Container = styled.header`
  width: 100%;
  max-height: 4.625rem;
  position: sticky;
  top: 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  border-bottom: 1px solid ${({ theme }) => theme.colors.gray2};
  background: ${({ theme }) => theme.colors.white};

  padding: 1.313rem 2rem;
  z-index: 2000;

  > div:first-child {
    display: flex;
    align-items: center;

    a > svg:first-child {
      width: 11.25rem;
      height: 2rem;

      @media (max-width: 400px) {
        .letter {
          display: none;
        }
      }
    }
  }

  > nav {
    display: flex;
    justify-content: center;
    margin-right: 1.875rem;
  }

  @media (max-width: 400px) {
    padding: 1rem;
  }
`;

export const ContentRightActionButtons = styled.div`
  display: flex;
  align-items: center;
  > button,
  > a {
    display: flex;
    align-items: center;
    border-radius: 5px;

    transition: 0.2s;

    color: ${({ theme }) => theme.colors.gray4};

    &:hover {
      color: ${({ theme }) => theme.colors.gray5};
    }
  }
`;

type TabsProps = {
  isActive: boolean;
};

export const Tab = styled.div<TabsProps>`
  width: 8.938rem;
  height: 4.625rem;
  padding: 0 0.5rem;
  display: flex;
  justify-content: center;

  & + div {
    margin-left: 1.5rem;
  }

  > a {
    color: ${({ theme }) => theme.colors.gray4};
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 4.625rem;

    transition: color 0.2s;

    &:hover {
      color: ${({ theme, isActive }) =>
        isActive ? theme.colors.gray7 : theme.colors.gray5};
    }
  }

  ${({ isActive }) =>
    isActive &&
    css`
      border-bottom: 1px solid ${({ theme }) => theme.colors.secondary};
      > a {
        color: ${({ theme }) => theme.colors.gray7};
        font-weight: 700;
      }
    `}
`;

export const UpgradeContainer = styled.button`
  padding: 6px 16px;
  justify-content: center;
  gap: 8px;

  margin-right: 2.25rem;

  border-radius: 16px !important;
  border: 0.5px solid rgba(255, 171, 211, 0.42);
  background: rgba(242, 125, 180, 0.12);
  box-shadow: 0px 0px 12px 0px rgba(242, 125, 180, 0.24);

  color: ${({ theme }) => theme.colors.secondary} !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;

  transition: all 0.3s ease-in !important;

  &:hover {
    background: rgba(242, 125, 180, 0.22);
  }
`;
