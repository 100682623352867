import React, { useEffect } from 'react';

import { Head } from 'src/components/Head';
import { useTranslation } from 'react-i18next';
import { Card } from 'src/components/Card';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import apiWorkspace from 'src/models/service/apiWorkspace';
import { useQuery } from 'react-query';
import api from 'src/models/service/api';
import {
  changeHasWorkspaceAccess,
  changeIsAccessing,
} from 'src/models/redux/reducers/MyWorks';
import {
  changeEditionModeEnabled,
  changeReviewModeEnabled,
} from 'src/models/redux/reducers/WorkspaceOverviewOptions';

import { ListProjects, Projects } from './components/List/ListProjects';
import { Container, ContentButtons, SelectOptionButton } from './styles';
import workspaceButtonStar from '../../../assets/workspace_button_star.png';
import { ListWorkspaces } from './components/List/ListWorkspaces';
import { Workspace } from './types';

type Options = 'projects' | 'workspaces';

export const MyProjects: React.FC = () => {
  const {
    auth: { user },
    myWorks: { isAccessing, hasWorkspaceAccess },
  } = useSelector((state: RootState) => state);

  const { t: translate } = useTranslation();

  const dispatch = useDispatch();

  function handleSelectOption(option: Options) {
    dispatch(changeIsAccessing(option));
  }

  const userHasPermissionReadWorkspace = user.permissions.find(
    (permission) => permission === 'read:workspaces',
  );
  const userHasPermissionCreateWorkspace = user.permissions.find(
    (permission) => permission === 'create:workspaces',
  );

  const { data: workspaceData } = useQuery(
    ['workspaces', 1],
    async () => {
      const { data } = await apiWorkspace.get<Workspace>(
        '/workspaces?skip=0&limit=6',
      );

      return data;
    },
    {
      staleTime: 1000 * 60,
    },
  );

  const {
    data: dataProjectsBergmanVersion,
    isLoading: isLoadingProjectsBergmanVersion,
  } = useQuery(['projects', 'modelling', 'bergman', '0.5.2'], async () => {
    const response = await api.get<Projects>(
      '/projects?project_type=modelling&engine_name=bergman&engine_version_gte=0.5.2&skip=0&limit=1',
    );

    return response.data;
  });

  useEffect(() => {
    if (
      userHasPermissionCreateWorkspace ||
      (userHasPermissionReadWorkspace && workspaceData?.total)
    ) {
      dispatch(changeHasWorkspaceAccess(true));
    } else if (workspaceData && workspaceData?.total === 0) {
      if (isAccessing === 'workspaces') {
        dispatch(changeIsAccessing('projects'));
      }
      dispatch(changeHasWorkspaceAccess(false));
    }

    if (isAccessing === 'workspaces' && hasWorkspaceAccess === false) {
      dispatch(changeIsAccessing('projects'));
    }
  }, [
    userHasPermissionCreateWorkspace,
    userHasPermissionReadWorkspace,
    dispatch,
    workspaceData,
    isAccessing,
    hasWorkspaceAccess,
  ]);

  useEffect(() => {
    dispatch(changeEditionModeEnabled(false));
    dispatch(changeReviewModeEnabled(false));
  }, [dispatch]);

  return (
    <Container>
      <Head title={translate('projectsHeadTitle')} />
      <div className="containerLinear">
        <Card
          textCard={translate('myWorks')}
          textDescription={translate('myWorksSubtitle')}
        />
        <ContentButtons>
          <SelectOptionButton
            selected={isAccessing === 'projects'}
            onClick={() => handleSelectOption('projects')}
            data-testid="button-select-projects"
            data-cy="button-select-projects"
          >
            {translate('myProjects')}
          </SelectOptionButton>
          {((userHasPermissionReadWorkspace && hasWorkspaceAccess) ||
            userHasPermissionCreateWorkspace) && (
            <SelectOptionButton
              selected={isAccessing === 'workspaces'}
              isWorkspace
              onClick={() => handleSelectOption('workspaces')}
              data-testid="button-select-workspaces"
              data-cy="button-select-workspaces"
            >
              <img src={workspaceButtonStar} alt="workspace_start" />
              <div>Workspaces</div>
            </SelectOptionButton>
          )}
        </ContentButtons>
        {isAccessing === 'projects' ? (
          <ListProjects />
        ) : (
          <ListWorkspaces
            canUserCreateWorkspace={
              !isLoadingProjectsBergmanVersion
                ? !!dataProjectsBergmanVersion?.total
                : null
            }
          />
        )}
      </div>
    </Container>
  );
};
