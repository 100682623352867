import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { AxiosError } from 'axios';
import { format } from 'date-fns';
import { PencilSimple } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from 'src/components/Card';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { SelectedFilters } from 'src/components/SelectedFilters';
import { SelectedFilterOptions } from 'src/components/SelectedFilters/types';
import { translateSomeMessagesFromBackend } from 'src/i18n';
import { WorkspaceProjectionsContext } from 'src/models/contexts/WorkspaceProjectionsContext';
import {
  WorkspaceProjectionsFrequency,
  WorkspaceProjectionsTransformation,
  changeWorkspaceProjectionsIsLatestDataActive,
  changeWorkspaceProjectionsLatestDataEnabled,
  changeWorkspaceProjectionsLoadingLevelData,
  changeWorkspaceProjectionsLoadingVariationData,
  changeWorkspaceProjectionsTransformations,
  changeWorkspaceProjectionsTransformationsEnabled,
} from 'src/models/redux/reducers/WorkspaceProjectionsOptions';
import apiWorkspace from 'src/models/service/apiWorkspace';
import { RootState } from 'src/redux/store';
import {
  frequencyLatestData,
  getLatestData,
} from 'src/utils/charts/getLatestData';
import { sleep } from 'src/utils/sleep';
import { transformUppercaseFirstLetter } from 'src/utils/strings/transformUppercaseFirstLetter';

import { ResultsChart } from './ResultsChart';
import { ResultsUnderstanding } from './ResultsUnderstanding';
import { ResultContainer } from './styles';
import { useQueryReleaseData } from '../../../hooks/useQueryReleaseData';

interface Error {
  detail?: {
    description?: string;
    detail?: string;
  };
}

interface XSelected {
  x: string;
  date: string;
}

interface Chart {
  date: (string | number)[];
  value: number[];
}

interface Result {
  forecast: Chart;
  historical: Chart;
  simulated: Chart;
}

interface ResponseResultChart {
  dates: string[];
  values: number[];
}

interface ResponseResult {
  forecast: ResponseResultChart;
  historical: ResponseResultChart;
}

interface AISelectionResultsContextTypes {
  isYFrequencyValid: boolean;
  xSelected: XSelected | null;
  isLoadingChart: boolean;
  dataLevel: Result | undefined;
  dataVariation: Result | undefined;
  isLatestDataDisabled: boolean;
  canSelectForecast: boolean;
  selectX: (x: string) => void;
}

export const AISelectionResultsContext = createContext(
  {} as AISelectionResultsContextTypes,
);

export const Results: React.FC = () => {
  const [xSelected, setXSelected] = useState<XSelected | null>(null);

  const {
    workspace,
    auth: { user },
    workspaceProjectionsOptions: {
      frequency,
      isLatestDataActive,
      transformations,
      loadingLevelData,
      loadingVariationData,
      inflation,
      forecastType,
    },
  } = useSelector((state: RootState) => state);

  const [chartFrequency, setChartFrequency] =
    useState<WorkspaceProjectionsFrequency>(frequency);
  const [chartIsLatestDataActive, setChartIsLatestDataActive] =
    useState(isLatestDataActive);
  const [frequencyAux, setFrequencyAux] =
    useState<WorkspaceProjectionsFrequency>(frequency);

  const { listOfVariables, isLoadingListOfVariables, errorListOfVariables } =
    useContext(WorkspaceProjectionsContext);

  const { t: translate } = useTranslation();

  const dispatch = useDispatch();

  const { data: releaseData } = useQueryReleaseData(
    workspace.id,
    workspace.releaseSelected?.id,
  );

  useEffect(() => {
    (async () => {
      await sleep(50);
      setChartFrequency(frequency);
    })();
  }, [frequency]);

  useEffect(() => {
    (async () => {
      await sleep(50);
      setChartIsLatestDataActive(isLatestDataActive);
    })();
  }, [isLatestDataActive]);

  const isYFrequencyValid = useMemo(() => {
    if (!workspace.frequency) {
      return false;
    }

    const yFrequency = workspace.frequency;

    if (frequency === 'original') {
      const frequencyAvailableForOriginal = [
        'monthly',
        'bimonthly',
        'quarterly',
        'half-year',
        'annual',
      ];

      return frequencyAvailableForOriginal.includes(yFrequency);
    }

    return yFrequency === 'monthly';
  }, [frequency, workspace.frequency]);

  const {
    data: dataVariation,
    isLoading: isLoadingVariation,
    isFetching: isFetchingVariation,
    error: errorVariation,
  } = useQuery<ResponseResult, AxiosError<Error>>(
    [
      'workspace',
      'projections',
      'series data',
      workspace.id,
      workspace.releaseSelected?.id,
      workspace.ySelected?.y_label,
      forecastType,
      releaseData?.data.steps?.length ?? 1,
      frequency,
      'variation',
      inflation,
    ],
    async () => {
      const inflationAux = inflation === 'nominal' ? 'inflate' : 'original';

      const { data } = await apiWorkspace.get<ResponseResult>(
        `/workspaces/${workspace.id}/releases/${
          workspace.releaseSelected?.id
        }/series/raw/${
          workspace.ySelected?.y_label
        }?frequency=${frequency}&transformation=variation&inflation=${inflationAux}&data_type=${forecastType}&step=${
          releaseData?.data.steps?.length ?? 1
        }`,
      );

      return {
        ...data,
        serie: workspace.ySelected?.y_label,
        simulated: {
          dates: [],
          values: [],
        },
      };
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled:
        !!workspace.id &&
        !!workspace.releaseSelected?.id &&
        !!workspace.ySelected?.y_label &&
        !!workspace.ySelected?.model_id &&
        !!listOfVariables?.y,
    },
  );

  const {
    data: dataLevel,
    isLoading: isLoadingLevel,
    isFetching: isFetchingLevel,
    error: errorLevel,
  } = useQuery<ResponseResult, AxiosError<Error>>(
    [
      'workspace',
      'projections',
      'series data',
      workspace.id,
      workspace.releaseSelected?.id,
      workspace.ySelected?.y_label,
      forecastType,
      releaseData?.data.steps?.length ?? 1,
      frequency,
      'level',
      inflation,
    ],
    async () => {
      const inflationAux = inflation === 'nominal' ? 'inflate' : 'original';

      const { data } = await apiWorkspace.get<ResponseResult>(
        `/workspaces/${workspace.id}/releases/${
          workspace.releaseSelected?.id
        }/series/raw/${
          workspace.ySelected?.y_label
        }?frequency=${frequency}&transformation=level&inflation=${inflationAux}&data_type=${forecastType}&step=${
          releaseData?.data.steps?.length ?? 1
        }`,
      );

      return {
        ...data,
        serie: workspace.ySelected?.y_label,
        simulated: {
          dates: [],
          values: [],
        },
      };
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled:
        !!workspace.id &&
        !!workspace.releaseSelected?.id &&
        !!workspace.ySelected?.y_label &&
        !!workspace.ySelected?.model_id &&
        !!listOfVariables?.y,
    },
  );

  const isLoadingChart = useMemo(
    () =>
      isLoadingLevel ||
      isFetchingLevel ||
      isLoadingVariation ||
      isFetchingVariation ||
      isLoadingListOfVariables ||
      (!dataLevel && !dataVariation),
    [
      dataLevel,
      dataVariation,
      isLoadingLevel,
      isFetchingLevel,
      isLoadingListOfVariables,
      isLoadingVariation,
      isFetchingVariation,
    ],
  );

  useEffect(() => {
    function checkIfTransformationIsAvailable() {
      const availableTransformation: WorkspaceProjectionsTransformation[] = [
        ...transformations,
      ];
      if (!isLoadingChart) {
        if (!dataLevel && availableTransformation.includes('level')) {
          const index = availableTransformation.findIndex(
            (transformation) => transformation === 'level',
          );
          availableTransformation.splice(index, 1);
        }
        if (!dataVariation && availableTransformation.includes('variation')) {
          const index = availableTransformation.findIndex(
            (transformation) => transformation === 'variation',
          );
          availableTransformation.splice(index, 1);
        }

        if (availableTransformation.length !== transformations.length) {
          dispatch(
            changeWorkspaceProjectionsTransformations(availableTransformation),
          );
        }
      }
    }

    checkIfTransformationIsAvailable();
  }, [dataLevel, dataVariation, dispatch, isLoadingChart, transformations]);

  const adjustDateToXSelected = useCallback(
    (x: string | undefined): string => {
      let date = '';

      if (x) {
        if (frequency === 'quarterly') {
          const [year, quarterly] = x.split(' Q');
          const month = String(Number(quarterly) * 3).padStart(2, '0');
          date = `${year}-${month}-01`;
        } else if (frequency === 'yearly') {
          const year = x;
          date = `${year}-12-01`;
        } else {
          date = x;
        }
      }

      return date;
    },
    [frequency],
  );

  const isLatestDataDisabled = useMemo(() => {
    const total =
      (dataLevel?.historical.values.length ?? 0) +
      (dataLevel?.forecast.values.length ?? 0);

    if (frequency === 'original') {
      return (
        total <=
        frequencyLatestData[
          workspace.frequency as keyof typeof frequencyLatestData
        ]
      );
    }
    if (frequency === 'monthly') {
      return total <= frequencyLatestData.monthly;
    }
    if (frequency === 'quarterly') {
      return total <= frequencyLatestData.quarterly;
    }

    if (frequency === 'yearly') {
      return total <= frequencyLatestData.annual;
    }

    return true;
  }, [
    dataLevel?.forecast.values.length,
    dataLevel?.historical.values.length,
    frequency,
    workspace.frequency,
  ]);

  const error = useMemo(
    () => (!!errorLevel && !!errorVariation) || errorListOfVariables,
    [errorLevel, errorListOfVariables, errorVariation],
  );

  useEffect(() => {
    if ((!isLoadingChart && isLatestDataDisabled) || error) {
      dispatch(changeWorkspaceProjectionsIsLatestDataActive(false));
    }
  }, [dispatch, error, isLatestDataDisabled, isLoadingChart]);

  function returnMessageError() {
    let messageError = errorLevel?.response?.data?.detail?.detail;

    if (
      messageError ===
        'Annual series summary is only available for series with at least 1 year of observation.' ||
      messageError ===
        'Quarterly series summary is only available for series with at least 1 quarter of observation.' ||
      messageError ===
        'Monthly aggregation is not available for dataset with missing values.'
    ) {
      return messageError;
    }

    messageError =
      errorListOfVariables?.response?.data?.detail?.description ??
      errorLevel?.response?.data?.detail?.description ??
      errorVariation?.response?.data?.detail?.description;

    if (
      messageError === 'No data is available.' ||
      messageError === 'The requested resource does not exist.'
    ) {
      if (frequency === 'quarterly') {
        return 'Quarterly Series Rate is not available for this model';
      }
      if (frequency === 'yearly') {
        return 'Annual Series Rate is not available for this model';
      }
    }

    return messageError;
  }

  const { dataLevelAdjusted, dataVariationAdjusted } = useMemo(() => {
    const formatDates = (dates: string[]) =>
      dates.map((date) => {
        const dateAux = new Date(date.replace('Z', ''));

        if (frequency === 'quarterly') {
          return `${dateAux.getFullYear()} Q${
            Math.floor(dateAux.getMonth() / 3) + 1
          }`;
        }

        return format(
          new Date(date.replace('Z', '')),
          frequency === 'yearly' ? 'yyyy' : 'yyyy-MM-dd',
        );
      });

    let dataLevelAdjustedAux: Result = {
      historical: {
        date: [],
        value: [],
      },
      forecast: {
        date: [],
        value: [],
      },
      simulated: {
        date: [],
        value: [],
      },
    };

    let dataVariationAdjustedAux: Result = {
      historical: {
        date: [],
        value: [],
      },
      forecast: {
        date: [],
        value: [],
      },
      simulated: {
        date: [],
        value: [],
      },
    };

    const dataLevelFormatted = {
      historical: {
        date: formatDates(dataLevel?.historical.dates ?? []),
        value: dataLevel?.historical.values ?? [],
      },
      forecast: {
        date: formatDates(dataLevel?.forecast.dates ?? []),
        value: dataLevel?.forecast.values ?? [],
      },
      simulated: {
        date: [],
        value: [],
      },
    };

    const dataVariationFormatted = {
      historical: {
        date: formatDates(dataVariation?.historical.dates ?? []),
        value: dataVariation?.historical.values ?? [],
      },
      forecast: {
        date: formatDates(dataVariation?.forecast.dates ?? []),
        value: dataVariation?.forecast.values ?? [],
      },
      simulated: {
        date: [],
        value: [],
      },
    };

    const { forecast, historical } = dataVariationFormatted;

    if (forecast.date.length && forecast.value.length) {
      if (historical.date.length && historical.date.length) {
        forecast.date = [
          historical.date[historical.date.length - 1],
          ...forecast.date,
        ];

        forecast.value = [
          historical.value[historical.value.length - 1],
          ...forecast.value,
        ];
      }
    }

    if (!isLatestDataActive) {
      if (dataLevel) {
        dataLevelAdjustedAux = dataLevelFormatted;
      }
      if (dataVariation) {
        dataVariationAdjustedAux = dataVariationFormatted;
      }
    } else if (frequency === 'original') {
      switch (workspace.frequency) {
        case 'daily':
          dataLevelAdjustedAux = getLatestData(dataLevelFormatted, 'daily');
          dataVariationAdjustedAux = getLatestData(
            dataVariationFormatted,
            'daily',
            true,
            dataLevelAdjustedAux.historical.date[0],
          );
          break;
        case 'weekly':
          dataLevelAdjustedAux = getLatestData(dataLevelFormatted, 'weekly');
          dataVariationAdjustedAux = getLatestData(
            dataVariationFormatted,
            'weekly',
            true,
            dataLevelAdjustedAux.historical.date[0],
          );
          break;
        case 'fortnightly':
          dataLevelAdjustedAux = getLatestData(
            dataLevelFormatted,
            'fortnightly',
          );
          dataVariationAdjustedAux = getLatestData(
            dataVariationFormatted,
            'fortnightly',
            true,
            dataLevelAdjustedAux.historical.date[0],
          );
          break;
        case 'monthly':
          dataLevelAdjustedAux = getLatestData(dataLevelFormatted, 'monthly');
          dataVariationAdjustedAux = getLatestData(
            dataVariationFormatted,
            'monthly',
            true,
            dataLevelAdjustedAux.historical.date[0],
          );
          break;
        case 'bimonthly':
          dataLevelAdjustedAux = getLatestData(dataLevelFormatted, 'bimonthly');
          dataVariationAdjustedAux = getLatestData(
            dataVariationFormatted,
            'bimonthly',
            true,
            dataLevelAdjustedAux.historical.date[0],
          );
          break;
        case 'quarterly':
          dataLevelAdjustedAux = getLatestData(dataLevelFormatted, 'quarterly');
          dataVariationAdjustedAux = getLatestData(
            dataVariationFormatted,
            'quarterly',
            true,
            dataLevelAdjustedAux.historical.date[0],
          );
          break;
        case 'half-year':
          dataLevelAdjustedAux = getLatestData(dataLevelFormatted, 'half-year');
          dataVariationAdjustedAux = getLatestData(
            dataVariationFormatted,
            'half-year',
            true,
            dataLevelAdjustedAux.historical.date[0],
          );
          break;
        case 'annual':
          dataLevelAdjustedAux = getLatestData(dataLevelFormatted, 'annual');
          dataVariationAdjustedAux = getLatestData(
            dataVariationFormatted,
            'annual',
            true,
            dataLevelAdjustedAux.historical.date[0],
          );
          break;
        default:
          if (dataLevel) {
            dataLevelAdjustedAux = dataLevelFormatted;
          }
          if (dataVariation) {
            dataVariationAdjustedAux = dataVariationFormatted;
          }
          break;
      }
    } else if (frequency === 'monthly') {
      dataLevelAdjustedAux = getLatestData(dataLevelFormatted, 'monthly');
      dataVariationAdjustedAux = getLatestData(
        dataVariationFormatted,
        'monthly',
        true,
        dataLevelAdjustedAux.historical.date[0],
      );
    } else if (frequency === 'quarterly') {
      dataLevelAdjustedAux = getLatestData(dataLevelFormatted, 'quarterly');
      dataVariationAdjustedAux = getLatestData(
        dataVariationFormatted,
        'quarterly',
        true,
        dataLevelAdjustedAux.historical.date[0],
      );
    } else {
      dataLevelAdjustedAux = getLatestData(dataLevelFormatted, 'annual');
      dataVariationAdjustedAux = getLatestData(
        dataVariationFormatted,
        'annual',
        true,
        dataLevelAdjustedAux.historical.date[0],
      );
    }

    return {
      dataLevelAdjusted: dataLevelAdjustedAux,
      dataVariationAdjusted: dataVariationAdjustedAux,
    };
  }, [
    dataLevel,
    dataVariation,
    frequency,
    isLatestDataActive,
    workspace.frequency,
  ]);

  const selectX = useCallback(
    (x: string) => {
      if (
        isYFrequencyValid &&
        (dataLevelAdjusted?.historical.date.some((date) => date === x) ||
          dataLevelAdjusted?.forecast.date.some((date) => date === x))
      ) {
        setXSelected({ x, date: adjustDateToXSelected(x) });
      }
    },
    [adjustDateToXSelected, dataLevelAdjusted, isYFrequencyValid],
  );

  useEffect(() => {
    if (frequency !== frequencyAux) {
      setFrequencyAux(frequency);
    }
  }, [frequency, frequencyAux]);

  useEffect(() => {
    function setDefaultXAndYSelected() {
      if (dataLevelAdjusted) {
        const lastIndex = dataLevelAdjusted.historical.date.length - 1;

        const x = dataLevelAdjusted.historical.date[lastIndex] as string;

        setXSelected({
          x,
          date: adjustDateToXSelected(x),
        });
      }
    }

    if (isYFrequencyValid && xSelected?.x === undefined) {
      setDefaultXAndYSelected();
    }

    if (frequency !== frequencyAux) {
      setDefaultXAndYSelected();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    adjustDateToXSelected,
    dataLevelAdjusted,
    isYFrequencyValid,
    frequency,
    frequencyAux,
  ]);

  const canSelectForecast = useMemo(() => true, []);

  useEffect(() => {
    const dataLevelQtty =
      (dataLevel?.historical.dates.length ?? 0) +
      (dataLevel?.forecast?.dates.length ?? 0);

    const dataVariationQtty =
      (dataVariation?.historical.dates.length ?? 0) +
      (dataVariation?.forecast?.dates.length ?? 0);

    const transfEnabled: WorkspaceProjectionsTransformation[] = [];

    if (dataLevelQtty) {
      transfEnabled.push('level');
    }

    if (dataVariationQtty) {
      transfEnabled.push('variation');
    }

    dispatch(changeWorkspaceProjectionsTransformationsEnabled(transfEnabled));
  }, [dataLevel, dataVariation, dispatch]);

  useEffect(() => {
    dispatch(
      changeWorkspaceProjectionsLatestDataEnabled(
        !isLatestDataDisabled && !error && !isLoadingChart,
      ),
    );
  }, [isLatestDataDisabled, error, isLoadingChart, dispatch]);

  useEffect(() => {
    if ((isLoadingLevel || isLoadingListOfVariables) && !loadingLevelData) {
      dispatch(changeWorkspaceProjectionsLoadingLevelData(true));
    } else if (
      !isLoadingLevel &&
      !isLoadingListOfVariables &&
      loadingLevelData
    ) {
      dispatch(changeWorkspaceProjectionsLoadingLevelData(false));
    }
  }, [isLoadingLevel, loadingLevelData, isLoadingListOfVariables, dispatch]);

  useEffect(() => {
    if (
      (isLoadingVariation || isLoadingListOfVariables) &&
      !loadingVariationData
    ) {
      dispatch(changeWorkspaceProjectionsLoadingVariationData(true));
    } else if (
      !isLoadingVariation &&
      !isLoadingListOfVariables &&
      loadingVariationData
    ) {
      dispatch(changeWorkspaceProjectionsLoadingVariationData(false));
    }
  }, [
    isLoadingVariation,
    loadingVariationData,
    isLoadingListOfVariables,
    dispatch,
  ]);

  const selectedFilterInflation =
    user.language === 'pt-br'
      ? `${translate('value')} ${inflation}`
      : `${transformUppercaseFirstLetter(inflation)} ${translate(
          'value',
        ).toLowerCase()}`;

  const selectedFilters: SelectedFilterOptions[] = [
    { type: 'frequency', selected: translate(frequency) },
  ];

  const hasForecastLabel =
    workspace.frequency === 'monthly' &&
    workspace.releaseSelected?.id !== workspace.releasePreview;

  hasForecastLabel &&
    selectedFilters.push({
      type: 'other',
      id: 'forecast',
      icon: <PencilSimple />,
      selected: translate(
        forecastType === 'adjusted'
          ? 'workspaceProjectionsMostRecent'
          : forecastType,
      ),
    });

  const hasInflationLabel = workspace.releaseSelected?.data.ys.some(
    (y) => y.is_inflated,
  );

  hasInflationLabel &&
    selectedFilters.push({
      type: 'inflation',
      selected: selectedFilterInflation,
    });

  if (isLatestDataActive) {
    selectedFilters.push({
      type: 'latest-data',
      selected: translate('latestData'),
    });
  }

  return (
    <AISelectionResultsContext.Provider
      value={{
        isYFrequencyValid,
        xSelected,
        isLoadingChart,
        dataLevel: dataLevelAdjusted,
        dataVariation: dataVariationAdjusted,
        isLatestDataDisabled,
        canSelectForecast,
        selectX,
      }}
    >
      <ResultContainer className="containerLinear">
        <Card
          textCard={translate('workspaceProjectionsResultTitle')}
          textDescription={translate('workspaceProjectionsResultDescription')}
        />

        <SelectedFilters
          filters={
            forecastType === 'adjusted' && workspace.frequency !== 'monthly'
              ? selectedFilters.filter((filter) => filter.id !== 'forecast')
              : selectedFilters
          }
          data-testid="selected-filters-results"
        />

        {error ? (
          <ContainerMaintenance
            content="chart"
            text={translateSomeMessagesFromBackend(
              returnMessageError() ?? '',
              user.language,
            )}
            size="sm"
          />
        ) : (
          <>
            {frequency === chartFrequency &&
            isLatestDataActive === chartIsLatestDataActive ? (
              <ResultsChart chartFrequency={chartFrequency} />
            ) : (
              <ContainerSkeleton data-testid="result-loading" />
            )}

            <ResultsUnderstanding />
          </>
        )}
      </ResultContainer>
    </AISelectionResultsContext.Provider>
  );
};
