import React from 'react';

import DatePicker from 'react-datepicker';
import { Calendar } from 'phosphor-react';
import { Frequency } from 'src/models/redux/reducers/Project';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import ptBR from 'date-fns/locale/pt-BR';
import enUS from 'date-fns/locale/en-US';

import { Input } from '../Input';
import { Container } from './styles';

export type DateRange = [Date | null, Date | null];

type Props = {
  dateRange: DateRange;
  label?: string;
  onChange: (value: DateRange) => void;
  frequency: Frequency | 'yearly';
  inputTestId: string;
  isDisabled?: boolean;
  rangeFilter?: [Date, Date];
};

export const RangeDatePicker: React.FC<Props> = ({
  dateRange,
  label,
  onChange,
  frequency,
  inputTestId,
  isDisabled = false,
  rangeFilter,
  ...rest
}) => {
  const { language } = useSelector((state: RootState) => state.auth.user);

  function handleDatePicker(valueAux: DateRange) {
    onChange(valueAux);
  }

  function handleValue(e: React.FocusEvent<HTMLInputElement, Element>) {
    e.preventDefault();
  }

  function onCalendarClose() {
    if (!dateRange[1]) {
      onChange([dateRange[0], dateRange[0]]);
    }
  }

  const pickerLanguage = language === 'pt-br' ? ptBR : enUS;

  const [startDate, endDate] = dateRange;

  const yearPicker = frequency === 'annual' || frequency === 'yearly';

  const dailyPicker = frequency === 'daily' || frequency === 'weekly';

  const monthPicker = !yearPicker && !dailyPicker;

  const dateFormat = monthPicker
    ? 'MM/yyyy'
    : yearPicker
    ? 'yyyy'
    : language === 'en-us'
    ? 'MM/dd/yyyy'
    : 'dd/MM/yyyy';

  return (
    <Container data-testid={`datepicker-${inputTestId}`}>
      <DatePicker
        selectsRange
        startDate={startDate || null}
        endDate={endDate || null}
        onChange={handleDatePicker}
        dateFormat={dateFormat}
        showMonthYearPicker={monthPicker}
        showYearPicker={yearPicker}
        disabledKeyboardNavigation
        customInput={
          <Input
            icon={<Calendar size={32} />}
            invertedIcon
            label={label}
            testid={inputTestId}
            style={{ width: '16rem' }}
            disabled={isDisabled}
          />
        }
        onChangeRaw={handleValue}
        className="customRangePicker"
        formatWeekDay={(day) => day[0].toUpperCase()}
        locale={pickerLanguage}
        showPopperArrow={false}
        data-testid="range-date-picker"
        onCalendarClose={onCalendarClose}
        showFourColumnMonthYearPicker
        disabled={isDisabled}
        minDate={rangeFilter ? rangeFilter[0] : undefined}
        maxDate={rangeFilter ? rangeFilter[1] : undefined}
        {...rest}
      />
    </Container>
  );
};
