import React from 'react';

import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/Button';
import { Modal } from 'src/components/Modal';
import { ModalFooter } from 'src/components/Modal/Footer/styles';
import { Status } from 'src/components/Status';

import { VariableTypeWarningModalProps } from './types';

export const VariableTypeWarningModal: React.FC<
  VariableTypeWarningModalProps
> = ({ setVisible }) => {
  const { t: translate } = useTranslation();

  return (
    <Modal
      visible
      setVisible={setVisible}
      dataCy="modal-variable-type-warning"
      style={{ width: '33rem' }}
    >
      <Status
        type="warning"
        isModal
        title={translate('variableTypeWarningTitle')}
        description={translate('variableTypeWarningDescription')}
      />

      <ModalFooter>
        <Button
          buttonType="primary"
          onClick={() => setVisible(false)}
          data-testid="button-ok"
        >
          Ok
        </Button>
      </ModalFooter>
    </Modal>
  );
};
