import React, {
  useCallback,
  useState,
  useEffect,
  useContext,
  useMemo,
} from 'react';

import { Td } from 'src/components/Table';
import { Select } from 'src/components/Select';
import { useFormatDateLanguage } from 'src/hooks/useFormatDateLanguage';
import { CaretDown, CaretUp, Warning, X } from 'phosphor-react';
import {
  ModelUpdateData,
  useModelUpdate,
} from 'src/models/pages/Workspace/hooks/useModelUpdate';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { queryClient } from 'src/service/queryClient';
import api from 'src/models/service/api';
import apiWorkspace from 'src/models/service/apiWorkspace';
import ms from 'ms';
import light from 'src/styles/themes/light';
import { CheckBox } from 'src/components/CheckBox';
import { useTranslation } from 'react-i18next';
import { WorkspaceConfigContext } from 'src/models/contexts/WorkspaceConfigContext';

import { ProjectProps } from '../../Modal/SelectProjects/types';
import { VariablesTableRow } from '../Row';
import {
  CheckBoxInflate,
  ModelUpdateContainer,
  WarningIcon,
} from '../Row/styles';
import { ModelUpdateOptionLabel } from '../../ModelUpdateOptionLabel';
import {
  ButtonIcon,
  ButtonSelectAllVariable,
  ContentProjectName,
  TrContent,
} from './styles';
import { ProjectModelIdMenu } from '../../ProjectModelIdMenu';
import {
  GetValueKey,
  SetValue,
  YsDisabled,
  YsError,
  YsInflate,
  YsModelId,
  YsModelUpdate,
  YsOriginalName,
  YsSelected,
} from '../../Step2/types';

type Frequency =
  | 'daily'
  | 'weekly'
  | 'fortnightly'
  | 'monthly'
  | 'bimonthly'
  | 'quarterly'
  | 'half-year'
  | 'annual'
  | 'yearly';

type Y = {
  id: string;
  name: string;
  label: string;
  status: string;
  info?: {
    frequency: Frequency;
  };
};

type Props = {
  project: ProjectProps;
  showOriginalYName: boolean;
  getValue: (key: GetValueKey) => SetValue[GetValueKey];
  setValue: (key: GetValueKey, value: SetValue[GetValueKey]) => void;
  validateNewLabel: (
    projectId: string,
    variableId: string,
    newLabel: string,
  ) => void;
};

export const ProjectRow: React.FC<Props> = ({
  project,
  showOriginalYName,
  getValue,
  setValue,
  validateNewLabel,
}) => {
  const [seeAllSeries, setSeeAllSeries] = useState(false);
  const [modelUpdatesNotHaveY, setModelUpdatesNotHaveY] = useState<{
    [key: string]: string[];
  }>({});

  const { isEdition, frequency, workspaceId } = useContext(
    WorkspaceConfigContext,
  );

  const { modelUpdateData, modelUpdateIsLoading, modelUpdateIsFetching } =
    useModelUpdate(project, workspaceId, isEdition);

  const { t: translate } = useTranslation();

  const translateFormat = useFormatDateLanguage();

  const ysKey = getValue('ysKey') as string[];
  const ysSelected = getValue('ysSelected') as YsSelected;
  const ysModelUpdate = getValue('ysModelUpdate') as YsModelUpdate;
  const ysModelId = getValue('ysModelId') as YsModelId;
  const ysInflate = getValue('ysInflate') as YsInflate;
  const ysOriginalName = getValue('ysOriginalName') as YsOriginalName;
  const ysError = getValue('ysError') as YsError;
  const ysDisabled = getValue('ysDisabled') as YsDisabled;

  const projectYs = useMemo(
    () => ysKey.filter((key) => key.startsWith(project.id)),
    [ysKey, project.id],
  );

  const projectYsEnabled = useMemo(
    () => projectYs.filter((key) => !ysDisabled[key]),
    [projectYs, ysDisabled],
  );

  const projectYsSelected = useMemo(
    () => projectYs.filter((key) => ysSelected[key]),
    [projectYs, ysSelected],
  );

  const projectError = useMemo(
    () => ysError[projectYsSelected.find((key) => ysError[key]) ?? ''],
    [projectYsSelected, ysError],
  );

  useEffect(() => {
    const lastTr = Array.from(
      document.querySelectorAll(
        '#workspace-table-container > table > tbody > tr',
      ),
    )
      .reverse()
      .find((tr) => getComputedStyle(tr).display !== 'none');

    const workspaceContainerHeight =
      document.getElementById('workspace-table-container')?.clientHeight ?? 0;

    const tableHeight =
      document.querySelector('#workspace-table-container > table')
        ?.clientHeight ?? 0;

    if (tableHeight >= workspaceContainerHeight) {
      const lastTableCells = lastTr?.childNodes ?? [];

      lastTableCells.forEach((td) => {
        //@ts-expect-error:ignora
        td.style.borderBottom = 'unset';
      });
    }

    return () => {
      document
        .querySelectorAll('#workspace-table-container > table > tbody > tr')
        .forEach((tr) =>
          tr.childNodes.forEach((td) => {
            //@ts-expect-error:ignora
            td.style.borderBottom = `1px solid ${light.colors.gray2}`;
          }),
        );
    };
  }, [seeAllSeries, projectYs]);

  function handleSeeSerie() {
    setSeeAllSeries((state) => !state);
  }

  const getYModelUpdated = useCallback(
    async (updateId: string, yId: string, yName: string) => {
      const requestApi = isEdition ? apiWorkspace : api;

      const key = ['projects', updateId];

      let ys: Y[] = [];

      const contentQuery = queryClient.getQueryData<Y[]>(key);

      if (contentQuery) {
        ys = contentQuery;
      } else {
        try {
          ys = await queryClient.fetchQuery<Y[]>(
            key,
            async () => {
              const response = await requestApi.get(
                `${
                  isEdition ? `/workspaces/${workspaceId}/users` : ''
                }/projects/${updateId}`,
              );

              return response.data.ys;
            },
            {
              staleTime: ms('1 day'),
            },
          );
        } catch {
          ys = [];
        }
      }

      const yAux =
        ys.find((yAux1) => {
          if (project.id === updateId) {
            /// esta pegando do projeto original
            return yAux1.id === yId;
          }

          if (yAux1.id === yId) return true; /// esta pegando de um model update (q id do model update muda removendo o nome forecast) mas caso nao mude compare com id senao com name

          return yAux1.name === yName && yId.includes(yAux1.id);
        }) || null;

      if (!yAux) {
        setModelUpdatesNotHaveY((state) => ({
          ...state,
          [yId]: [...(state?.[yId] ?? []), updateId],
        }));
      }

      return yAux;
    },
    [project.id, isEdition, workspaceId],
  );

  const modelUpdateKeys = projectYsSelected.filter(
    (key) => ysModelUpdate[key] && !ysModelUpdate[key].isDisabled,
  );

  const defaultModelUpdate =
    modelUpdateKeys.length &&
    modelUpdateKeys.every(
      (key) =>
        ysModelUpdate[key].value === ysModelUpdate[modelUpdateKeys[0]].value,
    )
      ? ysModelUpdate[modelUpdateKeys[0]]
      : undefined;

  const modelIdsWithSuccess = projectYsSelected.filter(
    (key) => !ysModelId[key].error,
  );

  const defaultModelIds =
    modelIdsWithSuccess.length &&
    modelIdsWithSuccess.every(
      (key) =>
        ysModelId[key].model_id === ysModelId[projectYsSelected[0]].model_id,
    )
      ? ysModelId[projectYsSelected[0]]
      : undefined;

  const defaultInflate =
    projectYsSelected.length &&
    projectYsSelected.every(
      (key) => ysInflate[key] === ysInflate[projectYsSelected[0]],
    )
      ? ysInflate[projectYsSelected[0]]
      : undefined;

  async function handleSelectModelUpdate(modelUpdateSelected: ModelUpdateData) {
    const updatedYsModelUpdate = { ...ysModelUpdate };

    for (let i = 0; i < modelUpdateKeys.length; i++) {
      const yId = modelUpdateKeys[i].replace(`${project.id}-`, '');

      if (
        ysModelUpdate[modelUpdateKeys[i]].value !== modelUpdateSelected.value
      ) {
        const yAux = await getYModelUpdated(
          modelUpdateSelected.value,
          yId,
          ysOriginalName[modelUpdateKeys[i]],
        );

        if (yAux) {
          updatedYsModelUpdate[modelUpdateKeys[i]] = {
            ...modelUpdateSelected,
            y: yAux.id,
          };
        }
      }
    }

    setValue('ysModelUpdate', updatedYsModelUpdate);
  }

  function handleSelectModelId(modelIdAux: 'ai-selection' | 'user-selection') {
    const updatedYsModelId = { ...ysModelId };

    projectYsSelected.forEach((key) => {
      updatedYsModelId[key] = {
        model_id: modelIdAux,
        model_label: modelIdAux,
        error: false,
      };
    });

    setValue('ysModelId', updatedYsModelId);
  }

  function handleSelectInflateSeries(value: boolean) {
    const updatedYsInflate = { ...ysInflate };

    projectYsEnabled.forEach((key) => {
      updatedYsInflate[key] = value;
    });

    setValue('ysInflate', updatedYsInflate);
  }

  function handleSelectSeries(value: boolean) {
    const updatedYsSelected = { ...ysSelected };

    projectYsEnabled.forEach((key) => {
      updatedYsSelected[key] = value;
    });

    setValue('ysSelected', updatedYsSelected);
  }

  function isRepeatedVariableLabelOrNameError(err: string) {
    return err === 'createWorkspaceRepeatedVariable';
  }

  const isLoadedAllModelUpdate =
    projectYsSelected.filter((key) => ysModelUpdate[key]?.label).length >=
    projectYsSelected.length;

  const isLoadedAllModelIds =
    projectYsSelected.filter((key) => ysModelId[key]?.model_id).length >=
    projectYsSelected.length;

  const hasInflateYs = frequency === 'monthly';

  useEffect(() => {
    async function loadY(yKey: string): Promise<YsModelUpdate | null> {
      if (modelUpdateData) {
        let i = 0;

        while (i < modelUpdateData.length) {
          if (
            !modelUpdateData[i].isDisabled &&
            modelUpdateData[i]?.value &&
            (!ysModelUpdate[yKey]?.value ||
              ysModelUpdate[yKey]?.value === modelUpdateData[i].value)
          ) {
            const yId = yKey.replace(`${project.id}-`, '');

            const yAux = await getYModelUpdated(
              modelUpdateData[i].value,
              yId,
              ysOriginalName[yKey],
            );

            if (yAux) {
              return {
                [yKey]: {
                  ...modelUpdateData[i],
                  y: yAux.id,
                },
              };
            }
          }
          i++;
        }
      }

      return {
        [yKey]: {
          label: `Original\n${project.created}`,
          value: project.id,
          isDisabled: false,
          y: null,
        },
      };
    }

    const loadYsModelUpdate = async () => {
      const updatedModelUpdate = { ...ysModelUpdate };

      for (let i = 0; i < projectYs.length; i++) {
        const y = projectYs[i];

        if (!ysModelUpdate[y]?.label) {
          const yUpdate = await loadY(y);

          if (yUpdate) {
            updatedModelUpdate[y] = yUpdate[y];
          }
        }
      }

      setValue('ysModelUpdate', updatedModelUpdate);
    };

    if (!isLoadedAllModelUpdate && modelUpdateData) {
      loadYsModelUpdate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoadedAllModelUpdate,
    getYModelUpdated,
    modelUpdateData,
    project.id,
    projectYs,
    setValue,
  ]);

  return (
    <>
      <TrContent>
        <Td className="projectFirstTd">
          <ContentProjectName>
            <p data-testid={`project-name-project-${project.id}`}>
              {project.name}
            </p>
            {projectError &&
              (!isRepeatedVariableLabelOrNameError(projectError ?? '') ? (
                <WarningIcon
                  data-testid={`rename-variable-error-project-${project.id}`}
                  color={light.colors.red2}
                >
                  <X weight="bold" size="0.875rem" />
                </WarningIcon>
              ) : (
                <WarningIcon
                  data-tooltip-id="config-workspace-tooltip"
                  data-testid={`rename-variable-warning-project-${project.id}`}
                  color={light.colors.secondary}
                >
                  <Warning weight="fill" size="1rem" />
                </WarningIcon>
              ))}
            <ButtonIcon
              type="button"
              onClick={handleSeeSerie}
              data-testid={`button-expand-project-${project.id}`}
            >
              {seeAllSeries ? (
                <CaretUp weight="bold" size="1rem" />
              ) : (
                <CaretDown weight="bold" size="1rem" />
              )}
            </ButtonIcon>
          </ContentProjectName>
        </Td>
        <Td>
          <CheckBox
            onChange={({ target: { checked } }) => {
              handleSelectSeries(checked);
            }}
            checked={projectYsEnabled.length === projectYsSelected.length}
            disabled={!projectYsEnabled.length}
            data-testid={`checkbox-project-${project.id}`}
          />
        </Td>
        <Td className="projectWithoutPaddingLeft">
          <ButtonSelectAllVariable
            disabled={!projectYsEnabled.length}
            onClick={() =>
              handleSelectSeries(
                !(projectYsEnabled.length === projectYsSelected.length),
              )
            }
            data-testid={`button-select-all-project-${project.id}`}
          >
            {translate('createWorkspaceSelectAll')}
          </ButtonSelectAllVariable>
        </Td>
        {showOriginalYName && <Td />}
        <Td>
          <ModelUpdateContainer>
            {modelUpdateIsFetching ||
            modelUpdateIsLoading ||
            !modelUpdateData ||
            !isLoadedAllModelUpdate ? (
              <ContainerSkeleton
                withLoading={false}
                style={{ height: '2.75rem' }}
                data-testid={`model-update-loading-project-${project.id}`}
              />
            ) : (
              <Select
                options={modelUpdateData}
                value={defaultModelUpdate}
                onChange={(e) => {
                  if (e) {
                    //@ts-expect-error:ignora
                    handleSelectModelUpdate(e);
                  }
                }}
                controlShouldRenderValue={!!defaultModelUpdate}
                menuPlacement="auto"
                style={{ width: '9rem' }}
                dataTestid={`model-update-project-${project.id}`}
                formatOptionLabel={({ label }) =>
                  ModelUpdateOptionLabel({
                    label,
                    dateFormat: translateFormat,
                  })
                }
                placeholder={
                  modelUpdateKeys.length
                    ? translate('createWorkspaceOthers')
                    : undefined
                }
                disabled={!modelUpdateKeys.length}
                isDisabled={!modelUpdateKeys.length}
                className={`selectFontWeight500 ${
                  modelUpdateKeys.length ? 'placeholderOthers' : ''
                }
                ${!defaultModelUpdate ? 'clearSelected' : ''}
                `}
              />
            )}
          </ModelUpdateContainer>
        </Td>
        <Td>
          {!isLoadedAllModelIds ? (
            <ContainerSkeleton
              withLoading={false}
              style={{ height: '2.75rem' }}
              data-testid={`model-ids-menu-loading-project-${project.id}`}
            />
          ) : (
            <ProjectModelIdMenu
              onClick={handleSelectModelId}
              value={defaultModelIds?.model_id ?? null}
              isDisabled={!modelIdsWithSuccess.length}
              projectId={project.id}
            />
          )}
        </Td>
        {hasInflateYs && (
          <Td className="projectLastTd">
            <CheckBoxInflate
              label=""
              onChange={({ target }) =>
                handleSelectInflateSeries(target.checked)
              }
              checked={defaultInflate}
              disabled={!projectYsSelected.length}
              data-cy={`checkbox-inflate-serie-project-${project.id}`}
              data-testid={`checkbox-inflate-serie-project-${project.id}`}
            />
          </Td>
        )}
      </TrContent>
      {seeAllSeries &&
        projectYs.map((key) => (
          <VariablesTableRow
            key={`row-${key}`}
            project={project}
            yKey={key}
            isVisible={seeAllSeries}
            getValue={getValue}
            setValue={setValue}
            validateNewLabel={validateNewLabel}
            showOriginalYName={showOriginalYName}
            modelUpdatesNotHaveY={modelUpdatesNotHaveY}
            getYModelUpdated={getYModelUpdated}
            modelUpdateId={ysModelUpdate[key]?.value}
          />
        ))}
    </>
  );
};
