export const SelectProjectsWorkspacePTBRTranslations = {
  selectProjectsWorkspaceTitle: 'Selecione os projetos',
  selectProjectsWorkspaceDescription:
    'Clique para selecionar e confirme quando finalizar.',
  selectProjectWorkspaceIsDisabledDifferentFrequency:
    'Você só pode escolher projetos com a mesma frequência da série temporal daqueles já selecionados.',
  selectProjectWorkspaceIsDisabledDifferentFrequencyOfWorkspace:
    'Você só pode escolher projetos com a mesma frequência da série temporal do seu workspace.',
  selectProjectWorkspaceIsDisabledApprovalFlow:
    'Você habilitou o fluxo de planejamento, então apenas projetos com frequência mensal de séries temporais serão permitidos.',
};
