import React from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { SubscribeAlert } from 'src/feature-store/components/SubscribeToAlert';
import { RootState } from 'src/redux/store';

import { IndicatorForAnalyzeContainer, TitleIndicator } from './styles';
import { IndicatorForAnalyzeProps } from './types';

export const IndicatorForAnalyze: React.FC<IndicatorForAnalyzeProps> = ({
  title,
  id,
}) => {
  const { email, isFreemium } = useSelector(
    (state: RootState) => state.auth.user,
  );
  const navigate = useNavigate();
  const { id: idParam } = useParams();
  const { t: translate } = useTranslation();

  const canSeeButtonSubscribe =
    email?.includes('@4intelligence.ai') ||
    email?.includes('@4intelligence.com.br') ||
    email?.includes('@4casthub.com.br');

  const messageToolTip = isFreemium ? translate('resourcePaidMakeUpgrade') : '';

  return (
    <IndicatorForAnalyzeContainer
      isActive={idParam === id}
      data-testid="container-indicator-for-analyze"
      onClick={(event) => {
        navigate(`/feature-store/favorites/${id}/data/summary`);
        event.stopPropagation();
      }}
      disabled={isFreemium}
      data-tooltip-id="freemium-message-paid-content"
      data-tooltip-html={messageToolTip}
    >
      <TitleIndicator
        data-testid={`text-indicator-for-analyze-${title}`
          .replaceAll(' ', '-')
          .toLocaleLowerCase()}
      >
        <span>{title}</span>
      </TitleIndicator>

      {!isFreemium && canSeeButtonSubscribe && (
        <SubscribeAlert id={id} email={email} />
      )}

      <Tooltip
        id="freemium-message-paid-content"
        className="customTooltipTheme"
      />
    </IndicatorForAnalyzeContainer>
  );
};
